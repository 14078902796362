import { useEffect } from "react"
import { Body, Link } from "@ninjaone/components"
import qs from "qs"
import { isNil, pluck } from "ramda"
import { fetchJson, getOverviewLink, isNilOrEmpty, reportErrorAndShowMessage } from "js/includes/common/utils"
import { Box, StyledSpan } from "js/includes/components/Styled"
import DeviceIcon from "js/includes/components/DeviceIcon"
import { useMountedState } from "js/includes/common/hooks"
import Loading from "js/includes/components/Loading"
import { spacing } from "@ninjaone/tokens"

export default function MultipleDevicesValueRenderer(field, { id, value } = {}, renderTextOnly) {
  const [loading, setLoading] = useMountedState(false)
  const [devices, setDevices] = useMountedState([])

  useEffect(() => {
    const getInitialSelection = async () => {
      try {
        setLoading(true)

        const _qs = qs.stringify(
          {
            pageSize: value?.entityIds?.length,
            nodeId: value?.entityIds,
          },
          { indices: false },
        )

        const devices = await fetchJson(`${"/query/devices"}?${_qs}`)

        setDevices(devices)
      } catch (error) {
        reportErrorAndShowMessage(error)
      } finally {
        setLoading(false)
      }
    }

    if (!isNilOrEmpty(value?.entityIds)) {
      getInitialSelection()
    }
  }, [value?.entityIds, setLoading, setDevices])

  return loading ? (
    <Loading />
  ) : isNil(value) || isNilOrEmpty(devices) ? null : renderTextOnly ? (
    <Body>{pluck("name", devices).join(", ")}</Body>
  ) : (
    <Box display="flex" flexWrap="wrap">
      {devices?.map((device, index) => {
        const { name, nodeClass, organizationName } = device ?? {}

        return (
          <Box key={`${device?.id}-device`} marginRight={spacing[1]}>
            <Link href={device ? getOverviewLink(device) : ""}>
              <Body color="inherit">
                <DeviceIcon {...{ nodeClass }} />
                <StyledSpan marginLeft={spacing[1]} marginRight={spacing[1]}>
                  {name}
                </StyledSpan>
                ({organizationName}){index < devices.length - 1 && <>, </>}
              </Body>
            </Link>
          </Box>
        )
      })}
    </Box>
  )
}
