import { useEffect } from "react"
import { Body, Link } from "@ninjaone/components"
import qs from "qs"
import { isNil, pluck } from "ramda"
import { fetchJson, isNilOrEmpty, reportErrorAndShowMessage } from "js/includes/common/utils"
import { Box, StyledSpan } from "js/includes/components/Styled"
import { useMountedState } from "js/includes/common/hooks"
import Loading from "js/includes/components/Loading"
import { spacing } from "@ninjaone/tokens"

export default function MultipleCustomerLocationValueRenderer(field, { id, value } = {}, renderTextOnly) {
  const [loading, setLoading] = useMountedState(false)
  const [locations, setLocations] = useMountedState([])

  useEffect(() => {
    const getInitialSelection = async () => {
      try {
        setLoading(true)

        const _qs = qs.stringify(
          {
            pageSize: value?.entityIds?.length,
            id: value?.entityIds,
          },
          { indices: false },
        )

        const locations = await fetchJson(`/query/locations?${_qs}`)

        setLocations(locations)
      } catch (error) {
        reportErrorAndShowMessage(error)
      } finally {
        setLoading(false)
      }
    }

    if (!isNilOrEmpty(value?.entityIds)) {
      getInitialSelection()
    }
  }, [value?.entityIds, setLoading, setLocations])

  return loading ? (
    <Loading />
  ) : isNil(value) || isNilOrEmpty(locations) ? null : renderTextOnly ? (
    <Body>{pluck("name", locations).join(", ")}</Body>
  ) : (
    <Box display="flex" flexWrap="wrap">
      {locations?.map((location, index) => {
        return (
          <Box key={`${location?.id}-location`} marginRight={spacing[1]}>
            <Link href={`#/customerDashboard/${location?.organizationId}/location/${location?.id}`}>
              <Body color="inherit">
                {location?.name}
                <StyledSpan marginLeft={spacing[1]}>({location?.organizationName})</StyledSpan>
                {index < locations.length - 1 && <>,</>}
              </Body>
            </Link>
          </Box>
        )
      })}
    </Box>
  )
}
