import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import { Modal } from "@ninjaone/components"
import PropTypes from "prop-types"
import { ArrowLeftIcon, ArrowRightIcon } from "@ninjaone/icons"

const WizardModal = ({
  unmount,
  closeAction,
  titleGroup,
  headingComponent,
  disabled,
  children,
  handleSubmit,
  handleNextStep,
  handleBackStep,
  stepsLength,
  activeHistoryIndex,
  invalidActiveStep,
  size = "sm",
}) => {
  return (
    <Modal
      {...{
        size,
        unmount,
        showCloseButton: false,
        showTitleSection: true,
        titleGroup,
        closeAction,
        headingComponent,
        buttons: [
          ...(activeHistoryIndex !== 0
            ? [
                {
                  type: "default",
                  variant: "secondary",
                  labelToken: localizationKey("Back"),
                  Icon: ArrowLeftIcon,
                  disabled,
                  onClick: handleBackStep,
                },
              ]
            : []),
          ...(activeHistoryIndex !== stepsLength - 1
            ? [
                {
                  type: "default",
                  variant: "primary",
                  labelToken: localizationKey("Next"),
                  disabled: disabled || invalidActiveStep,
                  iconPlacement: "right",
                  Icon: ArrowRightIcon,
                  onClick: handleNextStep,
                },
              ]
            : [
                {
                  type: "default",
                  variant: "primary",
                  labelToken: localizationKey("Create"),
                  disabled: disabled || invalidActiveStep,
                  onClick: handleSubmit,
                },
              ]),
        ],
      }}
    >
      {children}
    </Modal>
  )
}

WizardModal.propTypes = {
  unmount: PropTypes.func.isRequired,
  closeAction: PropTypes.func.isRequired,
  titleGroup: PropTypes.shape({
    titleText: PropTypes.string.isRequired,
    textWrap: PropTypes.bool,
  }).isRequired,
  headingComponent: PropTypes.node,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleNextStep: PropTypes.func.isRequired,
  handleBackStep: PropTypes.func.isRequired,
  stepsLength: PropTypes.number.isRequired,
  activeHistoryIndex: PropTypes.number.isRequired,
}

export default WizardModal
