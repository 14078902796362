import React from "react"
import { StyledFormControl } from "js/includes/components/Styled/Form"

export default function WYSIWYGFormFilter({ id, value, onChange, technicianPermission, validateForm }) {
  return (
    <StyledFormControl
      componentClass="textarea"
      maxLength={10000}
      width="100%"
      minHeight="122px"
      resize="vertical"
      value={value?.text ?? ""}
      onBlur={() => validateForm?.([id])}
      onChange={e => onChange({ [id]: { html: "", text: e.target.value, attachments: [] } || null })}
      disabled={technicianPermission !== "EDITABLE"}
    />
  )
}
