import React from "react"
import { nanoid } from "nanoid"
import { isNilOrEmpty } from "js/includes/common/utils"
import { Body } from "@ninjaone/components"

export default function TextMultilineValueRenderer(field, { value } = {}) {
  return isNilOrEmpty(value)
    ? null
    : value.split(/\r\n|\n/).map(line => (
        <Body type="body" as="p" key={nanoid(10)}>
          {line ? line : <br />}
        </Body>
      ))
}
