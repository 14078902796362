import { useEffect, useState } from "react"
import qs from "qs"
import { pluck } from "ramda"
import { fetchJson, reportErrorAndShowMessage } from "js/includes/common/utils"
import SearchableDropDown from "js/includes/components/SearchableDropDown"
import { useMountedState } from "js/includes/common/hooks"
import Loading from "js/includes/components/Loading"
import {
  handleFormFieldBlur,
  handleFormFieldChange,
  searchableDropdownCommonProps,
} from "js/includes/components/CustomFields/common"
import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"

export default function CustomerMultiSelectFormField({
  id,
  value,
  onChange,
  onBlur,
  isHookForm = false,
  validationState,
  dropdownContainerId,
  technicianPermission,
}) {
  const [initialValue] = useState(value)
  const [loading, setLoading] = useMountedState(false)
  const [clients, setClients] = useMountedState([])

  useEffect(() => {
    const getInitialSelection = async () => {
      try {
        setLoading(true)

        const _qs = qs.stringify(
          {
            pageSize: initialValue?.entityIds?.length,
            id: initialValue?.entityIds,
          },
          { indices: false },
        )

        const clients = await fetchJson(`/client?${_qs}`)

        setClients(clients)
      } catch (error) {
        reportErrorAndShowMessage(error)
      } finally {
        setLoading(false)
      }
    }

    if (initialValue?.entityIds) {
      getInitialSelection()
    }
  }, [initialValue, setLoading, setClients])

  return loading ? (
    <Loading />
  ) : (
    <SearchableDropDown
      {...{
        ...searchableDropdownCommonProps,
        dropdownContainerId,
        value: clients,
        searchableKey: "name",
        valueSelectorKey: "id",
        endpoint: "/query/organizations",
        width: "100%",
        isMulti: true,
        enableClear: true,
        keepInView: false,
        keepDropdownInView: true,
        excludeSelectedFromSelectableValues: true,
        disabled: technicianPermission !== "EDITABLE",
        noRowsRendererToken: localizationKey("No options available"),
        pageSize: 50,
        validationState,
        onSelect: selected => {
          setClients(selected)

          handleFormFieldChange({
            id,
            onChange,
            isHookForm,
          })(
            !!selected.length
              ? {
                  entityIds: pluck("id", selected),
                  type: "CLIENT",
                }
              : null,
          )
        },
        onBlur: handleFormFieldBlur({ onBlur, id, isHookForm }),
      }}
    />
  )
}
