import { useEffect } from "react"
import { useQuery } from "urql"

import { localizationKey, reportErrorAndShowMessage } from "js/includes/common/utils"
import { getTags } from "js/includes/ticketing/graphql"
import { useSorted } from "js/includes/common/hooks"

export const useTags = ({ shouldQuery }) => {
  const [{ error, data: tagsData, fetching: fetchingTags }] = useQuery({
    pause: !shouldQuery,
    query: getTags,
  })

  useEffect(() => {
    if (error) {
      reportErrorAndShowMessage(error, localizationKey("Error fetching tags"))
    }
  }, [error])

  const tags = useSorted(tagsData?.tags || [])
  return {
    tags,
    fetchingTags,
  }
}
