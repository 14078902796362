import { useRef, useState } from "react"
import pathParse from "path-parse"
import { includes } from "ramda"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle, faPaperclip, faTimes } from "@fortawesome/pro-solid-svg-icons"
import { localizationKey, localized, ninjaReportError, showErrorMessage } from "js/includes/common/utils"
import { Box, StyledSpan } from "js/includes/components/Styled"
import { HiddenInput, StyledButton } from "js/includes/components/Styled/Form"
import { getReadableBytes, toMB } from "js/includes/common/_conversions"
import { deleteTempAttachment, uploadTempAttachment } from "js/includes/common/client/customFields"
import ShowMessageDialog from "js/includes/components/MessageDialog"
import Loading from "js/includes/components/Loading"
import { EllipsisWithTooltip } from "js/includes/components/EllipsisWithTooltip"
import { handleFormFieldChange } from "js/includes/components/CustomFields/common"
import tokens from "@ninjaone/tokens"

function isValidAttachmentSize(fileSize, fileMaxSize) {
  if (fileSize > fileMaxSize) {
    showErrorMessage(
      localized("File size must be less than {{size}} MB", {
        size: fileMaxSize ?? 20,
      }),
    )
    return false
  }
  return true
}

function isValidFileExtension(extension, fileExtensions = []) {
  if (!includes(extension.toLowerCase(), fileExtensions)) {
    ShowMessageDialog({
      icon: { icon: faInfoCircle, type: "warning" },
      title: localizationKey("Invalid file type"),
      message: () =>
        `${localized(
          "You've attempted to attach an invalid file. Only the following file types are allowed:",
        )} ${fileExtensions}`,
      buttons: [{ id: "OK", label: localizationKey("OK") }],
    })
    return false
  }
  return true
}

export default function AttachmentFormField({
  id,
  data,
  value,
  onChange,
  isHookForm = false,
  entityType,
  initialValue,
  advancedSettings,
  technicianPermission,
}) {
  const inputRef = useRef(null)
  const [uploadProgress, setUploadProgress] = useState(null)
  const resourceEntity = data?.resourceEntity ?? entityType

  const handleChange = handleFormFieldChange({ onChange, id, isHookForm })

  const clearFile = async e => {
    e.preventDefault()
    try {
      handleChange(null)

      if (!initialValue) {
        deleteTempAttachment(resourceEntity, value.contentId)
      }
    } catch (error) {
      ninjaReportError(error)
    }
  }

  const addAttachment = e => {
    e.preventDefault()
    inputRef.current.click()
  }

  const onFileChange = async e => {
    const [file] = e.target.files

    if (!file) return

    try {
      const { name, ext } = pathParse(file?.name) || {}

      if (
        isValidFileExtension(ext, advancedSettings.fileExtensions) &&
        isValidAttachmentSize(toMB(file.size), advancedSettings?.fileMaxSize)
      ) {
        const response = await uploadTempAttachment(resourceEntity, file, progress => {
          setUploadProgress(Math.round(progress))
        })

        if (response?.resourceId) {
          handleChange({
            name,
            size: file?.size,
            mimeType: file?.type,
            contentId: response.resourceId,
            extension: ext.replace(".", "").toLowerCase(),
            uploadStatus: "PROCESSING",
          })
        }
      }
    } catch (error) {
      ninjaReportError(error)
    } finally {
      if (inputRef.current?.value) {
        inputRef.current.value = null
      }
      setUploadProgress(null)
    }
  }

  return (
    <Box width="100%" position="relative" display="flex" alignItems="center">
      <HiddenInput
        type="file"
        accept={advancedSettings.fileExtensions.join(",")}
        placeHolder={localized("Upload File")}
        onChange={onFileChange}
        disabled={technicianPermission !== "EDITABLE"}
        ref={inputRef}
      />

      {uploadProgress ? (
        <Box>
          <Loading /> {uploadProgress}%
        </Box>
      ) : (
        <Box>
          <StyledButton
            marginRight={2}
            color={value ? undefined : "ninjaDark"}
            disabled={technicianPermission !== "EDITABLE"}
            onClick={addAttachment}
          >
            <StyledSpan fontSize={tokens.typography.fontSize.headingS}>
              <FontAwesomeIcon icon={faPaperclip} />
            </StyledSpan>

            <StyledSpan
              display="inline-flex"
              maxWidth="450px"
              marginLeft={2}
              fontSize={tokens.typography.fontSize.body}
            >
              {value?.name && value?.name.length >= 90 ? (
                <EllipsisWithTooltip text={value.name} />
              ) : (
                value?.name ?? localized("Upload")
              )}
            </StyledSpan>

            {!!value?.size && <StyledSpan marginLeft={2}>{getReadableBytes(value?.size)}</StyledSpan>}
          </StyledButton>

          {value && (
            <StyledButton hoverTextColor="ninjaRed" disabled={technicianPermission !== "EDITABLE"} onClick={clearFile}>
              <FontAwesomeIcon icon={faTimes} />
            </StyledButton>
          )}
        </Box>
      )}
    </Box>
  )
}
