import { ControlLabel } from "react-bootstrap"
import tokens from "@ninjaone/tokens"
import { AngleDownIcon, RegularInfoCircleIconLight } from "@ninjaone/icons"
import { Button, Body, Tooltip } from "@ninjaone/components"

import { StyledCol } from "js/includes/components/Styled/Form"
import { localized } from "js/includes/common/utils"
import { Box, Flex } from "js/includes/components/Styled"

export default function FormGroupLabel({
  label,
  required,
  tooltipText,
  labelProps,
  expanded,
  setExpanded,
  type,
  technicianPermission,
}) {
  const isWYSIWYGField = type === "WYSIWYG"

  return (
    <StyledCol xs={4} componentClass={ControlLabel} {...labelProps}>
      <Flex alignItems="center" minWidth="400px" className="text-ellipsis">
        <Body tooltipText={label}>
          {label} {required && "*"}
        </Body>
        {tooltipText && (
          <Box marginLeft={tokens.spacing[1]}>
            <Tooltip label={tooltipText}>
              <RegularInfoCircleIconLight />
            </Tooltip>
          </Box>
        )}
      </Flex>
      {technicianPermission === "EDITABLE" && isWYSIWYGField && !expanded && (
        <Button
          offset={{
            left: tokens.spacing[1],
          }}
          Icon={AngleDownIcon}
          iconPlacement="right"
          variant="tertiary"
          onClick={() => setExpanded(true)}
          labelText={localized("Edit content")}
        />
      )}
    </StyledCol>
  )
}
