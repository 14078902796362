import React from "react"
import { FormControl } from "react-bootstrap"
import { isEmpty } from "ramda"

export default function PhoneFormFilter({ id, value, onChange }) {
  return (
    <FormControl
      type="text"
      value={value || ""}
      onInput={e => {
        return onChange({
          [id]: isEmpty(e.target.value) ? null : e.target.value.replace(/\D/g, ""),
        })
      }}
    />
  )
}
