import $ from "jquery"
import rivets from "rivets"
import moment from "moment"
import React, { lazy, Suspense, useEffect, useMemo, useRef, useState } from "react"
import styled from "@emotion/styled"
import { css, Global } from "@emotion/react"
import { useIdleTimer } from "react-idle-timer"
import { connect, useDispatch, useSelector } from "react-redux"
import { Redirect, Route, Switch, useLocation } from "react-router-dom"
import { compose, pluck, values } from "ramda"
import { SkipNav } from "@ninjaone/components"
import { spacing } from "@ninjaone/tokens"
import {
  debugLog,
  findRoute,
  getInactivityTimeout,
  isFeatureEnabled,
  isFullScreenMode,
  isMDMFeatureEnabled,
  isSuperAdmin,
  localized,
  logout,
  isUserAllowedToUseNinjaPSAAdministrativeActions,
  TitledRoute,
  useQueryParams,
  user,
  useNinjaPSAEnabledFromSettings,
} from "js/includes/common/utils"
import ErrorBoundary from "js/includes/components/ErrorBoundary"
import Announcement from "js/includes/components/Announcement"
import TopNavBar from "./TopNavBar"
import SideNavBar from "./SideNavBar"
import Footer from "./Footer"
import DeviceDashboard from "html/dashboards/device.html"
import NmsDashboard from "html/dashboards/nms.html"
import CustomerDashboard from "html/dashboards/customer.html"
import SystemDashboard from "html/dashboards/system.html"
import CloudMonitorDashboard from "html/dashboards/cloudMonitor.html"
import Indicator from "js/includes/components/Indicator"
import { Box } from "js/includes/components/Styled"
import { Provider as GraphQLProvider } from "urql"

import { focusOnDomElement } from "@ninjaone/utils"

import { graphqlTicketingClient } from "js/includes/common/client"
import showModal from "js/includes/common/services/showModal"
import { setupBackupMarketingPromo } from "js/includes/components/MarketingPromoPopup/promotions/backupMarketingPromo"
import { setupReferralMarketingPromo } from "js/includes/components/MarketingPromoPopup/promotions/referralMarketingPromo"
import UserInactivityModal from "js/includes/components/UserInactivityModal"
import AdministrationView from "js/includes/configuration/AdministrationView"
import { appInfo } from "js/includes/configuration/apps/appInfo"
import {
  administrationRoutes,
  agreementEditorRoutes,
  agreementTemplateEditorRoutes,
  deviceSearchRoutes,
  endUserEditorRoutes,
  endUserSearchRoutes,
  getStartedRoutes,
  globalSearchRoutes,
  invoiceEditorRoutes,
  mdmDashboardRoutes,
  ninjaPSAEditorRoutes,
  ninjaPSARoutes,
  quickConnectRoutes,
  reportingRoutes,
  roleEditorRoutes,
  systrayEditorRoutes,
  ticketingRoutes,
  unmanagedDeviceRoutes,
  userEditorRoutes,
  vmDashboardRoutes,
} from "js/includes/dashboards/siteMap"
import GetStarted from "js/includes/GetStartedPage/GetStarted"
import { TOP_BAR_HEIGHT } from "js/includes/application/constants"
import GlobalSearch from "js/includes/components/GlobalSearch/GlobalSearch"
import GlobalSearchPage from "js/includes/components/GlobalSearch/GlobalSearchPage"
import { buildAppInfoCustomData, getFlattenedAdministrationNavItems } from "js/includes/configuration/utils"
import { sidebarWidth, sidebarWidthCollapsed } from "js/includes/application/SideNavBar/common"
import { setIsSideBarCollapsed } from "js/state/actions/general/sideBar"

const Ticketing = lazy(() => import(/* webpackChunkName: "ticketing" */ "js/includes/ticketing"))
const NinjaPSA = lazy(() => import(/* webpackChunkName: "ninjaPSA" */ "js/includes/ninjaPSA"))
const SystrayEditor = lazy(() => import(/* webpackChunkName: "systrayEditor" */ "js/includes/editors/Systray"))
const Reporting = lazy(() => import(/* webpackChunkName: "reporting" */ "js/includes/configuration/reports"))
const EndUserEditor = lazy(() => import(/* webpackChunkName: "endUserEditor" */ "js/includes/editors/EndUsers/Editor"))
const UserEditor = lazy(() => import(/* webpackChunkName: "UserEditor" */ "js/includes/editors/User"))
const RoleEditor = lazy(() => import(/* webpackChunkName: "RoleEditor" */ "js/includes/editors/User/Role"))
const NinjaPsaQuickBooksEditor = lazy(() =>
  import(/* webpackChunkName: "RoleEditor" */ "js/includes/editors/NinjaPsa/Integrations/QuickBooksEditor"),
)
const AgreementEditor = lazy(() => import(/* webpackChunkName: "AgreementEditor" */ "js/includes/editors/Agreement"))
const AgreementTemplateEditor = lazy(() =>
  import(/* webpackChunkName: "AgreementTemplateEditor" */ "js/includes/editors/AgreementTemplates"),
)
const InvoiceEditor = lazy(() => import(/* webpackChunkName: "InvoiceEditor" */ "js/includes/editors/Invoice"))
const RemoteSupport = lazy(() => import(/* webpackChunkName: "remoteSupport" */ "js/includes/remoteSupport"))
const VMDashboard = lazy(() => import(/* webpackChunkName: "vMDashboard" */ "js/includes/vmDashboard/VMDashboard"))
const VMGuestDashboard = lazy(() =>
  import(/* webpackChunkName: "vMGuestDashboard" */ "js/includes/vmDashboard/VMGuestDashboard"),
)
const DeviceSearch = lazy(() => import(/* webpackChunkName: "DeviceSearch" */ "js/includes/deviceSearch"))
const EndUserSearch = lazy(() => import(/* webpackChunkName: "EndUserSearch" */ "js/includes/endUserSearch"))
const ClientAppEditorContainer = lazy(() =>
  import("js/includes/configuration/integrations/clientAppIDs/ClientAppEditorContainer"),
)
const QuickConnect = lazy(() => import(/* webpackChunkName: "quickConnect" */ "js/includes/quickConnect"))
const FIVE_MINUTES_IN_MILLISECONDS = 300000

const MobileDashboard = lazy(() =>
  import(/* webpackChunkName: "MobileDashboard" */ "js/includes/mobileDashboard/MobileDashboard"),
)

const UnmanagedDeviceDashboard = lazy(() =>
  import(/* webpackChunkName: "UnmanagedDeviceDashboard" */ "js/includes/unmanagedDevices/UnmanagedDeviceDashboard"),
)

// React Routes
export const routes = {
  clientApp: "/editor/client-app/:id?",

  ...userEditorRoutes,
  ...roleEditorRoutes,
  ...endUserEditorRoutes,
  ...ticketingRoutes,
  ...ninjaPSARoutes,
  ...ninjaPSAEditorRoutes,
  ...vmDashboardRoutes,
  ...deviceSearchRoutes,
  ...endUserSearchRoutes,
  ...mdmDashboardRoutes,
  ...quickConnectRoutes,
  ...reportingRoutes,
  ...administrationRoutes,
  ...systrayEditorRoutes,
  ...agreementEditorRoutes,
  ...agreementTemplateEditorRoutes,
  ...invoiceEditorRoutes,
  ...getStartedRoutes,
  ...globalSearchRoutes,
  ...unmanagedDeviceRoutes,
}

const integrationRoutes = compose(values, pluck("configRoute"))(appInfo)

const getRoutesThatUseNewSpacing = () => [
  routes.getStarted,
  routes.ticket,
  routes.ticketWithForm,
  routes.report,
  routes.reportLibrary,
  routes.reportSchedules,
  routes.reportHistory,
  routes.reportSettings,
  routes.globalSearch,
  routes.quickConnect,
  routes.quickConnectDevices,
  routes.quickConnectInvitations,
  routes.quickConnectActivities,
  ...integrationRoutes,
  ...Object.values(administrationRoutes),
  ...(isFeatureEnabled("dashboards_overview_v2")
    ? ["/systemDashboard/overview", "/customerDashboard/:id/overview", "/customerDashboard/:id/location/:locationId"]
    : []),
]

function checkIfReactRoute() {
  return findRoute(values(routes))
}

function shouldUseNewSpacingStandard() {
  return findRoute(getRoutesThatUseNewSpacing())
}

function createMarkup() {
  return {
    __html: `
    <div id="backbone-target">
      <div id="main-container" class="wrapper zero-min-height" rv-class-full-height="application:isFullScreenMode">
        <div rv-if="application:activeRoute | starts_with '#/deviceDashboard'" id="main" role="main" class="dashboard">
          ${DeviceDashboard}
        </div>
        <div rv-if="application:activeRoute | starts_with '#/nmsDashboard'" id="main" role="main" class="dashboard">
          ${NmsDashboard}
        </div>
        <div rv-if="application:activeRoute | starts_with '#/customerDashboard'" id="main" role="main" class="dashboard">
          ${CustomerDashboard}
        </div>
        <div rv-if="application:activeRoute | starts_with '#/systemDashboard'" class="dashboard">
          ${SystemDashboard}
        </div>
        <div rv-if="application:activeRoute | starts_with '#/cloudMonitorDashboard'" id="main" role="main" class="dashboard">
          ${CloudMonitorDashboard}
        </div>
      </div>
    </div>
  `,
  }
}
const StyledApplicationContainer = styled.div`
  &:not(.full-screen-mode) {
    #page-wrapper {
      max-width: calc(100vw - ${sidebarWidth}px);
      min-width: calc(1024px - ${sidebarWidth}px);
      &.sidebar-collapsed {
        max-width: calc(100vw - ${sidebarWidthCollapsed}px);
        min-width: calc(1024px - ${sidebarWidthCollapsed}px);
      }
    }
  }
`
const StyledTopBar = styled(Box)`
  display: flex;
  justify-content: space-between;
  height: ${TOP_BAR_HEIGHT}px;
  border-bottom: 1px solid ${({ theme }) => theme.colorBorderWeakest};
  margin-bottom: ${spacing[4]};

  #global-search {
    flex: 1;
    margin-right: ${spacing[4]};
  }
`

const StyledBackboneRouter = styled.div`
  flex: 1;
  display: ${({ isReactRoute }) => (isReactRoute ? "none" : "flex")};
  overflow: hidden;

  #backbone-target {
    flex: 1;
    display: flex;
  }

  #main-container {
    #widget-grid {
      padding: ${({ useNewSpacingStandard }) => (useNewSpacingStandard ? 0 : `${spacing[4]} ${spacing[6]}`)};
    }
  }
`
const StyledReactRouter = styled(Box)`
  flex: 1;
  display: ${({ isReactRoute }) => (isReactRoute ? "flex" : "none")};
  overflow: hidden;
`

const globalStyles = theme => css`
  body {
    background-color: ${theme.colorBackground};
  }

  .theme-bg-color {
    background-color: ${theme.colorThemeBackground};
  }

  .help-dropdown-container {
    position: relative;
  }

  #help-dropdown-alt-editor-container + .ninja-editor {
    z-index: auto;
  }

  // copy of styles from scrollbars.less but with emotion theming support
  * {
    /* Firefox */
    scrollbar-color: ${theme.colorForegroundScrollbar} transparent;

    /* Chrome, Edge, Safari */
    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${theme.colorForegroundScrollbar};
      border-radius: 5px;
      border: 2px solid transparent;
      background-clip: content-box;
    }

    ::-webkit-scrollbar-corner {
      background: transparent;
    }
  }
`

const mainStyles = css`
  display: flex;
  flex: 1;
  overflow-y: auto;
`

function Application({ announcement, isBranded, websiteBranding, appInfoCustomData }) {
  const dispatch = useDispatch()
  const location = useLocation()
  const [isReactRoute, setIsReactRoute] = useState()
  const [useNewSpacingStandard, setUseNewSpacingStandard] = useState()
  const [isCollapsedSidebar, setIsCollapsedSidebar] = useState(false)
  const query = useQueryParams()
  const searchValueFromQuery = query.get("searchValue")
  const searchFilterFromQuery = query.get("searchFilter")
  const isOnGlobalSearchPage = location.pathname === "/search"
  const isOnEditorPage = location.pathname.startsWith("/editor/")
  const administrationPages = useMemo(() => getFlattenedAdministrationNavItems({ appInfoCustomData }), [
    appInfoCustomData,
  ])

  const isRemoteSupportEnabled = isFeatureEnabled("remotesupport")
  const isQuickConnectEnabled = useSelector(state => state.quickConnect.configurations.enabled)

  const isNinjaPSAEnabledFromSettings = useNinjaPSAEnabledFromSettings()
  const isNinjaPSAEnabled = isNinjaPSAEnabledFromSettings && isUserAllowedToUseNinjaPSAAdministrativeActions()

  const userInactivityTimeout = getInactivityTimeout()

  const showInactivityModalOrLogout = () => {
    const currentIdleTime = new Date().getTime() - getLastActiveTime()
    if (currentIdleTime > userInactivityTimeout + FIVE_MINUTES_IN_MILLISECONDS) {
      return logout({ message: localized("application.youWereLoggedOutDueToInactivity") })
    }
    return showInactivityModal()
  }

  const showInactivityModal = () => {
    debugLog("total idle time", moment.duration({ MILLISECONDS: getTotalIdleTime() }).asMinutes())
    debugLog("last active time", moment(getLastActiveTime()).format("HH:mm:ss"))
    debugLog("show modal at", moment().format("HH:mm:ss"))
    showModal(<UserInactivityModal {...{ reset }} />)
  }

  const handleOnAction = () => {
    debugLog("user did something at", moment().format("HH:mm:ss"))
  }

  debugLog("userInactivityTimeout as minutes", moment.duration({ MILLISECONDS: userInactivityTimeout }).asMinutes())

  const { reset, start, getLastActiveTime, getTotalIdleTime } = useIdleTimer({
    ...(userInactivityTimeout && { timeout: userInactivityTimeout }),
    onIdle: showInactivityModalOrLogout,
    throttle: 5000,
    startManually: true,
    stopOnIdle: true,
    onAction: handleOnAction,
    events: [
      "load",
      "mousemove",
      "mousedown", // catches touchscreen presses
      "click", // catches touchpad clicks
      "scroll", // catches scrolling with arrow keys
      "keypress",
    ],
  })

  userInactivityTimeout && start()

  useEffect(() => {
    rivets.bind($("#backbone-target"), {
      controller: window.controller,
      application: window.application,
    })
    setupBackupMarketingPromo()
    setupReferralMarketingPromo()
  }, [])

  useEffect(() => {
    const reactRoute = checkIfReactRoute()
    setIsReactRoute(!!reactRoute)
    setUseNewSpacingStandard(shouldUseNewSpacingStandard())

    // This will destroy the current Backbone activeTab (model), if any, when the current route is a React one.
    // It will avoid unnecessary calls to endpoints in the background.
    reactRoute && window.controller?.clear()
  }, [location])

  const skipNavTopNavigation = {
    id: "top-navigation",
    ref: useRef(),
    label: localized("Top Navigation"),
    iconName: "FloatLeftIconSvg",
  }

  const skipNavLeftNavigation = {
    id: "application-sidebar-wrapper",
    ref: useRef(),
    label: localized("Left Navigation"),
    iconName: "FloatTopIconSvg",
  }

  const skipNavMainContent = {
    id: "main-content",
    ref: useRef(),
    label: localized("Main Content"),
    iconName: "ObjectsColumnIcon",
  }

  useEffect(() => {
    const onRouteChange = event => {
      const isEditorWithSkipNav =
        event.newURL.includes("#/editor/androidpolicy") ||
        event.newURL.includes("#/editor/iospolicy") ||
        event.newURL.includes("#/editor/vmpolicy")
      const contentEle = skipNavMainContent.ref.current

      if (!isEditorWithSkipNav) {
        focusOnDomElement(contentEle)
      }
    }

    window.addEventListener("hashchange", onRouteChange)
    return () => window.removeEventListener("hashchange", onRouteChange)
  }, [skipNavMainContent.ref])

  useEffect(() => {
    dispatch(setIsSideBarCollapsed(isCollapsedSidebar))
  }, [dispatch, isCollapsedSidebar])

  return (
    <StyledApplicationContainer
      id="wrapper"
      className={`${isFullScreenMode() ? "full-screen-mode" : ""} ${isSuperAdmin() ? "super-admin" : ""}`}
    >
      <Global styles={globalStyles} />
      <div id="application" className="help-dropdown-container">
        <SkipNav items={[skipNavTopNavigation, skipNavLeftNavigation, skipNavMainContent]} />
        <div ref={skipNavLeftNavigation.ref} id={skipNavLeftNavigation.id}>
          <ErrorBoundary>
            <SideNavBar {...{ isCollapsedSidebar, setIsCollapsedSidebar }} />
          </ErrorBoundary>
        </div>

        <div id="page-wrapper" className={isCollapsedSidebar ? "sidebar-collapsed" : ""}>
          <div id="application-announcement">
            <ErrorBoundary>
              {announcement && <Announcement id={announcement.id} message={announcement.content} />}
            </ErrorBoundary>
          </div>

          <header>
            <StyledTopBar
              {...{
                padding: `0 ${spacing[6]} 0 ${spacing[4]}`,
                ref: skipNavTopNavigation.ref,
                id: skipNavTopNavigation.id,
              }}
            >
              <ErrorBoundary>
                <div id="global-search">
                  <GlobalSearch
                    {...{
                      searchValueFromQuery,
                      searchFilterFromQuery,
                      isOnGlobalSearchPage,
                      isOnEditorPage,
                      administrationPages,
                    }}
                  />
                </div>
                <TopNavBar />
              </ErrorBoundary>
            </StyledTopBar>
          </header>

          <div ref={skipNavMainContent.ref} id={skipNavMainContent.id} />
          <main css={mainStyles}>
            <StyledBackboneRouter
              {...{ isReactRoute, useNewSpacingStandard, dangerouslySetInnerHTML: createMarkup() }}
            />

            <StyledReactRouter {...{ isReactRoute, padding: useNewSpacingStandard ? 0 : [0, 3] }}>
              <Suspense fallback={<Indicator />}>
                <Switch>
                  <Redirect from="/systemDashboard/gettingStarted" to="/getStarted" />
                  <TitledRoute exact path={routes.getStarted} title={localized("Get started")}>
                    <GetStarted />
                  </TitledRoute>
                  <Route exact path={Object.values(deviceSearchRoutes)}>
                    <DeviceSearch />
                  </Route>

                  {user("canCRUDEndUserSharing") && (
                    <Route path={routes.endUserSearch}>
                      <EndUserSearch />
                    </Route>
                  )}

                  <TitledRoute exact path={Object.values(quickConnectRoutes)} title={localized("Quick Connect")}>
                    {isQuickConnectEnabled ? <QuickConnect /> : <Redirect to="/systemDashboard/overview" />}
                  </TitledRoute>

                  <Route path={routes.vmDashboard}>
                    <VMDashboard />
                  </Route>

                  <Route path={routes.vmGuestDashboard}>
                    <VMGuestDashboard />
                  </Route>

                  {isMDMFeatureEnabled() && (
                    <Route path={routes.mdmDashboard}>
                      <MobileDashboard />
                    </Route>
                  )}

                  <Route path={routes.unmanagedDeviceDashboard}>
                    <UnmanagedDeviceDashboard />
                  </Route>

                  {user("canViewAndScheduleReports") && user("canViewAtLeastOneOrganization") && (
                    <Route path={routes.reporting}>
                      <Reporting />
                    </Route>
                  )}

                  <Route exact path={routes.newSystray}>
                    <SystrayEditor />
                  </Route>

                  <Route exact path={routes.editSystray}>
                    <SystrayEditor />
                  </Route>

                  <TitledRoute exact path={routes.remoteSupport} title={localized("Remote Support")}>
                    {isRemoteSupportEnabled && user("canUseRemoteSupport") ? (
                      <RemoteSupport />
                    ) : (
                      <Redirect to="/systemDashboard/overview" />
                    )}
                  </TitledRoute>

                  <TitledRoute exact path={Object.values(administrationRoutes)} title={localized("Administration")}>
                    <AdministrationView />
                  </TitledRoute>

                  <Route exact path={Object.values(userEditorRoutes)}>
                    <UserEditor />
                  </Route>

                  <Route exact path={Object.values(roleEditorRoutes)}>
                    <RoleEditor />
                  </Route>

                  {user("canCRUDEndUserSharing") && (
                    <Route path={Object.values(endUserEditorRoutes)}>
                      <EndUserEditor />
                    </Route>
                  )}

                  {isFeatureEnabled("ninja_psa") && (
                    <Route path={Object.values(agreementEditorRoutes)}>
                      <AgreementEditor />
                    </Route>
                  )}
                  {isFeatureEnabled("ninja_psa") && (
                    <Route path={Object.values(agreementTemplateEditorRoutes)}>
                      <AgreementTemplateEditor />
                    </Route>
                  )}
                  {isFeatureEnabled("ninja_psa") && (
                    <Route path={Object.values(invoiceEditorRoutes)}>
                      <InvoiceEditor />
                    </Route>
                  )}

                  <Route exact path={routes.clientApp}>
                    <ClientAppEditorContainer />
                  </Route>

                  <Route path={routes.ticketing}>
                    <GraphQLProvider value={graphqlTicketingClient()}>
                      <Ticketing />
                    </GraphQLProvider>
                  </Route>

                  {isNinjaPSAEnabled && (
                    <Route path={routes.ninjaPSA}>
                      <NinjaPSA />
                    </Route>
                  )}

                  {isNinjaPSAEnabled && (
                    <Route exact path={Object.values(ninjaPSAEditorRoutes)}>
                      <NinjaPsaQuickBooksEditor />
                    </Route>
                  )}

                  {searchValueFromQuery && searchFilterFromQuery && (
                    <Route exact path={routes.globalSearch}>
                      <GlobalSearchPage {...{ searchValueFromQuery, searchFilterFromQuery, administrationPages }} />
                    </Route>
                  )}
                </Switch>
              </Suspense>
            </StyledReactRouter>
          </main>

          <Footer {...{ isBranded, websiteBranding }} />
        </div>
      </div>
    </StyledApplicationContainer>
  )
}

const mapStateToProps = ({ application, websiteBranding, psa }) => ({
  announcement: application.announcement,
  isBranded: application.isBranded,
  websiteBranding,
  ...buildAppInfoCustomData(psa),
})

export default connect(mapStateToProps)(Application)
