// Taken from https://github.com/facebook/lexical/blob/main/packages/lexical-playground/src/nodes/ImageNode.tsx
import { $applyNodeReplacement, DecoratorNode } from "lexical"

import ImageComponent from "js/includes/components/CustomFields/WYSIWYGEditor/plugins/InlineImagePlugin/Component"

function convertInlineImageElement(domNode) {
  const contentId = domNode.getAttribute("src")?.startsWith("cid:") ? domNode.getAttribute("src")?.slice(4) : ""

  if (contentId) {
    const node = $createInlineImageNode({
      altText: domNode.getAttribute("alt"),
      height: domNode.getAttribute("height"),
      src: domNode.getAttribute("src"),
      width: domNode.getAttribute("width"),
      contentId,
    })

    return { node }
  }

  return null
}

export class InlineImageNode extends DecoratorNode {
  constructor({ src, altText, maxWidth, width, height, key, contentId }) {
    super(key)
    this.__src = src
    this.__altText = altText
    this.__maxWidth = maxWidth
    this.__width = width || "inherit" // TODO check if inherit is okay
    this.__height = height || "inherit" // TODO check if inherit is okay
    this.__contentId = contentId

    this.uploadsByContentIdRef = {}
    this.tempUriByContentIdRef = {}
  }

  static getType() {
    return "inline-image"
  }

  static clone(node) {
    return new InlineImageNode({
      src: node.__src,
      altText: node.__altText,
      maxWidth: node.__maxWidth,
      width: node.__width,
      height: node.__height,
      key: node.__key,
      contentId: node.__contentId,
    })
  }

  static importJSON(serializedNode) {
    const { altText, height, width, maxWidth, src, contentId, key } = serializedNode
    const node = $createInlineImageNode({
      altText,
      height,
      maxWidth,
      src,
      width,
      contentId,
      key,
    })

    return node
  }

  setUploadsByContentIdRef(uploadsByContentIdRef) {
    this.uploadsByContentIdRef = uploadsByContentIdRef
  }

  setTempUriByContentIdRef(tempUriByContentIdRef) {
    this.tempUriByContentIdRef = tempUriByContentIdRef
  }

  exportDOM() {
    const element = document.createElement("cid-img")

    element.setAttribute("src", this.__src)
    element.setAttribute("data-key", this.__key)
    element.setAttribute("alt", this.__altText)
    element.setAttribute("width", this.__width.toString())
    element.setAttribute("height", this.__height.toString())

    return { element }
  }

  static importDOM() {
    return {
      "cid-img": () => ({
        conversion: convertInlineImageElement,
        priority: 0,
      }),
      img: () => ({
        conversion: convertInlineImageElement,
        priority: 0,
      }),
    }
  }

  exportJSON() {
    return {
      altText: this.getAltText(),
      height: this.__height === "inherit" ? 0 : this.__height,
      maxWidth: this.__maxWidth,
      src: this.getSrc(),
      type: InlineImageNode.getType(),
      version: 1,
      width: this.__width === "inherit" ? 0 : this.__width,
      contentId: this.getContentId(),
    }
  }

  setWidthAndHeight(width, height) {
    const writable = this.getWritable()
    writable.__width = width
    writable.__height = height
  }

  createDOM(config) {
    const span = document.createElement("span")
    const className = config.theme.image
    if (className) {
      span.className = className
    }
    return span
  }

  updateDOM() {
    return false
  }

  getNodeKey() {
    return this.__key
  }

  getSrc() {
    return this.__src
  }

  getAltText() {
    return this.__altText
  }

  getContentId() {
    return this.__contentId
  }

  setUploadedImageSrc(src) {
    const self = this.getWritable()
    self.__src = `cid:${src}`
  }

  decorate() {
    return (
      <ImageComponent
        tempUriByContentIdRef={this.tempUriByContentIdRef}
        uploadsByContentIdRef={this.uploadsByContentIdRef}
        contentId={this.getContentId()}
        altText={this.__altText}
        width={this.__width}
        height={this.__height}
        maxWidth={this.__maxWidth}
        nodeKey={this.__key}
        resizable
      />
    )
  }
}

export function $createInlineImageNode({ altText, height, src, width, key, contentId }) {
  return $applyNodeReplacement(
    new InlineImageNode({
      src,
      altText,
      width,
      height,
      key,
      contentId,
    }),
  )
}

export function $isInlineImageNode(node) {
  return node instanceof InlineImageNode
}
