import { AlertMessage, Checkbox } from "@ninjaone/components"
import { spacing } from "@ninjaone/tokens"

import { MAX_WYSIWYG_HTML_LENGTH_FOR_AUTO_VIEW } from "js/includes/common/_constants"
import { localizationKey, localized } from "js/includes/common/utils"
import { Box } from "js/includes/components/Styled"

export default function ExpandLargeValueOnRender({ option: { id, options }, onChange, value }) {
  return (
    <Box marginTop={4} textWrap="all">
      <AlertMessage
        variant="warning"
        labelToken={localizationKey(
          "Values with lengths larger than {{maxValue}} characters are collapsed by default to avoid performance issues. Having several fields with large values may cause the app to behave slowly or may crash the browser.",
        )}
        labelTokenArgs={{ maxValue: MAX_WYSIWYG_HTML_LENGTH_FOR_AUTO_VIEW }}
      />
      <Box marginTop={spacing[4]}>
        <Checkbox
          checked={!!value}
          label={localized("Expand large values on render")}
          labelTextWrap
          onChange={({ isChecked }) => onChange(isChecked)}
        />
      </Box>
    </Box>
  )
}
