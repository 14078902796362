import { localizationKey, reportErrorAndShowMessage } from "js/includes/common/utils"

export function handleQCConnectError(error) {
  if (error?.code === "nc_failed_to_initiate_connection_with_message") {
    const message = error?.params?.message

    switch (message) {
      case "com.ninjaone.error.in_progress":
        reportErrorAndShowMessage(error, localizationKey("Please wait, the operation is still in progress."))
        break
      case "com.ninjaone.error.fetch_creds":
        reportErrorAndShowMessage(error, localizationKey("We couldn't retrieve your credentials. Please try again."))
        break
      case "com.ninjaone.error.invalid_creds":
        reportErrorAndShowMessage(
          error,
          localizationKey("The credentials you entered are invalid. Please check and try again."),
        )
        break
      case "com.ninjaone.error.streamer_download":
        reportErrorAndShowMessage(
          error,
          localizationKey("There was an error downloading the streamer. Please try again later."),
        )
        break
      case "com.ninjaone.error.streamer_checksum":
        reportErrorAndShowMessage(
          error,
          localizationKey("The downloaded streamer is corrupted. Please try downloading again."),
        )
        break
      case "com.ninjaone.error.streamer_start_download":
        reportErrorAndShowMessage(error, localizationKey("We couldn't start the streamer download. Please try again."))
        break
      case "com.ninjaone.error.streamer_install":
        reportErrorAndShowMessage(
          error,
          localizationKey("There was an issue installing the streamer. Please try again."),
        )
        break
      case "com.ninjaone.error.streamer_file":
        reportErrorAndShowMessage(
          error,
          localizationKey("There was a problem with the streamer file. Please check and try again."),
        )
        break
      case "com.ninjaone.error.streamer_start":
        reportErrorAndShowMessage(error, localizationKey("We couldn't start the streamer. Please try again."))
        break
      case "com.ninjaone.error.unknown":
        reportErrorAndShowMessage(error, localizationKey("An unknown error has occurred. Please try again later."))
        break
      case "com.ninjaone.error.not_authorized":
        reportErrorAndShowMessage(error, localizationKey("You’re not authorized to perform this action."))
        break
      case "com.ninjaone.error.connections_count":
        reportErrorAndShowMessage(error, localizationKey("Reached max number of connections"))
        break
      case "wamp.error.no_such_procedure":
        reportErrorAndShowMessage(error, localizationKey("No such procedure."))
        break
      default:
        reportErrorAndShowMessage(error)
    }
  } else {
    reportErrorAndShowMessage(error)
  }
}
