import React from "react"
import { Box } from "js/includes/components/Styled"
import { isNilOrEmpty } from "js/includes/common/utils"
import { useTheme } from "@emotion/react"

export default function PhoneValueRenderer(field, { value } = {}) {
  const theme = useTheme()
  return isNilOrEmpty(value) ? null : (
    <Box whiteSpace="normal" color={theme.colorTextStrong}>
      {value}
    </Box>
  )
}
