import React from "react"
import { pluck } from "ramda"
import SearchableDropDown from "js/includes/components/SearchableDropDown"

export default function FileExtensions({ option: { id, options }, onChange, value, validation, validateFormFields }) {
  return (
    <SearchableDropDown
      isMulti
      openOnFocus
      width="100%"
      minHeight="32px"
      borderRadius="2px"
      searchableKey="name"
      valueSelectorKey="id"
      rowHeight={30}
      options={options}
      keepInView={false}
      keepDropdownInView={true}
      value={options.filter(option => value?.includes?.(option.id))}
      onSelect={selection => onChange(selection ? pluck("id", selection) : [])}
      errorMessage={validation?.message?.fileExtensions}
      validationState={validation?.success?.fileExtensions === false ? "error" : null}
      onBlur={validateFormFields}
    />
  )
}
