import { useMountedState } from "@ninjaone/utils"
import { useMemo } from "react"
import { DataTable } from "@ninjaone/components"
import { cellTypes } from "@ninjaone/components/src/DataTable"
import { getReadableNodeRole } from "js/includes/common/_enums"
import { localized } from "js/includes/common/utils"
import { SECTION_MAX_HEIGHT, SECTION_WIDTH } from "js/includes/components/DeviceModal/AddUnmanagedDeviceModal/constants"
import { Box } from "js/includes/components/Styled"

const columns = () => [
  {
    id: "icon",
    Header: "",
    accessor: "icon",
    disableSortBy: true,
    getCellTypeProps: () => ({
      type: cellTypes.ICON,
      props: {
        svgIconName: "ComputerSpeaker",
      },
    }),
  },
  {
    id: "roleName",
    Header: localized("Name"),
    accessor: ({ name }) => getReadableNodeRole(name),
    getCellTypeProps: () => ({
      type: cellTypes.TEXT,
    }),
    maxWidth: "300px",
  },
]

export default function AddUnmanagedDeviceRoles({
  handleNextStep,
  loadingRoles,
  nodeRoleId,
  onChange,
  resetCustomFieldValues,
  unmanagedDeviceRoles,
}) {
  const [selectedRoleId, setSelectedRoleId] = useMountedState(nodeRoleId)

  const actions = useMemo(
    () => ({
      row: {
        action: async nodeRole => {
          setSelectedRoleId(nodeRole.id)
          await onChange("nodeRole", nodeRole)
          resetCustomFieldValues()
          handleNextStep()
        },
      },
    }),
    [handleNextStep, onChange, resetCustomFieldValues, setSelectedRoleId],
  )

  return (
    <Box
      paddingTop="2px"
      paddingLeft="2px"
      paddingRight="2px"
      height={SECTION_MAX_HEIGHT}
      width={SECTION_WIDTH}
      overflowY="auto"
    >
      <DataTable
        key={selectedRoleId}
        actions={actions}
        columns={columns()}
        getRowId={({ id }) => id}
        hideCheckboxes
        hideFilters
        hideHeaders
        hideResultsCount
        hideSettingsButton
        loading={loadingRoles}
        pageSizeLimit={50}
        rows={unmanagedDeviceRoles}
        searchBarFullWidth
        showSearchBar
        selectedRowIds={[selectedRoleId]}
        tableId="add-unmanmaged-device-roles-table"
        unselectedRowIds
      />
    </Box>
  )
}
