import { SearchableSelect } from "@ninjaone/components"

export default function ValueSelector({
  className,
  handleOnChange,
  options,
  value,
  ariaLabel,
  excludeOptions,
  fieldGroupKey,
  popoverWidth = "300px",
}) {
  return (
    <SearchableSelect
      {...{
        key: value, // workaround to make tooltip recalculate (problem with Text component useLayoutEffect deps)
        ariaLabel,
        options: options.filter(option => option.value === value || !excludeOptions?.includes(option.value)),
        value,
        onChange: selected => handleOnChange(selected),
        popoverProps: {
          fullWidth: false,
          width: popoverWidth,
        },
        ...(fieldGroupKey && { groupKey: fieldGroupKey }),
      }}
    />
  )
}
