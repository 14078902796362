/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { memo, useRef, useState } from "react"
import { useTheme } from "@emotion/react"
import Prism from "prismjs"
import { StyledWysiwygIframeHtmlView } from "./style"
import { useHtmlWithSignedUrls } from "./hooks/useHtmlWithSignedUrls"
import Loading from "../../Loading"

function ReadOnlyWysiwygIframeHtmlView({ id, width, height, initialHtml = "", attachmentProcessed }) {
  const contentWindowRef = useRef(null)
  const theme = useTheme()
  const [iFrameHeight, setIFrameHeight] = useState(0)
  const { loading, html } = useHtmlWithSignedUrls({
    initialHtml,
    contentWindowRef,
    attachmentProcessed,
  })

  return loading ? (
    <Loading />
  ) : (
    <iframe
      title={`wysiwyg value iframe id ${id}`}
      width={width ?? "100%"}
      height={height ?? iFrameHeight}
      sandbox="allow-same-origin allow-popups allow-popups-to-escape-sandbox"
      scrolling="no"
      frameBorder="0"
      onLoad={event => {
        if (height) return

        const { contentWindow } = event.target

        contentWindowRef.current = contentWindow

        setIFrameHeight(contentWindow.document.body.scrollHeight)

        const iFrameBody = contentWindow.document.body

        const resizeObserver = new ResizeObserver(entries => {
          entries.forEach(entry => {
            setIFrameHeight(entry.contentRect.height + 16)
          })
        })

        resizeObserver.observe(iFrameBody)

        const onVisibilityChange = () => {
          resizeObserver.disconnect()
          contentWindow.removeEventListener("visibilitychange", onVisibilityChange)
          contentWindow.addEventListener("visibilitychange", onVisibilityChange)
        }

        contentWindow.addEventListener("visibilitychange", onVisibilityChange)

        contentWindow.document.querySelectorAll("pre").forEach(pre => {
          const classes = pre.getAttribute("class") ?? ""
          const language = pre.getAttribute("data-highlight-language") ?? ""
          const gutter = pre.querySelectorAll("br")?.length + 1 ?? 0
          pre.setAttribute("class", `language-${language} ${classes}`)
          pre.setAttribute("data-gutter", Array.from({ length: gutter }, (_, i) => i + 1).join("\n"))
          pre.innerHTML = pre.innerHTML.replace(/[<]br[/]?[>]/gi, "\n")
          Prism.highlightElement(pre)
        })
      }}
      srcDoc={`
        <!DOCTYPE html>
        <html>
        <head>
          <meta charset="utf-8">
          <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap">
          <link rel="stylesheet" href="${process.env.PUBLIC_URL}/wysiwyg/css/fontawesome.min.6.4.0.css" />
          <link rel="stylesheet" href="${process.env.PUBLIC_URL}/wysiwyg/css/regular.min.6.4.0.css" />
          <link rel="stylesheet" href="${process.env.PUBLIC_URL}/wysiwyg/css/solid.min.6.4.0.css" />
          <link rel="stylesheet" href="${process.env.PUBLIC_URL}/wysiwyg/css/brands.min.6.4.0.css" />
          <link rel="stylesheet" href="${process.env.PUBLIC_URL}/wysiwyg/css/bootstrap-grid.min.5.3.1.css" />
          <link rel="stylesheet" href="${process.env.PUBLIC_URL}/wysiwyg/css/charts.min.1.1.0.css" />
          <link rel="stylesheet" href="${process.env.PUBLIC_URL}/wysiwyg/css/prism.min.1.29.0.css" />
          <title>Wysiwyg value iframe id ${id}</title>
        </head>
        <style>
          ${StyledWysiwygIframeHtmlView(theme)}
        </style>
        <body>
          ${html}
        </body>
        </html>
      `}
    />
  )
}

export default memo(ReadOnlyWysiwygIframeHtmlView)
