import { useEffect, useState } from "react"
import qs from "qs"
import { fetchJson, reportErrorAndShowMessage } from "js/includes/common/utils"
import SearchableDropDown from "js/includes/components/SearchableDropDown"
import { useMountedState } from "js/includes/common/hooks"
import Loading from "js/includes/components/Loading"
import {
  handleFormFieldBlur,
  handleFormFieldChange,
  searchableDropdownCommonProps,
} from "js/includes/components/CustomFields/common"
import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"

export default function CustomerDropdownFormField({
  id,
  value,
  onChange,
  onBlur,
  isHookForm = false,
  validationState,
  dropdownContainerId,
  technicianPermission,
}) {
  const [initialValue] = useState(value)
  const [loading, setLoading] = useMountedState(false)
  const [client, setClient] = useMountedState(null)

  useEffect(() => {
    const getInitialSelection = async () => {
      try {
        setLoading(true)

        const _qs = qs.stringify({
          id: initialValue?.entityId,
        })

        const [client] = await fetchJson(`/client?${_qs}`)

        setClient(client)
      } catch (error) {
        reportErrorAndShowMessage(error)
      } finally {
        setLoading(false)
      }
    }

    if (initialValue?.entityId) {
      getInitialSelection()
    }
  }, [initialValue, setLoading, setClient])

  return loading ? (
    <Loading />
  ) : (
    <SearchableDropDown
      {...{
        ...searchableDropdownCommonProps,
        dropdownContainerId,
        value: client,
        searchableKey: "name",
        valueSelectorKey: "id",
        endpoint: "/query/organizations",
        width: "100%",
        enableClear: true,
        keepInView: false,
        keepDropdownInView: true,
        excludeSelectedFromSelectableValues: true,
        disabled: technicianPermission !== "EDITABLE",
        noRowsRendererToken: localizationKey("No options available"),
        pageSize: 50,
        validationState,
        getInitialValue: () => client?.name,
        onSelect: selected => {
          setClient(selected)

          handleFormFieldChange({
            id,
            onChange,
            isHookForm,
          })(
            !!selected
              ? {
                  entityId: selected.id,
                  type: "CLIENT",
                }
              : null,
          )
        },
        onBlur: handleFormFieldBlur({ onBlur, id, isHookForm }),
      }}
    />
  )
}
