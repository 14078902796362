import { compose, filter, join, pluck } from "ramda"
import { isNotNilOrEmpty } from "js/includes/common/utils"
import { Body } from "@ninjaone/components"

export default function MultiSelectValueRenderer(field, { value } = {}) {
  const renderValue =
    compose(
      join(", "),
      pluck("name"),
      filter(option =>
        isNotNilOrEmpty(value) ? value?.includes?.(option.id) : field.defaultValue?.includes?.(option.id),
      ),
    )(field.options) || null
  return renderValue ? <Body type="body">{renderValue}</Body> : null
}
