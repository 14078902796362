import {
  always,
  assocPath,
  compose,
  cond,
  either,
  evolve,
  filter,
  flatten,
  hasPath,
  includes,
  isEmpty,
  map,
  mergeLeft,
  pathEq,
  pluck,
  propEq,
  reject,
  splitEvery,
  startsWith,
  toLower,
} from "ramda"
import qs from "qs"
import {
  fetchJson,
  fetch,
  reportErrorAndShowMessage,
  mapMemoryData,
  upload,
  downloadFile,
  getFileNameFromContentDispositionHeader,
  localizationKey,
  isAndroidDevice,
  ninjaReportError,
  isAppleMobileDevice,
  showErrorMessage,
  localized,
  parseJsonResponseFromBody,
  isFeatureEnabled,
  isPositiveInteger,
  isNilOrEmptyOrBlank,
  getRmmserviceDomainFromRegion,
  isBrandingSiteDomain,
  arrayToMapWithKey,
  isNotNilOrEmpty,
  isMacDevice,
  MDMNodeClass,
  showSuccessMessage,
  isAppleMobilePolicy,
} from "js/includes/common/utils"
import { getCompanyDisplayName } from "./divisionConfig"
import { getNodeRoles } from "./device"
import { MDMConnectionType } from "js/includes/configuration/integrations/mdm/constants"
import { PasswordPolicyScope } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/android/enums"
import { payloadPolicyAction } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/ios/customPayload/utils"

export const mdmBaseEndpoint = "/mdm"
const appleAppStoreURL = `${mdmBaseEndpoint}/application/apple/app-store`
const maxAppsNumberPerRequest = 100

// TODO: move all integration-related methods to `mdmApps`

export const fetchMDMConfiguration = appName => fetchJson(`/divisionconfig/${appName.toLowerCase()}`)

export const fetchMobileNode = nodeId => fetchJson(`/node/${nodeId}`)

export const fetchMobileNodeDash = async nodeId => {
  return fetchJson(`/webapp/nodedash/${nodeId}`, {
    options: {
      method: "POST",
      body: JSON.stringify({
        since: 0,
        dataspecs: [
          {
            dataspec: "mdm-systemInformation",
            limit: 1,
          },
        ],
      }),
    },
  })
}

export const fetchMobileSecurityDataspec = async (nodeId, nodeClass) => {
  const dataspecName = isAndroidDevice(nodeClass) ? "mdm-android-security" : "mdm-apple-security"
  try {
    const securityData = await fetchJson(`/webapp/nodedash/${nodeId}`, {
      options: {
        method: "POST",
        body: JSON.stringify({
          since: 0,
          dataspecs: [
            {
              dataspec: dataspecName,
              limit: 1,
            },
          ],
        }),
      },
    })
    return securityData?.node?.datasets?.[0]?.datapoints?.[0]?.data || {}
  } catch (error) {
    ninjaReportError(error)
    return {}
  }
}

export const fetchMobileNodeDataSpecs = async (nodeId, dataspecs) => {
  const {
    node: { datasets },
  } = await fetchJson(`/webapp/nodedash/${nodeId}`, {
    options: {
      method: "POST",
      body: JSON.stringify({
        since: 0,
        dataspecs: dataspecs.map(dataspec => ({
          dataspec,
          limit: 1,
        })),
      }),
    },
  })

  return datasets
}

export const fetchMobileMultipleActions = nodeIds => {
  const query = qs.stringify({ nodeId: [nodeIds] }, { indices: false })
  return fetchJson(`${mdmBaseEndpoint}/device/actions?${query}`)
}

export const getAndroidEnrollmentToken = async ({
  organizationId,
  locationId,
  allowPersonalUsage,
  nodeRole,
  connectionId,
}) => {
  const response = await fetchJson(
    `${mdmBaseEndpoint}/enrollment/android/enterprises/${connectionId}/enrollment-tokens`,
    {
      options: {
        method: "POST",
        body: JSON.stringify({ clientId: organizationId, locationId, allowPersonalUsage, nodeRole }),
      },
    },
  )
  const { qrCode, tokenValue, enrollmentUuid, code, qrCodeJson } = response

  return {
    qrCode: `data:image/png;base64,${qrCode}`,
    tokenValue,
    enrollmentUuid,
    code,
    qrCodeJson: qrCodeJson ? decodeURIComponent(qrCodeJson) : "",
  }
}

export const MobileActions = {
  LOCK_DEVICE: "lock",
  RESET_PASSCODE_DEVICE: "reset-passcode",
  CLEAR_PASSCODE: "clear-passcode",
  REBOOT_DEVICE: "reboot",
  DISOWN: "disown",
  ERASE_DEVICE: "erase",
}

export const sendAction = async (nodeId, action, payload = {}, method = "POST") => {
  return await fetchJson(`/mdm/device/${nodeId}/${action}`, {
    options: {
      method,
      body: JSON.stringify(payload),
    },
  })
}

export const sendActionMultipleDevices = async (nodeIds, action) => {
  return await fetchJson(`${mdmBaseEndpoint}/device/actions/execute`, {
    options: {
      method: "POST",
      body: JSON.stringify({
        nodeIds,
        action,
      }),
    },
  })
}

export const fetchDeviceStats = async nodeId => {
  const resultData = await fetchJson(`/webapp/nodedash/${nodeId}`, {
    options: {
      method: "POST",
      body: JSON.stringify({
        since: 0,
        dataspecs: [
          {
            dataspec: "mdm-memory",
            limit: 60,
          },
        ],
      }),
    },
  })
  return mapMemoryData(resultData)
}

export const sendAndroidEnrollment = ({ enterpriseId, enrollmentTokenUid, deliverOption, payload }) =>
  fetchJson(
    `/mdm/enrollment/android/enterprises/${enterpriseId}/enrollment-tokens/${enrollmentTokenUid}/${deliverOption}`,
    {
      options: {
        method: "POST",
        body: JSON.stringify(payload),
      },
    },
  )

export const sendAppleEnrollment = ({ pushCertificateId, code, deliverOption, payload }) =>
  fetchJson(`/mdm/enrollment/apple/apns/${pushCertificateId}/enrollment-profiles/${code}/${deliverOption}`, {
    options: {
      method: "POST",
      body: JSON.stringify(payload),
    },
  })

export const deleteDevice = nodeId =>
  fetchJson(`/mdm/device/${nodeId}`, {
    options: {
      method: "DELETE",
    },
  })

export const updateMobileName = (nodeId, newName) =>
  fetchJson(`/mdm/device/${nodeId}/display-name`, {
    options: {
      method: "PATCH",
      body: JSON.stringify({ newName }),
    },
  })

export const updateMobileNodeRoleId = (nodeId, nodeRoleId) =>
  fetchJson(`/mdm/device/${nodeId}/role`, {
    options: {
      method: "PATCH",
      body: JSON.stringify({ nodeRoleId }),
    },
  })

export const updateMobileParentPolicy = (nodeId, policyId) =>
  fetchJson(`/mdm/device/${nodeId}/policy`, {
    options: {
      method: "PATCH",
      body: JSON.stringify({ policyId }),
    },
  })

export const mobilePolicyErrorMessages = {
  duplicate_custom_payload_uuid: () => localized("A custom payload with the provided UUID already exists."),
  invalid_custom_payload_input: () => localized("Invalid custom payload input"),
}

const hasAppleInsertPayloadAction = policy => {
  if (!isAppleMobilePolicy(policy.nodeClass)) return false

  const payloadGuids = Object.keys(policy.content.customPayloads)
  for (const guid of payloadGuids) {
    if (pathEq(["content", "customPayloads", guid, "action"], payloadPolicyAction.INSERT, policy)) {
      return true
    }
  }
  return false
}

export const updateMDMPolicy = async policy => {
  let newPolicy = {
    ...policy,
    description: policy.description || "",
  }

  if (policy.nodeClass === MDMNodeClass.ANDROID) {
    // Remove deprecated password policy unspecified scope
    const passcodeRules = { ...policy.content.passcodeRules }
    delete passcodeRules[PasswordPolicyScope.SCOPE_UNSPECIFIED]
    newPolicy = {
      ...newPolicy,
      content: {
        ...policy.content,
        passcodeRules,
      },
    }
  }

  await fetchJson(`/mdm/policy/${policy.id}`, {
    options: { method: "PUT", body: JSON.stringify(newPolicy) },
  })

  // Get the server generated ids for apple customPayloads and clears current "actions"
  if (hasAppleInsertPayloadAction(policy)) {
    const {
      policy: { content },
    } = await fetchJson(`/policy/${policy.id}`)
    return assocPath(["content", "customPayloads"], content.customPayloads, newPolicy)
  }

  return newPolicy
}

export const updateMDMConfiguration = ({ type, enabled }) =>
  fetchJson("/mdm/config", {
    options: {
      method: "PATCH",
      body: JSON.stringify({ type, enabled }),
    },
  })

export const fetchApplicationsInformation = nodeId =>
  fetchJson(`/webapp/nodedash/${nodeId}`, {
    options: {
      method: "POST",
      body: JSON.stringify({
        since: 0,
        dataspecs: [
          {
            dataspec: "mdm-applicationreport",
            limit: 100,
          },
        ],
      }),
    },
  })

export const getEnterpriseSignupURL = async connectionName =>
  fetchJson("/mdm/enterprise/signup-url", {
    options: {
      method: "POST",
      body: JSON.stringify({ connectionName }),
    },
  })

export const getApplicationByPackageName = async (packageName, connectionId) =>
  fetchJson(`/mdm/application/android/enterprises/${connectionId}/applications/${packageName}`)

export const getMdmManagedConfigurationsWebToken = (brandingHostname, connectionId) =>
  fetchJson(`/mdm/application/android/enterprises/${connectionId}/web-token/managed-configuration`, {
    options: {
      method: "POST",
      body: JSON.stringify({
        brandingHostname: isBrandingSiteDomain() ? `${brandingHostname}.${getRmmserviceDomainFromRegion()}` : null,
      }),
    },
  })

export const getMdmWebToken = async (brandingHostname, connectionId) => {
  if (!isFeatureEnabled("mdm_android")) return {}
  const response = await fetchJson(`/mdm/application/android/enterprises/${connectionId}/web-token/play-store`, {
    options: {
      method: "POST",
      body: JSON.stringify({
        brandingHostname: isBrandingSiteDomain() ? `${brandingHostname}.${getRmmserviceDomainFromRegion()}` : null,
      }),
    },
  })
  return response
}

export const generateAppleEnrollmentFile = async ({ clientId, locationId, nodeRole, pushCertificateId }) => {
  const response = await fetchJson(`/mdm/enrollment/apple/apns/${pushCertificateId}/enrollment-profiles`, {
    options: {
      method: "POST",
      body: JSON.stringify({ clientId, locationId, nodeRole }),
    },
  })
  return {
    ...response,
    qrCode: `data:image/png;base64,${response.qrCode}`,
    tokenValue: response.code,
    qrCodeJson: response?.qrCodeJson ? decodeURIComponent(response.qrCodeJson) : "",
  }
}

const getEnrollmentProfileFile = async ({ clientId, locationId, nodeRole, pushCertificateId }) => {
  try {
    const response = await fetch(`/mdm/enrollment/apple/apns/${pushCertificateId}/enrollment-profiles/download`, {
      options: {
        method: "POST",
        body: JSON.stringify({ clientId, locationId, nodeRole }),
      },
    })
    const file = await response.blob()
    const url = URL.createObjectURL(file)

    return { response, url }
  } catch (error) {
    const response = await parseJsonResponseFromBody(error.response)
    if (response.resultCode === "client_push_certificate_expired") {
      showErrorMessage(localized("An error has occurred. The Apple Push Notification certificate has expired."))
    } else {
      reportErrorAndShowMessage(error, localizationKey("Failed to load enrollment profile"))
    }
  }
}

export const downloadEnrollmentFile = async data => {
  const { response, url } = await getEnrollmentProfileFile(data)
  const filename = getFileNameFromContentDispositionHeader(response.headers) ?? "enroll.mobileconfig"
  downloadFile(url, filename)
  showSuccessMessage(localized("File downloaded successfully"))
}

export const getMDMDefaults = nodeClass => {
  const deviceType = cond([
    [isAndroidDevice, always("android")],
    [either(isAppleMobileDevice, isMacDevice), always("apple")],
  ])(nodeClass)

  const params = qs.stringify({ ...(deviceType === "apple" && { platform: isMacDevice(nodeClass) ? "MACOS" : "IOS" }) })

  return fetchJson(`/mdm/defaults/${deviceType}?${params}`)
}

const filterIOSTag = map(evolve({ tags: filter(startsWith("iOS")) }))

export const getMDMRestrictions = async nodeClass => {
  const restrictions = (await getMDMDefaults(nodeClass)).restrictions ?? []
  if (isAppleMobileDevice(nodeClass)) {
    return filterIOSTag(restrictions)
  }
  return restrictions
}

export const getPolicyEnforcementOptions = async () => {
  const { settingNames } = await getMDMDefaults("ANDROID")
  return settingNames ?? []
}

export const getAppleADEData = async () => {
  if (!isFeatureEnabled("mdm_apple")) return null
  try {
    return await fetchJson("/mdm/enrollment/ade/account")
  } catch (error) {
    if (error.response?.status !== 404) {
      reportErrorAndShowMessage(error, localizationKey("An error occurred while loading ADE information"))
    }
    return null
  }
}

export const downloadADEPublicKey = async () => {
  try {
    const response = await fetch("/mdm/enrollment/ade/public-certificate")
    const file = await response.blob()
    const { companyDisplayName } = await getCompanyDisplayName()
    downloadFile(URL.createObjectURL(file), `${companyDisplayName.value}.pem`)
  } catch (error) {
    const response = await parseJsonResponseFromBody(error.response)
    if (response.resultCode === "client_push_certificate_expired") {
      showErrorMessage(localized("Can't download public key. The Apple Push Notification certificate has expired."))
    } else {
      throw error
    }
  }
}

export const uploadTokenFileADE = ({ file, isUpdate }) => {
  const { promise } = upload("/mdm/enrollment/ade/token", { method: isUpdate ? "PUT" : "POST", body: { file } })
  return promise
}

export const getCurrentADEProfile = async adeTokenId => {
  try {
    return await fetchJson(`/mdm/enrollment/ade/tokens/${adeTokenId}/profiles`)
  } catch (error) {
    return null
  }
}

export const getADEDevices = adeTokenId => fetchJson(`/mdm/enrollment/ade/tokens/${adeTokenId}/devices`)

export const setDevicesOrganization = ({ adeTokenId, organization, location, nodeRole, devices }) =>
  fetchJson(`/mdm/enrollment/ade/tokens/${adeTokenId}/profiles/assign`, {
    options: {
      method: "POST",
      body: JSON.stringify({
        clientId: organization.id,
        locationId: location.value,
        deviceIds: pluck("id", devices),
        nodeRole,
      }),
    },
  })

export const syncDevicesWithABM = adeTokenId =>
  fetchJson(`/mdm/enrollment/ade/tokens/${adeTokenId}/device/sync`, {
    options: { method: "POST" },
  })

export const clearOrgFromADEDevices = (adeTokenId, devices) =>
  fetchJson(`/mdm/enrollment/ade/tokens/${adeTokenId}/profiles/remove`, {
    options: {
      method: "PATCH",
      body: JSON.stringify({
        deviceIds: pluck("id", devices),
      }),
    },
  })

export const getMobileActionsByOwnership = async deviceId => {
  try {
    const customOwnershipActions = await fetchJson(`/mdm/device/${deviceId}/actions`)
    const enabledActions = compose(pluck("name"), reject(propEq("enabled", false)))(customOwnershipActions)
    return enabledActions
  } catch (error) {
    ninjaReportError(error)
    return []
  }
}

const lookUpIosMetadata = async (apps, params) => {
  const { isVPP } = params
  const url = isVPP ? "vpp/lookup" : "lookup"
  try {
    return await fetchJson(`/mdm/application/apple/app-store/${url}`, {
      options: {
        method: "POST",
        body: JSON.stringify(map(evolve({ country: toLower }), apps)),
      },
    })
  } catch (err) {
    return []
  }
}

const fetchIOSAppsMetadata = async (appsFromPolicy, params) => {
  const splitArray = splitEvery(maxAppsNumberPerRequest, appsFromPolicy)
  const promises = splitArray.map(apps => lookUpIosMetadata(apps, params))
  return flatten(await Promise.all(promises))
}

const mergeAppMetadata = (appsInPolicy, appsMetadata, metadataKey, appKey) => {
  const appsMetadataMap = arrayToMapWithKey(metadataKey, appsMetadata)
  return appsInPolicy.map(policyApp => {
    const metadata = appsMetadataMap[policyApp[appKey]]
    return metadata
      ? mergeLeft(policyApp, { ...metadata, id: metadata[metadataKey] })
      : { ...policyApp, id: policyApp[appKey] }
  })
}

const mergeAppMetadataWithId = (appsInPolicy, appsMetadata) => {
  return mergeAppMetadata(appsInPolicy, appsMetadata, "id", "applicationId")
}

export const mergeAppMetadataWithBundleId = (appsInPolicy, appsMetadata) => {
  return mergeAppMetadata(appsInPolicy, appsMetadata, "bundleId", "bundleId")
}

export const getIosAppsMetadata = async (appsInPolicy = []) => {
  if (appsInPolicy.length === 0) return []
  try {
    const result = await fetchIOSAppsMetadata(appsInPolicy, { isVPP: false })
    return mergeAppMetadataWithBundleId(appsInPolicy, result)
  } catch (error) {
    return []
  }
}

export const searchIOSApplications = async (search, country = "us", limit = 10) => {
  try {
    const queryParams = qs.stringify({
      term: search,
      country,
      limit,
    })
    const applications = await fetchJson(`/mdm/application/apple/app-store/search?${queryParams}`)
    return applications
  } catch (error) {
    reportErrorAndShowMessage(error, localizationKey("Error during application search"))
    return []
  }
}

export const getAppsAndBooksApplications = async ({ nodeClass }) => {
  const platforms = {
    [MDMNodeClass.APPLE_IOS]: "IOS",
    [MDMNodeClass.APPLE_IPADOS]: "IOS",
    [MDMNodeClass.MAC]: "MACOS",
    [MDMNodeClass.MAC_SERVER]: "MACOS",
  }
  const query = new URLSearchParams({ platform: platforms[nodeClass] })
  const { adamIds } = await fetchJson(`/mdm/connections/vpp/content-tokens/assets?${query}`)
  return fetchIOSAppsMetadata(
    adamIds.map(id => ({ applicationId: id, country: "us" })),
    { isVPP: true },
  )
}

export const getAppleCustomPayloadsMetadata = ({ policyId, payloadIds }) => {
  if (isEmpty(payloadIds)) return []

  const params = new URLSearchParams()
  for (const id of payloadIds) {
    id && params.append("id", id)
  }
  return fetchJson(`/mdm/policy/${policyId}/custom-payloads?${params.toString()}`)
}

export const getAndroidApplicationsMetadata = async ({ packageNameList = [], locale }) => {
  if (!isFeatureEnabled("mdm_android")) return {}
  try {
    const response = await Promise.all(
      packageNameList.map(({ packageNames, connectionId }) =>
        fetchJson(`/mdm/application/android/enterprises/${connectionId}/applications/lookup?locale=${locale}`, {
          options: {
            method: "POST",
            body: JSON.stringify({ packageNames }),
          },
        }),
      ),
    )
    return response.flat()
  } catch (error) {
    ninjaReportError(error)
    return []
  }
}

export const getVPPLicensedApps = async tokenId => {
  if (!isPositiveInteger(tokenId)) return []
  try {
    const response = await fetchJson(`/mdm/connections/vpp/content-tokens/${tokenId}/assets`)
    const assets = response.map(app => ({
      ...app,
      applicationId: app.adamId,
      country: "us",
    }))
    // NOTE: `isVPP: false` is set because when fetching assets the endpoint to use should be the root /lookup and not vpp/lookup
    const result = await fetchIOSAppsMetadata(assets, { isVPP: false })
    return mergeAppMetadataWithId(assets, result)
  } catch (error) {
    reportErrorAndShowMessage(error, localizationKey("Error fetching assets information"))
    return []
  }
}

export const getLocationDataSpec = async (mobileId, options = { last: true }) => {
  if (!hasPath(["pageSize"], options)) {
    options = assocPath(["pageSize"], 10, options)
  }
  try {
    const response = await fetchJson(`/mdm/device/${mobileId}/geolocation/search`, {
      options: {
        method: "POST",
        body: JSON.stringify(options),
      },
    })
    return response
  } catch (error) {
    ninjaReportError(error)
  }
}

export const getAndroidCriticalApps = async () => {
  if (!isFeatureEnabled("mdm_android")) return null

  try {
    const criticalApps = await fetchJson("/mdm/application/android/critical-system-apps")

    return criticalApps
  } catch (error) {
    ninjaReportError(error)
    return null
  }
}

export const getMDMDevices = async ({ nodeClasses = [], connectionFilter = {} }) => {
  const searchCriteria = []

  if (isNotNilOrEmpty(nodeClasses)) {
    const allNodeRoles = await getNodeRoles()
    const selectedNodeRoles = filter(({ nodeClass }) => includes(nodeClass, nodeClasses), allNodeRoles)
    searchCriteria.push({
      customFields: JSON.stringify({ selectedValues: pluck("id", selectedNodeRoles) }),
      id: -1,
      type: "node-role",
    })
  }

  if (isNotNilOrEmpty(connectionFilter)) {
    const { type, connectionIds } = connectionFilter
    searchCriteria.push({
      type,
      customFields: JSON.stringify({ selectedValues: connectionIds }),
    })
  }

  const { items } = await fetchJson("/search/runner", {
    options: {
      method: "POST",
      body: JSON.stringify({
        searchCriteria,
      }),
    },
  })
  return items
}

export const removeMDMConnection = connectionType =>
  fetchJson(
    connectionType === MDMConnectionType.ANDROID_ENTERPRISE ? `/mdm/enterprise` : `/mdm/connections/${connectionType}`,
    { options: { method: "DELETE" } },
  )

export const requestCurrentLocation = async nodeId => {
  try {
    const response = await fetch(`/mdm/device/${nodeId}/geolocation/request-latest`, {
      options: {
        method: "POST",
      },
    })
    return await response.json()
  } catch (error) {
    const errorResponse = await parseJsonResponseFromBody(error.response)

    if (hasPath(["restrictedSince"], errorResponse)) {
      return errorResponse
    } else {
      throw new Error(errorResponse.errorMessage)
    }
  }
}

export const deleteGeolocationHistory = (nodeId, deleteBy, days) => {
  const parameters = reject(isNilOrEmptyOrBlank)({ deleteBy, days })
  const queryString = new URLSearchParams(parameters).toString()
  return fetchJson(`/mdm/device/${nodeId}/geolocation?${queryString}`, {
    options: {
      method: "DELETE",
    },
  })
}

export const getAndroidRuntimePermissions = async () => {
  try {
    const permissionOverridesData = await fetchJson("/mdm/application/android/runtime-permissions")
    return map(permission => ({ value: permission, labelText: permission }))(permissionOverridesData)
  } catch (error) {
    ninjaReportError(error)
    return []
  }
}

export const requestDeviceWakeUp = async nodeId => {
  try {
    await fetchJson(`/mdm/device/${nodeId}/ninja-remote/wake-up`, {
      options: {
        method: "POST",
      },
    })
    return true
  } catch (error) {
    return false
  }
}

/**
 * Fetch the assigned organizations attached to a list of iOS applications. Note that only the organizations the user has access to will be returned.
 *
 * @param {{ applicationId: number, country: string }[]} apps
 * @returns {Promise}
 */
export const fetchAssignedOrganizations = apps => {
  return fetchJson(`${appleAppStoreURL}/vpp/lookup`, {
    options: {
      body: JSON.stringify(apps),
      method: "POST",
    },
  })
}

/**
 * Fetch the available OS version to update through Apple policies.
 *
 * @param { string } platform
 * @returns {Promise}
 */
export const fetchAvailablePatchVersions = platform => {
  return fetchJson(`${mdmBaseEndpoint}/application/apple/patch-metadata/platform?platform=${platform}`)
}
