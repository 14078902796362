import { isNilOrEmpty, localizationKey, localized } from "js/includes/common/utils"
import {
  DateFilters,
  ExpandLargeValueOnRender,
  FileExtensions,
  FileMaxSizeMb,
  IPAddressTypeSelector,
  MaxCharacters,
  MultiOptionsField,
  NodeClassSelector,
  NumericRangeSelector,
  OrganizationSelector,
  SecureFieldComplexityRules,
} from "js/includes/components/CustomFields/AdvancedOptions"
import { ATTACHMENT_FILE_EXTENSIONS } from "js/includes/ticketing/editor/shared/constants"

export const MULTI_OPTIONS_FIELD = {
  id: "multiOptionsField",
  renderComponent: MultiOptionsField,
  required: true,
}

export const FILE_MAX_SIZE_MB = {
  id: "fileMaxSize",
  label: localizationKey("File Max Size (MB)"),
  minValue: 2,
  maxValue: 20,
  renderComponent: FileMaxSizeMb,
  getInitialValue: value => value ?? 20,
}

export const MAX_CHARACTERS = {
  id: "maxCharacters",
  label: "configuration.customFields.maxCharacters",
  minValue: 200,
  maxValue: 10000,
  renderComponent: MaxCharacters,
  getInitialValue: value => value ?? 200,
}

export const FILE_EXTENSIONS_SELECTOR = {
  id: "fileExtensions",
  label: localizationKey("Accepted file types"),
  renderComponent: FileExtensions,
  options: ATTACHMENT_FILE_EXTENSIONS.map(ext => ({
    name: ext,
    id: ext,
  })),
  getInitialValue: value => value ?? ATTACHMENT_FILE_EXTENSIONS,
  required: true,
  validate: value =>
    !value.length ? { success: false, message: localized("Required") } : { success: true, message: "" },
  hideErrorMessageInline: true,
}

export const DATE_FILTERS = {
  id: "dateFilters",
  label: localizationKey("Filter Date Options"),
  renderComponent: DateFilters,
  // if change "options" please check & do appropriate changes in DATE_TIME_FILTERS.options
  options: [
    { id: "NONE", name: localized("None") },
    { id: "INCLUDE", name: localized("Include") },
    { id: "EXCLUDE", name: localized("Exclude") },
    { id: "PAST_DATES_ONLY", name: localized("Past Dates Only") },
    { id: "FUTURE_DATES_ONLY", name: localized("Future Dates Only") },
    { id: "RANGE", name: localized("Range") },
  ],
  getInitialValue: value =>
    value ?? {
      type: "NONE",
      selected: [],
    },
  validate: value => {
    if (value.type === "RANGE" && (value.selected.length !== 2 || value.selected.some(isNilOrEmpty))) {
      return { success: false, message: localized("Invalid date range") }
    }

    if (value.type === "EXCLUDE" && isNilOrEmpty(value.selected)) {
      return { success: false, message: localized("Invalid date") }
    }

    return { success: true, message: "" }
  },
}

export const DATE_TIME_FILTERS = {
  id: "dateFilters",
  label: localizationKey("Filter Date Options"),
  renderComponent: DateFilters,
  options: [DATE_FILTERS.options[0], ...DATE_FILTERS.options.slice(2)], // the same options as DATE except "INCLUDE"
  getInitialValue: value =>
    value ?? {
      type: "NONE",
      selected: [],
    },
  validate: DATE_FILTERS.validate,
}

export const ORGANIZATION_SELECTOR = {
  id: "org",
  label: localizationKey("Organization(s)"),
  renderComponent: OrganizationSelector,
  getInitialValue: value => value ?? null,
}

export const DEVICE_ROLES_SELECTOR = {
  id: "nodeClass",
  label: localizationKey("Device Class"),
  renderComponent: NodeClassSelector,
  getInitialValue: value => value ?? null,
}

export const NUMERIC_RANGE_SELECTOR = ({ enableDecimal = false }) => ({
  id: "numericRange",
  renderComponent: NumericRangeSelector,
  getInitialValue: value =>
    value ?? {
      min: null,
      max: null,
    },
  enableDecimal,
})

export const IP_ADDRESS_TYPE_SELECTOR = {
  id: "ipAddressType",
  label: localizationKey("IP Address Type"),
  renderComponent: IPAddressTypeSelector,
  options: [
    { id: "ALL", name: localized("All") },
    { id: "IPV4", name: "IPv4" },
    { id: "IPV6", name: "IPv6" },
  ],
  getInitialValue: value => value ?? "ALL",
}

export const COMPLEXITY_RULES_SELECTOR = {
  id: "complexityRules",
  label: localizationKey("Complexity Rules"),
  renderComponent: SecureFieldComplexityRules,
  getInitialValue: value =>
    value ?? {
      mustContainOneInteger: false,
      mustContainOneUppercaseLetter: false,
      mustContainOneLowercaseLetter: false,
      greaterOrEqualThanSixCharacters: false,
    },
}

export const EXPAND_LARGE_VALUE_ON_RENDER = {
  id: "expandLargeValueOnRender",
  label: null,
  renderComponent: ExpandLargeValueOnRender,
  getInitialValue: value => value ?? false,
}

const advancedOptions = [
  COMPLEXITY_RULES_SELECTOR,
  DATE_FILTERS,
  DATE_TIME_FILTERS,
  ORGANIZATION_SELECTOR,
  DEVICE_ROLES_SELECTOR,
  FILE_EXTENSIONS_SELECTOR,
  FILE_MAX_SIZE_MB,
  IP_ADDRESS_TYPE_SELECTOR,
  NUMERIC_RANGE_SELECTOR,
  MAX_CHARACTERS,
]

export default advancedOptions
