import SearchableDropDown from "js/includes/components/SearchableDropDown"
import {
  ENABLE_CLICK_ON_PORTAL_CLASS_NAME,
  handleFormFieldBlur,
  handleFormFieldChange,
} from "js/includes/components/CustomFields/common"

export default function DropdownFormField({
  id,
  value,
  options,
  onChange,
  onBlur,
  isHookForm = false,
  validationState,
  dropdownContainerId,
  technicianPermission,
}) {
  return (
    <SearchableDropDown
      dropdownClassName={ENABLE_CLICK_ON_PORTAL_CLASS_NAME}
      openOnFocus
      enableClear
      width="100%"
      minHeight="32px"
      borderRadius="2px"
      searchableKey="name"
      valueSelectorKey="id"
      rowHeight={30}
      options={options}
      keepInView={false}
      keepDropdownInView={true}
      dropdownContainerId={dropdownContainerId}
      disabled={technicianPermission !== "EDITABLE"}
      value={options.find(option => option.id === value)}
      onSelect={selection =>
        handleFormFieldChange({
          id,
          onChange,
          isHookForm,
        })(selection?.id ?? null)
      }
      onBlur={handleFormFieldBlur({ onBlur, id, isHookForm })}
      validationState={validationState}
    />
  )
}
