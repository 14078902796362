import { memo } from "react"
import {
  bytes,
  downloadFile,
  IMAGE_EXTENSIONS,
  isNotNilOrEmpty,
  localizationKey,
  ninjaReportError,
} from "js/includes/common/utils"
import pathParse from "path-parse"
import { FileAttachment } from "@ninjaone/components"
import { getSignedUrlFromContentId } from "js/includes/common/client"
import showModal from "js/includes/common/services/showModal"
import { ImagePreviewModal } from "js/includes/components/ImagePreviewModal"

const Attachment = memo(
  ({ attachment, file, onDelete, download = true, customIcons = {}, width = "100%", loading }) => {
    const MAX_FILE_SIZE_IN_BYTES = 104857600
    const fileSize = file?.size
    const defaultAttachment = {
      id: null,
      metadata: {
        name: file?.name || file?.metadata?.name || "attachment",
        mimeType: file?.type || file?.metadata?.mimeType,
        size: bytes(fileSize) || "",
        extension:
          (file?.name &&
            pathParse(file?.name)
              .ext.replace(".", "")
              .toLowerCase()) ||
          "",
      },
      contentId: file?.contentId || null,
      uploadStatus: fileSize > MAX_FILE_SIZE_IN_BYTES ? "FAILURE" : "SUCCESS",
    }

    const {
      id: attachmentId,
      uploadStatus,
      contentId,
      metadata: { name, extension, mimeType },
    } = attachment || defaultAttachment

    const newAttachment = {
      attachmentId,
      name,
      type: mimeType || extension,
      ...(attachment || defaultAttachment),
    }

    const isProcessing = uploadStatus === "PROCESSING"
    const isFailure = ["FAILURE", "SUSPICIOUS"].includes(uploadStatus)

    const onDownload = async () => {
      try {
        const { signedUrl } = await getSignedUrlFromContentId(contentId)

        if (signedUrl) {
          downloadFile(signedUrl, name)
        }
      } catch (error) {
        ninjaReportError(error)
      }
    }

    const handleClick = () => {
      if (IMAGE_EXTENSIONS.includes(extension.toLowerCase())) {
        showModal(<ImagePreviewModal {...{ attachmentId, onDownload, contentId }} />)
      } else {
        onDownload()
      }
    }

    return (
      <FileAttachment
        {...{
          file: newAttachment,
          progress: 100,
          ...(download && !isProcessing && !isFailure && { onClick: handleClick }),
          ...(isFailure && { variant: "ERROR", iconTooltip: localizationKey("Error") }),
          ...(isProcessing && { variant: "PROCESSING", progress: 0 }),
          ...(onDelete && {
            actions: [
              {
                action: onDelete,
                isRed: true,
                labelToken: localizationKey("Delete"),
              },
            ],
          }),
        }}
      />
    )
  },
)

export default function AttachmentValueRenderer(field, { value, id } = {}) {
  const attachment = {
    ...value,
    id,
    metadata: {
      name: value?.name,
      size: value?.size,
      extension: value?.extension,
    },
    type: value?.mimeType,
  }

  return (isNotNilOrEmpty(value) && <Attachment download attachment={attachment} />) || null
}
