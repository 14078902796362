import { useEffect } from "react"
import { COMMAND_PRIORITY_LOW, createCommand } from "lexical"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { ALLOW_SOURCE_PLUGIN_COMMAND, SET_IS_EDITING_SOURCE_COMMAND } from "@ninjaone/components/src/WYSIWYG"

export const COMPLETED_EDITING_HTML_COMMAND = createCommand("COMPLETED_EDITING_HTML_COMMAND")

export const SourcePlugin = ({ setIsEditingSource }) => {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    return editor.dispatchCommand(ALLOW_SOURCE_PLUGIN_COMMAND, true)
  }, [editor])

  useEffect(() => {
    return editor.registerCommand(
      SET_IS_EDITING_SOURCE_COMMAND,
      isEditingSource => {
        if (!isEditingSource) {
          editor.dispatchCommand(COMPLETED_EDITING_HTML_COMMAND)
        }

        setIsEditingSource?.(isEditingSource)
      },
      COMMAND_PRIORITY_LOW,
    )
  }, [editor, setIsEditingSource])

  return null
}
