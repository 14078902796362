import { Stepper } from "@ninjaone/components"
import { localized } from "js/includes/common/utils"
const normalizeInputValue = (incomingValue, valueSet, badInput) => {
  if (!incomingValue) return null

  return parseInt(incomingValue, 10)
}

const MaxCharacters = ({ option: { minValue, maxValue }, onChange, value }) => {
  return (
    <Stepper
      compact
      max={maxValue}
      min={minValue}
      value={value ?? ""}
      onChange={inputValue => {
        const normalizedValue = normalizeInputValue(inputValue, value)
        onChange(normalizedValue)
      }}
      footerLabelText={localized("200 - 10,000 characters")}
    />
  )
}

export default MaxCharacters
