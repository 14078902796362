import { useEffect } from "react"
import qs from "qs"
import { isNil } from "ramda"
import { fetchJson, getOverviewLink, reportErrorAndShowMessage } from "js/includes/common/utils"
import { Box, StyledSpan } from "js/includes/components/Styled"
import DeviceIcon from "js/includes/components/DeviceIcon"
import { useMountedState } from "js/includes/common/hooks"
import Loading from "js/includes/components/Loading"
import { Body, Link } from "@ninjaone/components"
import { spacing } from "@ninjaone/tokens"

export default function DeviceDropdownFormField(field, { id, value } = {}, renderTextOnly) {
  const [loading, setLoading] = useMountedState(false)
  const [device, setDevice] = useMountedState(null)

  const { name, nodeClass, organizationName } = device ?? {}

  useEffect(() => {
    const getInitialSelection = async () => {
      try {
        setLoading(true)

        const _qs = qs.stringify({
          pageSize: 1,
          nodeId: value?.entityId,
        })

        const [device] = await fetchJson(`${"/query/devices"}?${_qs}`)

        setDevice(device)
      } catch (error) {
        reportErrorAndShowMessage(error)
      } finally {
        setLoading(false)
      }
    }

    if (value?.entityId) {
      getInitialSelection()
    }
  }, [value?.entityId, setLoading, setDevice])

  return loading ? (
    <Loading />
  ) : isNil(value) || isNil(device) ? null : renderTextOnly ? (
    <Body>{name}</Body>
  ) : (
    <Box key={`${device?.id}-device`}>
      <Link href={device ? getOverviewLink(device) : ""}>
        <Body color="inherit">
          <DeviceIcon {...{ nodeClass }} />
          <StyledSpan marginLeft={spacing[1]} marginRight={spacing[1]}>
            {name}
          </StyledSpan>
          ({organizationName})
        </Body>
      </Link>
    </Box>
  )
}
