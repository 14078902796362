import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tokens from "@ninjaone/tokens"
import { CopyIcon } from "@ninjaone/icons"
import { localizationKey, clipboardWriteText } from "@ninjaone/webapp/src/js/includes/common/utils"
import Modal from "./Modal"

const StyledPre = styled.pre`
  font-family: ${tokens.typography.fontFamily.primary};
  color: ${({ theme }) => theme.colorTextWeak};
  font-size: ${tokens.typography.fontSize.body};
  line-height: ${tokens.typography.lineHeight};
  background-color: ${({ theme }) => theme.colorBackgroundInputDisabled};
  border-radius: ${tokens.spacing[1]};
  border-color: ${({ theme }) => theme.colorBorderWeak};
  height: 400px;
  text-wrap: wrap;
  word-break: break-word;
`

export default function PreformattedMessageModal({
  title,
  description,
  message,
  showCopyButton = true,
  unmount,
  ...restModalProps
}) {
  return (
    <Modal
      {...{
        size: "lg",
        heading: title,
        description: description,
        ...(showCopyButton && {
          buttons: [
            {
              labelToken: localizationKey("Copy"),
              onClick: () => clipboardWriteText(message),
              Icon: CopyIcon,
              type: "primary",
            },
          ],
        }),
        unmount,
        ...restModalProps,
      }}
    >
      <StyledPre data-testid="preformatted-message">{message}</StyledPre>
    </Modal>
  )
}

PreformattedMessageModal.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  message: PropTypes.string,
  showCopyButton: PropTypes.bool,
  unmount: PropTypes.func,
}
