import { useMemo } from "react"
import { handleFormFieldBlur, handleFormFieldChange } from "js/includes/components/CustomFields/common"
import { SearchableSelect } from "@ninjaone/components"

export default function MultiSelectFormField({
  id,
  value,
  options,
  onChange,
  onBlur,
  isHookForm = false,
  validationState,
  validationMessage,
  keepDropdownInView,
  technicianPermission,
  label,
  required,
  tooltipText,
}) {
  const formattedOptions = useMemo(
    () =>
      options.map(option => ({
        labelText: option.name,
        value: option.id,
      })),
    [options],
  )

  const parsedValue = useMemo(() => (value ? value.split(",").map(v => v.trim()) : []), [value])

  return (
    <SearchableSelect
      {...{
        isMulti: true,
        labelText: label,
        value: parsedValue,
        options: formattedOptions,
        openOnFocus: true,
        keepDropdownInView,
        disabled: technicianPermission !== "EDITABLE",
        validationState,
        onChange: selection => {
          handleFormFieldChange({
            id,
            onChange,
            isHookForm,
          })(selection.join(",") || null)
        },
        onBlur: () => {
          handleFormFieldBlur({ onBlur, id, isHookForm })
        },
        required,
        errorMessage: validationMessage,
        tooltipText,
      }}
    />
  )
}
