import { handleFormFieldBlur, handleFormFieldChange } from "js/includes/components/CustomFields/common"
import { Input } from "@ninjaone/components"
import { localized } from "js/includes/common/utils"

export default function TextFormField({
  id,
  value,
  onChange,
  onBlur,
  isHookForm = false,
  validateFormFields,
  validationMessage,
  technicianPermission,
  label,
  required,
  tooltipText,
}) {
  return (
    <Input
      type="text"
      maxLength={200}
      labelText={localized(label)}
      value={value ?? ""}
      onBlur={handleFormFieldBlur({ onBlur, id, isHookForm, validateFormFields })}
      onChange={e =>
        handleFormFieldChange({
          id,
          onChange,
          isHookForm,
        })(e.target.value || null)
      }
      disabled={technicianPermission !== "EDITABLE"}
      required={required}
      errorMessage={validationMessage}
      tooltipText={tooltipText}
    />
  )
}
