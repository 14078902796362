import React from "react"
import { Box } from "js/includes/components/Styled"

export default function FormSeparator(field, containerStyle) {
  return (
    <Box width="100%" {...containerStyle}>
      <hr className="thin no-margin" />
    </Box>
  )
}
