import { useEffect } from "react"
import ReadOnlyWysiwygIframeHtmlView from "js/includes/components/CustomFields/ReadOnlyWysiwygHtmlView/ReadOnlyWysiwygIframeHtmlView"
import { Box } from "js/includes/components/Styled"
import { isNilOrEmpty } from "js/includes/common/utils"
import { MAX_WYSIWYG_HTML_LENGTH_FOR_AUTO_VIEW } from "js/includes/common/_constants"

export const isLargeHtml = html => (html?.length ?? 0) > MAX_WYSIWYG_HTML_LENGTH_FOR_AUTO_VIEW

export default function WYSIWYGValueRenderer(
  field,
  { id, value, updateTime, attachmentProcessed } = {},
  { expanded, setExpanded },
) {
  useEffect(() => {
    setExpanded(!!field.advancedSettings?.expandLargeValueOnRender || !isLargeHtml(value?.html))
  }, [value?.html, field.advancedSettings?.expandLargeValueOnRender, setExpanded])

  if (isNilOrEmpty(value?.html)) {
    return null
  }

  return (
    expanded && (
      <Box
        width="100%"
        minHeight="40px"
        display="flex"
        borderRadius="4px"
        position="relative"
        flexDirection="column"
        backgroundColor="colorBackground"
      >
        <ReadOnlyWysiwygIframeHtmlView id={id} initialHtml={value.html} attachmentProcessed={attachmentProcessed} />
      </Box>
    )
  )
}
