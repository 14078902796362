import React, { useState, useEffect } from "react"
import qs from "qs"
import { pluck, reject } from "ramda"
import SearchableDropDown from "js/includes/components/SearchableDropDown"
import { searchableDropdownCommonProps } from "js/includes/components/CustomFields/common"
import { fetchJson, localized, localizationKey, reportErrorAndShowMessage } from "js/includes/common/utils"
import { useMountedState } from "js/includes/common/hooks"

const DEFAULT_CLIENT_OPTION = {
  id: 0,
  name: localized("Default Organization"),
}

export default function OrganizationSelector({ option: { id, options }, onChange, value }) {
  const [initialValue] = useState(value)
  const [loading, setLoading] = useMountedState(false)
  const [clients, setClients] = useMountedState([])

  useEffect(() => {
    const getInitialSelection = async () => {
      try {
        setLoading(true)

        const _qs = qs.stringify(
          {
            id: reject(clientId => clientId === DEFAULT_CLIENT_OPTION.id, initialValue),
          },
          { indices: false },
        )

        const response = await fetchJson(`/client?${_qs}`)
        const clients = response.map(client => {
          return client.id === DEFAULT_CLIENT_OPTION.id ? DEFAULT_CLIENT_OPTION : client
        })

        const hasDefaultOption = initialValue.includes(DEFAULT_CLIENT_OPTION.id)

        hasDefaultOption ? setClients([DEFAULT_CLIENT_OPTION, ...clients]) : setClients(clients)
      } catch (error) {
        reportErrorAndShowMessage(error)
      } finally {
        setLoading(false)
      }
    }

    if (initialValue) {
      getInitialSelection()
    }
  }, [initialValue, setLoading, setClients])

  return (
    <SearchableDropDown
      {...{
        ...searchableDropdownCommonProps,
        loading,
        value: clients,
        searchableKey: "name",
        valueSelectorKey: "id",
        endpoint: "/query/organizations",
        width: "100%",
        isMulti: true,
        enableClear: true,
        keepInView: false,
        keepDropdownInView: true,
        excludeSelectedFromSelectableValues: true,
        noRowsRendererToken: localizationKey("No options available"),
        pageSize: 50,
        additionalDefaultOptions: [DEFAULT_CLIENT_OPTION],
        onSelect: selected => {
          setClients(selected)

          onChange(!!selected.length ? pluck("id", selected) : null)
        },
      }}
    />
  )
}
