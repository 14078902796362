import { memo, useMemo, useState } from "react"
import { useController, useFormContext } from "react-hook-form"

import { StyledCol, StyledFormGroup, StyledRow } from "js/includes/components/Styled/Form"
import { fieldTypes } from "js/includes/components/CustomFields/fieldTypes"
import FormGroupLabel from "./FormGroupLabel"
import FormGroupFooter from "js/includes/components/CustomFields/FormGroups/FormGroupFooter"
import { isNotNilOrEmpty } from "js/includes/common/utils"

const fieldTypesThatShouldRenderExternalLabel = [
  "CHECKBOX",
  "ATTACHMENT",
  "WYSIWYG",
  "CLIENT_DROPDOWN",
  "CLIENT_MULTI_SELECT",
  "CLIENT_LOCATION_DROPDOWN",
  "CLIENT_LOCATION_MULTI_SELECT",
  "NODE_DROPDOWN",
  "NODE_MULTI_SELECT",
  "DROPDOWN",
  "DATE_TIME",
  "DATE",
  "TEXT_ENCRYPTED",
]

function FormGroupBase({ data, refs, field, initialValue, handleCacheTempImages }) {
  const {
    formState: { errors },
  } = useFormContext()
  const controller = useController({
    name: field.id.toString(),
    ...(field.type === "CHECKBOX" && { defaultValue: false }),
  })
  const [expanded, setExpanded] = useState(false)
  const FormField = useMemo(() => fieldTypes[field?.type].renderFormField, [field?.type])
  const validationState = isNotNilOrEmpty(errors[field.id]) ? "error" : null
  const validationMessage = errors[field.id]?.message ?? ""
  const isWYSIWYGField = field?.type === "WYSIWYG"

  return (
    <StyledFormGroup textAlign="left" marginBottom={4} validationState={validationState} maxWidth="100vw">
      <StyledRow display="block" margin={0}>
        <StyledCol xs={12} flexDirection="column">
          {fieldTypesThatShouldRenderExternalLabel.includes(field?.type) && (
            <FormGroupLabel
              {...{
                ...field,
                labelProps: { padding: "0", display: "flex", alignItems: "center" },
                expanded,
                setExpanded,
              }}
            />
          )}
          <FormField
            {...{
              ...field,
              ...controller.field,
              initialValue,
              isHookForm: true,
              data,
              validationState,
              validationMessage,
              ...(isWYSIWYGField && {
                refs,
                onSetContentRefs: handleCacheTempImages,
                expanded,
                setExpanded,
              }),
            }}
          />
          <FormGroupFooter
            {...{
              footerText: field.footerText,
              validationState: validationState,
              validationMessage: validationMessage,
            }}
          />
        </StyledCol>
      </StyledRow>
    </StyledFormGroup>
  )
}

export default memo(FormGroupBase)
