import { saveCustomFieldValues } from "js/includes/common/client/customFields"
import { createUnmanagedDevice } from "js/includes/common/client/unmanagedDevices"
import { filterEditableFieldValues } from "js/includes/components/CustomFields/common"
import { mapObjIndexed } from "ramda"
import { useCallback, useRef } from "react"
import AddUnmanagedDeviceCoreFields from "js/includes/components/DeviceModal/AddUnmanagedDeviceModal/AddUnmanagedDeviceCoreFields"
import AddUnmanagedDeviceCustomFields from "js/includes/components/DeviceModal/AddUnmanagedDeviceModal/AddUnmanagedDeviceCustomFields"
import AddUnmanagedDeviceRoles from "js/includes/components/DeviceModal/AddUnmanagedDeviceModal/AddUnmanagedDeviceRoles"
import Wizard from "@ninjaone/components/src/WizardModal/Wizard"
import { useMountedState } from "@ninjaone/utils"
import {
  arrayToMapWithKey,
  isNilOrEmpty,
  localizationKey,
  reportErrorAndShowMessage,
  showSuccessMessage,
} from "js/includes/common/utils"
import { localized } from "js/includes/common/utils/ssrAndWebUtils"

export const stepName = Object.freeze({
  ROLES: "roles",
  CORE_FIELDS: "coreFields",
  CUSTOM_FIELDS: "customFields",
})

export default function AddUnmanagedDeviceWizard({
  fields,
  nodeRoleId,
  nodeRoleName,
  loadingFields,
  loadingRoles,
  onChange,
  unmanagedDeviceRoles,
  unmount,
  validation,
  validateForm,
  validateFormFields,
  values,
}) {
  const [saving, setSaving] = useMountedState(false)
  const [validationError, setValidationError] = useMountedState(false)

  const customFieldValuesRef = useRef({})

  const resetCustomFieldValues = useCallback(() => {
    customFieldValuesRef.current = {}
  }, [])

  const handleSaveCustomFieldValues = useCallback(
    async ({ values, nodeId }) => {
      const parsedValues = mapObjIndexed(
        (value, key) => ({
          attributeId: parseInt(key, 10),
          value,
        }),
        values,
      )

      const editableValues = filterEditableFieldValues(fields, parsedValues)

      if (editableValues.length) {
        const response = await saveCustomFieldValues({
          entity: "node",
          values: editableValues,
          nodeId,
        })
        const updatedValues = arrayToMapWithKey("attributeId", response)

        if (updatedValues) {
          return updatedValues
        }
      }
    },
    [fields],
  )

  const handleCreateUnmanagedDevice = useCallback(async () => {
    try {
      if (validateForm() && !saving) {
        setSaving(true)

        const createdUnmanagedDevice = await createUnmanagedDevice({
          name: values.name,
          orgId: values.organization.id,
          locationId: values.location.value,
          assetTypeId: nodeRoleId,
          serialNumber: values.serialNumber,
        })

        await handleSaveCustomFieldValues({
          values: customFieldValuesRef.current,
          nodeId: createdUnmanagedDevice.nodeId,
        })

        unmount()

        showSuccessMessage(localized("Unmanaged device created succesfully"))
      }
    } catch (error) {
      reportErrorAndShowMessage(error, localizationKey("Error creating unmanaged device"))
    } finally {
      setSaving(false)
    }
  }, [handleSaveCustomFieldValues, nodeRoleId, saving, setSaving, unmount, validateForm, values])

  const steps = [
    {
      id: 1,
      name: stepName.ROLES,
      label: nodeRoleName || localized("Select role"),
      isValid: () => !!nodeRoleId,
      props: {
        loadingRoles,
        nodeRoleId,
        onChange,
        saving,
        unmanagedDeviceRoles,
        resetCustomFieldValues,
      },
      Content: AddUnmanagedDeviceRoles,
    },
    {
      id: 2,
      name: stepName.CORE_FIELDS,
      label: localized("Device information"),
      isValid: () => {
        return (
          validation.success.name &&
          validation.success.nodeRole &&
          validation.success.location &&
          validation.success.organization
        )
      },
      props: {
        onChange,
        saving,
        validation,
        validateFormFields,
        values,
      },
      Content: AddUnmanagedDeviceCoreFields,
    },
    {
      id: 3,
      name: stepName.CUSTOM_FIELDS,
      label: localized("Custom fields"),
      isValid: () => isNilOrEmpty(fields) || validationError,
      props: {
        loadingFields,
        onChange,
        fields,
        customFieldValuesRef,
        setValidationError,
      },
      Content: AddUnmanagedDeviceCustomFields,
    },
  ]

  return (
    <Wizard
      size="md"
      steps={steps}
      titleGroup={{
        titleText: localized("Add an unmanaged device"),
        textWrap: false,
      }}
      closeAction={unmount}
      unmount={unmount}
      isLoading={loadingRoles || saving}
      handleSubmit={handleCreateUnmanagedDevice}
    />
  )
}
