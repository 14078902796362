import { compose, join, pluck } from "ramda"
import { useState } from "react"

import { Highlighter } from "@ninjaone/components"

import { isNilOrEmpty, localized } from "js/includes/common/utils"
import SearchableDropDown from "js/includes/components/SearchableDropDown"
import { getSearchableDropdownTagRenderer } from "js/includes/ticketing/TriggerEditor/components/utils"
import { getAsArray, getSelectedValue, getValuesMap } from "js/includes/ticketing/TriggerEditor/utils"

const getSelectedIds = compose(join(","), pluck("id"))

const OPTION_ID_KEY = "id"
const OPTION_LABEL_KEY = "name"

export const LocationEditor = ({ isMulti = true, value, handleOnChange, metaData, errorMessage, ariaLabel }) => {
  const [selectedLocations, setSelectedLocations] = useState([])
  if (isNilOrEmpty(value) && !!selectedLocations.length) {
    setSelectedLocations([])
  }
  const valuesMap = getValuesMap({ metaData, options: selectedLocations })
  const selected = getSelectedValue({ value, isMulti, valuesMap })

  const handleOnSelect = selected => {
    // Important: set value first so that selectedLocations is not emptied above
    handleOnChange(Array.isArray(selected) ? getSelectedIds(selected) : selected?.[OPTION_ID_KEY] || null)
    setSelectedLocations(getAsArray(selected))
  }

  const getOptionLabel = ({ option }) => {
    const locationName = option?.[OPTION_LABEL_KEY] || localized("N/A")
    const deletedText = `${valuesMap[option[OPTION_ID_KEY]]?.deleted || true ? ` (${localized("Deleted")})` : ""}`
    const organizationName = option.organizationName || localized("N/A")
    return `${locationName} | ${organizationName}${deletedText}`
  }

  return (
    <SearchableDropDown
      isMulti={isMulti}
      keepDropdownInView
      keepInView={false}
      pageSize={50}
      endpoint="/query/locations"
      valueSelectorKey={OPTION_ID_KEY}
      searchableKey={OPTION_LABEL_KEY}
      value={selected}
      onSelect={handleOnSelect}
      getOptionLabel={() => getOptionLabel({ option: selected })}
      tagRenderer={getSearchableDropdownTagRenderer({ getOptionLabel })}
      rowRenderer={(rowData, inputValue, searchableKey) => {
        const label = rowData[searchableKey]
        const labelWithOrganization = `${label} (${rowData.organizationName})`
        return (
          <div className="text-ellipsis line-height-initial" title={labelWithOrganization}>
            {inputValue && label.toLowerCase().includes(inputValue.toLowerCase()) ? (
              <Highlighter text={labelWithOrganization} highlight={inputValue} />
            ) : (
              labelWithOrganization
            )}
          </div>
        )
      }}
      useSelectStyling
      {...{
        ariaAttributes: {
          "aria-label": ariaLabel,
        },
        ...(errorMessage && {
          errorMessage,
          validationState: "error",
        }),
      }}
    />
  )
}
