import React from "react"
import styled from "@emotion/styled"

import { isUserAllowedToAccessSecureFields } from "js/includes/common/services/userPermissions"
import { Box, Flex } from "js/includes/components/Styled"
import { Input, UnstyledButton } from "@ninjaone/components"
import showModal from "js/includes/common/services/showModal"
import { localized } from "js/includes/common/utils"
import TextEncryptedModal from "./TextEncryptedModal"

const StyledFlex = styled(Flex)`
  width: 100%;
  position: relative;
  align-items: center;
`

const StyledButton = styled(UnstyledButton)`
  color: ${({ theme }) => theme.colorTextActionStrong};
`

export default function TextEncryptedFormField({
  id,
  value,
  onChange,
  onBlur,
  isHookForm = false,
  entityType,
  validationState,
  validationMessage,
  validation,
  initialValue,
  advancedSettings,
  validateFormFields,
  technicianPermission,
  required,
  label,
}) {
  const hasValidationError = validationState === "error"

  return (
    <StyledFlex maxWidth={480}>
      <Box flex={1}>
        <Input
          maxLength={advancedSettings.maxCharacters ?? 200}
          ariaLabel={label}
          autoComplete="new-password"
          type="password"
          value={value?.substring(0, 9) ?? ""}
          disabled={technicianPermission !== "EDITABLE"}
          placeholder={localized("No password")}
          errorMessage={validationMessage}
          readOnly
        />
      </Box>
      {isUserAllowedToAccessSecureFields(entityType) && technicianPermission === "EDITABLE" && (
        <Box position="absolute" right={hasValidationError ? "36px" : "12px"} bottom={9}>
          <StyledButton
            key="editValue"
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
              showModal(
                <TextEncryptedModal
                  {...{
                    advancedSettings,
                    entityType,
                    initialValue,
                    hasValidationError,
                    validateFormFields,
                    validationMessage,
                    value,
                    onChange,
                    onBlur,
                    isHookForm,
                    id,
                    required,
                  }}
                />,
              )
            }}
          >
            {localized("Edit")}
          </StyledButton>
        </Box>
      )}
    </StyledFlex>
  )
}
