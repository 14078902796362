import { memo, useCallback, useEffect } from "react"
import { $getRoot, $insertNodes, TextNode } from "lexical"
import PropTypes from "prop-types"
import { $createCodeNode, CodeHighlightNode, CodeNode } from "@lexical/code"
import { LexicalComposer } from "@lexical/react/LexicalComposer"
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin"
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin"
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin"
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin"
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin"
import {
  CodeHighlightPlugin,
  ContentEditable,
  ExtendedTextNode,
  Placeholder,
  StyledEditor,
  StyledEditorContainer,
  StyledEditorScroller,
  StyledEditorShell,
  ToolbarPlugin,
  XMLParserPlugin,
  theme,
} from "@ninjaone/components/src/WYSIWYG"
import { AlertMessage } from "@ninjaone/components"
import { spacing } from "@ninjaone/tokens"
import { Box } from "js/includes/components/Styled"

export const WysiwygXMLEditor = memo(
  ({ initialStringContent, onInitialParseError, placeholderToken, onChange, onError, errorMessage }) => {
    const _onChange = useCallback(
      editorState => {
        onChange &&
          onChange(
            editorState.read(() => {
              const root = $getRoot()
              return root.getTextContent()
            }),
          )
      },
      [onChange],
    )

    return (
      <LexicalComposer
        initialConfig={{
          theme,
          onError,
          nodes: [
            CodeNode,
            CodeHighlightNode,
            ExtendedTextNode,
            { replace: TextNode, with: node => new ExtendedTextNode(node.__text) },
          ],
        }}
      >
        <StyledEditorShell>
          <ToolbarPlugin cidKey="resourceId" isBlockTypeDisabled isCodeLanguageDisabled allowSource />
          {errorMessage && (
            <Box margin={[spacing[2], spacing[4], 0]}>
              <AlertMessage variant="danger">{errorMessage}</AlertMessage>
            </Box>
          )}
          <StyledEditorContainer>
            <RichTextPlugin
              ErrorBoundary={LexicalErrorBoundary}
              contentEditable={
                <StyledEditorScroller id="editor-input">
                  <StyledEditor>
                    <ContentEditable placeholder={<Placeholder token={placeholderToken} />} />
                  </StyledEditor>
                </StyledEditorScroller>
              }
            />
            <CodeHighlightPlugin />
            <HistoryPlugin />
            <ClearEditorPlugin />
            <TabIndentationPlugin />
            <XMLParserPlugin initialXML={initialStringContent ?? ""} onParseError={onInitialParseError} />
            <OnChangePlugin onChange={_onChange} />
            <PreventCodeBlockDeletionPlugin />
          </StyledEditorContainer>
        </StyledEditorShell>
      </LexicalComposer>
    )
  },
)

const PreventCodeBlockDeletionPlugin = () => {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        const root = $getRoot()
        const hasBlockNode = root.getChildren().some(node => node.getType() === "code")

        // Re-insert the code block if it was deleted
        if (!hasBlockNode) {
          editor.update(() => {
            root.clear()
            root.selectStart()
            const newBlockNode = $createCodeNode("xml")
            $insertNodes([newBlockNode])
          })
        }
      })
    })
  }, [editor])

  return null
}

WysiwygXMLEditor.displayName = "WysiwygXMLEditor"
WysiwygXMLEditor.propTypes = {
  initialStringContent: PropTypes.string,
  onInitialParseError: PropTypes.func,
  placeholderToken: PropTypes.string,
  onChange: PropTypes.func,
  onError: PropTypes.func.isRequired,
}
