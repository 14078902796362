import React from "react"
import SearchableDropDown from "js/includes/components/SearchableDropDown"

export default function IPAddressTypeSelector({ option: { id, options }, onChange, value }) {
  return (
    <SearchableDropDown
      openOnFocus
      width="100%"
      minHeight="32px"
      borderRadius="2px"
      searchableKey="name"
      valueSelectorKey="id"
      rowHeight={30}
      options={options}
      keepInView={false}
      keepDropdownInView={true}
      value={options.find(o => o.id === value)}
      onSelect={selection => onChange(selection.id)}
    />
  )
}
