import { memo } from "react"
import { NONE } from "js/includes/components/CustomFields/common"
import { uiElementTypes } from "js/includes/components/CustomFields/fieldTypes"
import FormGroupBase from "js/includes/components/CustomFields/FormGroups/FormGroupBase"
import { Box } from "js/includes/components/Styled"

function CustomFieldsForm({ attachmentRefs, fields: customFields, initialValues, handleCacheTempImages, data }) {
  return (
    <Box>
      {customFields.map(
        field =>
          field?.technicianPermission !== NONE.value && (
            <Box key={field.id || field.uiElementUid}>
              {field.id ? (
                <FormGroupBase
                  {...{
                    refs: attachmentRefs,
                    handleCacheTempImages,
                    data,
                    field,
                    initialValue: initialValues[field.id],
                  }}
                />
              ) : (
                uiElementTypes[field.uiElementType].renderComponent?.(field, {
                  paddingLeft: field.uiElementType === "SEPARATOR" ? 0 : 3,
                  marginBottom: 4,
                })
              )}
            </Box>
          ),
      )}
    </Box>
  )
}

export default memo(CustomFieldsForm)
