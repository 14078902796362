import { compose, join, pluck } from "ramda"
import { useState } from "react"

import { isNilOrEmpty, localized } from "js/includes/common/utils"
import SearchableDropDown from "js/includes/components/SearchableDropDown"
import { getSearchableDropdownTagRenderer } from "js/includes/ticketing/TriggerEditor/components/utils"
import { getAsArray, getSelectedValue, getValuesMap } from "js/includes/ticketing/TriggerEditor/utils"

const OPTION_LABEL_KEY = "name"
const OPTION_ID_KEY = "id"
const getSelectedIds = compose(join(","), pluck("id"))

const getDeletedLabel = ({ valuesMap, option }) => {
  return valuesMap[option?.[OPTION_ID_KEY]]?.deleted ? ` (${localized("Deleted")})` : ""
}

export const OrganizationEditor = ({ isMulti = true, value, handleOnChange, metaData, errorMessage }) => {
  const [selectedOrganizations, setSelectedOrganizations] = useState([])

  if (isNilOrEmpty(value) && !!selectedOrganizations.length) {
    setSelectedOrganizations([])
  }

  const valuesMap = getValuesMap({ metaData, options: selectedOrganizations })
  const selected = getSelectedValue({ value, isMulti, valuesMap })

  const handleOnSelect = selected => {
    // Important: set value first so that setSelectedOrganizations is not emptied above
    handleOnChange(Array.isArray(selected) ? getSelectedIds(selected) : selected?.[OPTION_ID_KEY] || null)
    setSelectedOrganizations(getAsArray(selected))
  }

  const getOptionLabel = ({ option }) => {
    return `${option?.[OPTION_LABEL_KEY] || ""}${getDeletedLabel({ valuesMap, option })}`
  }

  return (
    <SearchableDropDown
      isMulti={isMulti}
      keepDropdownInView
      pageSize={50}
      endpoint="/query/organizations"
      valueSelectorKey={OPTION_ID_KEY}
      searchableKey={OPTION_LABEL_KEY}
      value={selected}
      onSelect={handleOnSelect}
      getOptionLabel={() => getOptionLabel({ option: selected })}
      tagRenderer={getSearchableDropdownTagRenderer({ getOptionLabel })}
      keepInView={false}
      useSelectStyling
      {...(errorMessage && {
        errorMessage,
        validationState: "error",
      })}
    />
  )
}
