import React, { useState, useEffect } from "react"
import { pluck } from "ramda"
import SearchableDropDown from "js/includes/components/SearchableDropDown"
import { searchableDropdownCommonProps } from "js/includes/components/CustomFields/common"
import { useMountedState } from "js/includes/common/hooks"
import { reportErrorAndShowMessage } from "js/includes/common/utils"
import { getReadableNodeRole } from "js/includes/common/_enums"
import { getNodeClasses } from "js/includes/common/client/device"
import DeviceIcon from "js/includes/components/DeviceIcon"

export default function NodeClassSelector({ option: { id, options }, onChange, value }) {
  const [initialValue] = useState(value)
  const [nodeClasses, setNodeClasses] = useMountedState([])
  const [loadingRoles, setLoadingRoles] = useMountedState(false)
  const [nodeClassOptions, setNodeClassOptions] = useMountedState([])

  useEffect(() => {
    const _getNodeClasses = async () => {
      setLoadingRoles(true)
      try {
        const nodeClasses = await getNodeClasses()
        const nodeClassOptions = nodeClasses.map(nodeClass => ({
          id: nodeClass,
          name: getReadableNodeRole(nodeClass),
        }))

        setNodeClassOptions(nodeClassOptions)

        if (initialValue) {
          setNodeClasses(nodeClassOptions.filter(({ id }) => initialValue?.includes(id)))
        }
      } catch (error) {
        reportErrorAndShowMessage(error)
      } finally {
        setLoadingRoles(false)
      }
    }

    _getNodeClasses()
  }, [initialValue, setNodeClasses, setLoadingRoles, setNodeClassOptions])

  return (
    <SearchableDropDown
      {...{
        ...searchableDropdownCommonProps,
        searchableKey: "name",
        valueSelectorKey: "id",
        width: "100%",
        isMulti: true,
        value: nodeClasses,
        options: nodeClassOptions,
        loading: loadingRoles,
        enableClear: true,
        keepInView: false,
        keepDropdownInView: true,
        tagRenderer: ({
          option,
          renderTagOptionName,
          renderTagDeleteAction,
          deleteTagAction,
          tagLabelValue,
          TagWrapper,
        }) => {
          const nodeClass = option?.id

          return (
            <TagWrapper key={option.id}>
              <span className="m-r-xs">
                <DeviceIcon {...{ nodeClass }} />
              </span>

              {option?.name}
              {renderTagDeleteAction()}
            </TagWrapper>
          )
        },
        onSelect: selected => {
          setNodeClasses(selected)

          onChange(!!selected.length ? pluck("id", selected) : null)
        },
      }}
    />
  )
}
