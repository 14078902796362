import React from "react"
import { isNil } from "ramda"
import { StyledAnchor } from "js/includes/components/Styled"

export default function UrlValueRenderer(field, { value } = {}) {
  return isNil(value) ? null : (
    <StyledAnchor href={value} target="_blank" rel="noopener noreferrer" whiteSpace="normal">
      {value}
    </StyledAnchor>
  )
}
