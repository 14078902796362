import { Suspense, useCallback, useEffect, useRef, useState } from "react"

// Taken from https://github.com/facebook/lexical/blob/main/packages/lexical-playground/src/nodes/ImageComponent.tsx
import {
  $getNodeByKey,
  $getSelection,
  $isNodeSelection,
  CLICK_COMMAND,
  COMMAND_PRIORITY_LOW,
  DRAGSTART_COMMAND,
  KEY_BACKSPACE_COMMAND,
  KEY_DELETE_COMMAND,
} from "lexical"

import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { useLexicalNodeSelection } from "@lexical/react/useLexicalNodeSelection"
import { mergeRegister } from "@lexical/utils"
import { getSignedUrlFromContentId } from "js/includes/common/client"
import { useMountedState } from "js/includes/common/hooks"
import { localizationKey, reportErrorAndShowMessage } from "js/includes/common/utils"
import Spinner from "js/includes/components/Spinner"
import { $isInlineImageNode } from "js/includes/components/CustomFields/WYSIWYGEditor/plugins/InlineImagePlugin/Node"
import { ImageResizer } from "@ninjaone/components/src/WYSIWYG"
import useImageEvents from "js/includes/components/CustomFields/WYSIWYGEditor/plugins/InlineImagePlugin/hooks/useImageEvents"
import { withLazyRenderInView } from "js/includes/components/CustomFields/common/withLazyRenderInView"

const LazyImage = ({ className, imageRef, src, width, height, maxWidth, altText }) => {
  return (
    <img
      ref={imageRef}
      className={className}
      src={src}
      style={{
        height: `${height}px`,
        maxWidth,
        width: `${width}px`,
      }}
      alt={altText}
      draggable="false"
      loading="lazy"
    />
  )
}

function ImageComponent(props) {
  const {
    altText,
    nodeKey,
    width,
    height,
    maxWidth,
    resizable,
    isInView,
    contentId,
    tempUriByContentIdRef,
    uploadsByContentIdRef,
  } = props
  const imageRef = useRef(null)
  const buttonRef = useRef(null)
  const activeEditorRef = useRef(null)

  const isTempUploadedImage = !!uploadsByContentIdRef?.current[contentId]
  const isNewNode = isTempUploadedImage

  const [isSelected, setSelected, clearSelection] = useLexicalNodeSelection(nodeKey)
  const [isResizing, setIsResizing] = useState(false)
  const [selection, setSelection] = useMountedState(null)
  const [editor] = useLexicalComposerContext()

  const [src, setSrc] = useMountedState(tempUriByContentIdRef.current[contentId] ?? `cid:${contentId}`)
  const [loadingSignedURL, setLoadingSignedURL] = useMountedState(src?.startsWith("cid:"))

  const { onDelete, onClick } = useImageEvents({
    editor,
    isResizing,
    isSelected,
    setSelected,
    setSelection,
    clearSelection,
    nodeKey,
    imageRef,
    buttonRef,
    activeEditorRef,
  })

  const loadSignedUrl = useCallback(async () => {
    if (!isNewNode && isInView && contentId) {
      try {
        const { signedUrl } = await getSignedUrlFromContentId(contentId)
        setSrc(signedUrl)
      } catch (error) {
        reportErrorAndShowMessage(error, localizationKey("Failed to load inline image"))
      } finally {
        setLoadingSignedURL(false)
      }
    }
  }, [isNewNode, isInView, contentId, setSrc, setLoadingSignedURL])

  useEffect(loadSignedUrl, [loadSignedUrl])

  useEffect(() => {
    const unregister = mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        setSelection(editorState.read(() => $getSelection()))
      }),
      editor.registerCommand(CLICK_COMMAND, onClick, COMMAND_PRIORITY_LOW),
      editor.registerCommand(
        DRAGSTART_COMMAND,
        event => {
          if (event.target === imageRef.current) {
            // TODO This is just a temporary workaround for FF to behave like other browsers.
            // Ideally, this handles drag & drop too (and all browsers).
            event.preventDefault()
            return true
          }
          return false
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(KEY_DELETE_COMMAND, onDelete, COMMAND_PRIORITY_LOW),
      editor.registerCommand(KEY_BACKSPACE_COMMAND, onDelete, COMMAND_PRIORITY_LOW),
    )

    return unregister
  }, [editor, onDelete, onClick, setSelection])

  const onResizeEnd = (nextWidth, nextHeight) => {
    // Delay hiding the resize bars for click case
    setTimeout(() => {
      setIsResizing(false)
    }, 200)

    editor.update(() => {
      const node = $getNodeByKey(nodeKey)
      if ($isInlineImageNode(node)) {
        node.setWidthAndHeight(nextWidth, nextHeight)
      }
    })
  }

  const onResizeStart = () => setIsResizing(true)

  const editable = editor.isEditable()
  const isNodeSelection = $isNodeSelection(selection)
  const draggable = isSelected && isNodeSelection && !isResizing && editable
  const isFocused = isSelected || isResizing

  return (
    <Suspense fallback={<Spinner />}>
      <>
        <div draggable={draggable}>
          {loadingSignedURL ? null : (
            <LazyImage
              src={src}
              altText={altText}
              imageRef={imageRef}
              width={width}
              height={height}
              maxWidth={maxWidth}
              className={isFocused ? `focused ${isNodeSelection ? "draggable" : ""}` : null}
            />
          )}
        </div>
        {resizable && isNodeSelection && isFocused && editable && (
          <ImageResizer
            editor={editor}
            buttonRef={buttonRef}
            imageRef={imageRef}
            maxWidth={maxWidth}
            onResizeStart={onResizeStart}
            onResizeEnd={onResizeEnd}
          />
        )}
      </>
    </Suspense>
  )
}

export default withLazyRenderInView(ImageComponent)
