import PropTypes from "prop-types"
import { values } from "ramda"

import { automationTypes } from "js/includes/ticketing/common/automations"

export const attributeType = PropTypes.shape({
  labelText: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
})

export const validationType = PropTypes.shape({
  success: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
})

export const ticketAttributesType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
)

export const attributesType = PropTypes.arrayOf(
  PropTypes.shape({
    labelText: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    content: PropTypes.object,
    groupName: PropTypes.string.isRequired,
  }),
)

const conditionType = PropTypes.shape({
  field: PropTypes.string,
  id: PropTypes.string.isRequired,
  operator: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
})

export const conditionsType = PropTypes.shape({
  all: PropTypes.arrayOf(conditionType).isRequired,
  any: PropTypes.arrayOf(conditionType).isRequired,
})

const optionsType = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }),
)

const fieldOptionType = PropTypes.shape({
  labelText: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
})

const fieldType = PropTypes.shape({
  options: PropTypes.arrayOf(fieldOptionType).isRequired,
  optionsMap: PropTypes.objectOf(fieldOptionType),
})

export const fieldsType = PropTypes.shape({
  all: fieldType.isRequired,
  any: fieldType.isRequired,
})

export const organizationsType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
)

export const triggerType = PropTypes.oneOf([...values(automationTypes), "BOARD"])

export const formOptionsType = optionsType

export const tagsType = PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string.isRequired }))

export const actionsType = PropTypes.arrayOf(
  PropTypes.shape({
    field: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    operator: PropTypes.string,
    uid: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.number, PropTypes.string]),
  }),
)

export const notificationChannelsType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
)
