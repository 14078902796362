import { useEffect, useState } from "react"
import styled from "@emotion/styled"
import { nanoid } from "nanoid"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/pro-light-svg-icons"
import { faSync, faTimes } from "@fortawesome/pro-solid-svg-icons"

import tokens from "@ninjaone/tokens"
import { Body, ConfirmationModal, Input, Tooltip } from "@ninjaone/components"
import { Label } from "@ninjaone/components/src/Form/Label"
import { sizer } from "@ninjaone/utils"

import { Box, Flex } from "js/includes/components/Styled"
import { localizationKey, localized, reportErrorAndShowMessage } from "js/includes/common/utils"
import { getCustomFieldSecureValue } from "js/includes/common/client/customFields"
import {
  ENABLE_CLICK_ON_PORTAL_CLASS_NAME,
  handleFormFieldBlur,
  handleFormFieldChange,
} from "js/includes/components/CustomFields/common/utils"
import CircleTimer from "js/includes/components/CustomFields/FormFields/TextEncryptedFormField/CircleTimer"

function generatePasswordDescription(advancedSettings) {
  const { complexityRules } = advancedSettings

  const rulesMap = {
    mustContainOneInteger: localized("one integer"),
    mustContainOneUppercaseLetter: localized("one uppercase letter"),
    mustContainOneLowercaseLetter: localized("one lowercase letter"),
    greaterOrEqualThanSixCharacters: localized("at least six characters"),
  }

  const rules = Object.entries(complexityRules)
    .filter(([_, isActive]) => isActive)
    .map(([rule]) => rulesMap[rule])

  return rules.length > 0
    ? `${localized("Password must contain: ")} ${rules.join(", ")}.`
    : localized(
        "Suggested passwords contain: One integer, one uppercase letter, one lowercase letter, at least six characters.",
      )
}

const StyledList = styled.ul`
  list-style: initial;
  padding-left: ${tokens.spacing[5]};
  margin-top: ${tokens.spacing[2]};
`

const StyledIcon = styled(Box)`
  cursor: pointer;
`

const StyledInput = styled(Input)`
  padding-right: ${({ paddingRight }) => paddingRight};
`

const TextEncryptedModal = ({
  unmount,
  advancedSettings,
  onConfirm,
  id,
  entityType,
  initialValue,
  hasValidationError,
  validationMessage,
  validateFormFields,
  value,
  onChange,
  onBlur,
  isHookForm,
  required,
}) => {
  const isValuePlaceholder = value?.startsWith("SECURE_VALUE_PLACEHOLDER")

  const [inputValue, setInputValue] = useState(isValuePlaceholder ? "" : value)
  const [isAuthorized, setIsAuthorized] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const [showTimer, setShowTimer] = useState(false)

  const generateValue = e => {
    e.stopPropagation()
    const generatedValue = nanoid(10) + nanoid(10)
    setInputValue(generatedValue)
  }

  const clearValue = e => {
    e.stopPropagation()
    setInputValue("")
    setShowTimer(false)
  }

  const togglePlainTextValue = e => {
    e.stopPropagation()
    setIsVisible(prevState => !prevState)
    setShowTimer(!isVisible)
  }

  const passwordRulesDescription = generatePasswordDescription(advancedSettings)

  useEffect(() => {
    ;(async () => {
      try {
        if (isValuePlaceholder) {
          const response = await getCustomFieldSecureValue(entityType, initialValue?.id)
          setIsAuthorized(true)
          setInputValue(response.value)
        }
      } catch (error) {
        if (!error.isHandledMfaError) {
          reportErrorAndShowMessage(error)
        }
      }
    })()

    return () => {
      setIsAuthorized(false)
    }
  }, [value, entityType, initialValue?.id, isValuePlaceholder])

  return isAuthorized || !isValuePlaceholder ? (
    <ConfirmationModal
      backgroundClassName={ENABLE_CLICK_ON_PORTAL_CLASS_NAME}
      unmount={unmount}
      titleToken={localizationKey("Edit Secure password")}
      descriptionToken={localizationKey("Assign a password to this document.")}
      actionToken={localizationKey("Confirm")}
      onConfirm={() => {
        handleFormFieldChange({ onChange, id, isHookForm })(inputValue === "" ? null : inputValue)
        unmount()
      }}
      size="sm"
    >
      <Box position="relative">
        <Flex gap={tokens.spacing[1]}>
          <Label labelText={localized("Password")} required={required} />
          {showTimer && (
            <CircleTimer
              timerCount={20}
              resetTimer={() => {
                setShowTimer(false)
                setIsVisible(false)
              }}
            />
          )}
        </Flex>
        <Box position="relative">
          <StyledInput
            type={isVisible ? "text" : "password"}
            value={inputValue}
            placeholder={localized("Enter password")}
            errorMessage={validationMessage}
            onBlur={handleFormFieldBlur({ onBlur, id, isHookForm, validateFormFields })}
            onChange={e => {
              setInputValue(e.target.value)
            }}
            paddingRight={hasValidationError ? "80px" : "50px"}
          />
          <Box position="absolute" right={hasValidationError ? "36px" : "12px"} top="50%" transform="translateY(-50%)">
            <Flex gap={sizer(2)}>
              {!!inputValue ? (
                <StyledIcon onClick={clearValue}>
                  <Tooltip label={localized("Clear")}>
                    <FontAwesomeIcon icon={faTimes} />
                  </Tooltip>
                </StyledIcon>
              ) : (
                <StyledIcon key="generateValue" onClick={generateValue} onMouseDown={e => e.preventDefault()}>
                  <Tooltip label={localized("Generate password")}>
                    <FontAwesomeIcon icon={faSync} />
                  </Tooltip>
                </StyledIcon>
              )}
              <StyledIcon onClick={togglePlainTextValue}>
                <Tooltip label={isVisible ? localized("Hide password") : localized("Show password")}>
                  <FontAwesomeIcon icon={isVisible ? faEyeSlash : faEye} />
                </Tooltip>
              </StyledIcon>
            </Flex>
          </Box>
        </Box>
      </Box>
      <StyledList>
        <li>
          <Body type="bodyXs" textWrap>
            {localized("Confirmed changes are not saved until the editor is saved.")}
          </Body>
        </li>
        <li>
          <Body type="bodyXs" textWrap>
            {passwordRulesDescription}
          </Body>
        </li>
      </StyledList>
    </ConfirmationModal>
  ) : null
}

export default TextEncryptedModal
