import { useEffect, useState } from "react"
import qs from "qs"
import { compose, map, uniq } from "ramda"
import { fetchJson, localizationKey, localized, reportErrorAndShowMessage } from "js/includes/common/utils"
import SearchableDeviceDropdown from "js/includes/components/SearchableDeviceDropdown"
import { Box, StyledSpan } from "js/includes/components/Styled"
import DeviceIcon from "js/includes/components/DeviceIcon"
import { useMountedState } from "js/includes/common/hooks"
import Loading from "js/includes/components/Loading"
import {
  handleFormFieldBlur,
  handleFormFieldChange,
  searchableDropdownCommonProps,
} from "js/includes/components/CustomFields/common"

export default function DeviceDropdownFormField({
  id,
  value,
  onChange,
  onBlur,
  isHookForm = false,
  validationState,
  advancedSettings,
  dropdownContainerId,
  technicianPermission,
  data,
}) {
  const [clientIds] = useState(
    compose(
      uniq,
      map(clientId => (clientId === 0 && data?.clientId ? data?.clientId : clientId)),
    )(advancedSettings?.org ?? []),
  )

  const [initialValue] = useState(value)
  const [loading, setLoading] = useMountedState(false)
  const [device, setDevice] = useMountedState(null)

  useEffect(() => {
    const getInitialSelection = async () => {
      try {
        setLoading(true)

        const _qs = qs.stringify({
          pageSize: 1,
          nodeId: initialValue?.entityId,
        })

        const [device] = await fetchJson(`${"/query/devices"}?${_qs}`)

        setDevice(device)
      } catch (error) {
        reportErrorAndShowMessage(error)
      } finally {
        setLoading(false)
      }
    }

    if (initialValue?.entityId) {
      getInitialSelection()
    }
  }, [initialValue, setLoading, setDevice])

  return loading ? (
    <Loading />
  ) : (
    <SearchableDeviceDropdown
      {...{
        org: clientIds,
        nodeClass: advancedSettings?.nodeClass,
        pageSize: 50,
        customDropdownOptions: {
          ...searchableDropdownCommonProps,
          dropdownContainerId,
          value: device,
          width: "100%",
          enableClear: true,
          keepInView: false,
          keepDropdownInView: true,
          excludeSelectedFromSelectableValues: true,
          disabled: technicianPermission !== "EDITABLE",
          noRowsRendererToken: localizationKey("No options available"),
          validationState,
          getInitialValue: () => device?.name,
        },
        customRowRenderer: rowData => {
          const { organizationName, nodeClass, name, id } = rowData
          return (
            <Box key={`${id}-device`}>
              <h4 className="no-margin p-l-xs line-height-initial">
                <StyledSpan marginRight={1}>
                  <DeviceIcon {...{ nodeClass, className: "" }} />
                </StyledSpan>
                <StyledSpan>{name}</StyledSpan>
              </h4>
              <Box marginTop={1} paddingLeft={1} lineHeight="initial" className="text-ellipsis">
                {organizationName || localized("N/A")}
              </Box>
            </Box>
          )
        },
        onSelect: selected => {
          setDevice(selected)

          handleFormFieldChange({
            id,
            onChange,
            isHookForm,
          })(
            !!selected
              ? {
                  entityId: selected.id,
                  type: "NODE",
                }
              : null,
          )
        },
        onBlur: handleFormFieldBlur({ onBlur, id, isHookForm }),
      }}
    />
  )
}
