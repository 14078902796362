import { isNil } from "ramda"
import { Checkbox } from "js/includes/components/NinjaReactICheck"
import { StyledButton } from "js/includes/components/Styled/Form"
import { Flex, StyledFontAwesomeIcon } from "js/includes/components/Styled"
import { faTimes } from "@fortawesome/pro-solid-svg-icons"
import { Tooltip } from "@ninjaone/components"
import { isNotNil, localized, yesNo } from "js/includes/common/utils"
import { sizer } from "@ninjaone/utils"
import { handleFormFieldChange } from "js/includes/components/CustomFields/common"

export const yesNoUnset = value => (isNil(value) ? localized("No Value") : yesNo(value))

export default function CheckboxFormField({ id, technicianPermission, value, onChange, isHookForm = false }) {
  const isDisabled = technicianPermission !== "EDITABLE"

  const handleChange = handleFormFieldChange({ onChange, id, isHookForm })

  return (
    <Flex width="100%" position="relative" alignItems="center">
      <Tooltip label={yesNoUnset(value)}>
        <Checkbox
          checked={value || false}
          checkboxClass="icheckbox_square-blue"
          disabled={isDisabled}
          onChange={() => handleChange(!value)}
        />
      </Tooltip>

      {isNotNil(value) && (
        <Tooltip label={localized("Unset Value")}>
          <StyledButton
            padding={sizer(0, 3)}
            hoverTextColor="ninjaDark"
            color="ninjaDark"
            disabled={isDisabled}
            onClick={e => {
              e.stopPropagation()
              handleChange(null)
            }}
          >
            <StyledFontAwesomeIcon icon={faTimes} />
          </StyledButton>
        </Tooltip>
      )}
    </Flex>
  )
}
