import { useState } from "react"
import moment from "moment"
import { DatePicker, TimePicker } from "@ninjaone/components"
import { Flex } from "js/includes/components/Styled"
import {
  disabledDays,
  handleFormFieldBlur,
  handleFormFieldChange,
  isValidDate,
} from "js/includes/components/CustomFields/common"
import { localized } from "js/includes/common/utils"

export default function DateTimeFormField({
  id,
  value,
  onChange,
  onBlur,
  isHookForm = false,
  validationMessage,
  fixedRdtPicker,
  advancedSettings,
  validateFormFields,
  technicianPermission,
}) {
  const [dateTime, setDateTime] = useState(value ? new Date(value) : null)

  const handleChange = handleFormFieldChange({
    id,
    onChange,
    isHookForm,
  })

  const handleBlur = handleFormFieldBlur({ onBlur, id, isHookForm, validateFormFields })

  const updateDateOnly = (prevState, momentDateTime) => {
    const momentPrevDate = moment(prevState)
    const newDateTime = moment(momentDateTime)

    newDateTime.hours(momentPrevDate.hours())
    newDateTime.minutes(momentPrevDate.minutes())
    newDateTime.seconds(momentPrevDate.seconds())

    return newDateTime
  }

  const updateTimeOnly = (prevState, momentDateTime) => {
    const momentPrevDate = moment(prevState)
    const newDateTime = moment(momentPrevDate)

    newDateTime.hours(momentDateTime.hours())
    newDateTime.minutes(momentDateTime.minutes())
    newDateTime.seconds(momentDateTime.seconds())

    return newDateTime
  }

  const updateDateTime = ({ date: dateTime, type }) =>
    setDateTime(prevState => {
      if (!dateTime) {
        handleChange(null)
        return
      }

      const momentDateTime = moment(dateTime)

      if (prevState && moment(prevState).isValid()) {
        let newDateTime

        if (type === "DATE") {
          newDateTime = updateDateOnly(prevState, momentDateTime)
        } else if (type === "TIME") {
          newDateTime = updateTimeOnly(prevState, momentDateTime)
        } else {
          newDateTime = momentDateTime
        }

        handleChange(newDateTime.valueOf())
        return newDateTime.toDate()
      } else {
        const newDateTime = momentDateTime.valueOf()
        handleChange(newDateTime)
        return momentDateTime.toDate()
      }
    })

  const isDisabledDay = (dateTime, advancedSettings) => {
    const disabledDaysOrRange = disabledDays(advancedSettings)

    if (Array.isArray(disabledDaysOrRange)) {
      return disabledDaysOrRange.some(disabledDate => moment(disabledDate).isSame(moment(dateTime), "day"))
    }

    if (disabledDaysOrRange && typeof disabledDaysOrRange === "object") {
      const { before = null, after = null } = disabledDaysOrRange

      if (before && after)
        return moment(dateTime).isAfter(moment(after), "day") || moment(dateTime).isBefore(moment(before), "day")

      if (before) return moment(dateTime).isBefore(moment(before), "day")

      if (after) return moment(dateTime).isAfter(moment(after), "day")
    }

    return false
  }

  return (
    <Flex gap={2}>
      <DatePicker
        ariaLabel={localized("custom field date picker")}
        selectedDays={dateTime}
        disabledDays={disabledDays(advancedSettings)}
        onDayChange={({ date }) => {
          if (!isValidDate(date, advancedSettings)) return
          updateDateTime({ date, type: "DATE" })
        }}
        onError={handleBlur}
        disabled={technicianPermission !== "EDITABLE"}
        readOnly
        errorMessage={validationMessage}
        useDropdownNavigation
      />
      <TimePicker
        initialValue={value}
        value={dateTime}
        onTimeChange={date => updateDateTime({ date, type: "TIME" })}
        disableClear={!!dateTime}
        onBlur={handleBlur}
        disabled={technicianPermission !== "EDITABLE" || !dateTime || isDisabledDay(dateTime, advancedSettings)}
        portal={false}
        ariaLabel={localized("custom field time picker")}
      />
    </Flex>
  )
}
