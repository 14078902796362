import {
  applySpec,
  complement,
  compose,
  defaultTo,
  equals,
  filter,
  isEmpty,
  map,
  mergeLeft,
  path,
  propEq,
  reduce,
  uniq,
} from "ramda"
import { localized, showErrorMessage } from "js/includes/common/utils"

export const payloadPolicyAction = {
  INSERT: "INSERT",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
}

export const showGlobalErrorMessage = () =>
  showErrorMessage(localized("An error has occurred on the XML configurations editor."))

export const getFormattingErrorMessage = error => {
  const errorLine = path(["loc", "start", "line"], error)
  return errorLine
    ? localized("There's an formatting issue in the XML at line {{errorLine}}", {
        errorLine,
      })
    : localized("There's an issue in the XML. Please review.")
}

export const defaultPayloadType = "Configuration"

const filterDefaultConfigurationType = filter(complement(equals(defaultPayloadType)))

export const isPayloadMetadataNotLoaded = rows => isEmpty(rows) || rows.some(({ name }) => !name)

export const mapPayloadsWithMetadata = (payloadsFromPolicy = [], payloadsMetadata = []) => {
  if (isEmpty(payloadsMetadata)) return payloadsFromPolicy
  return payloadsFromPolicy.map(payload => {
    const payloadMetadata = payloadsMetadata.find(propEq("id", payload.id))
    if (!payloadMetadata) return payload
    const {
      name,
      content,
      metadata: { contentType, type },
    } = payloadMetadata

    const _contentType = [...contentType, ...[type ?? defaultPayloadType]]
    return mergeLeft(payload, {
      name,
      content,
      contentType: _contentType.length > 1 ? filterDefaultConfigurationType(_contentType) : _contentType,
    })
  })
}

const defaultToConfiguration = defaultTo(defaultPayloadType)

export const getPayloadTypesFromRows = compose(
  map(applySpec({ label: defaultToConfiguration, value: defaultToConfiguration })),
  uniq,
  reduce((acc, { contentType }) => acc.concat(contentType), []),
)
