import { useEffect } from "react"
import qs from "qs"
import { isNil } from "ramda"
import { fetchJson, reportErrorAndShowMessage } from "js/includes/common/utils"
import { Box, StyledSpan } from "js/includes/components/Styled"
import { useMountedState } from "js/includes/common/hooks"
import Loading from "js/includes/components/Loading"
import { Body, Link } from "@ninjaone/components"
import { spacing } from "@ninjaone/tokens"

export default function SingleCustomerLocationValueRenderer(field, { id, value } = {}, renderTextOnly) {
  const [loading, setLoading] = useMountedState(false)
  const [location, setLocation] = useMountedState(null)

  useEffect(() => {
    const getInitialSelection = async () => {
      try {
        setLoading(true)

        const _qs = qs.stringify({
          pageSize: 1,
          id: value?.entityId,
        })

        const [location] = await fetchJson(`/query/locations?${_qs}`)

        setLocation(location)
      } catch (error) {
        reportErrorAndShowMessage(error)
      } finally {
        setLoading(false)
      }
    }

    if (value?.entityId) {
      getInitialSelection()
    }
  }, [value?.entityId, setLoading, setLocation])

  return loading ? (
    <Loading />
  ) : isNil(value) || isNil(location) ? null : renderTextOnly ? (
    <Body>{location?.name}</Body>
  ) : (
    <Box key={`${location?.id}-location`}>
      <Link href={`#/customerDashboard/${location?.organizationId}/location/${location?.id}`}>
        <Body color="inherit">
          {location?.name}
          <StyledSpan marginLeft={spacing[1]}>({location?.organizationName})</StyledSpan>
        </Body>
      </Link>
    </Box>
  )
}
