import { handleFormFieldChange } from "js/includes/components/CustomFields/common"
import { PhoneInput } from "@ninjaone/components"
import { localized } from "js/includes/common/utils"

export default function PhoneFormField({
  id,
  value,
  onChange,
  onBlur,
  isHookForm = false,
  validationState,
  validationMessage,
  validateFormFields,
  technicianPermission,
  label,
  required,
  tooltipText,
}) {
  const handleChange = handleFormFieldChange({ onChange, id, isHookForm })

  return (
    <PhoneInput
      {...{
        id,
        labelText: localized(label),
        value,
        onChange: phone => {
          handleChange(phone || null)
        },
        onBlur,
        isHookForm,
        validateFormFields,
        technicianPermission,
        errorMessage: validationMessage,
        required,
        tooltipText,
      }}
    />
  )
}
