import { $getSelection, $isRangeSelection, SELECTION_INSERT_CLIPBOARD_NODES_COMMAND } from "lexical"

import { $insertDataTransferForRichText } from "@lexical/clipboard"
import { $generateNodesFromDOM } from "@lexical/html"

import { convertHexToBase64, extractImageDataFromRtf, isMsWord, parseHtml } from "@ninjaone/components/src/WYSIWYG"

// Based on onPasteForRichText from https://github.com/facebook/lexical/blob/main/packages/lexical-rich-text/src/index.ts
export const onPasteForRichText = (event, editor) => {
  event.preventDefault()
  editor.update(
    () => {
      const selection = $getSelection()
      const clipboardData = event instanceof InputEvent || event instanceof KeyboardEvent ? null : event.clipboardData
      if (
        clipboardData &&
        $isRangeSelection(
          selection,
        ) /* || $isTableSelection(selection) TODO: replace with $isTableSelection if this is used*/
      ) {
        // Based on $insertDataTransferForRichText from https://github.com/facebook/lexical/blob/main/packages/lexical-clipboard/src/clipboard.ts
        const htmlString = clipboardData.getData("text/html")
        if (!htmlString) return false

        // TODO: Fix list items
        if (isMsWord(htmlString)) {
          const dom = parseHtml(htmlString)

          const images = dom.querySelectorAll("img")
          const imagesRtf = extractImageDataFromRtf(event.clipboardData.getData("text/rtf"))

          if (images.length === imagesRtf.length) {
            for (let i = 0; i < images.length; i++) {
              const image = images[i]
              const type = imagesRtf[i].type

              if (image.src.startsWith("file:///")) {
                const src = `data:${type};base64,${convertHexToBase64(imagesRtf[i].hex)}`
                image.setAttribute("src", src)
              }
            }
          }

          const selection = $getSelection()
          const nodes = $generateNodesFromDOM(editor, dom)
          if (
            !editor.dispatchCommand(SELECTION_INSERT_CLIPBOARD_NODES_COMMAND, {
              nodes,
              selection,
            })
          ) {
            selection.insertNodes(nodes)
            return true
          } else {
            return false
          }
        } else {
          $insertDataTransferForRichText(clipboardData, selection, editor)
          return true
        }
      }
    },
    {
      tag: "paste",
    },
  )
}

const imageCorrection = {
  "<cid-img": "<img",
  "></cid-img>": "/>",
}

const regex = new RegExp(`<cid-img|></cid-img>`, "gm")

export const normalizeHtml = html => html.replace(regex, matched => imageCorrection[matched])

export const getContentIdFromSrc = (src, tempUriByContentIdRef) => {
  if (src?.startsWith("https")) {
    const splitUrl = src.split("?")[0].split("/")
    return {
      contentId: splitUrl[splitUrl.length - 1] || null,
    }
  }

  if (src?.startsWith("data:image/png;base64")) {
    const contentId = Object.entries(tempUriByContentIdRef.current).reduce((result, [key, value]) => {
      if (value === src) {
        result = key
      }

      return result
    }, "")

    return {
      contentId: contentId ?? null,
    }
  }

  return {
    contentId: null,
  }
}
