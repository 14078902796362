import React from "react"
import { StyledFormGroup, StyledCol, StyledRow } from "js/includes/components/Styled/Form"

export default function FormTitle({ uiElementValue }, containerStyle) {
  return (
    <StyledFormGroup textAlign="left" {...containerStyle}>
      <StyledRow display="block" margin={0}>
        <StyledCol>
          <h3 className="no-margin">{uiElementValue?.toUpperCase?.()}</h3>
        </StyledCol>
      </StyledRow>
    </StyledFormGroup>
  )
}
