import { useEffect, useRef } from "react"
import { parseHtml } from "@ninjaone/components/src/WYSIWYG"
import { getSignedUrlFromContentId } from "js/includes/common/client"
import { useMountedState } from "js/includes/common/hooks"
import {
  isNotEmpty,
  localizationKey,
  localized,
  reportErrorAndShowMessage,
  ninjaReportError,
} from "js/includes/common/utils"

const replaceEmptyParagraphs = html => {
  return html?.replace(/<p[^>]*>\s*<\/p>/gm, "<br>")
}

const cidImagesRegex = /<img[^?<]+?src="cid:(.+?)"[^?>]*?>/gm

const getCidKey = contentId => `cid:${contentId}`

const loadingImageHtml = contentId => {
  return `<p id="${contentId}">${localized("Loading image ...")}</p>`
}

const brokenImageHtml = () => {
  return `<p>${localized("Broken Image")}</p>`
}

export const useHtmlWithSignedUrls = ({ initialHtml, contentWindowRef, attachmentProcessed }) => {
  const processingImgHtmlRef = useRef({})
  const processingContentIdRef = useRef({})
  const [html, setHtml] = useMountedState(replaceEmptyParagraphs(initialHtml))
  const [loading, setLoading] = useMountedState(false)

  useEffect(() => {
    ;(async function fetchInitialSignedUrls() {
      const handled = {}
      const promises = []
      const successful = {}
      const processing = []
      const unsuccessful = []
      const htmlHasImages = initialHtml?.includes(`src="cid:`)

      if (htmlHasImages) {
        setLoading(true)

        try {
          const matches = initialHtml.matchAll(cidImagesRegex)

          for (const match of matches) {
            const contentId = match[1]
            const key = getCidKey(contentId)

            if (handled[key]) {
              continue
            }

            promises.push(getSignedUrlFromContentId(contentId))

            handled[key] = true
          }

          const responses = await Promise.all(promises)

          responses.forEach(attachment => {
            const { attachmentId, uploadStatus, signedUrl, metadata: { contentId } = {} } = attachment

            if (!contentId) {
              return
            }

            if (uploadStatus === "SUCCESS") {
              const key = getCidKey(contentId)
              successful[key] = signedUrl
            } else if (uploadStatus === "PROCESSING") {
              processing.push(`<img[^?<]+?src="cid:(${contentId})"[^?>]*?>`)
              processingContentIdRef.current[attachmentId] = contentId
            } else {
              unsuccessful.push(`<img[^?<]+?src="cid:${contentId}"[^?>]*?>`)
            }
          })
        } catch (error) {
          reportErrorAndShowMessage(error, localizationKey("Failed to load inline images"))
        } finally {
          setLoading(false)
        }
      }

      setHtml(html => {
        let updatedHtml = initialHtml

        if (isNotEmpty(successful)) {
          const imgSrcRegex = new RegExp(Object.keys(successful).join("|"), "gm")
          updatedHtml = updatedHtml.replace(imgSrcRegex, matched => successful[matched])
        }

        if (isNotEmpty(processing)) {
          updatedHtml = processing.reduce((html, regex) => {
            const imgRegex = new RegExp(regex, "gm")
            return html.replace(imgRegex, (matched, contentId) => {
              // Save the matched html to handle
              processingImgHtmlRef.current[contentId] = matched
              return loadingImageHtml(contentId)
            })
          }, updatedHtml)
        }

        if (isNotEmpty(unsuccessful)) {
          const imgRegex = new RegExp(unsuccessful.join("|"), "gm")
          updatedHtml = updatedHtml.replace(imgRegex, matched => brokenImageHtml())
        }

        return replaceEmptyParagraphs(updatedHtml)
      })
    })()
  }, [initialHtml, setHtml, setLoading])

  useEffect(() => {
    if (attachmentProcessed) {
      ;(async function processPendingAttachment() {
        const contentId = processingContentIdRef.current[attachmentProcessed.attachmentId]

        if (!contentId) return

        const tempElement = contentWindowRef.current.document.getElementById(contentId)

        if (!tempElement) return

        try {
          const { signedUrl, uploadStatus } = await getSignedUrlFromContentId(contentId)

          if (uploadStatus === "SUCCESS") {
            const imgHtmlWithContentId = processingImgHtmlRef.current[contentId]
            const imgHtmlWithSignedUrl = imgHtmlWithContentId.replace(`cid:${contentId}`, signedUrl)
            const imgElement = parseHtml(imgHtmlWithSignedUrl).body.firstChild

            tempElement.replaceWith(imgElement)
          } else {
            const brokenImgElement = parseHtml(brokenImageHtml()).body.firstChild

            tempElement.replaceWith(brokenImgElement)
          }
        } catch (error) {
          ninjaReportError(error)
        }
      })()
    }
  }, [contentWindowRef, attachmentProcessed])

  return { loading, html }
}
