import { Input } from "@ninjaone/components"
import { handleFormFieldBlur, handleFormFieldChange } from "js/includes/components/CustomFields/common"
import { localized } from "js/includes/common/utils"

const prefix = "https://"
const regex = /^(https?:\/\/)/i

const removePrefix = value => {
  return value.replace(regex, "")
}

const UrlFormField = ({
  id,
  value,
  onChange,
  onBlur,
  isHookForm = false,
  validateFormFields,
  validationMessage,
  technicianPermission,
  label,
  required,
  tooltipText,
}) => {
  const handleChange = ({ target: { value } }) => {
    handleFormFieldChange({ onChange, id, isHookForm })(
      !!value ? prefix + removePrefix(value).replace(/\s+/g, "") : null,
    )
  }

  return (
    <Input
      id={String(id)}
      type="text"
      labelText={localized(label)}
      value={!!value ? removePrefix(value) : ""}
      onChange={handleChange}
      onBlur={handleFormFieldBlur({ onBlur, id, isHookForm, validateFormFields })}
      disabled={technicianPermission !== "EDITABLE"}
      placeholder={localized("Url")}
      prefix={prefix}
      maxLength={200}
      errorMessage={validationMessage}
      required={required}
      tooltipText={tooltipText}
    />
  )
}

export default UrlFormField
