import { useTheme } from "@emotion/react"
import { CountdownCircleTimer } from "react-countdown-circle-timer"

const CircleTimer = ({ timerCount, resetTimer }) => {
  const theme = useTheme()

  return (
    <CountdownCircleTimer
      isPlaying
      duration={timerCount}
      colors={[theme.colorThemeTextWeak]}
      trailColor={theme.colorBackground}
      colorsTime={[]}
      strokeWidth={1}
      size={20}
      onComplete={() => {
        resetTimer()
      }}
    >
      {({ remainingTime }) => remainingTime}
    </CountdownCircleTimer>
  )
}

export default CircleTimer
