import { useState, memo, useCallback } from "react"
import { Box, Flex } from "js/includes/components/Styled"
import { Breadcrumb } from "@ninjaone/components"
import WizardModal from "@ninjaone/components/src/WizardModal/WizardModal"
import PropTypes from "prop-types"

const WizardStep = ({ children, isActive }) => {
  if (!isActive) return null

  return <div>{children}</div>
}

WizardStep.propTypes = {
  children: PropTypes.node.isRequired,
  isActive: PropTypes.bool.isRequired,
}

const Wizard = ({ steps, size, titleGroup, closeAction, unmount, isLoading, handleSubmit }) => {
  const [activeStep, setActiveStep] = useState({
    index: 0,
    name: steps[0].name,
  })

  const stepsLength = steps.length
  const activeStepIndex = activeStep.index
  const invalidActiveStep =
    typeof steps[activeStep.index].isValid === "function" ? !steps[activeStep.index].isValid() : false

  const handleNextStep = useCallback(() => {
    setActiveStep(activeStep => {
      const activeStepIndex = Math.min(activeStep.index + 1, stepsLength - 1)
      return {
        index: activeStepIndex,
        name: steps[activeStepIndex].name,
      }
    })
  }, [steps, stepsLength])

  const handleBackStep = useCallback(() => {
    setActiveStep(activeStep => {
      const activeStepIndex = Math.max(activeStep.index - 1, 0)
      return {
        index: activeStepIndex,
        name: steps[activeStepIndex].name,
      }
    })
  }, [steps])

  const breadcrumbs = steps.map((step, index) => ({
    name: step.label,
    active: index === activeStepIndex,
  }))

  return (
    <WizardModal
      unmount={unmount}
      size={size}
      titleGroup={titleGroup}
      handleSubmit={handleSubmit}
      closeAction={closeAction}
      handleNextStep={handleNextStep}
      handleBackStep={handleBackStep}
      stepsLength={stepsLength}
      activeHistoryIndex={activeStepIndex}
      disabled={isLoading}
      invalidActiveStep={invalidActiveStep}
      headingComponent={
        breadcrumbs.length > 0 && (
          <Flex justifyContent="space-between" alignItems="center">
            <Box width={300}>
              <Breadcrumb history={breadcrumbs} disableLastLink disableActiveLastLink disableCollapse />
            </Box>
          </Flex>
        )
      }
    >
      {steps.map((step, index) => {
        const Content = step.Content
        return (
          <WizardStep key={index} isActive={index === activeStepIndex}>
            <Content {...step.props} {...{ handleNextStep, handleBackStep }} />
          </WizardStep>
        )
      })}
    </WizardModal>
  )
}

Wizard.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      Content: PropTypes.node.isRequired,
      name: PropTypes.string.isRequired,
      props: PropTypes.object,
    }),
  ).isRequired,
  titleGroup: PropTypes.shape({
    titleText: PropTypes.string.isRequired,
    textWrap: PropTypes.bool,
  }).isRequired,
  closeAction: PropTypes.func.isRequired,
  unmount: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

export default memo(Wizard)
