import React, { useState } from "react"
import tokens from "@ninjaone/tokens"
import styled from "@emotion/styled"
import { EllipsisHIconSvg } from "@ninjaone/icons"
import { Button, Dropdown, Heading, Input, Tags } from "@ninjaone/components"
import { sizer } from "@ninjaone/utils"
import { Box, Flex } from "js/includes/components/Styled"
import { isEnterKey, localizationKey, localized } from "js/includes/common/utils"
import StyledDraggableList from "js/includes/components/Styled/DraggableList/StyledDraggableList"

const StyledAddButton = styled(Button)`
  height: 40px;
  &:disabled {
    color: ${({ theme }) => theme.colorTextWeakest};
    background-color: ${({ theme }) => theme.colorBackgroundCtaDisabled};
  }
`

const MultiOptionsField = ({ value, onChange, validation }) => {
  const [option, setOption] = useState("")

  return (
    <Box flex={1} width="100%">
      <Box marginBottom={tokens.spacing[1]} textAlign="left">
        <span>
          {localized("Option Value")}
          <sup>*</sup>
        </span>

        <Flex gap={sizer(1)} alignItems="center">
          <Box flexGrow={1}>
            <Input
              maxLength={50}
              value={option}
              onChange={e => {
                setOption(e.target.value)
              }}
              ariaLabel={localized("Add multi select option")}
              errorMessage={validation?.message?.options}
              onKeyPress={e => {
                if (isEnterKey(e)) {
                  e.preventDefault()
                  e.stopPropagation()

                  if (!option.trim().length) return

                  if (option && !value.options.some(o => o.name === option)) {
                    onChange({
                      options: [
                        ...value.options,
                        {
                          name: option,
                        },
                      ],
                    })
                    setOption("")
                  }
                }
              }}
            />
          </Box>
          <StyledAddButton
            disabled={!option.trim().length || value.options.some(o => o.name === option)}
            onClick={() => {
              if (option) {
                onChange({
                  options: [...value.options, { name: option }],
                })
                setOption("")
              }
            }}
            labelToken={localizationKey("Add")}
          />
        </Flex>
      </Box>

      <StyledDraggableList
        keyProp="name"
        dragLabel={value.options.length > 0 && localized("Drag items to re-order")}
        elements={value.options}
        onChange={options => {
          onChange({
            ...value,
            options,
          })
        }}
        renderElement={option => {
          const isDefaultOption = value.defaultValue === option.name || value.defaultValue === option.id
          const menuOptions = [
            ...(!isDefaultOption
              ? [
                  {
                    action: () =>
                      onChange({
                        ...value,
                        defaultValue: option.id || option.name,
                      }),
                    labelToken: localizationKey("Mark as default"),
                  },
                ]
              : []),
          ]

          return (
            <Flex justifyContent="space-between" alignItems="center">
              <Flex gap={tokens.spacing[2]}>
                <Heading level={6}>{option.name}</Heading>
                {isDefaultOption && <Tags labels={[{ id: "default", label: "Default" }]} />}
              </Flex>

              {menuOptions.length > 0 && (
                <Dropdown modal variant="compact" matchWidth alignRight options={menuOptions}>
                  <Box color="colorTextStrong">
                    <EllipsisHIconSvg />
                  </Box>
                </Dropdown>
              )}
            </Flex>
          )
        }}
        onRemoveElement={(_, option) => {
          const isDefaultOption = value.defaultValue === option.name || value.defaultValue === option.id
          const optionItemsFiltered = value.options.filter(o => o.name !== option.name)

          onChange({
            options: optionItemsFiltered,
            ...(isDefaultOption && {
              defaultValue: null,
            }),
          })
        }}
      />
    </Box>
  )
}

export default MultiOptionsField
