import tokens from "@ninjaone/tokens"
import { Input } from "@ninjaone/components"
import { MAX_FLOAT_VALUE, MAX_INTEGER_VALUE, MIN_FLOAT_VALUE, MIN_INTEGER_VALUE } from "js/includes/common/_constants"
import { isNilOrEmpty, localized } from "js/includes/common/utils"
import { Box, Flex } from "js/includes/components/Styled"

const INTEGER_STEP = 1
const DECIMAL_STEP = 0.1

const calculateMax = ({ topMax, isCurrentMaxEmpty, inputValue, currentMaxValue }) => {
  if (isCurrentMaxEmpty) return null

  const newMaxValue = inputValue >= currentMaxValue ? inputValue + 1 : currentMaxValue

  return Math.min(topMax, newMaxValue)
}

const calculateMin = ({ topMin, topMax, inputValue, enableDecimal }) => {
  if ((enableDecimal && !inputValue) || inputValue === null) {
    return inputValue
  }

  const minLimit = inputValue < 0 ? inputValue : Math.max(topMin, inputValue)

  return Math.min(minLimit, topMax - 1)
}

const getFinalInputValue = (enableDecimal, inputValue) => {
  if (!inputValue.trim().length) return null
  return enableDecimal ? Number(inputValue) : Math.trunc(Number(inputValue))
}

export default function NumericRangeSelector({ onChange, value, enableDecimal }) {
  const topMax = enableDecimal ? MAX_FLOAT_VALUE : MAX_INTEGER_VALUE
  const topMin = enableDecimal ? MIN_FLOAT_VALUE : MIN_INTEGER_VALUE
  const inputStep = enableDecimal ? DECIMAL_STEP : INTEGER_STEP

  return (
    <Box>
      <Flex flexDirection="column">
        <Box>
          <Input
            {...{
              type: "number",
              step: inputStep,
              labelText: localized("Minimum numeric value"),
              value: value.min ?? "",
              placeholder: localized("Enter value"),
              onChange: e => {
                const inputValue = e.target.value
                const finalInputValue = getFinalInputValue(enableDecimal, inputValue)

                onChange({
                  min: calculateMin({ topMin, topMax, inputValue: finalInputValue, enableDecimal }),
                  max: calculateMax({
                    topMax,
                    isCurrentMaxEmpty: isNilOrEmpty(value?.max),
                    inputValue: finalInputValue,
                    currentMaxValue: value?.max,
                  }),
                })
              },
            }}
          />
        </Box>
        <Box marginTop={tokens.spacing[2]}>
          <Input
            {...{
              type: "number",
              step: inputStep,
              labelText: localized("Maximum numeric value"),
              value: value.max ?? "",
              placeholder: localized("Enter value"),
              marginLeft: 2,
              onBlur: () => {
                if (value?.min && value?.max && value?.max <= value?.min) {
                  onChange({
                    min: value?.min,
                    max: Math.min(topMax, value?.min + 1),
                  })
                }
              },
              onChange: e => {
                const inputValue = e.target.value
                const isMaxEmpty = inputValue.trim() === ""
                const finalInputValue = getFinalInputValue(enableDecimal, inputValue, isMaxEmpty)

                onChange({
                  min: value?.min,
                  max: finalInputValue > topMax ? topMax : finalInputValue,
                })
              },
            }}
          />
        </Box>
      </Flex>
    </Box>
  )
}
