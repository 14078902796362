import { useTheme } from "@emotion/react"
import { EmptyTableIcon } from "@ninjaone/icons"
import { Text } from "@ninjaone/components"
import { Flex } from "js/includes/components/Styled"
import { localizationKey } from "js/includes/common/utils"

const NoDataFound = ({ labelToken = localizationKey("No data found") }) => {
  const theme = useTheme()
  return (
    <Flex flexDirection="column" alignItems="center" justifyContent="center" height="100%">
      <EmptyTableIcon
        colors={{ box: theme.color.veryLightGray, shadow: theme.color.lightGray, border: theme.color.gray }}
      />
      <Text bold textWrap color={theme.color.black["050"]} token={labelToken} />
    </Flex>
  )
}

export default NoDataFound
