import { reduce } from "ramda"
import { useCallback, useMemo } from "react"
import { useForm } from "react-hook-form"
import { buildFormValidate, fieldTypes, parseCustomFieldValue } from "js/includes/components/CustomFields/fieldTypes"
import { isNotNilOrEmpty, localized, showErrorMessage } from "js/includes/common/utils"
import { useSetContentRefs } from "js/includes/components/CustomFields/WYSIWYGEditor/plugins/InlineImagePlugin/hooks/useSetContentRefs"

const mapCustomFieldToValue = (fields, values = {}) =>
  fields.reduce((acc, field, index) => {
    if (field.id) {
      acc[field.id] = parseCustomFieldValue(field, values[field.id])
    }

    return acc
  }, {})

const getEditorInitialValues = (fields, values) => {
  return reduce(
    (acc, field) => {
      if (values[field.id]) {
        acc[field.id] = fieldTypes[field.type]?.getInitialValue(field, values[field.id])
      }
      return acc
    },
    {},
    fields,
  )
}

export const useRoleCustomFieldsEditor = ({ fields = [], customFieldValuesRef = { current: {} } }) => {
  const validators = useMemo(() => buildFormValidate(fields), [fields])

  const initialValues = useMemo(
    () =>
      isNotNilOrEmpty(customFieldValuesRef.current)
        ? customFieldValuesRef.current
        : getEditorInitialValues(fields, customFieldValuesRef.current),
    [customFieldValuesRef, fields],
  )

  const defaultValues = useMemo(() => mapCustomFieldToValue(fields, initialValues), [fields, initialValues])

  const methods = useForm({
    mode: "all",
    shouldFocusError: false,
    defaultValues,
    resolver: (values, context, options) => {
      const errors = {}
      const fieldsBeingValidated = options.names

      fieldsBeingValidated.forEach(fieldName => {
        const validator = validators[fieldName]

        if (validator) {
          const { type = "validate", success, message } = validator(values[fieldName])

          if (!success) {
            errors[fieldName] = {
              type,
              message,
            }
          }
        }
      })

      customFieldValuesRef.current = values

      return {
        values,
        errors,
      }
    },
  })
  const [attachmentRefs, setRefs] = useSetContentRefs()

  const handleCacheTempImages = useCallback(
    ({ uploadsByContentIdRef, tempUriByContentIdRef }) => setRefs({ uploadsByContentIdRef, tempUriByContentIdRef }),
    [setRefs],
  )

  const handleInvalidSaveClick = useCallback(errors => {
    const types = Object.values(errors).map(({ type }) => type)

    if (types.includes("required")) {
      showErrorMessage(localized("All required fields must be completed before saving."))
    }
  }, [])

  return {
    attachmentRefs,
    handleCacheTempImages,
    handleInvalidSaveClick,
    initialValues,
    methods,
  }
}
