import { faVmGuest, faVmHost, faHyperVGuest, faHyperVHost, faThreeRackServer } from "media/icons"
import { faCloud, faGlobe, faQuestion, faInfoCircle, faMobileAlt } from "@fortawesome/pro-solid-svg-icons"
import { faWindows, faApple, faLinux, faAndroid } from "@fortawesome/free-brands-svg-icons"
import {
  any,
  includes,
  find,
  cond,
  T,
  compose,
  reject,
  startsWith,
  map,
  prop,
  join,
  uniq,
  split,
  defaultTo,
  head,
  either,
  isEmpty,
  anyPass,
  always,
  all,
  F,
  equals,
  isNil,
} from "ramda"
import { user, isFeatureEnabled, localizeMappingName, showErrorMessage } from "js/includes/common/utils"
import { runDeviceSearchToolbarActions } from "js/includes/common/client/device"
import { getReadableNodeRole } from "js/includes/common/_enums"
import { contains, localized, localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import ShowMessageDialog from "js/includes/components/MessageDialog"
import showModal from "js/includes/common/services/showModal"
import { triggerJob } from "js/includes/common/services/deviceHealth"
import RebootLaterModal from "js/includes/deviceSearch/components/DeviceList/DeviceListToolbar/reboot/RebootLaterModal"

export const timestamp = item => {
  if (isCloudMonitor(item.nodeClass)) {
    return item.submitTime
  } else if (item.isUp) {
    if (item.nodeType === "NMS_TARGET") {
      return item.submitTime
    } else {
      return item.contactTime
    }
  } else {
    return item.contactTime
  }
}

export const WINDOWS_SERVER = "WINDOWS_SERVER"
export const WINDOWS_WORKSTATION = "WINDOWS_WORKSTATION"
export const MAC = "MAC"
export const MAC_SERVER = "MAC_SERVER"
export const LINUX_WORKSTATION = "LINUX_WORKSTATION"
export const LINUX_SERVER = "LINUX_SERVER"

export const isWindowsServer = contains([WINDOWS_SERVER])
export const isWindowsWorkstation = contains([WINDOWS_WORKSTATION])
export const isWindowsDevice = either(isWindowsWorkstation, isWindowsServer)
export const isUpWindowsDevice = ({ isUp, nodeClass }) => isUp && isWindowsDevice(nodeClass)
export const isDownWindowsDevice = ({ isUp, nodeClass }) => !isUp && isWindowsDevice(nodeClass)

export const isHPDevice = ({ manufacturer, nodeClass }) =>
  manufacturer?.toLowerCase().includes("hewlett") && nodeClass === WINDOWS_WORKSTATION
export const isHPEnterpriseDevice = ({ manufacturer, nodeClass }) =>
  manufacturer?.toLowerCase() === "hp" && nodeClass === WINDOWS_SERVER

//TODO: remove use of isMac
export const isMac = contains([MAC])
export const isMacServer = contains([MAC_SERVER])
export const isMacDevice = either(isMac, isMacServer)
export const isUpMacDevice = ({ isUp, nodeClass }) => isUp && isMacDevice(nodeClass)
export const isDownMac = ({ isUp, nodeClass }) => !isUp && isMac(nodeClass)
export const isDownMacDevice = ({ isUp, nodeClass }) => !isUp && isMacDevice(nodeClass)

export const isLinuxServer = contains([LINUX_SERVER])
export const isLinuxWorkstation = contains([LINUX_WORKSTATION])
export const isLinuxDevice = either(isLinuxWorkstation, isLinuxServer)
export const isUpLinuxDevice = ({ isUp, nodeClass }) => isUp && isLinuxDevice(nodeClass)

export const isUpWindowsOrMacOrLinuxDevice = anyPass([isUpWindowsDevice, isUpMacDevice, isUpLinuxDevice])
export const isUpWindowsOrMacDevice = either(isUpWindowsDevice, isUpMacDevice)
export const isWindowsOrMacDevice = either(isWindowsDevice, isMacDevice)
export const isRmmDevice = anyPass([isWindowsDevice, isMacDevice, isLinuxDevice])
export const atLeastOneIsRmmDevice = any(isRmmDevice)

export const isVmWareHost = contains(["VMWARE_VM_HOST"])
export const isVmWareGuest = contains(["VMWARE_VM_GUEST"])
export const isVmHyperVHost = contains(["HYPERV_VMM_HOST"])
export const isVmHyperVGuest = contains(["HYPERV_VMM_GUEST"])
export const isVmHost = anyPass([isVmWareHost, isVmHyperVHost])
export const isVmGuest = anyPass([isVmWareGuest, isVmHyperVGuest])
export const isVMHostOrGuest = anyPass([isVmHost, isVmGuest])
export const isCloudMonitor = contains(["CLOUD_MONITOR_TARGET", "CLOUD_MONITOR_TARGET_EMAIL_SERVER"])
export const isNmsAgent = contains(["NMS_NETWORK_MANAGEMENT_AGENT"])

export const isMobileDevice = contains(["ANDROID", "APPLE_IOS", "APPLE_IPADOS"])
export const isMobileAppleDevice = contains(["APPLE_IOS", "APPLE_IPADOS"])
export const isAndroidDevice = contains(["ANDROID"])
export const isIosDevice = contains(["APPLE_IOS"])
export const isIpadDevice = contains(["APPLE_IPADOS"])
export const isAppleMobileDevice = either(isIosDevice, isIpadDevice)
export const isUnmanagedDevice = contains(["UNMANAGED_DEVICE"])

export const getPathSeparator = ({ nodeClass }) => (isMacDevice(nodeClass) || isLinuxDevice(nodeClass) ? "/" : "\\")

export const nmsMonitorList = [
  "NMS_SWITCH",
  "NMS_ROUTER",
  "NMS_FIREWALL",
  "NMS_PRIVATE_NETWORK_GATEWAY",
  "NMS_PRINTER",
  "NMS_SCANNER",
  "NMS_DIAL_MANAGER",
  "NMS_WAP",
  "NMS_IPSLA",
  "NMS_COMPUTER",
  "NMS_VM_HOST",
  "NMS_APPLIANCE",
  "NMS_OTHER",
  "NMS_SERVER",
  "NMS_PHONE",
  "NMS_VIRTUAL_MACHINE",
]

export const isNmsMonitor = contains(nmsMonitorList)
export const isNms = anyPass([isNmsAgent, isNmsMonitor])

export const isITCloud = () => {
  return window.location.host.toLowerCase() === "ninjarmm.itcloud.ca"
}

export const isManufacturerWithWarranty = manufacturerName => {
  const listOfManufacturers = ["acer", "dell", "hp", "asus", "lenovo", "microsoft"]
  return manufacturerName && any(manufacturer => includes(manufacturer, manufacturerName), listOfManufacturers)
}

export const getRedirectToManufacturerWarrantyPage = (manufacturerName, serialNumber = "") => {
  const manufacturers = [
    { name: "acer", website: "https://www.acer.com/ac/en/US/content/support" },
    { name: "dell", website: `https://www.dell.com/support/home/en-us/product-support/servicetag/${serialNumber}` },
    { name: "hpe", website: "https://support.hpe.com/hpsc/wc/public/home" },
    { name: "hp", website: "https://support.hp.com/us-en/checkwarranty" },
    { name: "asus", website: "https://www.asus.com/us/support/" },
    { name: "lenovo", website: `https://pcsupport.lenovo.com/us/en/products/${serialNumber}/warranty` },
    { name: "microsoft", website: `https://mybusinessservice.surface.com/en-US/CheckWarranty/CheckWarranty` },
  ]
  const manufacturer = find(m => includes(m.name, manufacturerName), manufacturers)
  return manufacturer && manufacturer.website
}

export const isVmMonitorDelegate = device => {
  const { responsibilities } = device
  return responsibilities && responsibilities.includes("VMM_AGENT") && getChildNodes(device.id).length
}

export const getChildNodes = parentNodeId => window.deviceList.where({ parentNodeId })

const getIconData = cond([
  [isWindowsDevice, always({ icon: faWindows, ninjaSvgIconName: "WindowsIconSvg" })],
  [isMacDevice, always({ icon: faApple, ninjaSvgIconName: "AppleIconSvg" })],
  [isLinuxDevice, always({ icon: faLinux, ninjaSvgIconName: "LinuxIconSvg" })],
  [isVmWareHost, always({ icon: faVmHost, ninjaSvgIconName: "VmHostIconSvg" })],
  [isVmWareGuest, always({ icon: faVmGuest, ninjaSvgIconName: "VmGuestIconSvg" })],
  [isVmHyperVHost, always({ icon: faHyperVHost, ninjaSvgIconName: "HyperVHostIconSvg" })],
  [isVmHyperVGuest, always({ icon: faHyperVGuest, ninjaSvgIconName: "HyperVGuestIconSvg" })],
  [isCloudMonitor, always({ icon: faCloud, ninjaSvgIconName: "CloudMonitorIconSvg" })],
  [isNmsAgent, always({ icon: faThreeRackServer, ninjaSvgIconName: "ThreeRackServerIconSvg" })],
  [isNmsMonitor, always({ icon: faGlobe, ninjaSvgIconName: "NmsIconSvg" })],
  [isAndroidDevice, always({ icon: faAndroid, ninjaSvgIconName: "AndroidIconSvg" })],
  [isAppleMobileDevice, always({ icon: faApple, ninjaSvgIconName: "AppleIconSvg" })],
  [isMobileDevice, always({ icon: faMobileAlt, ninjaSvgIconName: "MobileAltIconSvg" })],
  [T, always({ icon: faQuestion, ninjaSvgIconName: "QuestionIconSvg" })],
])

export const getIcon = nodeClass => getIconData(nodeClass).icon

export const getDeviceNinjaSvgIconName = nodeClass => getIconData(nodeClass).ninjaSvgIconName

export const getDeviceDashboardRootPath = node =>
  cond([
    [isRmmDevice, always(`#/deviceDashboard/${node.id}`)],
    [isCloudMonitor, always(`#/cloudMonitorDashboard/${node.id}`)],
    [isNms, always(`#/nmsDashboard/${node.id}`)],
    [isVmHost, always(`#/vmDashboard/${node.id}`)],
    [isVmGuest, always(`#/vmGuestDashboard/${node.id}`)],
    [isMobileDevice, always(`#/mobileDashboard/${node.id}`)],
    [isUnmanagedDevice, always(`#/unmanagedDeviceDashboard/${node.id}`)],
    [
      T,
      t => {
        throw new Error(`Invalid nodeClass: ${t}`)
      },
    ],
  ])(node.nodeClass)

export const getOverviewLink = node => `${getDeviceDashboardRootPath(node)}/overview`
export const getDeviceTicketingBoardLink = node => `${getDeviceDashboardRootPath(node)}/ticketing`
export const getBackupOverviewLink = node => `${getDeviceDashboardRootPath(node)}/backup/overview`

// [{ipAddress}] -> "192..."
export const formatPrivateIPAddresses = compose(
  join(", "),
  uniq,
  reject(either(startsWith("169."), isEmpty)),
  map(compose(head, split(";"), defaultTo(""), prop("ipAddress"))),
  defaultTo([]),
)

export const isUp = device => {
  const isUp = device.isUp

  if (!isUp) {
    ShowMessageDialog({
      icon: { icon: faInfoCircle, type: "warning" },
      title: () => `${device.name || device.friendlyName || device.systemName} ${localized("is offline")}`,
      message: localizationKey(
        "You can't connect or send commands to an offline device. This action requires the device to be online.",
      ),
      buttons: [{ id: "OK", label: localizationKey("OK") }],
    })
  }
  return isUp
}

export const getRebootJobId = cond([
  [isWindowsDevice, always("7f72d612-463e-4fac-b6ed-435b9c568ce9")],
  [isMacDevice, always("40BE7B53-7567-4DFC-A434-E1E33DE752BA")],
  [isLinuxDevice, always("812efb48-452a-481e-add2-765f0eb1426c")],
  [
    T,
    nodeClass => {
      throw new Error(`Invalid nodeClass ${nodeClass}`)
    },
  ],
])

export const getForcedRebootJobId = cond([
  [isWindowsDevice, always("e2b5f0f4-0774-43fa-86ec-3ccf4bdf5915")],
  [isMacDevice, always("40BE7B53-7567-4DFC-A434-E1E33DE752BA")],
  [isLinuxDevice, always("812efb48-452a-481e-add2-765f0eb1426c")],
  [
    T,
    nodeClass => {
      throw new Error(`Invalid nodeClass ${nodeClass}`)
    },
  ],
])

const rebootDevices = (devices, content) => {
  devices.forEach(device =>
    triggerJob({
      node: device,
      jobUid: getRebootJobId(device.nodeClass),
      ...(content && { content }),
    }),
  )
}

export const askForReboot = async devices => {
  const confirmation = await ShowMessageDialog({
    icon: { icon: faInfoCircle, type: "info" },
    title:
      devices.length === 1
        ? localizationKey("Are you sure you want to reboot this device?")
        : localizationKey("Are you sure you want to reboot these devices?"),
    buttons: [
      { id: true, label: localizationKey("Yes") },
      { id: false, label: localizationKey("No") },
    ],
  })
  confirmation && rebootDevices(devices)
}

export const rebootLater = devices => {
  showModal(<RebootLaterModal {...{ devices }} />)
}

const MAINTENANCE_SCRIPTS_ID = 3

export const canBeRebooted = ({ nodeRoleId, nodeClass, isUp } = {}) =>
  user("canUpdateDevices", { nodeClass, nodeRoleId }) &&
  user("canRunScripts", [MAINTENANCE_SCRIPTS_ID]) &&
  isRmmDevice(nodeClass) &&
  isUp

export const devicesCanBeRebooted = all(canBeRebooted)

export const getOsPatchJobCategory = cond([
  [isWindowsDevice, always("WINDOWS_UPDATES")],
  [isMacDevice, always("MACOS_UPDATES")],
  [isLinuxDevice, always("LINUX_UPDATES")],
])

export const isPatchingFeatureEnabled = nodeClass =>
  cond([
    [isLinuxDevice, () => isFeatureEnabled("linux_patching")],
    [isMacDevice, () => isFeatureEnabled("mac_os_patching")],
    [isWindowsDevice, T],
  ])(nodeClass)

export const isSoftwarePatchingFeatureEnabled = nodeClass =>
  cond([
    [isMacDevice, () => isFeatureEnabled("mac_3pp_patching")],
    [isWindowsDevice, T],
    [T, F],
  ])(nodeClass)

export const canRunActiveDirectoryDiscovery = devices => {
  return (
    user("canAccessActiveDirectoryDiscovery") &&
    devices.length === 1 &&
    devices[0].isDomainController &&
    devices[0].isUp
  )
}

export const nodeRolesByType = {
  isMacServer: {
    chassisType: "UNKNOWN",
    nodeClass: ["MAC_SERVER"],
  },
  isMonitorServer: {
    chassisType: "UNKNOWN",
    nodeClass: ["CLOUD_MONITOR_TARGET"],
  },
  isNms: {
    chassisType: "UNKNOWN",
    nodeClass: [
      "NMS_SWITCH",
      "NMS_SERVER",
      "NMS_PHONE",
      "NMS_ROUTER",
      "NMS_FIREWALL",
      "NMS_PRIVATE_NETWORK_GATEWAY",
      "NMS_PRINTER",
      "NMS_SCANNER",
      "NMS_DIAL_MANAGER",
      "NMS_WAP",
      "NMS_IPSLA",
      "NMS_COMPUTER",
      "NMS_VM_HOST",
      "NMS_APPLIANCE",
      "NMS_OTHER",
      "NMS_VIRTUAL_MACHINE",
      "NMS_NETWORK_MANAGEMENT_AGENT",
    ],
  },
  isWindowsServer: {
    chassisType: "UNKNOWN",
    nodeClass: ["WINDOWS_SERVER"],
  },
  isLinuxServer: {
    chassisType: "UNKNOWN",
    nodeClass: ["LINUX_SERVER"],
  },
  isVMWareHost: {
    chassisType: "UNKNOWN",
    nodeClass: ["VMWARE_VM_HOST"],
  },
  isVMWareVirtualMachine: {
    chassisType: "UNKNOWN",
    nodeClass: ["VMWARE_VM_GUEST"],
  },
  isWindowsDesktop: {
    chassisType: "DESKTOP",
    nodeClass: ["WINDOWS_WORKSTATION"],
  },
  isWindowsLaptop: {
    chassisType: "LAPTOP",
    nodeClass: ["WINDOWS_WORKSTATION"],
  },
  isMacDesktop: {
    chassisType: "DESKTOP",
    nodeClass: ["MAC"],
  },
  isMacLaptop: {
    chassisType: "LAPTOP",
    nodeClass: ["MAC"],
  },
  isLinuxDesktop: {
    chassisType: "DESKTOP",
    nodeClass: ["LINUX_WORKSTATION"],
  },
  isLinuxLaptop: {
    chassisType: "LAPTOP",
    nodeClass: ["LINUX_WORKSTATION"],
  },
  isHyperVHost: {
    chassisType: "UNKNOWN",
    nodeClass: ["HYPERV_VMM_HOST"],
  },
  isHyperVVirtualMachine: {
    chassisType: "UNKNOWN",
    nodeClass: ["HYPERV_VMM_GUEST"],
  },
  isAndroidDevice: {
    chassisType: "MOBILE",
    nodeClass: ["ANDROID"],
  },
  isIosDevice: {
    chassisType: "MOBILE",
    nodeClass: ["APPLE_IOS"],
  },
  isIpadDevice: {
    chassisType: "MOBILE",
    nodeClass: ["APPLE_IPADOS"],
  },
}

export const getPolicySourceLabel = policyIdSource => {
  return (
    {
      NODE: localized("Assigned from device settings"),
      CLIENT: localized("Assigned from organization settings"),
      CLIENT_LOCATION: localized("Assigned from location settings"),
    }[policyIdSource] || ""
  )
}

export const getNodeRoleLabel = ({ name, nodeClass }) =>
  `${localizeMappingName(name)} - (${getReadableNodeRole(nodeClass)})`

export const getNmsRoles = nodeRoles => nodeRoles.filter(({ nodeClass }) => nodeClass.includes("NMS"))

export const getIsValidNodeClassTypeAV = (node, productCode) =>
  cond([
    [isNil, F],
    [equals("SENTINEL_ONE"), () => isUpWindowsOrMacOrLinuxDevice(node)],
    [equals("WEBROOT"), () => isUpWindowsDevice(node)],
    [T, () => isUpWindowsOrMacDevice(node)],
  ])(productCode)

export const getDeviceSearchRunnerParams = () => {
  const {
    deviceSearch: { actionRunnerParams },
  } = window.store.getState()
  return actionRunnerParams
}

export const runDeviceSearchToolbarAction = ({ action, data, cacheKey, selectedNodeIds, unselectedNodeIds }) => {
  const defaultParams = getDeviceSearchRunnerParams()
  const params = {
    action,
    data,
    cacheKey: cacheKey ?? defaultParams.cacheKey,
    selectedNodeIds: selectedNodeIds ?? defaultParams.selectedNodeIds,
    unselectedNodeIds: unselectedNodeIds ?? defaultParams.unselectedNodeIds,
  }

  return new Promise(async (resolve, reject) => {
    try {
      const result = await runDeviceSearchToolbarActions(params)
      if (result.status === "ERROR") {
        reject(result)
      } else {
        resolve(result)
      }
    } catch (error) {
      if (error.resultCode === "DEVICE_SEARCH_CACHE_MEMORY_EXPIRED") {
        showErrorMessage(localized("Devices cache memory has expired"))
        reject({ isDevicesCacheExpiredError: true, reason: error.resultCode })
      } else {
        reject(error)
      }
    }
  })
}
