import { useEffect } from "react"
import { Body, Link } from "@ninjaone/components"
import qs from "qs"
import { isNil, pluck } from "ramda"
import { fetchJson, isNilOrEmpty, reportErrorAndShowMessage } from "js/includes/common/utils"
import { Box } from "js/includes/components/Styled"
import { useMountedState } from "js/includes/common/hooks"
import Loading from "js/includes/components/Loading"
import { spacing } from "@ninjaone/tokens"

export default function MultipleCustomerValueRenderer(field, { id, value } = {}, renderTextOnly) {
  const [loading, setLoading] = useMountedState(false)
  const [clients, setClients] = useMountedState([])

  useEffect(() => {
    const getInitialSelection = async () => {
      try {
        setLoading(true)

        const _qs = qs.stringify(
          {
            id: value?.entityIds,
          },
          { indices: false },
        )

        const clients = await fetchJson(`/client?${_qs}`)

        setClients(clients)
      } catch (error) {
        reportErrorAndShowMessage(error)
      } finally {
        setLoading(false)
      }
    }

    if (!isNilOrEmpty(value?.entityIds)) {
      getInitialSelection()
    }
  }, [value?.entityIds, setLoading, setClients])

  return loading ? (
    <Loading />
  ) : isNil(value) || isNilOrEmpty(clients) ? null : renderTextOnly ? (
    <Body>{pluck("name", clients).join(", ")}</Body>
  ) : (
    <Box display="flex" flexWrap="wrap">
      {clients?.map((client, index) => {
        return (
          <Box key={`${client?.id}-client`} marginRight={spacing[1]}>
            <Link href={`#/customerDashboard/${client?.id}/overview`}>
              <Body color="inherit">
                {client?.name}
                {index < clients.length - 1 && <>,</>}
              </Body>
            </Link>
          </Box>
        )
      })}
    </Box>
  )
}
