import { isNil } from "ramda"
import { localized } from "js/includes/common/utils"
import { Body } from "@ninjaone/components"

export default function NumericValueRenderer(field, { value } = {}) {
  return (
    (!isNil(value) && !isNaN(value) && (
      <Body type="body">
        {localized(+value, {
          maxRound: 7,
        })}
      </Body>
    )) ||
    null
  )
}
