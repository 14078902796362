import { memo, useState, PureComponent, useEffect, useCallback, useRef } from "react"
import { connect } from "react-redux"
import { includes } from "ramda"
import { css } from "@emotion/react"
import { faTicket, faRefresh } from "@fortawesome/pro-light-svg-icons"
import { sizer } from "@ninjaone/utils"
import {
  localized,
  reportErrorAndShowMessage,
  showSuccessMessage,
  isUserAllowedToViewPendingEmails,
  PubSub,
  ticketingWampOperations,
  isFeatureEnabled,
} from "js/includes/common/utils"
import {
  requestPendingEmailsCount as _requestPendingEmailsCount,
  updateSMTPHealth as _updateSMTPHealth,
} from "js/state/actions/ticketing"
import { fetchTriggerBoards as _fetchTriggerBoards } from "js/state/actions/general/boardTabs"
import { fetchBoardsConfiguration as _fetchBoardsConfiguration } from "js/state/actions/general/boardsConfiguration"
import SubNavLink from "./SubNavLink"
import SubNavItemContent from "./SubNavItemContent"
import SubNav from "./SubNav"
import SubNavDrawer from "./SubNavDrawer"
import SubNavHoverDropdownItem from "./SubNavHoverDropdownItem"

const ROOT_PATHNAME = "/ticketing/"
const BOARD_PATHNAME = "/ticketing/board/"

const BOARDS_REFRESH_DELAY_IN_MILLIS = 5000

const showAllButtonStyle = css`
  width: 100%;
  background: none;
  border: none;
  padding: 0;
  margin-top: ${sizer(2)};
`

const linkStyle = theme => css`
  display: flex;
  align-items: center;
  padding-left: ${sizer(2)};
  padding-right: ${sizer(2)};
  border-radius: 2px;

  && {
    color: ${theme.colorThemeText};
  }

  &:hover {
    background-color: ${theme.colorThemeBackgroundHover};
  }

  &:not(:focus-visible):not(:focus-within):not(:hover) {
    .action-icons {
      visibility: hidden;
    }
  }

  &.active {
    color: ${theme.colorThemeTextSelected};
  }

  &:focus-visible {
    background-color: ${theme.colorThemeBackgroundHover};
    outline: 2px solid ${theme.colorForegroundFocus};
    outline-offset: -2px;
  }
`

class _PendingEmailBoard extends PureComponent {
  scheduleBoardRefresh() {
    if (this._refreshBoardTimeout) {
      return
    }
    this._refreshBoardTimeout = setTimeout(async () => {
      await this.props.requestPendingEmailsCount()
      this._refreshBoardTimeout = null
    }, BOARDS_REFRESH_DELAY_IN_MILLIS)
  }

  subscribeToTicketingNotifications() {
    if (isFeatureEnabled("stop_auto_refresh")) return

    this._subscriptionToken = PubSub.subscribe("ticketing-internal", (_, data) => {
      const { op } = data

      if (includes(op, ticketingWampOperations.pendingEmails)) {
        this.scheduleBoardRefresh()
      }
    })
  }

  componentDidMount() {
    this.props.requestPendingEmailsCount()
    this.subscribeToTicketingNotifications()
  }

  componentWillUnmount() {
    if (this._subscriptionToken) {
      PubSub.unsubscribe(this._subscriptionToken)
      this._subscriptionToken = null
    }
    if (this._refreshBoardTimeout) {
      clearTimeout(this._refreshBoardTimeout)
      this._refreshBoardTimeout = null
    }
  }

  render() {
    const { pendingEmailsCount, linkRenderer } = this.props
    return linkRenderer({ pendingEmailsCount, href: "/ticketing/pendingEmails" })
  }
}

const PendingEmailBoard = connect(
  ({
    ticketing: {
      boards: { pendingEmailsCount },
    },
  }) => ({
    pendingEmailsCount,
  }),
  { requestPendingEmailsCount: _requestPendingEmailsCount },
)(_PendingEmailBoard)

const BoardTabs = memo(
  ({
    boardTabs: { top = [], rest = [] },
    activeRoute,
    requestPendingEmailsCount,
    fetchTriggerBoards,
    updateSMTPHealth,
    collapsed,
  }) => {
    const refreshingRef = useRef()
    const refreshBoardsTimeoutRef = useRef()
    const firstLinkRef = useRef()
    const [showAll, setShowAll] = useState(false)
    const shouldShowMoreButton = rest.length > 0

    const refreshBoards = useCallback(
      async ({ showSuccess = true } = {}) => {
        try {
          refreshingRef.current = true
          await Promise.all([fetchTriggerBoards(), requestPendingEmailsCount()])
          showSuccess && showSuccessMessage(localized("general.success"))
        } catch (error) {
          reportErrorAndShowMessage(error, "ticketing.errorRefreshingBoards")
        } finally {
          refreshingRef.current = false
        }
      },
      [fetchTriggerBoards, requestPendingEmailsCount],
    )

    const scheduleBoardsRefresh = useCallback(() => {
      if (refreshingRef.current || refreshBoardsTimeoutRef.current) {
        return
      }

      refreshBoardsTimeoutRef.current = setTimeout(async () => {
        await refreshBoards({ showSuccess: false })
        refreshBoardsTimeoutRef.current = null
      }, BOARDS_REFRESH_DELAY_IN_MILLIS)
    }, [refreshBoards])

    useEffect(() => {
      const ticketOperations = new Set(ticketingWampOperations.tickets)
      const token = !isFeatureEnabled("stop_auto_refresh")
        ? PubSub.subscribe("ticketing-internal", (_, updateData) => {
            const { op, status, type } = updateData

            if (op === "SMTP_EMAIL_SENT" && type === "SMTP") {
              updateSMTPHealth(status)
            } else if (ticketOperations.has(op)) {
              scheduleBoardsRefresh()
            }
          })
        : null

      return function cleanup() {
        token && PubSub.unsubscribe(token)
      }
    }, [updateSMTPHealth, scheduleBoardsRefresh])

    useEffect(() => {
      return () => {
        if (refreshBoardsTimeoutRef.current) {
          clearTimeout(refreshBoardsTimeoutRef.current)
          refreshBoardsTimeoutRef.current = null
        }
      }
    }, [])

    const getTicketNavItemTitle = (name, count) =>
      `${name} (${localized(count, {
        notation: "compact",
      })})`

    const getHref = (id, href) => {
      if (!href) return

      return `#${id ? href + id : href}`
    }

    const toggleShowButton = (
      <button
        css={[showAllButtonStyle, linkStyle]}
        onClick={() => {
          if (!showAll) {
            firstLinkRef.current.focus()
          }

          setShowAll(prevState => !prevState)
        }}
      >
        <SubNavItemContent name={showAll ? localized("Show Less") : localized("Show All")} />
      </button>
    )

    const getNavItemProps = ({ id, href, name, ticketCount }) => ({
      href: getHref(id, href),
      name: getTicketNavItemTitle(name, ticketCount),
    })

    const navRenderer = () => (
      <SubNav ariaLabel={localized("Board navigation")}>
        <ul>
          {top.map(({ id, name, ticketCount }, index) => (
            <li key={id}>
              <SubNavLink
                {...getNavItemProps({
                  id,
                  href: BOARD_PATHNAME,
                  name,
                  ticketCount,
                })}
                {...{ ...(index === 0 && { linkRef: firstLinkRef }) }}
              />
            </li>
          ))}
          {showAll &&
            rest.map(({ id, name, ticketCount }) => (
              <li key={id}>
                <SubNavLink {...getNavItemProps({ id, href: BOARD_PATHNAME, name, ticketCount })} />
              </li>
            ))}
          {isUserAllowedToViewPendingEmails() && (
            <li key="pending-emails-board">
              <PendingEmailBoard
                linkRenderer={({ pendingEmailsCount: ticketCount, href }) => (
                  <SubNavLink {...getNavItemProps({ href, name: localized("Pending Emails"), ticketCount })} />
                )}
              />
            </li>
          )}
        </ul>
        {shouldShowMoreButton && toggleShowButton}
      </SubNav>
    )

    const getHoverNavItemProps = ({ id, href, name, ticketCount }) => ({
      key: id,
      route: getHref(id, href),
      name: getTicketNavItemTitle(name, ticketCount),
      collapsed,
    })

    const hoverNavItemsRenderer = () => (
      <>
        <div>
          {top.map(({ id, name, ticketCount }) => (
            <SubNavHoverDropdownItem {...getHoverNavItemProps({ id, href: BOARD_PATHNAME, name, ticketCount })} />
          ))}
          {rest.map(({ name, ticketCount, id }) => (
            <SubNavHoverDropdownItem {...getHoverNavItemProps({ id, href: BOARD_PATHNAME, name, ticketCount })} />
          ))}
          {isUserAllowedToViewPendingEmails() && (
            <PendingEmailBoard
              key="pending-emails-board"
              linkRenderer={({ pendingEmailsCount: ticketCount, href }) => (
                <SubNavHoverDropdownItem
                  {...getHoverNavItemProps({ href, name: localized("Pending Emails"), ticketCount })}
                />
              )}
            />
          )}
        </div>
      </>
    )

    return (
      <div id="board">
        <SubNavDrawer
          {...{
            collapsed,
            navRenderer,
            hoverNavItemsRenderer,
            icon: faTicket,
            label: localized("Ticketing"),
            actionIcon: faRefresh,
            actionIconTooltip: localized("Refresh"),
            onClickAction: e => {
              e.preventDefault()
              e.stopPropagation()
              refreshBoards()
            },
            active: activeRoute.includes(ROOT_PATHNAME),
            id: "ticketing",
          }}
        />
      </div>
    )
  },
)

const prepareBoardTabs = (sortedTabs = [], boardsConfiguration) => {
  const maxTopBoards = boardsConfiguration?.numberOfVisibleBoards ?? 5
  const top = sortedTabs.slice(0, maxTopBoards)
  const rest = sortedTabs.slice(maxTopBoards, sortedTabs.length)

  return {
    boardTabs: {
      top,
      rest,
    },
  }
}

export default connect(
  ({ general: { boardTabs, boardsConfiguration } }) => prepareBoardTabs(boardTabs, boardsConfiguration),
  {
    requestPendingEmailsCount: _requestPendingEmailsCount,
    fetchTriggerBoards: _fetchTriggerBoards,
    fetchBoardsConfiguration: _fetchBoardsConfiguration,
    updateSMTPHealth: _updateSMTPHealth,
  },
)(BoardTabs)
