import React from "react"
import styled from "@emotion/styled"
import Datetime from "react-datetime"
import { Form, FormGroup, ControlLabel, Row, Col, FormControl, ModalBody, Panel } from "react-bootstrap"
import tokens from "@ninjaone/tokens"
import { colors } from "js/includes/common/theme/colors"
import { position, border, layout, flexbox, space, color, typography, shouldForwardProp, hover } from "../system"
import Modal from "js/includes/components/Modal"

export const StyledErrorMessage = styled.em`
  padding: 0 1px;
  font-style: normal;
  font-size: 11px;
  line-height: 15px;
  color: ${colors.ninjaRed};
  ${color}
  ${space}
  ${position}
`

export const StyledButton = styled.button`
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  background-color: ${({ backgroundColor }) => colors[backgroundColor] ?? "rgba(0,0,0,0)"};
  color: ${({ color, theme }) => colors[color] || theme[color] || color || colors.ninjaBlueSaturated};
  
  ${position}
  ${border}
  ${layout}
  ${flexbox}
  ${space}
  ${typography}
  ${hover}

  &:hover {
    color: ${({ theme, hoverTextColor }) =>
      colors[hoverTextColor] || theme[hoverTextColor] || hoverTextColor || colors.btnLinkHover};
    background-color: ${({ hoverBackgroundColor, backgroundColor }) =>
      colors[hoverBackgroundColor] || colors[backgroundColor] || "rgba(0,0,0,0)"};
  }

  &:disabled {
    color: ${colors.ninjaLight};
  }
`

export const StyledForm = styled(Form, { shouldForwardProp })`
  margin-top: 15px;
  display: grid;
  grid-row-gap: 10px;
  ${position}
  ${border}
  ${layout}
  ${flexbox}
  ${space}
  ${color}
`

export const StyledFormGroup = styled(FormGroup, { shouldForwardProp })`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0;
  ${position}
  ${border}
  ${layout}
  ${flexbox}
  ${space}
  ${color}
  ${typography}
`

export const StyledLabel = styled(ControlLabel, { shouldForwardProp })`
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: ${colors.ninjaMedium};
  ${position}
  ${border}
  ${layout}
  ${flexbox}
  ${space}
  ${color}
  ${typography}
`

export const StyledRow = styled(Row, { shouldForwardProp })`
  display: flex;
  ${position}
  ${border}
  ${layout}
  ${flexbox}
  ${space}
  ${color}
  ${typography}
`

export const StyledCol = styled(Col, { shouldForwardProp })`
  display: flex;
  flex: 1;
  ${position}
  ${border}
  ${layout}
  ${flexbox}
  ${space}
  ${color}
  ${typography}
`

export const StyledDateTime = styled(Datetime, { shouldForwardProp })`
  ${position}
  ${border}
  ${layout}
  ${flexbox}
  ${space}
  ${color}
  ${typography}
  
  ${({ theme, useSelectStyling }) =>
    useSelectStyling &&
    `
    input {
      color: ${theme.colorTextStrong};
      height: 38px;  
      border: 1px solid ${theme.colorBorderWeak};
      border-radius: ${tokens.spacing[1]};
      
      &:focus-visible {
        border: 1px solid ${theme.colorBorderDecorativeStrong};
        outline: 2px solid ${theme.colorForegroundFocus};
        background-color: ${theme.colorForegroundSelected};  
      }
      &:disabled {
        color: ${theme.colorTextDisabled};
        background-color: ${theme.colorBackgroundInputDisabled};
        cursor: not-allowed;
      }
    }
  `}

  .rdtPicker td.rdtActive.rdtDisabled {
    background: ${colors.brightGray};
    color: #428bca;
    cursor: not-allowed;
  }

  ${({ fixedRdtPicker }) =>
    fixedRdtPicker
      ? `
    .rdtPicker {
      position: fixed !important;
    }
  `
      : ""}
`

export const StyledFormControl = styled(FormControl, { shouldForwardProp })`
  ${position}
  ${border}
  ${layout}
  ${flexbox}
  ${space}
  ${color}
  ${typography}
`

export const StyledPanel = styled(Panel, { shouldForwardProp })`
  ${position}
  ${border}
  ${layout}
  ${flexbox}
  ${space}
  ${color}
  ${typography}
`

export const StyledModalBody = styled(ModalBody, { shouldForwardProp })`
  ${position}
  ${border}
  ${layout}
  ${flexbox}
  ${space}
  ${color}
  ${typography}
`

const ModalWrapper = ({ className, ...props }) => <Modal bodyClassName={className} {...props} />

export const StyledModal = styled(ModalWrapper, { shouldForwardProp })`
  ${position}
  ${border}
  ${layout}
  ${flexbox}
  ${space}
  ${color}
  ${typography}
`

export const HiddenInput = styled.input`
  display: none !important;
`

export const StyledInput = styled.input`
  display: flex;
  flex: 1;
  height: ${({ height }) => height || "35px"};
  border: none;
  border-radius: 3px;
  padding: 8px 115px 8px 8px;
  transition: all 100ms ease 0s;
  box-shadow: 0px 0px 0px 1px ${({ invalid }) => (invalid ? colors.reactVirtualizedRed : colors.reactSelectGrey)};

  &:focus {
    outline: none;
    box-shadow: 0px 0px 0px 2px ${colors.reactVirtualizedBlue};
  }

  &:disabled {
    box-shadow: 0px 0px 0px 1px ${({ invalid }) => (invalid ? colors.reactVirtualizedRed : colors.reactSelectGrey)};
    background-color: ${colors.reactSelectLight};
    color: ${colors.reactSelectDarkGrey};
  }

  &:read-only {
    box-shadow: 0px 0px 0px 1px ${({ invalid }) => (invalid ? colors.reactVirtualizedRed : colors.reactSelectGrey)};
    cursor: not-allowed;
    color: ${colors.reactSelectDarkGrey};
  }

  ${position}
  ${border}
  ${layout}
  ${flexbox}
  ${space}
  ${color}
  ${typography}
`
