import { Body, ConfirmationModal } from "@ninjaone/components"
import { localized } from "js/includes/common/utils"

const DeviceOfflineModal = ({ node, unmount }) => {
  const deviceName = node.name || node.friendlyName || node.systemName

  return (
    <ConfirmationModal
      {...{
        showActionButton: false,
        titleText: localized("{{deviceName}} is offline", {
          deviceName,
        }),
        unmount,
      }}
    >
      <Body textWrap>
        {localized(
          "You can't connect or send commands to an offline device. This action requires the device to be online.",
        )}
      </Body>
    </ConfirmationModal>
  )
}

export default DeviceOfflineModal
