import { Input } from "@ninjaone/components"
import { sizer } from "@ninjaone/utils"
import { localizationKey, localized } from "js/includes/common/utils"
import { SECTION_MAX_HEIGHT, SECTION_WIDTH } from "js/includes/components/DeviceModal/AddUnmanagedDeviceModal/constants"
import LocationPicker from "js/includes/components/OrganizationLocationPicker/LocationPicker"
import OrganizationPicker from "js/includes/components/OrganizationLocationPicker/OrganizationPicker"
import { Box } from "js/includes/components/Styled"

export default function AddUnmanagedDeviceCoreFields({
  disabled,
  onChange,
  saving,
  validation,
  validateFormFields,
  values: { name, organization, location, serialNumber },
}) {
  return (
    <Box paddingLeft="2px" paddingRight="2px" height={SECTION_MAX_HEIGHT} width={SECTION_WIDTH} overflowY="auto">
      <OrganizationPicker
        required
        labelAbove
        openOnFocus
        disabled={disabled || saving}
        searchPlaceholderToken={localizationKey("Selected Organization")}
        changeOrganization={organization => {
          onChange("organization", organization)
        }}
        validationState={validation?.success?.organization === false ? "error" : null}
        {...{ organization, validateForm: validateFormFields }}
      />

      <LocationPicker
        required
        labelAbove
        changeLocation={location => {
          onChange("location", location)
        }}
        disabled={disabled || saving || !organization?.id}
        validationState={validation?.success?.location === false ? "error" : null}
        {...{ organization, location }}
      />

      <Box marginTop={sizer(4)}>
        <Input
          required
          useCharacterCount
          name="name"
          labelText={localized("Name")}
          value={name}
          onChange={onChange}
          onBlur={() => validateFormFields("name")}
          maxLength={200}
          minWidth="100%"
          errorMessage={validation.message.name}
          disabled={disabled || saving}
        />
      </Box>

      <Box marginTop={sizer(4)}>
        <Input
          useCharacterCount
          name="serialNumber"
          labelText={localized("Serial number")}
          value={serialNumber}
          onChange={onChange}
          onBlur={() => validateFormFields("serialNumber")}
          maxLength={200}
          minWidth="100%"
          errorMessage={validation.message.serialNumber}
          disabled={disabled || saving}
        />
      </Box>
    </Box>
  )
}
