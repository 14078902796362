import React, { useState, useEffect, useRef } from "react"
import { useTheme } from "@emotion/react"
import styled from "@emotion/styled"
import { min, max } from "ramda"
import Portal from "js/includes/components/Portal"
import OutsideClickAlerter from "js/includes/components/OutsideClickAlerter"
import PropTypes from "prop-types"

export const POPOVER_MAX_WIDTH = 400
export const ARROW_SIZE = 10
const Z_INDEX = 2000
export const SPACING = 5

const arrowStyle = `
  width: 0;
  height: 0;
  position: fixed;
  border-top: ${ARROW_SIZE}px solid transparent;
  border-bottom: ${ARROW_SIZE}px solid transparent;
`

const StyledPopoverLightArrow = styled.span`
  ${arrowStyle}
  top: ${({ top }) => top}px;
  left: ${({ placement, left }) => (placement === "right" ? left + 1 : left - 1)}px;
  z-index: ${Z_INDEX + 2};
  border-${({ placement }) => placement}: ${ARROW_SIZE}px solid ${({ theme }) => theme.colorBackgroundWidget};
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
`

const StyledPopoverDarkArrow = styled.span`
  ${arrowStyle}
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  z-index: ${Z_INDEX + 1};
  border-${({ placement }) => placement}: ${ARROW_SIZE}px solid ${({ theme }) => theme.colorBorderWeak};
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
`

const PopoverArrow = ({ placement, top, left, visible = true }) => (
  <>
    <StyledPopoverLightArrow {...{ placement, top, left, visible }} />
    <StyledPopoverDarkArrow {...{ placement, top, left, visible }} />
  </>
)

const initializeBoundaries = () => ({
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  width: 0,
  height: 0,
  x: 0,
  y: 0,
})

const StickyPopover = ({
  children,
  trigger,
  onClickOutside,
  onUnmount,
  placement,
  showPopoverArrow,
  style,
  innerStyle,
  popoverArrowStyle,
  onVisible,
  customWrapperTop,
  bottomElementRenderer,
  usePointerDown,
}) => {
  const theme = useTheme()
  const [visible, setVisible] = useState(false)
  const mounted = useRef(true)
  const wrapperRef = useRef(null)
  const popoverRef = useRef(null)

  const [{ wrapperBoundaries, popoverBoundaries }, setBoundaries] = useState({
    wrapperBoundaries: initializeBoundaries(),
    popoverBoundaries: initializeBoundaries(),
  })
  useEffect(() => {
    mounted.current = true
    const wrapperElement = wrapperRef.current
    const popoverElement = popoverRef.current

    function updatePosition() {
      const wrapperBoundaries = wrapperElement.getBoundingClientRect()
      const popoverBoundaries = popoverElement.getBoundingClientRect()

      if (mounted.current && wrapperElement) {
        setBoundaries({
          wrapperBoundaries,
          popoverBoundaries,
        })
        setVisible(true)
        onVisible?.()
        window.requestAnimationFrame(updatePosition)
      }
    }

    window.requestAnimationFrame(updatePosition)

    return () => {
      mounted.current = false
      onUnmount()
    }
  }, [onUnmount, placement, onVisible])

  const wrapperWidth = wrapperBoundaries.width
  const wrapperHeight = wrapperBoundaries.height
  const popoverWidth = popoverBoundaries.width
  const popoverHeight = popoverBoundaries.height

  const wrapperTop = customWrapperTop
    ? customWrapperTop(wrapperBoundaries)
    : wrapperBoundaries.top - popoverHeight / 2 + ARROW_SIZE / 2

  const wrapperLeft =
    placement === "right"
      ? wrapperBoundaries.left + (wrapperWidth + SPACING + ARROW_SIZE)
      : wrapperBoundaries.left - (popoverWidth + SPACING + ARROW_SIZE)

  const top = max(min(wrapperTop, window.innerHeight - popoverHeight), 0)
  const left = max(min(wrapperLeft, window.innerWidth - popoverWidth), 0)

  const arrowTop = wrapperBoundaries.top + wrapperHeight / 2 - ARROW_SIZE
  const arrowLeft =
    placement === "right"
      ? wrapperBoundaries.left + wrapperWidth + ARROW_SIZE / 2
      : wrapperBoundaries.left - ARROW_SIZE - SPACING

  const isOverflowing = popoverHeight > window.innerHeight

  const defaultStyle = {
    zIndex: Z_INDEX,
    top: top + "px",
    left: left + "px",
    display: "block",
    visibility: visible ? "visible" : "hidden",
    position: "fixed",
    maxWidth: POPOVER_MAX_WIDTH + "px",
    border: `1px solid ${theme.colorBorderWeak}`,
  }

  const defaultInnerStyle = {
    backgroundColor: theme.colorBackgroundWidget,
    ...(isOverflowing && {
      overflowY: "scroll",
      maxHeight: window.innerHeight,
    }),
  }

  const defaultPopoverArrowStyle = {
    placement,
    top: arrowTop,
    left: arrowLeft,
  }

  return (
    <div ref={wrapperRef}>
      {trigger}

      <Portal>
        <OutsideClickAlerter usePointerDown={usePointerDown} handleClickOutside={onClickOutside ?? onUnmount}>
          <>
            <div
              ref={popoverRef}
              style={
                style
                  ? style({
                      defaultStyle,
                      isOverflowing,
                      wrapperBoundaries,
                      wrapperHeight,
                      popoverBoundaries,
                      popoverHeight,
                      left,
                      top,
                      visible,
                      theme,
                    })
                  : defaultStyle
              }
            >
              <div style={innerStyle ? innerStyle({ defaultInnerStyle }) : defaultInnerStyle}>{children}</div>
            </div>
            {showPopoverArrow && (
              <PopoverArrow
                {...{
                  ...(popoverArrowStyle
                    ? popoverArrowStyle({ defaultPopoverArrowStyle, popoverBoundaries, wrapperBoundaries, visible })
                    : defaultPopoverArrowStyle),
                }}
              />
            )}
          </>
        </OutsideClickAlerter>
      </Portal>

      {bottomElementRenderer?.({ popoverBoundaries })}
    </div>
  )
}

StickyPopover.propTypes = {
  placement: PropTypes.string,
  trigger: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  onClickOutside: PropTypes.func,
  onUnmount: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  showPopoverArrow: PropTypes.bool,
  style: PropTypes.func,
  popoverArrowStyle: PropTypes.func,
  onVisible: PropTypes.func,
  bottomElementRenderer: PropTypes.func,
  usePointerDown: PropTypes.bool,
}

StickyPopover.defaultProps = {
  placement: "right",
  showPopoverArrow: true,
  onUnmount: () => {},
  usePointerDown: true,
}

export default StickyPopover
