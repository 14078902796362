import React from "react"
import SearchableDropDown from "js/includes/components/SearchableDropDown"
import OptionRow from "js/includes/components/OptionRow"
import DeviceIcon from "js/includes/components/DeviceIcon"
import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"

export default function SearchableDeviceDropdown({
  customDataFieldFormatter,
  customDropdownOptions,
  customFetchOptions,
  customRowRenderer,
  onSelect,
  pageSize,
  autoFocus,
  openOnFocus,
  onClose,
  enableClear,
  org,
  nodeClass,
  value,
  keepDropdownInView = true,
  isMulti,
  searchOnly,
  disabled = false,
}) {
  return (
    <SearchableDropDown
      {...{
        value,
        isMulti,
        width: "100%",
        pageSize,
        rowHeight: 50,
        placeholderToken: localizationKey("Search"),
        searchableKey: "name",
        valueSelectorKey: "id",
        endpoint: "/query/devices",
        onSelect,
        autoFocus,
        openOnFocus,
        onClose,
        searchOnly,
        disabled,
        enableClear,
        keepDropdownInView,
        searchParams: ({ query }) => ({
          ...(query && { name: query }),
          ...(org && { org }),
          ...(nodeClass && { nodeClass }),
        }),
        rowRenderer: rowData => {
          const { name, id, nodeClass, organizationName } = rowData
          return (
            customRowRenderer?.(rowData) ?? (
              <OptionRow
                {...{
                  renderIcon: () => (nodeClass ? <DeviceIcon {...{ nodeClass }} /> : null),
                  id,
                  name,
                  description: organizationName,
                  targetType: "DEVICE",
                }}
              />
            )
          )
        },
        ...customDropdownOptions,
      }}
    />
  )
}
