import { forwardRef, memo, useRef, useState } from "react"
import styled from "@emotion/styled"
import { CodeHighlightNode, CodeNode } from "@lexical/code"
import { HashtagNode } from "@lexical/hashtag"
import { AutoLinkNode, LinkNode } from "@lexical/link"
import { ListItemNode, ListNode } from "@lexical/list"
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin"
import { LexicalComposer } from "@lexical/react/LexicalComposer"
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary"
import { HashtagPlugin } from "@lexical/react/LexicalHashtagPlugin"
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin"
import { EditorRefPlugin } from "@lexical/react/LexicalEditorRefPlugin"
import { HorizontalRuleNode } from "@lexical/react/LexicalHorizontalRuleNode"
import { HorizontalRulePlugin } from "@lexical/react/LexicalHorizontalRulePlugin"
import { ListPlugin } from "@lexical/react/LexicalListPlugin"
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin"
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin"
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin"
import { TablePlugin } from "@lexical/react/LexicalTablePlugin"
import { HeadingNode, QuoteNode } from "@lexical/rich-text"
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table"
import { AutoLinkPlugin as LexicalAutoLinkPlugin } from "@lexical/react/LexicalAutoLinkPlugin"
import { $generateHtmlFromNodes } from "@lexical/html"
import { $getRoot, TextNode } from "lexical"

import { AlertMessage, Button } from "@ninjaone/components"
import tokens from "@ninjaone/tokens"
import { Flex } from "js/includes/components/Styled"
import { localizationKey } from "js/includes/common/utils"

import {
  $isEditorEmpty,
  CodeHighlightPlugin,
  ContentEditable,
  ContentInjectorPlugin,
  EditorBlurPlugin,
  ExtendedTextNode,
  FloatingLinkEditorPlugin,
  FloatingTextFormatToolbarPlugin,
  HTMLInjectorPlugin,
  HTMLParserPlugin,
  LinkPlugin,
  ListMaxIndentLevelPlugin,
  MessagePlugin,
  Placeholder,
  StyledEditor,
  StyledEditorContainer,
  StyledEditorScroller,
  StyledEditorShell as _StyledEditorShell,
  TabFocusPlugin,
  TableActionMenuPlugin,
  TableCellResizerPlugin,
  theme,
  ToolbarPlugin,
} from "@ninjaone/components/src/WYSIWYG"

import {
  InlineImageNode,
  InlineImagePlugin,
  SourcePlugin,
} from "js/includes/components/CustomFields/WYSIWYGEditor/plugins"
import { normalizeHtml } from "js/includes/components/CustomFields/WYSIWYGEditor/plugins/InlineImagePlugin/utils"

const shouldForwardProp = prop =>
  !["noBorder", "noPadding", "errorMessage", "fullWidth", "isPublicResponse"].includes(prop)

export const StyledEditorReadOnly = styled.div`
  position: relative;
  overflow-x: auto;
`

export const StyledEditorShell = styled(_StyledEditorShell, { shouldForwardProp })`
  border: ${({ theme, noBorder, errorMessage }) =>
    noBorder ? 0 : `1px solid ${errorMessage ? theme.colorBorderError : theme.colorBorderWeakest}`};
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
`

export const StyledContentEditable = styled(ContentEditable, { shouldForwardProp })`
  padding: ${({ noPadding }) => (noPadding ? 0 : `${tokens.spacing[2]} ${tokens.spacing[4]} ${tokens.spacing[9]}`)};
  background-color: ${({ theme }) => theme.colorBackground};

  code {
    white-space: wrap;

    .editor-text-code {
      word-break: break-all;
    }
  }

  code.editor-code::before {
    height: 100%;
  }
`

const nodes = [
  HeadingNode,
  QuoteNode,
  HashtagNode,
  LinkNode,
  CodeNode,
  CodeHighlightNode,
  HorizontalRuleNode,
  ListItemNode,
  ListNode,
  TableCellNode,
  TableNode,
  TableRowNode,
  AutoLinkNode,
  InlineImageNode,
  ExtendedTextNode,
  { replace: TextNode, with: node => new ExtendedTextNode(node.__text) },
]

function WYSIWYGEditor(props, ref) {
  const {
    fullWidth,
    noPadding,
    noBorder,
    onBlur,
    onChangePluginProps = {},
    htmlParserPluginProps = {},
    imagePluginProps = {},
    richTextPluginProps = {},
    autoLinkPluginProps = {},
    validationProps = {},
    tableActionMenuProps = {},
    toolbarPluginProps = {},
  } = props

  const [initialHTML, setInitialHTML] = useState(htmlParserPluginProps?.initialHTML || "")
  const { current: updateOnChange } = useRef(htmlParserPluginProps?.updateOnChange)

  const {
    current: { onSetContentRefs, onUploadImage, uploadsByContentIdRef, tempUriByContentIdRef },
  } = useRef(imagePluginProps)

  const { onChange } = onChangePluginProps

  const { matchers: lexicalAutoLinkMatchers } = autoLinkPluginProps

  const { placeholderToken } = richTextPluginProps
  const { disableAutoLink } = autoLinkPluginProps
  const { excluded: toolbarPluginExcluded, dropdownClassName, tableModalBackgroundClassName } = toolbarPluginProps
  const { containerClassName } = tableActionMenuProps

  const [floatingAnchorElement, setFloatingAnchorElement] = useState(null)
  const [isLinkEditMode, setIsLinkEditMode] = useState(false)
  const [isEditingSource, setIsEditingSource] = useState(false)
  const [hasError, setHasError] = useState(false)

  const onRef = element => {
    if (element !== null) {
      setFloatingAnchorElement(element)
    }
  }

  const handleClearContent = () => {
    setInitialHTML("")
    onChange(null)
    setHasError(false)
  }

  return (
    <>
      <LexicalComposer
        initialConfig={{
          onError: error => {
            setHasError(true)
          },
          theme,
          nodes,
        }}
      >
        {hasError ? (
          <Flex gap={tokens.spacing[2]} alignItems="center" width={720}>
            <AlertMessage
              variant="danger"
              labelToken={localizationKey("The HTML value contains unsupported elements and cannot be edited.")}
            />
            <Button labelToken={localizationKey("Clear content and edit")} onClick={handleClearContent} />
          </Flex>
        ) : (
          <>
            <StyledEditorShell errorMessage={validationProps.errorMessage} noBorder={noBorder} fullWidth={fullWidth}>
              {!toolbarPluginExcluded && (
                <ToolbarPlugin
                  cidKey="resourceId"
                  classNames={{
                    dropdownClassName,
                    tableModalBackgroundClassName,
                  }}
                  onUploadImage={onUploadImage}
                />
              )}
              <MessagePlugin />
              <StyledEditorContainer>
                <RichTextPlugin
                  ErrorBoundary={LexicalErrorBoundary}
                  contentEditable={
                    <StyledEditorScroller id="editor-input">
                      <StyledEditor ref={onRef}>
                        <StyledContentEditable
                          className="editor-root"
                          noPadding={noPadding}
                          placeholder={<Placeholder token={placeholderToken} />}
                        />
                      </StyledEditor>
                    </StyledEditorScroller>
                  }
                />
                {ref && <EditorRefPlugin editorRef={ref} />}
                <HistoryPlugin />
                <ClearEditorPlugin />
                <ListPlugin />
                <LinkPlugin />
                {!disableAutoLink && lexicalAutoLinkMatchers?.length && (
                  <LexicalAutoLinkPlugin matchers={lexicalAutoLinkMatchers} />
                )}
                <CodeHighlightPlugin />
                <HorizontalRulePlugin />
                <TablePlugin />
                <TableCellResizerPlugin />
                <HashtagPlugin />
                <HTMLParserPlugin initialHTML={initialHTML} updateOnChange={updateOnChange} />
                <TabFocusPlugin />
                <TabIndentationPlugin />
                <SourcePlugin setIsEditingSource={setIsEditingSource} />
                <OnChangePlugin
                  onChange={(editorState, editor) => {
                    if (!isEditingSource) {
                      editor.update(() => {
                        const root = $getRoot()
                        const text = root.getTextContent()
                        const html = $generateHtmlFromNodes(editor)

                        const value = $isEditorEmpty()
                          ? null
                          : {
                              html: normalizeHtml(html),
                              text,
                            }

                        onChange(value)
                      })
                    }
                  }}
                />
                <ListMaxIndentLevelPlugin maxDepth={5} />
                <ContentInjectorPlugin />
                {floatingAnchorElement && (
                  <>
                    <FloatingTextFormatToolbarPlugin
                      anchorElem={floatingAnchorElement}
                      setIsLinkEditMode={setIsLinkEditMode}
                    />
                    <FloatingLinkEditorPlugin
                      anchorElem={floatingAnchorElement}
                      isLinkEditMode={isLinkEditMode}
                      setIsLinkEditMode={setIsLinkEditMode}
                    />
                    <TableActionMenuPlugin containerClassName={containerClassName} anchorElem={floatingAnchorElement} />
                  </>
                )}
                <InlineImagePlugin
                  cidKey="resourceId"
                  onSetContentRefs={onSetContentRefs}
                  onUploadImage={onUploadImage}
                  uploadsByContentIdRef={uploadsByContentIdRef}
                  tempUriByContentIdRef={tempUriByContentIdRef}
                />
              </StyledEditorContainer>
            </StyledEditorShell>
            <EditorBlurPlugin onBlur={onBlur} />
            <HTMLInjectorPlugin />
          </>
        )}
      </LexicalComposer>
    </>
  )
}

export default memo(forwardRef(WYSIWYGEditor))
