import tokens from "@ninjaone/tokens"
import { colors } from "js/includes/common/theme"

export const StyledWysiwygIframeHtmlView = theme => `
  body {
    margin: 0;
    background-color: transparent;
    word-break: break-word;
    font-family: inter, sans-serif;
  }

  *,
  ::after,
  ::before {
    box-sizing: border-box;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  h1,
  h2 {
    margin-top: 10px;
  }

  blockquote {
    padding: 10px 20px;
    margin: 0 0 10px;
    border-left: 5px solid ${theme.color.brightGray};
    white-space: pre-wrap;
    overflow-wrap: break-word;
    word-break: break-word;
  }
  
  code.editor-code, pre.editor-code {
    color: #D53948;
    font-family: monospace;
    display: block;
    background-color: ${theme.colorBackgroundAccentNeutralWeak};
    padding: ${tokens.spacing[2]} ${tokens.spacing[2]} ${tokens.spacing[2]} ${tokens.spacing[9]};
    line-height: ${tokens.typography.lineHeight};
    font-size: ${tokens.typography.fontSize.bodyXs};
    margin: 0;
    margin-top: ${tokens.spacing[2]};
    margin-bottom: ${tokens.spacing[2]};
    overflow-x: auto;
    position: relative;
    tab-size: 2;
    word-break: break-all;
    
    .token.operator {
      background-color: ${theme.colorBackgroundAccentNeutralWeak};
    }
  }
  
  pre.editor-code::before {
    content: attr(data-gutter);
    background-color: rgb(202, 208, 214);
    position: absolute;
    left: 0px;
    top: 0px;
    border-right: 1px solid rgb(149, 160, 168);
    padding: 8px;
    color: rgb(21, 22, 23);
    white-space: pre-wrap;
    text-align: right;
    min-width: 24px;
    height: 100%;
  }
    
  ol,
  ul {
    list-style-type: revert;
  }

  p,
  ul,
  li {
    color: ${theme.colorTextStrong};
    font-size: 14px;
    font-weight: 400;
    word-wrap: break-word;
    white-space: pre-wrap;
  }

  ul.unstyled {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  h1 {
    color: ${theme.colorTextStrong};
    font-size: 24px;
    font-weight: 600;
    word-wrap: break-word;
    white-space: pre-wrap;
  }

  h2 {
    color: ${theme.colorTextStrong};
    font-size: 20px;
    font-weight: 500;
    word-wrap: break-word;
    white-space: pre-wrap;
  }

  h3 {
    color: ${theme.colorTextStrong};
    font-size: ${tokens.typography.fontSize.headingS};
    font-weight: 500;
    word-wrap: break-word;
    white-space: pre-wrap;
  }

  h4 {
    color: ${theme.colorTextWeakest};
    font-size: 14px;
    font-weight: 400;
    word-wrap: break-word;
    white-space: pre-wrap;
  }

  h5 {
    color: ${theme.colorTextWeakest};
    font-size: ${tokens.typography.fontSize.bodyXs};
    font-weight: 400;
    word-wrap: break-word;
    white-space: pre-wrap;
  }

  strong {
    color: ${theme.colorTextStrong};
    font-weight: bold;
    word-wrap: break-word;
    white-space: pre-wrap;
  }

  a {
    color: ${theme.colorTextAction};
    text-decoration: none;
    white-space: pre-wrap;
  }

  a:hover {
    color: ${theme.color.ninjaBlueDark};
  }

  a:active {
    color: ${theme.color.ninjaBlueDark};
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid ${theme.colorBorderWeak} !important;
  }

  th,
  td {
    text-align: left !important;
    padding: ${tokens.spacing[2]};
    white-space: pre-wrap !important;
    border: 0.5px solid ${theme.colorBorderWeak} !important;
  }
  
  th {
    color: ${theme.colorTextStrong};
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    word-wrap: break-word;
    white-space: pre-wrap;
  }

  td {
    color: ${theme.colorTextWeak};
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    white-space: pre-wrap;
  }

  tbody tr:hover {
    background-color: ${theme.colorBackgroundAccentNeutralWeakest};
  }

  tr.danger {
    padding: 7px ${tokens.spacing[2]};
    border-left: 6px ${theme.color.error["100"]} solid;
  }

  tr.warning {
    padding: 7px ${tokens.spacing[2]};
    border-left: 6px ${theme.colorAlertWarning} solid;
  }

  tr.success {
    padding: 7px ${tokens.spacing[2]};
    border-left: 6px ${theme.colorAlertSuccess} solid;
  }

  tr.unknown {
    padding: 7px ${tokens.spacing[2]};
    border-left: 6px ${theme.color.ninjaMedium} solid;
  }

  tr.other {
    padding: 7px ${tokens.spacing[2]};
    border-left: 6px ${theme.colorBorderDecorativeStrong} solid;
  }
  
  s, u {
    text-decoration: none;
  }

  .field-container {
    justify-content: center;
    align-items: center;
    max-width: 100%;
    gap: 10px;
    overflow: auto;
  }

  .card {
    padding: ${tokens.spacing[6]};
    background: ${theme.colorBackground};
    border-radius: ${tokens.borderRadius[1]};
    border: 0.5px ${theme.colorBorderWeak} solid;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: ${tokens.spacing[2]};
    display: inline-flex;
    white-space: pre-wrap;
  }

  .card-title {
    color: ${theme.colorTextStrong};
    font-size: ${tokens.typography.fontSize.headingS};
    font-weight: 500;
    line-height: ${tokens.spacing[6]};
    word-wrap: break-word;
    white-space: pre-wrap;
  }

  .card-title-box {
    align-self: stretch;
    justify-content: space-between;
    align-items: center;
    gap: 149px;
    display: inline-flex;
  }

  .card-link-box {
    border-radius: ${tokens.borderRadius[1]};
    justify-content: center;
    align-items: center;
    gap: ${tokens.spacing[2]};
    display: flex;
  }

  .card-link {
    color: ${theme.colorTextAction};
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    word-wrap: break-word;
  }

  .card-body {
    color: ${theme.colorTextStrong};
    font-size: 14px;
    font-weight: 400;
    line-height: ${tokens.spacing[6]};
    word-wrap: break-word;
    width: 100%;
    white-space: pre-wrap;
  }

  .stat-card {
    width: 100%;
    padding: ${tokens.spacing[6]};
    border-radius: ${tokens.borderRadius[1]};
    border: 0.5px ${theme.colorBorderWeak} solid;
    flex-direction: column;
    gap: ${tokens.spacing[2]};
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
    padding-top: ${tokens.spacing[9]};
    padding-bottom: ${tokens.spacing[9]};
    text-align: Center;
    margin-bottom: ${tokens.spacing[6]};
    height: 148px;
    white-space: pre-wrap;
  }

  .stat-value {
    height: 50%;
    font-size: 40px;
    color: ${colors.reactVirtualizedLightGrey};
    margin-bottom: 10px;
  }

  .stat-desc {
    height: 50%;
    white-space: nowrap;
  }

  .btn {
    padding: ${tokens.spacing[3]};
    background: ${theme.colorBackgroundCta};
    border-radius: ${tokens.borderRadius[1]};
    justify-content: center;
    align-items: center;
    display: inline-flex;
    color: ${theme.colorTextHighContrast};
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    word-wrap: break-word;
    text-decoration: none;
    border: 1px solid transparent;
    transition: background-color 0.3s ease, border-color 0.3s ease;
    outline: none;
  }

  .btn:hover {
    background: ${theme.colorBackgroundAccentCtaStrongest};
  }

  .btn:focus {
    border: 1px solid ${theme.colorBorderDecorativeStrong};
  }

  .btn.secondary {
    background: ${theme.colorBackground};
    color: ${theme.colorTextAction};
    padding: 12.5px;
    border: 0.5px solid ${theme.colorBorderWeak};
  }

  .btn.secondary:hover {
    background: ${theme.colorBackgroundAccentNeutralWeakest};
  }

  .btn.secondary:focus {
    border-color: 1px solid ${theme.colorBorderDecorativeStrong};
  }

  .btn.danger {
    background: ${theme.colorBackgroundCtaDanger};
    color: ${theme.colorTextHighContrast};
    border: 0.5px solid transparent;
  }

  .btn.danger:hover {
    background: ${theme.colorForegroundCtaDangerHover};
  }

  .btn.danger:focus {
    border-color: 1px solid ${theme.colorBorderDecorativeStrong};
  }

  .info-card {
    width: 100%;
    padding: ${tokens.spacing[3]};
    background: ${theme.colorBackgroundAccentCtaWeak};
    border-radius: ${tokens.borderRadius[1]};
    justify-content: flex-start;
    align-items: flex-start;
    gap: ${tokens.spacing[2]};
    display: inline-flex;
    margin-bottom: 10px;
    white-space: pre-wrap;
  }

  .info-icon {
    text-align: center;
    color: ${theme.colorTextAction};
    font-size: 14px;
    font-weight: 900;
    word-wrap: break-word;
  }

  .info-text {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: ${tokens.spacing[2]};
    display: inline-flex;
    white-space: pre-wrap;
  }

  .info-title {
    color: ${theme.colorTextStrong};
    font-size: 14px;
    font-weight: 600;
    word-wrap: break-word;
    white-space: pre-wrap;
  }

  .info-description {
    color: ${theme.colorTextStrong};
    font-size: 14px;
    font-weight: 400;
    word-wrap: break-word;
    white-space: pre-wrap;
  }

  .info-card.error {
    background-color: ${theme.colorAlertErrorWeak};
  }

  .info-card.error .info-icon {
    color: ${theme.colorAlertError};
  }

  .info-card.warning {
    background-color: ${theme.colorAlertWarningWeak};
  }

  .info-card.warning .info-icon {
    color: ${theme.colorAlertWarning};
  }

  .info-card.success {
    background-color: ${theme.colorAlertSuccessWeak};
  }

  .info-card.success .info-icon {
    color: ${theme.colorAlertSuccess};
  }

  .tag {
    padding: 2px ${tokens.spacing[2]};
    background: ${theme.color.success["100"]};
    border-radius: 2px;
    justify-content: center;
    align-items: center;
    gap: ${tokens.spacing[2]};
    display: inline-flex;
    color: ${theme.colorTextHighContrast};
    font-size: 14px;
    font-weight: 400;
    word-wrap: break-word;
  }

  .tag.disabled {
    background: ${theme.color.black["010"]};
    color: ${theme.color.black["065"]};
  }

  .tag.expired {
    background: ${theme.color.black["010"]};
    color: ${theme.color.black["100"]};
  }

  .close {
    position: absolute;
    top: ${tokens.spacing[6]};
    right: 27px;
    color: ${theme.color.black["100"]};
    text-decoration: none;
    font-size: 24px;
    font-weight: 300;
  }

  .nowrap {
    white-space: nowrap;
  }

  .linechart {
    width: 100%;
    height: 50px;
    display: flex;
  }

  .chart-key {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  
  .editor-text-strikethrough {
    text-decoration: line-through;
  }
  
  .editor-text-underline {
    text-decoration: underline;
  }
  
  .editor-text-underline-strikethrough {
    text-decoration: line-through underline;
  }
  
  .editor-text-code {
    padding: 1px 2px;
    border-radius: ${tokens.spacing[1]};
    font-size: ${tokens.typography.fontSize.bodyXs};
    background-color: ${theme.colorBackgroundAccentNeutralWeak};
    border: 1px solid ${theme.colorBorderStrong};
    color: ${theme.colorTextDanger};
  }
  
  .editor-nested-list-item {
    display: inline-block;
  }
  
  .editor-ol1 {
    list-style-type: decimal;
  }
  
  .editor-ol1 .editor-ol2 {
    list-style-type: upper-alpha;
  }
  
  .editor-ol1 .editor-ol2 .editor-ol3 {
    list-style-type: lower-alpha;
  }
  
  .editor-ol1 .editor-ol2 .editor-ol3 .editor-ol4 {
    list-style-type: upper-roman;
  }
  
  .editor-ol1 .editor-ol2 .editor-ol3 .editor-ol4 .editor-ol5 {
    list-style-type: lower-roman;
  }
  
  .editor-text-superscript {
    vertical-align: super;
    font-size: smaller;
  }
  
  .editor-text-subscript {
    vertical-align: sub;
    font-size: smaller;
  }
`
