import { useEffect, useRef, useState } from "react"

export const withLazyRenderInView = Component => {
  return function LazyRenderInView(props) {
    const targetElement = useRef(null)
    const [isInView, setIsInView] = useState(false)

    useEffect(() => {
      const element = targetElement.current
      const observer = new IntersectionObserver(
        function onIntersection(entries) {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              setIsInView(true)
              observer.unobserve(element)
            }
          })
        },
        {
          root: null,
          rootMargin: "0px",
        },
      )

      observer.observe(element)

      return () => observer.unobserve(element)
    }, [])

    return <div ref={targetElement}>{<Component {...props} isInView={isInView} />}</div>
  }
}
