import { always, cond, T, gt, lte, and } from "ramda"
import { useTheme } from "@emotion/react"

import { Body } from "@ninjaone/components"
import { typography } from "@ninjaone/tokens"
import { sizer } from "@ninjaone/utils"

import { localizationKey, localized, localizedWith } from "js/includes/common/utils"
import { ConfirmationModal } from "js/includes/components/ConfirmationModal"
import { Box, Flex } from "js/includes/components/Styled"

const getTitleToken = cond([
  [({ allChecked }) => allChecked, always(localizationKey("Delete all {{quantity}} ticket products?"))],
  [({ quantity }) => gt(quantity, 20), always(localizationKey("Delete {{quantity}} ticket products?"))],
  [({ quantity }) => and(gt(quantity, 1), lte(quantity, 20)), always(localizationKey("Delete ticket products?"))],
  [T, always(localizationKey("Delete ticket product?"))],
])

const getDescriptionToken = cond([
  [
    ({ allChecked }) => allChecked,
    always(
      localizationKey(
        "You are about to permanently delete all {{quantity}} ticket products. Once deleted, this action cannot be undone.",
      ),
    ),
  ],
  [
    ({ quantity }) => gt(quantity, 20),
    always(
      localizationKey(
        "You are about to permanently delete {{quantity}} ticket products. Once deleted, this action cannot be undone.",
      ),
    ),
  ],
  [
    T,
    always(
      localizationKey(
        "You are about to permanently delete the following ticket products. Once deleted, this action cannot be undone.",
      ),
    ),
  ],
])

function SingleItemDeletionDescription({ item }) {
  return (
    <Body textWrap>
      {localizedWith(
        "You are about to permanently delete <%boldTextRenderer>itemName<%>. Once deleted, this action cannot be undone.",
        {
          boldTextRenderer: () => (
            <Body as="span" fontWeight={typography.fontWeight.semiBold}>
              {item.name}
            </Body>
          ),
        },
      )}
    </Body>
  )
}

function MultipleItemsDeletionDescription({ descriptionToken, quantity }) {
  return <Body textWrap>{localized(descriptionToken, { quantity })}</Body>
}

function ItemList({ list }) {
  const theme = useTheme()

  const quantity = list.length
  const scrollable = quantity > 9
  const showList = quantity > 1 && quantity <= 20

  if (!showList) return null

  return (
    <Flex
      gridGap={sizer(1)}
      padding={sizer(2)}
      width="100%"
      flexDirection="column"
      {...(scrollable && {
        maxHeight: "200px",
        overflowY: "scroll",
        marginTop: sizer(4),
        backgroundColor: theme.colorBackgroundSubtle,
      })}
    >
      {list.map(({ name, id }) => (
        <Box key={id}>
          <Body>{name}</Body>
        </Box>
      ))}
    </Flex>
  )
}

export const DeleteTicketProductsConfirmationModal = ({ unmount, list, allChecked, onDelete }) => {
  const quantity = list.length
  const titleToken = getTitleToken({ quantity, allChecked })
  const descriptionToken = getDescriptionToken({ quantity, allChecked })

  return (
    <ConfirmationModal
      titleText={localized(titleToken, { quantity })}
      actionToken={localizationKey("Delete")}
      type="danger"
      disabled={false}
      onConfirm={() => onDelete({ unmount, list, allChecked })}
      unmount={unmount}
    >
      {list.length === 1 ? (
        <SingleItemDeletionDescription item={list[0]} />
      ) : (
        <MultipleItemsDeletionDescription descriptionToken={descriptionToken} quantity={quantity} />
      )}
      {!allChecked && <ItemList list={list} />}
    </ConfirmationModal>
  )
}
