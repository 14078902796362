import { any, compose, forEach, head, includes, map, prop, uniq } from "ramda"
import { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"

import { spacing, borderRadius } from "@ninjaone/tokens"
import { Body, DataTable, Heading, TitleGroup } from "@ninjaone/components"
import { filterTypes } from "@ninjaone/components/src/DataTable"
import { useMountedState } from "@ninjaone/utils"
import styled from "@emotion/styled"

import { Box, Flex } from "js/includes/components/Styled"
import {
  actionDialog,
  isNotNil,
  localizationKey,
  localized,
  localizedWith,
  objectWithIdKeysToList,
  reportErrorAndShowMessage,
} from "js/includes/common/utils"
import {
  getOverrideStateColumn,
  getInlineTags,
  isInheritedRow,
  isNotOverriddenRow,
} from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/util"
import showModal from "js/includes/common/services/showModal"
import { getMDMDefaults } from "js/includes/common/client"
import { deletePolicyItem, revertPolicySection } from "js/state/actions/policyEditor/editor"
import PersistentPreferredActivitiesModal from "./PersistentPreferredActivitiesModal"

const ACTIVITIES_LIST_LIMIT = 20

const StyledList = styled.ul`
  background-color: ${({ theme }) => theme.colorBackgroundAccentNeutralWeakest};
  border-radius: ${borderRadius[1]};
  color: ${({ theme }) => theme.colorTextStrong};
  margin-top: ${spacing[4]};
  max-height: 240px;
  overflow: auto;
  padding: ${spacing[2]};
`

const getColumns = ({ isChildPolicy }) => [
  {
    id: "displayName",
    Header: localized("Name"),
    accessor: "displayName",
    minWidth: "135",
  },
  {
    id: "receiverActivity",
    Header: localized("Activity"),
    accessor: "receiverActivity",
    minWidth: "400",
  },
  {
    id: "action",
    Header: localized("Actions"),
    accessor: ({ actions = [] }) => head(actions),
    getCellInlineTags: ({ actions = [] }) => getInlineTags(actions, "actions-items"),
  },
  {
    id: "categories",
    Header: localized("Categories"),
    accessor: ({ categories = [] }) => head(categories),
    getCellInlineTags: ({ categories = [] }) => getInlineTags(categories, "categories-items"),
  },
  ...(isChildPolicy ? [getOverrideStateColumn()] : []),
]

const mapFilterOptions = compose(
  map(option => ({ value: option, label: option, labelText: option })),
  uniq,
)

const getRemoveMessageComponent = activities => {
  const activitiesLength = activities.length
  const message =
    activitiesLength === 1
      ? localizedWith(
          "You are about to remove <%activityName>activityName<%>. Once saved, any application defaults associated with this activity will revert to system default. This action cannot be undone.",
          {
            activityName: () => <strong>{activities[0].displayName}</strong>,
          },
        )
      : localizedWith(
          "You are about to remove <%activitiesLength>activitiesLength<%>. Once saved, any application defaults associated with these activities will revert to system default. This action cannot be undone.",
          {
            activitiesLength: () => (
              <strong>{localized("the following {{length}} activities", { length: activitiesLength })}</strong>
            ),
          },
        )
  const isAppsListVisible = activitiesLength > 1 && activitiesLength <= ACTIVITIES_LIST_LIMIT
  return () => (
    <>
      <Body textWrap color="colorTextStrong">
        {message}
      </Body>
      {isAppsListVisible && (
        <StyledList>{map(({ displayName, guid }) => <li key={guid}>{displayName}</li>)(activities)}</StyledList>
      )}
    </>
  )
}

export default function MobileApplicationsAdvancedSettings() {
  const dispatch = useDispatch()
  const {
    applications: { persistentPreferredActivitiesSettings = {} },
    parentPolicy = {},
  } = useSelector(state => ({
    applications: state.policyEditor?.policy?.content?.applications,
    parentPolicy: state.policyEditor?.parentPolicy,
  }))
  const isChildPolicy = isNotNil(parentPolicy)
  const columns = getColumns({ isChildPolicy })
  const [actionOptions, setActionOptions] = useMountedState([])
  const [categoryOptions, setCategoryOpitons] = useMountedState([])

  const activities = useMemo(() => {
    return objectWithIdKeysToList(persistentPreferredActivitiesSettings, "guid")
  }, [persistentPreferredActivitiesSettings])

  useEffect(() => {
    async function fetchOptionsData() {
      try {
        const { actions = [], categories = [] } = await getMDMDefaults("ANDROID")
        setActionOptions(mapFilterOptions(actions))
        setCategoryOpitons(mapFilterOptions(categories))
      } catch (error) {
        reportErrorAndShowMessage(error, localizationKey("Error while loading the actions and categories data"))
      }
    }

    fetchOptionsData()
  }, [setActionOptions, setCategoryOpitons])

  const filters = {
    primary: [
      {
        name: "action",
        type: filterTypes.SINGLE_SELECT,
        labelToken: localizationKey("Action"),
        componentProps: {
          options: actionOptions,
        },
        filter: ({ row, value }) => includes(value, row?.actions || []),
      },
      {
        name: "category",
        type: filterTypes.SINGLE_SELECT,
        labelToken: localizationKey("Category"),
        componentProps: {
          options: categoryOptions,
        },
        filter: ({ row, value }) => includes(value, row?.categories || []),
      },
    ],
  }

  const openActivityModal = (selected = null) => {
    showModal(
      <PersistentPreferredActivitiesModal
        {...{
          actionOptions,
          persistentPreferredActivitiesSettings,
          parentPolicy,
          categoryOptions,
          activity: selected,
        }}
      />,
      {
        withProvider: true,
      },
    )
  }

  return (
    <Flex gap={spacing[6]} marginLeft={spacing[6]} flexDirection="column" height="100%">
      <TitleGroup
        titleToken={localizationKey("Advanced")}
        descriptionToken={localizationKey("Configurations for advanced functionality management.")}
      />
      <Box height="100%">
        <Heading level={3} marginBottom={spacing[4]}>
          {localized("Persistent preferred activities")}
        </Heading>
        <DataTable
          {...{
            tableId: "mobile-applications-advanced-settings-table",
            columns,
            rows: activities,
            globalActionsButton: {
              buttonProps: {
                labelToken: localizationKey("Add activity"),
                action: () => {
                  showModal(
                    <PersistentPreferredActivitiesModal
                      {...{
                        actionOptions,
                        persistentPreferredActivitiesSettings,
                        parentPolicy,
                        categoryOptions,
                      }}
                    />,
                    {
                      withProvider: true,
                    },
                  )
                },
              },
            },
            filters,
            actions: {
              primary: [
                {
                  labelToken: localizationKey("Revert overrides"),
                  action: compose(
                    forEach(activity =>
                      dispatch(
                        revertPolicySection(
                          `applications.persistentPreferredActivitiesSettings.${activity.guid}`,
                          activity,
                        ),
                      ),
                    ),
                    prop("selected"),
                  ),
                  hideMultiAction: any(isNotOverriddenRow),
                  hideRowAction: isNotOverriddenRow,
                },
                {
                  labelToken: localizationKey("Edit"),
                  action: ({ selected }) => openActivityModal(selected[0]),
                  splitAfter: true,
                  hideMultiAction: selected => selected.length > 1,
                },
                {
                  labelToken: localizationKey("Remove"),
                  hideMultiAction: any(isInheritedRow),
                  hideRowAction: row => isInheritedRow(row),
                  action: async ({ selected }) => {
                    try {
                      const deleteButtonPressed = await actionDialog({
                        title:
                          selected.length === 1
                            ? localizationKey("Remove activity?")
                            : localizationKey("Remove activities?"),
                        message: null,
                        MessageComponent: getRemoveMessageComponent(selected),
                        actionButtonLabel: localizationKey("Remove"),
                        type: "critical",
                        isLegacy: false,
                      })
                      if (deleteButtonPressed) {
                        for (const { guid } of selected) {
                          dispatch(deletePolicyItem(`applications.persistentPreferredActivitiesSettings.${guid}`))
                        }
                      }
                    } catch (error) {
                      reportErrorAndShowMessage(error, localizationKey("Error while deleting"))
                    }
                  },
                  isRed: true,
                  variant: "danger",
                },
              ],
            },
          }}
        />
      </Box>
    </Flex>
  )
}
