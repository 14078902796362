import { isNil } from "ramda"
import { Link } from "@ninjaone/components"

export default function IpAddressValueRenderer(field, { value } = {}) {
  const valueWithProtocol = `https://${value}`
  return (
    (!isNil(value) && (
      <Link href={valueWithProtocol} target="_blank" rel="noopener noreferrer">
        {value}
      </Link>
    )) ||
    null
  )
}
