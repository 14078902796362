import React from "react"
import Tooltip from "js/includes/components/Tooltip"

export default function OptionRow({ renderIcon, id, name, description, targetType }) {
  return (
    <div key={`${id}-${targetType}`} className="full-width">
      <div className="display-flex align-items-center">
        {renderIcon()}
        <h4 className="no-margin p-l-xs">{name}</h4>
      </div>
      {description && (
        <div className="text-ellipsis text-left m-t-xs">
          <Tooltip output={description}>
            <span>{description}</span>
          </Tooltip>
        </div>
      )}
    </div>
  )
}
