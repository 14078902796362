import { Body } from "@ninjaone/components"
import tokens from "@ninjaone/tokens"
import {
  fetch,
  showSuccessMessage,
  showErrorMessage,
  localized,
  runDeviceSearchToolbarAction,
  localizedWith,
} from "js/includes/common/utils"
import { NinjaResponseError } from "js/includes/common/types"
import { showModalAsync } from "js/includes/common/services/showModalAsync"
import DisableMaintenanceConfirmationModal from "js/includes/deviceSearch/components/DeviceList/DeviceListToolbar/maintanance/DisableMaintenanceConfirmationModal"

export const enableMaintenanceOnDevices = ({
  deviceIds,
  schedule,
  options,
  reasonMessage,
  fetchDevicesInMaintenance,
  dispatchCallback,
  fromDeviceSearch,
  activeSearch,
}) => async dispatch => {
  const requestBody = {
    schedule,
    options,
    reasonMessage: reasonMessage?.trim(),
    ...(!fromDeviceSearch && { deviceIds }),
  }

  const response = fromDeviceSearch
    ? await runDeviceSearchToolbarAction({
        action: "RUN_MAINTENANCE_CONFIGURE",
        data: requestBody,
      })
    : await fetch("/maintenance", { options: { method: "POST", body: JSON.stringify(requestBody) } })
  if (!fromDeviceSearch && response.status !== 204) {
    throw new NinjaResponseError(response)
  } else {
    showSuccessMessage()
    fetchDevicesInMaintenance && fetchDevicesInMaintenance()
    dispatchCallback && dispatchCallback({ type: "CLEAR_SELECTION" })
  }
}

export const disableMaintenanceOnDevices = ({
  deviceIds,
  deviceName,
  fetchDevicesInMaintenance,
  dispatchCallback,
  fromDeviceSearch,
  activeSearch,
}) => async dispatch => {
  try {
    const getConfirmationMessage = () => {
      if (fromDeviceSearch) {
        return localized(
          "Any suppressed features will be reset once maintenance mode ends for all devices that meet the selected criteria.",
        )
      }
      if (deviceName) {
        return localizedWith(
          "Any suppressed features for <%deviceName> <%> will be reset once maintenance mode ends.",
          {
            deviceName: () => (
              <Body as="strong" fontWeight={tokens.typography.fontWeight.medium} textWrap>
                {deviceName}
              </Body>
            ),
          },
        )
      }
      return deviceIds.length === 1
        ? localized("Any suppressed features will be reset once maintenance mode ends.")
        : localized("Any suppressed features for {{deviceCount}} devices will be reset once maintenance mode ends.", {
            deviceCount: deviceIds.length,
          })
    }

    const confirmDisable = await showModalAsync(
      <DisableMaintenanceConfirmationModal message={getConfirmationMessage()} />,
      { withProvider: true },
    )

    if (confirmDisable) {
      const response = fromDeviceSearch
        ? await runDeviceSearchToolbarAction({
            action: "RUN_MAINTENANCE_DISABLE",
          })
        : await fetch("/maintenance/schedules/deleteList", {
            options: { method: "POST", body: JSON.stringify({ deviceIds }) },
          })

      if (!fromDeviceSearch && response?.status !== 204) {
        throw new NinjaResponseError(response)
      } else {
        showSuccessMessage()
        fetchDevicesInMaintenance && fetchDevicesInMaintenance()
        dispatchCallback && dispatchCallback({ type: "CLEAR_SELECTION" })
      }
    }
  } catch (error) {
    if (!error.isDevicesCacheExpiredError) {
      showErrorMessage(localized("Failed to disable maintenance."))
    }
  }
}
