import React, { useCallback } from "react"
import { sizer } from "@ninjaone/utils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStarOfLife, faEllipsis } from "@fortawesome/pro-solid-svg-icons"
import { faEye } from "@fortawesome/pro-light-svg-icons"
import { Box, Flex } from "js/includes/components/Styled"
import { getCustomFieldSecureValue, getDocumentRevisionSecretValue } from "js/includes/common/client/customFields"
import { isNilOrEmpty, reportErrorAndShowMessage, localized } from "js/includes/common/utils"
import { useMountedState, useCountDown } from "js/includes/common/hooks"
import {
  hasDeviceSecureFieldsAccess,
  hasDocumentationManagementAccess,
} from "js/includes/common/services/userPermissions"
import { ACCESS } from "js/includes/editors/User/Permissions/common"
import { Tooltip, ClipboardCopy, Text, Body } from "@ninjaone/components"

import styled from "@emotion/styled"

const StyledBox = styled.div`
  color: ${({ theme }) => theme.colorTextStrong};
  border-radius: ${sizer(1)};
  width: ${sizer(8)};
  height: ${sizer(8)};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colorForegroundHover};
  }
`

const StyledIcon = ({ icon, text, ...rest }) => (
  <Tooltip label={text}>
    <StyledBox {...rest}>
      <FontAwesomeIcon icon={icon} />
    </StyledBox>
  </Tooltip>
)

const initialTime = 20 * 1000
const interval = 1000

export default function TextEncryptedValueRenderer(
  field,
  { id, value, documentId, revision, required } = {},
  { setTimer } = {},
) {
  const [visible, setVisible] = useMountedState(false)
  const [plainTextValue, setPlainTextValue] = useMountedState("")
  const onCountDownComplete = useCallback(() => {
    setVisible(false)
    setPlainTextValue("")
  }, [setVisible, setPlainTextValue])
  // eslint-disable-next-line no-unused-vars
  const [_, { start, reset }] = useCountDown(initialTime, interval, onCountDownComplete)

  const showActions = false

  const copySecureValueToClipboard = async () => {
    try {
      const shouldGetSecureValue = !plainTextValue

      if (shouldGetSecureValue) {
        const response = await (revision
          ? getDocumentRevisionSecretValue({ documentId, revision, attributeId: id })
          : getCustomFieldSecureValue(field.entityType, id))

        return response.value
      }
    } catch (error) {
      if (!error.isHandledMfaError) {
        reportErrorAndShowMessage(error)
      }
      return ""
    }
  }

  const togglePlainTextValue = async () => {
    if (visible) {
      reset()
      setVisible(false)
      setPlainTextValue("")
      setTimer(0)
    } else {
      try {
        const shouldGetSecureValue = !plainTextValue

        if (shouldGetSecureValue) {
          const response = await (revision
            ? getDocumentRevisionSecretValue({ documentId, revision, attributeId: id })
            : getCustomFieldSecureValue(field.entityType, id))

          setPlainTextValue(response.value)
          setVisible(true)
        }

        start()
        setTimer(initialTime / 1000)
      } catch (error) {
        if (!error.isHandledMfaError) {
          reportErrorAndShowMessage(error)
        }
      }
    }
  }

  const isUserAllowedToAccessSecureFields =
    field.entityType === "DOCUMENT"
      ? hasDocumentationManagementAccess(`SecureFields.${ACCESS}`)
      : hasDeviceSecureFieldsAccess()

  return isNilOrEmpty(value) ? null : (
    <Flex marginTop={1} width={400} justifyContent="space-between" alignItems="center" height={sizer(8)}>
      <Box width="100%">
        {visible && isUserAllowedToAccessSecureFields ? (
          <Text fontSize="16px" fontWeight="bold" color="colorTextStrong">
            {plainTextValue}
          </Text>
        ) : (
          <Body type="body" lineHeight={24}>
            {[...Array(10).keys()].map(i => (
              <FontAwesomeIcon key={i} icon={faStarOfLife} />
            ))}
          </Body>
        )}
      </Box>

      {isUserAllowedToAccessSecureFields && (
        <Flex>
          <StyledIcon text={localized("Show password")} onClick={togglePlainTextValue} icon={faEye} height={21} />
          <ClipboardCopy
            value={plainTextValue}
            getValue={copySecureValueToClipboard}
            tooltipText={localized("Copy password")}
            iconColor="colorTextWeak"
          />
          {showActions && <StyledIcon icon={faEllipsis} />}
        </Flex>
      )}
    </Flex>
  )
}
