import styled from "@emotion/styled"
import { ComboboxItem, ComboboxItemCheck, SelectItem, useSelectContext } from "@ariakit/react"
import isPropValid from "@emotion/is-prop-valid"

import tokens from "@ninjaone/tokens"
import { CheckIconRegular } from "@ninjaone/icons"

import Body from "../../Typography/Body"

import { getCheckboxContainerStyles, getCheckboxIconByState, getCheckboxStyles } from "../../Checkbox"
import { defaultOptionHeight, optionIconWidth, selectedOptionIconWidth } from "../constants"
import { shouldIgnoreProps } from "../utils"

const StyledComboboxItem = styled(ComboboxItem, {
  shouldForwardProp: prop => isPropValid(prop) || shouldIgnoreProps(prop),
})`
  outline: none;
  position: relative;

  min-height: ${defaultOptionHeight};
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: start;

  gap: ${tokens.spacing[2]};

  cursor: pointer;
  padding: ${tokens.spacing[1]} ${tokens.spacing[3]};

  .option-content {
    display: flex;
    align-items: center;
    gap: ${tokens.spacing[2]};

    max-width: ${({ isMulti, hasIcon }) => {
      if (isMulti && hasIcon) return `calc(100% - ${optionIconWidth} - ${selectedOptionIconWidth})`
      if (hasIcon) return `calc(100% - ${optionIconWidth})`
      if (isMulti) return `calc(100% - ${selectedOptionIconWidth})`

      return "100%"
    }};
  }

  span {
    color: ${({ theme }) => theme.colorTextStrong};
  }

  &[aria-selected="true"] {
    background: ${({ theme }) => theme.colorForegroundSelected};

    p,
    span {
      color: ${({ theme }) => theme.colorTextAction};
    }

    .option-content {
      ${({ isMulti, hasIcon }) => {
        if (!isMulti) {
          if (hasIcon) return `width: calc(100% - ${optionIconWidth} - ${selectedOptionIconWidth})`

          return `width: calc(100%  - ${selectedOptionIconWidth})`
        }
      }};
    }

    .selected-option-icon {
      display: block;
    }
  }

  &[data-active-item="true"] {
    background: ${({ theme }) => theme.colorForegroundHover};
  }

  &[aria-disabled="true"] {
    cursor: not-allowed;

    p,
    span {
      color: ${({ theme }) => theme.colorTextDisabled};
    }

    // When the option has an icon and is disabled, we need to change the color
    svg,
    path {
      fill: ${({ theme }) => theme.colorTextDisabled};
    }
  }

  .selected-option-icon {
    display: none;
    position: absolute;

    top: ${({ description }) => (description ? "6.5px" : "unset")};
    right: ${tokens.spacing[3]};
  }

  button[role="checkbox"] {
    cursor: pointer;
  }
`

const StyledIconContainer = styled.div`
  min-height: 24px;
  min-width: 24px;

  overflow: hidden;

  width: 24px;
  height: 24px;

  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledOptionContentContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
`

const StyledComboboxItemCheck = styled.div`
  ${({ theme, checked, disabled }) => getCheckboxContainerStyles({ theme, checked, disabled })}
  ${({ theme, checked, disabled }) => getCheckboxStyles({ theme, checked, disabled })}
`

export default function SelectOption({ value, isMulti, labelText, disabled, icon, description, index }) {
  const selectStore = useSelectContext()

  const selectedValue = selectStore.useState("value")
  const checked = isMulti && selectedValue.includes(value)

  return (
    <SelectItem
      {...{
        value,
        isMulti,
        disabled,
        key: value,
        description,
        hasIcon: !!icon,
        render: <StyledComboboxItem />,
        "data-ninja-searchable-select-option": "",
      }}
    >
      {isMulti && (
        <ComboboxItemCheck
          {...{
            disabled,
            render: (
              <StyledComboboxItemCheck {...{ checked, disabled }}>
                {(!disabled || checked) && getCheckboxIconByState()}
              </StyledComboboxItemCheck>
            ),
          }}
        />
      )}

      <div className="option-content">
        {icon && (
          <StyledIconContainer>
            <span>{icon}</span>
          </StyledIconContainer>
        )}

        <StyledOptionContentContainer>
          <Body>{labelText}</Body>

          {description && <Body {...{ color: "colorTextWeakest", type: "bodyXs" }}>{description}</Body>}
        </StyledOptionContentContainer>
      </div>

      {!isMulti && <CheckIconRegular {...{ color: "colorTextActionStrong", className: "selected-option-icon" }} />}
    </SelectItem>
  )
}
