import { useState } from "react"
import { always, any, append, cond, filter, includes, propEq, startsWith, T } from "ramda"
import { useDispatch, useSelector } from "react-redux"
import { v4 as uuidv4 } from "uuid"

import { Input, Modal, SearchableSelect } from "@ninjaone/components"
import { spacing } from "@ninjaone/tokens"

import { useForm } from "js/includes/common/hooks"
import {
  applyMultipleValidations,
  getValidationError,
  getValidationSuccess,
  isValidAlphanumericOrUnderscore,
  localizationKey,
  localized,
  objectWithIdKeysToList,
  validations,
} from "js/includes/common/utils"
import { Flex } from "js/includes/components/Styled"
import { getLastSegment, isValidActivityName } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/android/util"
import { updatePolicyItem } from "js/state/actions/policyEditor/editor"
import { defaultInheritance } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/util"

const validateActivityFormat = activity => {
  const success = isValidActivityName(activity)
  return {
    success,
    message: success
      ? ""
      : localized(
          "The activity name must have at least two segments, each segment must start with a letter and all characters must be alphanumeric or an underscore.",
        ),
  }
}

const activityPrefixes = {
  action: "android.intent.action.",
  category: "android.intent.category.",
}

const validateDuplicatedActivities = ({ value, persistentPreferredActivitiesSettings, field, guid = null }) => {
  const activitiesArray = objectWithIdKeysToList(persistentPreferredActivitiesSettings, "guid")
  const data = guid ? filter(activityItem => activityItem.guid !== guid)(activitiesArray) : activitiesArray
  const exists = any(propEq(field, value))(data)
  return exists ? getValidationError(localizationKey("This value is already in use")) : getValidationSuccess()
}

const handleCreateOptionValidations = ({ option, optionsList, prefixValidation }) => {
  const isPrefixValid = startsWith(prefixValidation, option)
  const lastSegment = getLastSegment([option])
  return cond([
    [() => includes(option, optionsList), always(localized("{{name}} is already in use", { name: option }))],
    [
      () => !isPrefixValid || !isValidAlphanumericOrUnderscore(lastSegment),
      always(localized("There is a value with invalid format")),
    ],
    [T, always(null)],
  ])(option)
}

export default function PersistentPreferredActivitiesModal({
  persistentPreferredActivitiesSettings,
  actionOptions,
  categoryOptions,
  unmount,
  activity,
}) {
  const dispatch = useDispatch()
  const {
    values: { displayName, receiverActivity, actions, categories },
    onChange,
    validateForm,
    validation,
  } = useForm({
    validateOnChange: true,
    fields: {
      displayName: activity?.displayName || "",
      receiverActivity: activity?.receiverActivity || "",
      actions: activity?.actions || [],
      categories: activity?.categories || [],
    },
    validate: {
      displayName: applyMultipleValidations([
        validations.required,
        value =>
          validateDuplicatedActivities({
            field: "displayName",
            value,
            persistentPreferredActivitiesSettings,
            guid: activity?.guid,
          }),
      ]),
      receiverActivity: applyMultipleValidations([
        validations.required,
        validateActivityFormat,
        value =>
          validateDuplicatedActivities({
            field: "receiverActivity",
            value,
            persistentPreferredActivitiesSettings,
            guid: activity?.guid,
          }),
      ]),
      actions: validations.required,
      categories: validations.required,
    },
  })
  const { parentPolicy = {} } = useSelector(state => ({
    parentPolicy: state.policyEditor?.parentPolicy,
  }))
  const [errorMessageOnActions, setErrorMessageOnActions] = useState(null)
  const [errorMessageOnCategories, setErrorMessageOnCategories] = useState(null)
  const isEdit = !!activity?.guid

  return (
    <Modal
      {...{ unmount }}
      size="sm"
      titleGroup={{
        titleText: isEdit ? localized("Edit Activity") : localized("Add Activity"),
        descriptionText: localized(
          "Allows the activities of specific applications to handle one or more system action/category. One example may be setting a default Home/Launcher app.",
        ),
        textWrapLineLimit: 3,
      }}
      buttons={[
        {
          labelToken: isEdit ? localizationKey("Update activity") : localizationKey("Add activity"),
          type: "save",
          onClick: () => {
            if (validateForm()) {
              const guid = isEdit ? activity.guid : uuidv4()
              const updatedActivity = {
                displayName,
                receiverActivity,
                actions,
                categories,
                ...(isEdit ? { inheritance: activity?.inheritance } : defaultInheritance),
              }
              dispatch(
                updatePolicyItem(
                  `applications.persistentPreferredActivitiesSettings.${guid}`,
                  parentPolicy,
                  updatedActivity,
                ),
              )
              unmount()
            }
          },
        },
      ]}
      scrollable
    >
      <Flex flexDirection="column" gap={spacing[5]}>
        <Input
          labelText={localized("Configuration name")}
          placeholder={localized("Configuration name")}
          value={displayName}
          name="displayName"
          onChange={onChange}
          errorMessage={validation.message?.displayName}
          required
        />
        <Input
          labelText={localized("Activity")}
          placeholder={"com.android.launcher.MainActivity"}
          value={receiverActivity}
          name="receiverActivity"
          onChange={onChange}
          errorMessage={validation.message?.receiverActivity}
          tooltipText={localized(
            "Input the application activity to be used for the below actions & categories. Reach out to the app developer for supported activities.",
          )}
          required
        />
        <SearchableSelect
          labelText={localized("Select an action")}
          placeholderText={localized("Select")}
          onChange={selected => onChange("actions", selected)}
          options={actionOptions}
          value={actions}
          popoverProps={{ fullWidth: true }}
          isMulti
          required
          comboboxProps={{
            resetValueOnHide: true,
            creatable: true,
            onCreate: action => {
              const errorMessage = handleCreateOptionValidations({
                option: action,
                optionsList: actions,
                prefixValidation: activityPrefixes.action,
              })
              if (errorMessage) {
                setErrorMessageOnActions(errorMessage)
                return
              }
              actionOptions.push({ value: action, label: action, labelText: action })
              onChange("actions", append(action, actions))
              setErrorMessageOnActions(null)
            },
          }}
          errorMessage={errorMessageOnActions ? errorMessageOnActions : validation.message?.actions}
          tooltipText={localized(
            "Actions follow the prefix format android.intent.action.<name>. (e.g. android.intent.action.VIEW).",
          )}
        />
        <SearchableSelect
          labelText={localized("Select a category")}
          placeholderText={localized("Select")}
          onChange={selected => onChange("categories", selected)}
          options={categoryOptions}
          value={categories}
          popoverProps={{ fullWidth: true }}
          isMulti
          required
          comboboxProps={{
            resetValueOnHide: true,
            creatable: true,
            onCreate: category => {
              const value = activityPrefixes.category + category
              const errorMessage = handleCreateOptionValidations({
                option: value,
                optionsList: categories,
                prefixValidation: activityPrefixes.category,
              })
              if (errorMessage) {
                setErrorMessageOnCategories(errorMessage)
                return
              }
              categoryOptions.push({ value, label: category, labelText: category })
              onChange("categories", append(value, categories))
              setErrorMessageOnCategories(null)
            },
          }}
          errorMessage={errorMessageOnCategories ? errorMessageOnCategories : validation.message?.categories}
          tooltipText={localized(
            "Categories follow the prefix format android.intent.category.<name>. (e.g. android.intent.category.LAUNCHER).",
          )}
        />
      </Flex>
    </Modal>
  )
}
