import { __, compose, cond, equals, includes, last, split, T, either } from "ramda"
import { parsePhoneNumber } from "react-phone-number-input"
import { retryPromise, getVersionHash, getNinjaLocalizationCdnRoute } from "js/includes/common/utils/ssrAndWebUtils"
import { getReadableNodeRole } from "js/includes/common/_enums"
import { localizationKey, localized, localizedF } from "./autocomplete"
import { getLocalizedTokens, initialDefaultLanguage } from "./langAndTokens"

export const fetchLocalizationResourceWithRetry = (environment, language) =>
  retryPromise(
    retriesLeft =>
      new Promise(async (resolve, reject) => {
        try {
          const cacheKey = retriesLeft === 1 ? getVersionHash() : Date.now()
          const url = `${getNinjaLocalizationCdnRoute(environment)}/${language}.json?cacheKey=${cacheKey}`
          const response = await window.fetch(url)
          resolve(response)
        } catch (e) {
          reject(e)
        }
      }),
    1,
  )

export const normalizeLocaleToken = token => {
  let [first, second] = token.split("-")
  return `${first}-${second.toUpperCase()}`
}

const nonBrowserAvailableLanguages = [
  { name: "en-us", token: localizationKey("English (United States)") },
  { name: "en-gb", token: localizationKey("English (Great Britain)") },
  { name: "en-au", token: localizationKey("English (Australia)") },
  { name: "de-de", token: localizationKey("German (Germany)") },
  { name: "fr-fr", token: localizationKey("French (France)") },
  { name: "fr-ca", token: localizationKey("French (Canada)") },
  { name: "es-es", token: localizationKey("Spanish (Spain)") },
  { name: "it-it", token: localizationKey("Italian (Italy)") },
  { name: "pt-br", token: localizationKey("Portuguese (Brazil)") },
  { name: "pl-pl", token: localizationKey("Polish (Poland)") },
  { name: "nl-nl", token: localizationKey("Dutch (Netherlands)") },
  { name: "nn-nn", token: localizationKey("Norwegian (Norway)") },
  { name: "sv-sv", token: localizationKey("Swedish (Sweden)") },
  { name: "ru-ru", token: localizationKey("Russian (Russia)") },
  { name: "da-da", token: localizationKey("Danish (Denmark)") },
]

export const availableLanguages = [
  { name: "browser-lang", token: localizationKey("Browser Language (default)") },
  ...nonBrowserAvailableLanguages,
]

const toLangLocale = lang => {
  const lLang = lang.toLowerCase()
  return lLang.length === 2 ? `${lLang}-${lLang}` : lLang
}

const preciseLangLookup = lang => {
  const langLocale = toLangLocale(lang)

  const fullLangLocaleMatch = nonBrowserAvailableLanguages.find(lang => lang.name === langLocale)
  if (fullLangLocaleMatch) {
    return fullLangLocaleMatch.name
  }

  // try to find only by lang part, for example we don't have de-at (Austria) but German will be a good match
  const langPart = langLocale.split("-")[0]
  return nonBrowserAvailableLanguages.find(lang => lang.name.startsWith(langPart))?.name
}

export const getLanguageFromBrowserSettings = () => {
  if (window.navigator.language) {
    const matchedLang = preciseLangLookup(window.navigator.language)
    if (matchedLang) {
      return matchedLang
    }
  }

  // try to find other customer known languages from navigator.languages
  for (const lang of window.navigator.languages ?? []) {
    const matchedLang = preciseLangLookup(lang)
    if (matchedLang) {
      return matchedLang
    }
  }
}

export const getDefaultLanguage = properties => {
  return getLanguageFromBrowserSettings() ?? properties?.["application.language"] ?? initialDefaultLanguage
}

export const getCurrentCountryCode = () => {
  const regex = /(-[A-Z]{2})$/g
  const language = window.navigator.languages.find(lang => regex.test(lang))
  if (language) {
    return compose(last, split("-"))(language)
  }
  const { locale } = window.store.getState().application
  return locale?.toUpperCase() ?? "US"
}

export const setLocale = async lang => {
  try {
    await import(`moment/locale/${lang.toLowerCase()}`)
    window.store.dispatch({ type: "SET_APP_LOCALE", locale: lang })
  } catch (e) {
    if (lang.toLowerCase().includes("-")) {
      const [locale] = lang.split("-")
      await setLocale(locale)
    } else {
      console.warn(`Can't find module for locale ${lang}, defaulting to english (American)`)
    }
  }
}

export const isRtl = compose(
  includes(__, [
    "ae" /* Avestan */,
    "ar" /* 'العربية', Arabic */,
    "arc" /* Aramaic */,
    "bcc" /* 'بلوچی مکرانی', Southern Balochi */,
    "bqi" /* 'بختياري', Bakthiari */,
    "ckb" /* 'Soranî / کوردی', Sorani */,
    "dv" /* Dhivehi */,
    "fa" /* 'فارسی', Persian */,
    "glk" /* 'گیلکی', Gilaki */,
    "he" /* 'עברית', Hebrew */,
    "il" /* 'עברית', Hebrew */,
    "ku" /* 'Kurdî / كوردی', Kurdish */,
    "mzn" /* 'مازِرونی', Mazanderani */,
    "nqo" /* N'Ko */,
    "pnb" /* 'پنجابی', Western Punjabi */,
    "ps" /* 'پښتو', Pashto, */,
    "sd" /* 'سنڌي', Sindhi */,
    "ug" /* 'Uyghurche / ئۇيغۇرچە', Uyghur */,
    "ur" /* 'اردو', Urdu */,
    "yi" /* 'ייִדיש', Yiddish */,
  ]),
)

export const getLanguageDir = () => {
  const countryCode = getCurrentCountryCode()
  return isRtl(countryCode.toLowerCase()) ? "rtl" : "ltr"
}

export const isEuropeanCountry = compose(
  includes(__, [
    "AD",
    "AL",
    "AM",
    "AT",
    "AZ",
    "BA",
    "BE",
    "BG",
    "CH",
    "CY",
    "CZ",
    "DE",
    "DK",
    "EE",
    "ES",
    "FI",
    "FR",
    "GB",
    "GE",
    "GL",
    "GR",
    "HR",
    "HU",
    "IE",
    "IS",
    "IT",
    "LI",
    "LT",
    "LU",
    "LV",
    "MC",
    "ME",
    "MD",
    "MK",
    "MT",
    "NL",
    "NO",
    "PL",
    "PT",
    "RS",
    "RO",
    "RU",
    "SE",
    "SI",
    "SK",
    "SM",
    "TR",
    "UA",
    "AX",
    "BY",
    "GI",
    "GG",
    "VA",
    "IM",
    "JE",
  ]),
  parsePhoneNumber,
)

export const localizeMappingName = cond([
  [equals("WINDOWS_WORKSTATION"), localizedF("Windows Workstation")],
  [equals("WINDOWS_SERVER"), localizedF("Windows Server")],
  [equals("WINDOWS_DESKTOP"), localizedF("Windows Desktop")],
  [equals("WINDOWS_LAPTOP"), localizedF("Windows Laptop")],
  [equals("LINUX"), localizedF("Linux")],
  [equals("LINUX_SERVER"), localizedF("Linux Server")],
  [equals("LINUX_DESKTOP"), localizedF("Linux Desktop")],
  [equals("LINUX_LAPTOP"), localizedF("Linux Laptop")],
  [equals("CLOUD_MONITOR_TARGET"), localizedF("Cloud Monitor Target")],
  [equals("MAC"), localizedF("Mac")],
  [equals("MAC_DESKTOP"), localizedF("Mac Desktop")],
  [equals("MAC_LAPTOP"), localizedF("Mac Laptop")],
  [equals("MAC_SERVER"), localizedF("Mac Server")],
  [either(equals("VMWARE_VM_HOST"), equals("VMWARE_HOST")), localizedF("VMWare Host")],
  [either(equals("VMWARE_VM_GUEST"), equals("VMWARE_VIRTUAL_MACHINE")), localizedF("VMWare Virtual Machine")],
  [either(equals("HYPERV_VMM_HOST"), equals("HYPER-V_HOST")), localizedF("Hyper-V Host")],
  [either(equals("HYPERV_VMM_GUEST"), equals("HYPER-V_VIRTUAL_MACHINE")), localizedF("Hyper-V Virtual Machine")],
  [equals("NMS_SWITCH"), localizedF("Switch")],
  [equals("NMS_ROUTER"), localizedF("Router")],
  [equals("NMS_FIREWALL"), localizedF("Firewall")],
  [equals("NMS_PRIVATE_NETWORK_GATEWAY"), localizedF("Private Network Gateway")],
  [equals("NMS_PRINTER"), localizedF("Printer")],
  [equals("NMS_SCANNER"), localizedF("Scanner")],
  [equals("NMS_DIAL_MANAGER"), localizedF("Dial Manager")],
  [equals("NMS_WAP"), localizedF("WAP")],
  [equals("NMS_IPSLA"), localizedF("IPSLA")],
  [equals("NMS_COMPUTER"), localizedF("Computer")],
  [equals("NMS_VM_HOST"), localizedF("VM Host")],
  [equals("NMS_APPLIANCE"), localizedF("Appliance")],
  [equals("NMS_OTHER"), localizedF("Other")],
  [equals("NMS_SERVER"), localizedF("Server")],
  [equals("NMS_PHONE"), localizedF("Phone")],
  [equals("NMS_VIRTUAL_MACHINE"), localizedF("Virtual Machine")],
  [equals("NMS_NETWORK_MANAGEMENT_AGENT"), localizedF("Network Management Agent")],
  [equals("SERVER"), localizedF("Servers")],
  [equals("MONITOR"), localizedF("Monitor")],
  [equals("APPLE_MOBILE"), localizedF("Apple Mobile")],
  [equals("APPLE_IOS"), getReadableNodeRole],
  [equals("APPLE_IPADOS"), getReadableNodeRole],
  [equals("IOS"), getReadableNodeRole],
  [equals("ANDROID"), getReadableNodeRole],
  [T, nodeClass => nodeClass],
])

export const getLocalizedOption = option =>
  option && {
    value: option.value,
    label: localized(option.token),
  }

export const getDataTableTokens = () => getLocalizedTokens().dataTable
