import _ from "underscore"
import React from "react"
import { __, last, split, map, concat, compose, any, filter, keys, startsWith, cond, always, all } from "ramda"
import { DeviceModel } from "./FullModels"
import { getReadableNodeRole, getReadableNodeType, getReadablePolicyRole } from "../../includes/common/_enums"
import InfoModel from "./InfoModel"
import {
  getIcon,
  localized,
  localizationKey,
  getOverviewLink as _getDeviceOverviewLink,
  isMacDevice,
  isLinuxDevice as _isLinuxDevice,
  isWindowsDevice as _isWindowsDevice,
  isNinja as _isNinja,
  ninjaReportError,
} from "js/includes/common/utils"
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons"
import ShowMessageDialog from "js/includes/components/MessageDialog"
import showModal from "js/includes/common/services/showModal"
import CreatePolicyModal from "js/includes/editors/Policy/Models/General/CreatePolicyModal.jsx"
import {
  isGranted,
  isGrantedForAllEntities,
  isGrantedForAtLeastOneEntity,
  isNodeGranted,
  isNodeGrantedForAtLeastOneEntity,
} from "js/includes/common/services/userPermissions"
import { DEFAULT_ENTITY_ID, getPermissions } from "js/includes/editors/User/Permissions/common"

export const DeviceInfoModel = InfoModel.extend(
  {
    urlRoot: "/node",
    initialize() {
      this.constructor.__super__.initialize.apply(this, undefined)
      _.bindAll(this, "open")
    },
    isMacOrLinux() {
      return this.get("isMac") || this.get("isMacServer") || this.get("isLinux") || this.get("isLinuxServer")
    },
    isMacDevice() {
      return isMacDevice(this.get("nodeClass"))
    },
    isLinuxDevice() {
      return _isLinuxDevice(this.get("nodeClass"))
    },
    isWindowsDevice() {
      return _isWindowsDevice(this.get("nodeClass"))
    },
    getOSPatchesControlTooltip() {
      const osType = cond([
        [_isWindowsDevice, always("Windows")],
        [isMacDevice, always("Mac")],
        [_isLinuxDevice, always("Linux")],
      ])(this.get("nodeClass"))

      return localized(
        "This mode will let Ninja take full control of {{osType}} Patching and allow pre-approval/denial of patch categories or individual patches, at policy or device level.",
        { osType },
      )
    },
    isCloudMonitor() {
      return ["CLOUD_MONITOR_TARGET", "CLOUD_MONITOR_TARGET_EMAIL_SERVER"].includes(this.get("nodeType"))
    },
    getOverviewLink() {
      return _getDeviceOverviewLink(this.attributes)
    },
    getDeviceOverviewLink() {
      return "#/deviceDashboard/" + this.get("id") + "/overview"
    },
    getDeviceDetailsLink() {
      return "#/deviceDashboard/" + this.get("id") + "/details"
    },
    getDeviceSettingsLink() {
      return "#/deviceDashboard/" + this.get("id") + "/settings"
    },
    getDeviceToolsLink() {
      return "#/deviceDashboard/" + this.get("id") + "/tools"
    },
    getDeviceBackupsDashboardLink() {
      return `#/deviceDashboard/${this.get("id")}/backup`
    },
    getDeviceActivitiesDashboardLink() {
      return `#/deviceDashboard/${this.get("id")}/activities`
    },
    getDeviceCustomFieldsDashboardLink() {
      return `#/deviceDashboard/${this.get("id")}/customFields`
    },
    getDeviceSoftwarePatchesLink() {
      return `#/deviceDashboard/${this.get("id")}/software`
    },
    getDeviceSoftwareInventoryLink() {
      return `#/deviceDashboard/${this.get("id")}/software/inventory`
    },
    getDeviceOsPatchesLink() {
      return `#/deviceDashboard/${this.get("id")}/osPatches`
    },
    getDeviceTicketingDashboardLink() {
      return `#/deviceDashboard/${this.get("id")}/ticketing`
    },
    getCloudMonitorDashboardLink() {
      return "#/cloudMonitorDashboard/" + this.get("id") + "/"
    },
    getNmsOverviewLink() {
      return "#/nmsDashboard/" + this.get("id") + "/overview"
    },
    getNmsNetFlowLink() {
      return "#/nmsDashboard/" + this.get("id") + "/netflow"
    },
    getNmsDetailsLink() {
      return "#/nmsDashboard/" + this.get("id") + "/details"
    },
    getNmsSettingsLink() {
      return "#/nmsDashboard/" + this.get("id") + "/settings"
    },
    getNmsActivitiesLink() {
      return "#/nmsDashboard/" + this.get("id") + "/activities"
    },
    getNmsCustomFieldsDashboardLink() {
      return `#/nmsDashboard/${this.get("id")}/customFields`
    },
    getNmsTicketingDashboardLink() {
      return `#/nmsDashboard/${this.get("id")}/ticketing`
    },
    getPolicyLink() {
      return "#/editor/policy/" + this.get("policyId")
    },
    getOrganizationLink() {
      return `#/customerDashboard/${this.get("clientId")}/overview`
    },
    getReadableNodeRole() {
      return this.get("nodeClass") ? getReadableNodeRole(this.get("nodeClass")) : null
    },
    getReadableNodeType() {
      return this.get("nodeType") ? getReadableNodeType(this.get("nodeType")) : null
    },
    getDeviceVulnerabilitiesLink() {
      return `#/deviceDashboard/${this.get("id")}/vulnerabilities`
    },

    open(e) {
      window.location.hash = this.getOverviewLink()
    },

    isUp(showWarning) {
      var thisModel = this
      var isUp = this.get("isUp")
      if (!isUp && showWarning) {
        ShowMessageDialog({
          icon: { icon: faInfoCircle, type: "warning" },
          title: () => `${thisModel.get("name")} ${localized("is offline")}`,
          message: localizationKey(
            "You can't connect or send commands to an offline device. This action requires the device to be online.",
          ),
          buttons: [{ id: "OK", label: localizationKey("OK") }],
        })
      }
      return isUp
    },

    isDownServer() {
      return this.get("isServer") && this.get("isDown")
    },

    getLastLoggedInUser() {
      return this.get("lastLoggedInUser")
    },

    matchesSearchText(searchText) {
      return (
        this.get("name")
          .toLowerCase()
          .indexOf(searchText) !== -1 ||
        (this.get("systemName") &&
          this.get("systemName")
            .toLowerCase()
            .indexOf(searchText) !== -1) ||
        (this.get("publicIp") && this.get("publicIp").indexOf(searchText) !== -1) ||
        (this.get("lastLoggedInUser") &&
          this.get("lastLoggedInUser")
            .toLowerCase()
            .indexOf(searchText) !== -1) ||
        (this.get("biosSerialNumber") &&
          this.get("biosSerialNumber")
            .toLowerCase()
            .indexOf(searchText) !== -1) ||
        (Array.isArray(this.get("ipAddresses")) &&
          this.get("ipAddresses").some(ipAddress => ipAddress.toLowerCase().indexOf(searchText) !== -1)) ||
        (Array.isArray(this.get("macAddresses")) &&
          this.get("macAddresses").some(macAddress => macAddress.toLowerCase().indexOf(searchText) !== -1))
      )
    },

    getIcon() {
      return getIcon(this.get("nodeClass"))
    },
  },
  {
    async getDeviceModelWithNewName(deviceId, name) {
      const node = DeviceModel.getModel(deviceId)
      const policyIdSource = node.get("policyIdSource")
      await node.fetch()
      node.set("friendlyName", name)
      if (policyIdSource === "CLIENT") {
        node.set("policyId", undefined)
      }
      return node
    },
    async onMfaSubmit(deviceId, name, mfaProps) {
      const node = await this.getDeviceModelWithNewName(deviceId, name)
      node.set(mfaProps)
      await node.save({ showSavingMessage: true, useMfaUrl: true })
    },
    async rename(deviceId, name) {
      try {
        const node = await this.getDeviceModelWithNewName(deviceId, name)

        await node.save({
          showSavingMessage: true,
          onMfaSubmit: (hideMessage, event, mfaProps) => this.onMfaSubmit(deviceId, name, mfaProps),
        })

        await window.deviceList.fetchById(deviceId)
      } catch (error) {
        ninjaReportError(error)
      }
    },
  },
)

export const CustomerInfoModel = InfoModel.extend({
  urlRoot: "/client",
  initialize() {
    this.constructor.__super__.initialize.apply(this, undefined)
    _.bindAll(this, "open")
  },
  getOverviewLink() {
    return `#/customerDashboard/${this.get("id")}/overview`
  },
  getSoftwareInventoryLink() {
    return `#/customerDashboard/${this.get("id")}/software/inventory`
  },
  getSoftwarePatchesLink() {
    return `#/customerDashboard/${this.get("id")}/software`
  },
  getOsPatchesLink() {
    return `#/customerDashboard/${this.get("id")}/osPatches`
  },
  getLocationLink() {
    return `#/customerDashboard/${this.get("id")}/location`
  },
  getCustomerAlertsDashboardLink() {
    return `#/customerDashboard/${this.get("id")}/alerts`
  },
  getCustomerThreatsDashboardLink() {
    return `#/customerDashboard/${this.get("id")}/threats`
  },
  getCustomerApprovalsDashboardLink() {
    return `#/customerDashboard/${this.get("id")}/approvals`
  },
  getCustomerMaintenanceDashboardLink() {
    return `#/customerDashboard/${this.get("id")}/maintenance`
  },
  getCustomerBackupsDashboardLink() {
    return `#/customerDashboard/${this.get("id")}/backup`
  },
  getCustomerActivitiesDashboardLink() {
    return `#/customerDashboard/${this.get("id")}/activities`
  },
  getDocumentTemplatesDashboardLink() {
    return `#/customerDashboard/${this.get("id")}/documentation`
  },
  getCustomerCustomFieldsDashboardLink() {
    return `#/customerDashboard/${this.get("id")}/customFields`
  },
  getCustomerTicketingDashboardLink() {
    return `#/customerDashboard/${this.get("id")}/ticketing`
  },
  getCustomerPendingScriptsDashboardLink() {
    return `#/customerDashboard/${this.get("id")}/pendingScripts`
  },
  getCustomerFinanceDashboardLink() {
    return `#/customerDashboard/${this.get("id")}/finance`
  },
  getCustomerAgentInstallers() {
    return `#/customerDashboard/${this.get("id")}/agentInstallers`
  },
  getCustomerVulnerabilitiesDashboardLink() {
    return `#/customerDashboard/${this.get("id")}/vulnerabilities`
  },
  open() {
    window.location.hash = this.getOverviewLink()
  },
})

export const PolicyInfoModel = InfoModel.extend({
  urlRoot: "/policy",
  initialize() {
    this.constructor.__super__.initialize.apply(this, undefined)
    _.bindAll(this, "copy")
  },
  getReadableNodeRole() {
    return getReadablePolicyRole(this.get("nodeRole"))
  },
  getSourcePolicy() {
    const copiedId = this.get("id")
    const copiedDescription = this.get("description")
    const copiedNodeRole = this.get("nodeRole")
    const antivirusProductCode = this.get("antivirusProductCode")
    const updateTime = this.get("updateTime")
    const parentPolicyId = this.get("parentPolicyId")
    return { copiedId, copiedDescription, copiedNodeRole, antivirusProductCode, updateTime, parentPolicyId }
  },
  copy(e) {
    e.stopPropagation()
    const {
      copiedId,
      copiedDescription,
      copiedNodeRole,
      antivirusProductCode,
      updateTime,
      parentPolicyId,
    } = this.getSourcePolicy()
    showModal(
      <CreatePolicyModal
        {...{
          parentPolicyId,
          isCopy: true,
          copiedDescription,
          copiedNodeRole,
          antivirusProductCode,
          updateTime,
          copiedId,
        }}
      />,
    )
  },
  override(deviceId) {
    window.location.hash = "#/editor/policy/" + this.get("id") + "/" + deviceId
  },
})

export const UserInfoModel = InfoModel.extend({
  urlRoot: "/appuser",
  defaultUserInactivityTimeout: 240,
  initialize() {
    this.constructor.__super__.initialize.apply(this, undefined)
    if (window.test_server_permissions) this.set("sysadmin", true)
    _.bindAll(this, "copy")
  },
  getName() {
    return this.get("firstName") + " " + this.get("lastName")
  },
  copy(e) {
    e.stopPropagation()
    window.location.hash = "#/editor/user/" + this.get("id") + "/copy"
  },
  canConfigureTeamViewer() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "SYSTEM", permissionName: "TeamViewer.Configure.Access" })
  },
  canConnectToTeamViewer() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "NODE", permissionName: "RemoteAccess.TeamViewer.Access" })
  },
  canConfigureNinjaConnect() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "SYSTEM", permissionName: "NinjaConnect.Configure.Access" })
  },
  canConfigureQuickConnect() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "SYSTEM", permissionName: "QuickConnect.Configure.Access" })
  },
  canConnectToNinjaConnect() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "NODE", permissionName: "RemoteAccess.NinjaConnect.Access" })
  },
  canConnectToNinjaConnectInStealthMode() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "SYSTEM", permissionName: "NinjaConnect.StealthMode.Access" })
  },
  canUpdateDevicesAndMaintenanceMode(device) {
    try {
      if (this.get("sysadmin")) {
        return true
      }

      return (
        this.canUpdateDevices(device) &&
        isGranted({ entityType: "SYSTEM", permissionName: "MaintenanceMode.Editor.Access" })
      )
    } catch {
      return false
    }
  },
  canEditMaintenanceMode() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "SYSTEM", permissionName: "MaintenanceMode.Editor.Access" })
  },
  canConfigureSplashtop() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "SYSTEM", permissionName: "Splashtop.Configure.Access" })
  },
  canConnectToSplashtop() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "NODE", permissionName: "RemoteAccess.Splashtop.Access" })
  },
  canConfigureCloudberry() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "SYSTEM", permissionName: "Cloudberry.Configure.Access" })
  },
  canConfigureSystemActivities() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "SYSTEM", permissionName: "Configuration.Activities.Access" })
  },
  canConfigureITGlue() {
    return this.get("sysadmin")
  },
  canConnectToConnectWiseControl() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "NODE", permissionName: "RemoteAccess.Connectwise.Access" })
  },
  canConfigureConnectWiseControl() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "SYSTEM", permissionName: "Connectwise.Configure.Access" })
  },
  canConfigurePsa() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "SYSTEM", permissionName: "Configuration.PSA.Access" })
  },
  canUseRDP() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "NODE", permissionName: "RemoteAccess.RDP.Access" })
  },
  async canUseRemoteTools() {
    if (this.get("sysadmin")) {
      return true
    }

    const permissions = getPermissions()
    return compose(
      any(permissionName => isGranted({ entityType: "NODE", permissionName })),
      map(compose(concat(__, ".Access"), last, split("NODE."))),
      filter(startsWith("NODE.RemoteTools")),
      keys,
    )(permissions)
  },
  canUseRemoteToolsFileExplorer() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "NODE", permissionName: "RemoteTools.FileExplorer.Access" })
  },
  canUseRemoteToolsTaskManager() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "NODE", permissionName: "RemoteTools.TaskManager.Access" })
  },
  canUseRemoteToolsServicesManager() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "NODE", permissionName: "RemoteTools.ServicesManager.Access" })
  },
  canUseRemoteToolsRemoteRegistry() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "NODE", permissionName: "RemoteTools.RemoteRegistry.Access" })
  },
  canUseRemoteToolsCommandLine() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "NODE", permissionName: "RemoteTools.CommandLine.Access" })
  },
  canUninstallSoftware() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "SYSTEM", permissionName: "UninstallSoftware.Access" })
  },
  canUninstallPatch() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "SYSTEM", permissionName: "UninstallPatch.Access" })
  },
  canReadAtLeastOnePolicy() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGrantedForAtLeastOneEntity({ entityType: "POLICY", permissionName: "View" })
  },
  canReadPolicies(policyId) {
    if (!Number.isInteger(policyId)) {
      throw new Error("Invalid non integer policyId argument")
    }

    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "POLICY", permissionName: "View", entityId: policyId })
  },
  canReadAllPolicies() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGrantedForAllEntities({ entityType: "POLICY", permissionName: "View" })
  },
  canReadAtLeastOneScript() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGrantedForAtLeastOneEntity({ entityType: "SCRIPT_CATEGORY", permissionName: "View" })
  },
  canReadScripts(scriptCategoriesIds) {
    if (!scriptCategoriesIds?.length) {
      throw new Error("Invalid non array scriptCategoriesIds argument")
    } else {
      return all(scriptCategoryId => {
        if (!Number.isInteger(scriptCategoryId)) {
          throw new Error("Invalid non integer scriptCategoryId argument")
        }

        if (this.get("sysadmin")) {
          return true
        }

        return isGranted({ entityType: "SCRIPT_CATEGORY", permissionName: "View", entityId: scriptCategoryId })
      })(scriptCategoriesIds)
    }
  },
  canReadDevices({ nodeClass, nodeRoleId } = {}) {
    if (!nodeClass || !nodeRoleId) {
      throw new Error("Required parameters missing")
    }

    if (this.get("sysadmin")) {
      return true
    }

    return isNodeGranted({ permissionName: "View", nodeClass, nodeRoleId })
  },
  canCreateUsers() {
    return this.get("sysadmin")
  },
  canCreatePolicies() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "POLICY", permissionName: "Create" })
  },
  canConfigureBranding() {
    return this.get("sysadmin")
  },
  canCreateCustomers() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "ORGANIZATION", permissionName: "Create" })
  },
  hasAccessToAllCustomers() {
    return isGrantedForAllEntities({ entityType: "ORGANIZATION", permissionName: "View" })
  },
  hasAccessToAllDevices() {
    return isGrantedForAllEntities({
      entityType: "NODE",
      permissionName: ["View", "Role.View", "Class.View"],
    })
  },
  canCreateDevices() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "NODE", permissionName: "Create" })
  },
  canCreateAtLeastOneScript() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGrantedForAtLeastOneEntity({ entityType: "SCRIPT_CATEGORY", permissionName: "Update" })
  },
  canCreateScripts(scriptCategoriesIds) {
    if (!scriptCategoriesIds?.length) {
      throw new Error("Invalid non array scriptCategoriesIds argument")
    } else {
      return all(scriptCategoryId => {
        if (!Number.isInteger(scriptCategoryId)) {
          throw new Error("Invalid non integer scriptCategoryId argument")
        }
        if (this.get("sysadmin")) {
          return true
        }

        return isGranted({ entityType: "SCRIPT_CATEGORY", permissionName: "Update", entityId: scriptCategoryId })
      })(scriptCategoriesIds)
    }
  },
  canCreateScriptCategories() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "SCRIPT_CATEGORY", permissionName: "Create" })
  },
  canUpdateAtLeastOnePolicy() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGrantedForAtLeastOneEntity({ entityType: "POLICY", permissionName: "Update" })
  },
  canUpdatePolicies(policyId) {
    if (!Number.isInteger(policyId)) {
      throw new Error("Invalid non integer policyId argument")
    }

    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "POLICY", permissionName: "Update", entityId: policyId })
  },
  canUpdateCustomers(customerId) {
    if (!Number.isInteger(customerId)) {
      throw new Error("Invalid non integer customerId argument")
    }

    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "ORGANIZATION", permissionName: "Update", entityId: customerId })
  },
  canUpdateAtLeastOneDevice() {
    if (this.get("sysadmin")) {
      return true
    }

    return isNodeGrantedForAtLeastOneEntity({ permissionName: "Update" })
  },
  canUpdateDevices({ nodeClass, nodeRoleId } = {}) {
    if (!nodeClass || !nodeRoleId) {
      throw new Error("Required parameters missing")
    }

    if (this.get("sysadmin")) {
      return true
    }

    return isNodeGranted({ permissionName: "Update", nodeClass, nodeRoleId })
  },
  canUpdateMultipleDevices(devices = []) {
    return all(device => this.canUpdateDevices(device))(devices)
  },
  canViewAndScheduleReports() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "SYSTEM", permissionName: "Reports.ViewSchedule" })
  },
  canClaimAndScheduleReports() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "SYSTEM", permissionName: "Reports.ViewClaimSchedule" })
  },
  canManageReports() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "SYSTEM", permissionName: "Reports.FullAccess" })
  },
  canUpdateScripts(scriptCategoriesIds) {
    if (!scriptCategoriesIds?.length) {
      throw new Error("Invalid non array scriptCategoriesIds argument")
    } else {
      return all(scriptCategoryId => {
        if (!Number.isInteger(scriptCategoryId)) {
          throw new Error("Invalid non integer scriptCategoryId argument")
        }

        if (this.get("sysadmin")) {
          return true
        }

        return isGranted({ entityType: "SCRIPT_CATEGORY", permissionName: "Update", entityId: scriptCategoryId })
      })(scriptCategoriesIds)
    }
  },
  canDeletePolicies(policyId) {
    if (!Number.isInteger(policyId)) {
      throw new Error("Invalid non positive integer policyId argument")
    }

    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "POLICY", permissionName: "Delete", entityId: policyId })
  },
  canDeleteCustomers(customerId) {
    if (!Number.isInteger(customerId)) {
      throw new Error("Invalid non positive integer customerId argument")
    }

    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "ORGANIZATION", permissionName: "Delete", entityId: customerId })
  },
  canViewCustomer(customerId) {
    if (!Number.isInteger(customerId)) {
      throw new Error("Invalid non positive integer customerId argument")
    }

    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "ORGANIZATION", permissionName: "View", entityId: customerId })
  },
  canDeleteDevices({ nodeClass, nodeRoleId } = {}) {
    if (this.get("sysadmin")) {
      return true
    }

    return isNodeGranted({ permissionName: "Delete", nodeClass, nodeRoleId })
  },
  canDeleteScripts(scriptCategoriesIds) {
    if (!scriptCategoriesIds?.length) {
      throw new Error("Invalid non array scriptCategoriesIds argument")
    } else {
      return all(scriptCategoryId => {
        if (!Number.isInteger(scriptCategoryId)) {
          throw new Error("Invalid non integer scriptCategoryId argument")
        }

        if (this.get("sysadmin")) {
          return true
        }

        return isGranted({ entityType: "SCRIPT_CATEGORY", permissionName: "Delete", entityId: scriptCategoryId })
      })(scriptCategoriesIds)
    }
  },
  canDeleteScriptCategories(scriptCategoryId) {
    if (!Number.isInteger(scriptCategoryId)) {
      throw new Error("Invalid non integer scriptCategoryId argument")
    }

    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "SCRIPT_CATEGORY", permissionName: "Delete", entityId: scriptCategoryId })
  },
  canShareGroup() {
    return this.get("sysadmin")
  },
  canViewScheduledTasks() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "SCRIPT_CATEGORY", permissionName: "Scheduled.Task.View" })
  },
  canCreateScheduledTasks() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "SCRIPT_CATEGORY", permissionName: "Scheduled.Task.Create" })
  },
  canUpdateScheduledTasks() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "SCRIPT_CATEGORY", permissionName: "Scheduled.Task.Update" })
  },
  canDeleteScheduledTasks() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "SCRIPT_CATEGORY", permissionName: "Scheduled.Task.Delete" })
  },
  canCRUDEndUserSharing() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "SYSTEM", permissionName: "EndUserSharing.Access" })
  },
  canReadCreateAndUpdateTicketing() {
    if (this.get("sysadmin")) {
      return true
    }

    return (
      this.get("sysadmin") ||
      (_.contains(this.get("content").permissions?.ticketCrud, "READ") &&
        _.contains(this.get("content").permissions?.ticketCrud, "CREATE") &&
        _.contains(this.get("content").permissions?.ticketCrud, "UPDATE"))
    )
  },
  canViewBackupData() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "SYSTEM", permissionName: "Backup.View" })
  },
  canViewAndRestoreBackupData() {
    if (this.get("sysadmin")) {
      return true
    }

    return (
      isGranted({ entityType: "SYSTEM", permissionName: "Backup.View" }) &&
      isGranted({ entityType: "SYSTEM", permissionName: "Backup.Restore" })
    )
  },
  canViewAndManageBackupData() {
    if (this.get("sysadmin")) {
      return true
    }

    return (
      isGranted({ entityType: "SYSTEM", permissionName: "Backup.View" }) &&
      isGranted({ entityType: "SYSTEM", permissionName: "Backup.Manage" })
    )
  },
  canViewRestoreAndManageBackupData() {
    if (this.get("sysadmin")) {
      return true
    }

    return (
      isGranted({ entityType: "SYSTEM", permissionName: "Backup.View" }) &&
      isGranted({ entityType: "SYSTEM", permissionName: "Backup.Restore" }) &&
      isGranted({ entityType: "SYSTEM", permissionName: "Backup.Manage" })
    )
  },
  canConfigureUserAuthentication() {
    return this.get("sysadmin")
  },
  //ONE OFF CODE FOR RELIANCE TECH. (see callers also)
  //TODO: TO BE REMOVED WHEN NEW PERMISSIONS ARE DONE
  getRunOnlyScriptCategories() {
    return this.get("content").runOnlyScriptCategories ?? []
  },
  canRunScripts(scriptCategoriesIds) {
    if (!scriptCategoriesIds?.length) {
      throw new Error("Invalid non array scriptCategoriesIds argument")
    } else {
      return all(scriptCategoryId => {
        if (!Number.isInteger(scriptCategoryId)) {
          throw new Error("Invalid non integer scriptCategoryId argument")
        }

        if (this.get("sysadmin")) {
          return true
        }

        return isGranted({ entityType: "SCRIPT_CATEGORY", permissionName: "Run", entityId: scriptCategoryId })
      })(scriptCategoriesIds)
    }
  },
  canRunAtLeastOneAdhocScript() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGrantedForAtLeastOneEntity({ entityType: "SCRIPT_CATEGORY", permissionName: "Run" })
  },
  canUseRemoteSupport() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "SYSTEM", permissionName: "RemoteSupport.Access" })
  },
  canAccessActiveDirectoryManagement() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "SYSTEM", permissionName: "ActiveDirectoryManagement.Access" })
  },
  canAccessActiveDirectoryDiscovery() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "SYSTEM", permissionName: "ActiveDirectoryDiscovery.Access" })
  },
  canConfigureGravityZone() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "SYSTEM", permissionName: "GravityZone.Access" })
  },
  canRunGravityZoneScan() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "SYSTEM", permissionName: "GravityZone.Scan.Access" })
  },
  canConfigureSentinelOne() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "SYSTEM", permissionName: "SentinelOne.Access" })
  },
  canConfigureCrowdStrike() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "SYSTEM", permissionName: "CrowdStrike.Access" })
  },
  canViewAtLeastOneOrganization() {
    if (this.get("sysadmin")) {
      return true
    }

    return isGrantedForAtLeastOneEntity({ entityType: "ORGANIZATION", permissionName: "View" })
  },
  hasGroupPermission(permissionName, entityId = DEFAULT_ENTITY_ID) {
    if (this.get("sysadmin")) {
      return true
    }

    return isGranted({ entityType: "SEARCH", permissionName, entityId })
  },
  canViewAtLeastOneGroup() {
    return isGrantedForAtLeastOneEntity({ entityType: "SEARCH", permissionName: "Search.View" })
  },
  canReadGroups(entityId) {
    return this.hasGroupPermission("Search.View", entityId)
  },
  canCreateGroups() {
    return this.hasGroupPermission("Search.Create")
  },
  canDeleteGroups(entityId) {
    return this.hasGroupPermission("Search.FullAccess", entityId)
  },
  canUpdateGroups(entityId) {
    return this.hasGroupPermission("Search.Update", entityId)
  },
  isNinja() {
    return _isNinja(this.get("email"))
  },
  getUserInactivityTimeout() {
    return this.get("content").userInactivityTimeout || this.defaultUserInactivityTimeout
  },
  getGeneralTabLink() {
    const isAdmin = this.get("sysadmin")
    return this.canConfigureSystemActivities() && !isAdmin
      ? "/administration/general/activities"
      : "/administration/general/settings"
  },
})

export const PsaTicketTemplateInfoModel = InfoModel.extend({
  urlRoot: "/tickettemplate",
})

export const APIKeyInfoModel = InfoModel.extend({
  urlRoot: "/apikeypair",
  list: null,
})
