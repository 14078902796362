import { useCallback, useEffect, useMemo, useRef } from "react"
import { nanoid } from "nanoid"
import { noop } from "@ninjaone/utils"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { $wrapNodeInElement, isMimeType, mediaFileReader, mergeRegister } from "@lexical/utils"
import { DRAG_DROP_PASTE } from "@lexical/rich-text"
import { $generateNodesFromDOM } from "@lexical/html"
import {
  $createParagraphNode,
  $createRangeSelection,
  $getSelection,
  $insertNodes,
  $isNodeSelection,
  $isRootOrShadowRoot,
  $nodesOfType,
  $setSelection,
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_EDITOR,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_LOW,
  DRAGOVER_COMMAND,
  DRAGSTART_COMMAND,
  DROP_COMMAND,
  PASTE_COMMAND,
  SELECTION_INSERT_CLIPBOARD_NODES_COMMAND,
} from "lexical"

import {
  ALLOW_IMAGE_PLUGIN_COMMAND,
  CAN_USE_DOM,
  convertBase64ToFile,
  convertHexToBase64,
  extractImageDataFromRtf,
  extractImageTypeFromBase64,
  INSERT_IMAGE_COMMAND,
  isMsWord,
  isValidImageFile,
  parseHtml,
} from "@ninjaone/components/src/WYSIWYG"
import { IMAGE_FILE_MIME_TYPES, isNilOrEmpty, isNotNil } from "js/includes/common/utils"
import { COMPLETED_EDITING_HTML_COMMAND } from "js/includes/components/CustomFields/WYSIWYGEditor/plugins/SourcePlugin"
import {
  $createInlineImageNode,
  $isInlineImageNode,
  InlineImageNode,
} from "js/includes/components/CustomFields/WYSIWYGEditor/plugins/InlineImagePlugin/Node"
import { getContentIdFromSrc } from "js/includes/components/CustomFields/WYSIWYGEditor/plugins/InlineImagePlugin/utils"

const getDOMSelection = targetWindow => (CAN_USE_DOM ? (targetWindow || window).getSelection() : null)

export const isTempUri = src => src.startsWith("data:image")

export const InlineImagePlugin = ({
  uploadsByContentIdRef: _uploadsByContentIdRef,
  tempUriByContentIdRef: _tempUriByContentIdRef,
  onSetContentRefs = noop,
  onUploadImage,
  onUploadImageError,
}) => {
  const [editor] = useLexicalComposerContext()
  const defaultUploadsByContentIdRef = useRef({})
  const defaultTempUriByContentIdRef = useRef({})

  const uploadsByContentIdRef = useMemo(() => _uploadsByContentIdRef ?? defaultUploadsByContentIdRef, [
    _uploadsByContentIdRef,
  ])
  const tempUriByContentIdRef = useMemo(() => _tempUriByContentIdRef ?? defaultTempUriByContentIdRef, [
    _tempUriByContentIdRef,
  ])

  // Make sure this function never changes
  const setUploadsByContentIdRef = useCallback(
    (contentId, upload) => {
      uploadsByContentIdRef.current = {
        ...uploadsByContentIdRef.current,
        [contentId]: upload,
      }
      onSetContentRefs({ uploadsByContentIdRef })
    },
    [onSetContentRefs, uploadsByContentIdRef],
  )

  const setTempUriByContentIdRef = useCallback(
    (contentId, uri) => {
      tempUriByContentIdRef.current = {
        ...tempUriByContentIdRef.current,
        [contentId]: uri,
      }
      onSetContentRefs({ tempUriByContentIdRef })
    },
    [onSetContentRefs, tempUriByContentIdRef],
  )

  const updateRefsAfterEditingHTML = useCallback(() => {
    editor.update(() => {
      const newUploadsByNodeKeyRef = {}
      const newTempUriByNodeKeyRef = {}
      const inlineImageNodes = $nodesOfType(InlineImageNode)

      inlineImageNodes.forEach(node => {
        const contentId = node.getContentId()

        newUploadsByNodeKeyRef[contentId] = uploadsByContentIdRef.current[contentId]
        newTempUriByNodeKeyRef[contentId] = tempUriByContentIdRef.current[contentId]
      })

      uploadsByContentIdRef.current = { ...uploadsByContentIdRef.current, ...newUploadsByNodeKeyRef }
      tempUriByContentIdRef.current = { ...tempUriByContentIdRef.current, ...newTempUriByNodeKeyRef }
    })

    return true
  }, [editor, tempUriByContentIdRef, uploadsByContentIdRef])

  const onUploadImageAsync = useCallback(
    async (uri, resourceId) => {
      if (!isTempUri(uri)) {
        return
      }

      try {
        const type = extractImageTypeFromBase64(uri)
        const filename = `${nanoid()}.${type}`
        const file = await convertBase64ToFile(uri, filename, `image/${type}`)

        if (isValidImageFile(file)) {
          return await onUploadImage(file, resourceId)
        }
      } catch (error) {
        onUploadImageError?.(error)
      }
    },
    [onUploadImage, onUploadImageError],
  )

  const uploadImageAndReplace = useCallback(
    async payload => {
      if (isTempUri(payload.src)) {
        const uploadResponse = await onUploadImageAsync(payload.src, payload.response?.resourceId)

        if (uploadResponse) {
          const contentId = uploadResponse.resourceId

          editor.update(() => {
            const inlineImageNode = $createInlineImageNode({
              ...payload,
              contentId,
              src: `cid:${contentId}`,
            })

            $insertNodes([inlineImageNode])

            if ($isRootOrShadowRoot(inlineImageNode.getParentOrThrow())) {
              $wrapNodeInElement(inlineImageNode, $createParagraphNode).selectEnd()
            }
          })

          setUploadsByContentIdRef(contentId, uploadResponse)
          setTempUriByContentIdRef(contentId, payload.src)
        }
      } else {
        const inlineImageNode = $createInlineImageNode(payload)
        $insertNodes([inlineImageNode])

        if ($isRootOrShadowRoot(inlineImageNode.getParentOrThrow())) {
          $wrapNodeInElement(inlineImageNode, $createParagraphNode).selectEnd()
        }
      }

      return true
    },
    [editor, onUploadImageAsync, setTempUriByContentIdRef, setUploadsByContentIdRef],
  )

  const setInlineImageNodeRefs = useCallback(
    inlineImageNode => {
      if (isNilOrEmpty(inlineImageNode.uploadsByNodeKeyRef)) {
        inlineImageNode.setUploadsByContentIdRef(uploadsByContentIdRef)
      }
      if (isNilOrEmpty(inlineImageNode.tempUriByNodeKeyRef)) {
        inlineImageNode.setTempUriByContentIdRef(tempUriByContentIdRef)
      }
    },
    [tempUriByContentIdRef, uploadsByContentIdRef],
  )

  const handleMsDocumentPaste = useCallback(
    async ({ event, htmlString }) => {
      const dom = parseHtml(htmlString)
      const images = dom.querySelectorAll("img")
      const imagesRtf = extractImageDataFromRtf(event.clipboardData.getData("text/rtf"))

      if (images.length === imagesRtf.length) {
        const promises = []
        const sources = []

        images.forEach((image, i) => {
          const type = imagesRtf[i].type
          const src = `data:${type};base64,${convertHexToBase64(imagesRtf[i].hex)}`

          if (image.src.startsWith("file:///")) {
            promises.push(onUploadImageAsync(src))
            sources.push(src)
          }
        })

        const signedUrls = await Promise.all(promises)

        images.forEach((image, i) => {
          const src = sources[i]
          const uploadResponse = signedUrls[i]
          const contentId = uploadResponse.resourceId

          setUploadsByContentIdRef(contentId, uploadResponse)
          setTempUriByContentIdRef(contentId, src)

          image.setAttribute("src", `cid:${contentId}`)
        })
      }

      editor.update(() => {
        const selection = $getSelection()
        const nodes = $generateNodesFromDOM(editor, dom)

        if (
          !editor.dispatchCommand(SELECTION_INSERT_CLIPBOARD_NODES_COMMAND, {
            nodes,
            selection,
          })
        ) {
          selection.insertNodes(nodes)
          return true
        } else {
          return false
        }
      })
    },
    [editor, onUploadImageAsync, setTempUriByContentIdRef, setUploadsByContentIdRef],
  )

  useEffect(() => {
    return editor.registerNodeTransform(InlineImageNode, inlineImageNode => {
      setInlineImageNodeRefs(inlineImageNode)
    })
  }, [editor, setInlineImageNodeRefs])

  useEffect(() => {
    if (!editor.hasNodes([InlineImageNode])) {
      throw new Error("InlineImagePlugin: InlineImageNode not registered on editor")
    }

    editor.dispatchCommand(ALLOW_IMAGE_PLUGIN_COMMAND, true)

    return mergeRegister(
      editor.registerCommand(
        INSERT_IMAGE_COMMAND,
        payload => {
          uploadImageAndReplace(payload)
          return true
        },
        COMMAND_PRIORITY_EDITOR,
      ),

      // Taken from https://github.com/facebook/lexical/blob/main/packages/lexical-playground/src/plugins/DragDropPastePlugin/index.ts
      // This is needed when copying a single picture from the file system or even a single file system image from Word desktop app
      editor.registerCommand(
        DRAG_DROP_PASTE,
        files => {
          ;(async () => {
            const filesResult = await mediaFileReader(files, IMAGE_FILE_MIME_TYPES)

            for (const { file, result } of filesResult) {
              if (isMimeType(file, IMAGE_FILE_MIME_TYPES)) {
                editor.dispatchCommand(INSERT_IMAGE_COMMAND, {
                  altText: file.name,
                  src: result,
                })
              }
            }
          })()
          return true
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(
        PASTE_COMMAND,
        event => {
          const clipboardData = event.clipboardData
          if (!clipboardData) return false

          const htmlString = event.clipboardData.getData("text/html")
          if (!htmlString) return false

          // TODO: Fix list items
          if (isMsWord(htmlString)) {
            handleMsDocumentPaste({
              event,
              htmlString,
            })

            return true
          }

          if (isNotNil(htmlString)) {
            const div = document.createElement("div")
            div.innerHTML = htmlString.trim()
            const image = div.querySelector("img")
            const src = image?.getAttribute("src")
            const { contentId } = getContentIdFromSrc(src, tempUriByContentIdRef)

            if (contentId) {
              editor.update(() => {
                const node = $createInlineImageNode({
                  src: `cid:${contentId}`,
                  contentId,
                })
                $getSelection().insertNodes([node])
              })
            }
          }

          return false
        },
        COMMAND_PRIORITY_CRITICAL,
      ),

      editor.registerCommand(DRAGSTART_COMMAND, event => onDragStart(event), COMMAND_PRIORITY_HIGH),
      editor.registerCommand(DRAGOVER_COMMAND, event => onDragover(event), COMMAND_PRIORITY_LOW),
      editor.registerCommand(DROP_COMMAND, event => onDrop(event, editor), COMMAND_PRIORITY_HIGH),
      editor.registerCommand(COMPLETED_EDITING_HTML_COMMAND, updateRefsAfterEditingHTML, COMMAND_PRIORITY_HIGH),
    )
  }, [
    editor,
    uploadImageAndReplace,
    onUploadImageAsync,
    updateRefsAfterEditingHTML,
    handleMsDocumentPaste,
    onSetContentRefs,
    tempUriByContentIdRef,
  ])

  return null
}

const TRANSPARENT_IMAGE = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
const img = document.createElement("img")
img.src = TRANSPARENT_IMAGE

function onDragStart(event) {
  const node = getImageNodeInSelection()
  if (!node) return false

  const dataTransfer = event.dataTransfer
  if (!dataTransfer) return false

  dataTransfer.setData("text/plain", "_")
  dataTransfer.setDragImage(img, 0, 0)
  dataTransfer.setData(
    "application/x-lexical-drag",
    JSON.stringify({
      data: {
        key: node.getKey(),
        src: node.__src,
        width: node.__width,
        height: node.__height,
        maxWidth: node.__maxWidth,
      },
      type: "image",
    }),
  )

  return true
}

function onDragover(event) {
  const node = getImageNodeInSelection()
  if (!node) return false

  if (!canDropImage(event)) {
    event.preventDefault()
  }

  return true
}

function onDrop(event, editor) {
  const node = getImageNodeInSelection()
  if (!node) return false

  const data = getDragImageData(event)
  if (!data) return false

  event.preventDefault()

  if (canDropImage(event)) {
    const range = getDragSelection(event)
    node.remove()
    const rangeSelection = $createRangeSelection()
    if (range !== null && range !== undefined) {
      rangeSelection.applyDOMRange(range)
    }
    $setSelection(rangeSelection)
    editor.dispatchCommand(INSERT_IMAGE_COMMAND, data)
  }

  return true
}

function getImageNodeInSelection() {
  const selection = $getSelection()
  if (!$isNodeSelection(selection)) return null

  const nodes = selection.getNodes()
  const node = nodes[0]

  return $isInlineImageNode(node) ? node : null
}

function getDragImageData(event) {
  const dragData = event.dataTransfer?.getData("application/x-lexical-drag")
  if (!dragData) return null

  const { type, data } = JSON.parse(dragData)
  if (type !== "image") return null

  return data
}

function canDropImage(event) {
  const target = event.target
  return !!(
    target &&
    target instanceof HTMLElement &&
    !target.closest("code, span.editor-image") &&
    target.parentElement &&
    target.parentElement.closest("div.editor-root")
  )
}

function getDragSelection(event) {
  let range
  const target = event.target
  const targetWindow =
    target == null ? null : target.nodeType === 9 ? target.defaultView : target.ownerDocument.defaultView
  const domSelection = getDOMSelection(targetWindow)

  if (document.caretRangeFromPoint) {
    range = document.caretRangeFromPoint(event.clientX, event.clientY)
  } else if (event.rangeParent && domSelection !== null) {
    domSelection.collapse(event.rangeParent, event.rangeOffset || 0)
    range = domSelection.getRangeAt(0)
  } else {
    throw Error("Cannot get the selection when dragging")
  }

  return range
}
