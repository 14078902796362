import { fetchJson } from "js/includes/common/utils"

function getUrl(deviceId, userType, stealthMode = false) {
  switch (userType) {
    case "TECHNICIAN":
      if (stealthMode) return `/control/node/${deviceId}/session/request-stealth`
      else return `/control/node/${deviceId}/session/request`
    case "END_USER":
      return `/end-user/connect/ninja-connect/${deviceId}`
    default:
      throw new Error("Invalid user type")
  }
}

const requestNinjaControlBody = {
  session_settings: {
    confirmView: true,
    confirmControl: true,
    confirmTransfer: true,
    recording: "allow",
  },
}
export const requestNinjaControl = (deviceId, userType, stealthMode = false) => {
  const requestUrl = getUrl(deviceId, userType, stealthMode)
  return fetchJson(requestUrl, {
    options: {
      method: "POST",
      body: JSON.stringify({
        ...requestNinjaControlBody,
        session_settings: {
          ...requestNinjaControlBody.session_settings,
        },
        session_type: stealthMode ? 2 : 0,
      }),
    },
  })
}

export const requestMDMNinjaControl = deviceId => {
  return fetchJson(`/mdm/node/${deviceId}/connect`, {
    options: { method: "POST", body: JSON.stringify(requestNinjaControlBody) },
  })
}
