import { useCallback, useRef } from "react"

export const useSetContentRefs = () => {
  const uploadsByContentIdRef = useRef({})
  const tempUriByContentIdRef = useRef({})

  const setContentRefs = useCallback(refs => {
    uploadsByContentIdRef.current = {
      ...uploadsByContentIdRef.current,
      ...(refs.uploadsByContentIdRef?.current && { ...refs.uploadsByContentIdRef.current }),
    }
    tempUriByContentIdRef.current = {
      ...tempUriByContentIdRef.current,
      ...(refs.tempUriByContentIdRef?.current && { ...refs.tempUriByContentIdRef.current }),
    }
  }, [])

  return [
    {
      uploadsByContentIdRef,
      tempUriByContentIdRef,
    },
    setContentRefs,
  ]
}
