import React from "react"
import moment from "moment-timezone"
import styled from "@emotion/styled"
import { reject } from "ramda"
import { nanoid } from "nanoid"
import DayPicker, { DateUtils } from "react-day-picker"
import { faCalendarTimes, faTimes } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { date } from "js/includes/common/utils"
import { Box } from "js/includes/components/Styled"
import { StyledButton } from "js/includes/components/Styled/Form"
import SearchableDropDown from "js/includes/components/SearchableDropDown"
import "react-day-picker/lib/style.css"

const StyledDayPickerContainer = styled(Box)`
  display: flex;
  height: 240px;

  .DayPicker-wrapper {
    font-size: 12px;

    padding-bottom: 0px;

    .DayPicker-Months {
      .DayPicker-Month {
        margin: 8px 5px;
      }
    }
  }
`

export default function DateFilters({ option: { options }, onChange, value }) {
  const handleMultiDayClick = (day, { selected: isSelected }) => {
    const selected = value?.selected?.concat?.() ?? []
    const dayValue = moment(day)
      .startOf("day")
      .format("YYYY-MM-DD Z")

    if (isSelected) {
      const selectedIndex = selected.findIndex(selectedDay => selectedDay === dayValue)

      selected.splice(selectedIndex, 1)
    } else {
      selected.push(dayValue)
    }

    onChange({
      ...value,
      selected: selected.sort(),
    })
  }

  const handleRangeDayClick = async day => {
    const [start, end] = value.selected
    const from = start ? moment(start, "YYYY-MM-DD Z").toDate() : null
    const to = end ? moment(end, "YYYY-MM-DD Z").toDate() : null

    const range = DateUtils.addDayToRange(day, { from, to })

    onChange({
      ...value,
      selected: [
        range.from
          ? moment(range.from)
              .startOf("day")
              .format("YYYY-MM-DD Z")
          : null,
        range.to
          ? moment(range.to)
              .endOf("day")
              .format("YYYY-MM-DD Z")
          : null,
      ],
    })
  }

  return (
    <Box width="100%">
      <SearchableDropDown
        openOnFocus
        width="100%"
        minHeight="32px"
        borderRadius="2px"
        searchableKey="name"
        valueSelectorKey="id"
        rowHeight={30}
        options={options}
        keepInView={false}
        keepDropdownInView={true}
        value={options.find(option => value?.type === option.id)}
        onSelect={selection =>
          onChange({
            type: selection.id,
            selected: [],
          })
        }
      />

      {value?.type === "RANGE" && (
        <StyledDayPickerContainer backgroundColor="colorBackgroundWidget">
          <DayPicker
            className="DateRangePicker date-range-filter"
            numberOfMonths={2}
            disabledDays={{
              before: value.selected[0] ? moment(value.selected[0], "YYYY-MM-DD Z").toDate() : null,
            }}
            selectedDays={[
              value.selected[0] ? moment(value.selected[0], "YYYY-MM-DD Z").toDate() : null,
              {
                from: value.selected[0] ? moment(value.selected[0], "YYYY-MM-DD Z").toDate() : null,
                to: value.selected[1] ? moment(value.selected[1], "YYYY-MM-DD Z").toDate() : null,
              },
            ]}
            modifiers={{
              start: value?.selected?.[0],
              end: value?.selected?.[0],
            }}
            onDayClick={handleRangeDayClick}
          />
        </StyledDayPickerContainer>
      )}

      {["INCLUDE", "EXCLUDE"].includes(value?.type) && (
        <StyledDayPickerContainer backgroundColor="colorBackgroundWidget">
          <DayPicker
            className="DateRangePicker"
            numberOfMonths={2}
            selectedDays={value?.selected?.map(selectedDay => moment(selectedDay, "YYYY-MM-DD Z").toDate())}
            onDayClick={handleMultiDayClick}
          />
          {value?.selected?.length ? (
            <Box flex={1} margin="8px 0px" padding="0px 5px" minHeight="0px" overflowY="auto">
              {value.selected.map(day => {
                return (
                  <Box key={nanoid(10)} marginBottom={1} position="reltive" display="flex">
                    <Box flex={1}>{date(moment(day, "YYYY-MM-DD Z"))}</Box>

                    <StyledButton
                      marginLeft={1}
                      type="button"
                      cursor="pointer"
                      color="reactVirtualizedLightGrey"
                      hoverTextColor="ninjaRed"
                      onClick={e => {
                        onChange({
                          ...value,
                          selected: reject(selected => selected === day, value.selected),
                        })
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </StyledButton>
                  </Box>
                )
              })}
            </Box>
          ) : (
            <Box
              flex={1}
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              fontSize="24px"
              color="ninjaLight"
            >
              <FontAwesomeIcon icon={faCalendarTimes} />
            </Box>
          )}
        </StyledDayPickerContainer>
      )}
    </Box>
  )
}
