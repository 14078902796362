import { getOrganizationAndLocation } from "js/includes/common/client/organization"
import { NinjaResponseError } from "js/includes/common/types"
import { fetchJson } from "js/includes/common/utils"

export const getUnmanagedDevice = async nodeId => {
  const [nodeResponse] = await Promise.all([fetchJson(`/node/${nodeId}`), window.deviceList.fetchById(nodeId)])

  if (nodeResponse.resultCode === "SUCCESS") {
    const { node, healthStatus } = nodeResponse
    const { organization, location } = await getOrganizationAndLocation(node)

    return {
      ...node,
      healthStatus,
      organization,
      location,
    }
  } else {
    throw new NinjaResponseError(nodeResponse)
  }
}

export const createUnmanagedDevice = async unmanagedDevice => {
  return fetchJson("/unmanaged-device", {
    options: {
      method: "POST",
      body: JSON.stringify(unmanagedDevice),
    },
  })
}
