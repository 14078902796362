import { memo, useCallback, useEffect, useMemo, useState } from "react"
import pathParse from "path-parse"
import { nanoid } from "nanoid"

import { ninjaReportError, uploadFile } from "js/includes/common/utils"
import { Box } from "js/includes/components/Styled"
import { MAX_WYSIWYG_HTML_LENGTH_FOR_AUTO_VIEW } from "js/includes/common/_constants"
import {
  ENABLE_CLICK_ON_PORTAL_CLASS_NAME,
  handleFormFieldBlur,
  handleFormFieldChange,
} from "js/includes/components/CustomFields/common"
import { WYSIWYGEditor } from "js/includes/components/CustomFields/WYSIWYGEditor"

export const buildTempAttachment = file => {
  const { name, ext } = pathParse(file.name)

  return {
    resourceId: null,
    tempId: nanoid(),
    metadata: {
      name,
      mimeType: file.type,
      size: file.size, // TODO use another function since bytes unit is wrong (should be KiB instead of KB)
      extension: ext.replace(".", "").toLowerCase(),
      inline: true,
    },
  }
}

const buildTempAttachmentWithResourceId = ({ resourceId, attachment }) => ({
  ...attachment,
  resourceId,
  metadata: { ...attachment.metadata, contentId: resourceId },
})

function WYSIWYGEditorFormField(props) {
  const {
    id,
    data,
    expanded = true,
    setExpanded,
    refs,
    richTextPluginProps,
    value,
    onChange,
    onBlur,
    isHookForm = false,
    onSetContentRefs,
    entityType,
    validationState,
    validationMessage,
    validateFormFields,
    technicianPermission,
    forceExpand = false,
  } = props
  const [initialValue] = useState(value)
  const validationProps = useMemo(
    () => ({
      errorMessage: validationState === "error" ? validationMessage : "",
    }),
    [validationMessage, validationState],
  )
  const htmlParserPluginProps = useMemo(
    () => ({
      initialHTML: initialValue?.html,
    }),
    [initialValue?.html],
  )
  const onChangePluginProps = useMemo(
    () => ({
      onChange: value => handleFormFieldChange({ onChange, id, isHookForm })(value),
    }),
    [id, isHookForm, onChange],
  )

  const onUploadImage = useCallback(
    async (file, resourceId) => {
      const resourceEntity = data?.resourceEntity ?? entityType
      const attachment = buildTempAttachment(file)

      if (resourceId) {
        return buildTempAttachmentWithResourceId({ resourceId, attachment })
      } else {
        try {
          const { resourceId } = await uploadFile({
            file,
            url: `/attachments/tmp/upload-file/${resourceEntity}/cache-metadata?inline=true`,
          })
          return buildTempAttachmentWithResourceId({ resourceId, attachment })
        } catch (error) {
          ninjaReportError(error)
        }
      }
    },
    [data?.resourceEntity, entityType],
  )

  const imagePluginProps = useMemo(
    () => ({
      onSetContentRefs,
      onUploadImage,
      uploadsByContentIdRef: refs?.uploadsByContentIdRef,
      tempUriByContentIdRef: refs?.tempUriByContentIdRef,
    }),
    [onSetContentRefs, onUploadImage, refs],
  )

  useEffect(() => {
    const isLargeHtml = (initialValue?.html?.length ?? 0) > MAX_WYSIWYG_HTML_LENGTH_FOR_AUTO_VIEW
    setExpanded?.(!isLargeHtml)
  }, [initialValue, setExpanded])

  return (
    technicianPermission === "EDITABLE" &&
    (forceExpand || expanded) && (
      <Box
        width="100%"
        minHeight="40px"
        display="flex"
        position="relative"
        backgroundColor="colorBackground"
        alignItems="center"
        justifyContent="space-between"
      >
        <WYSIWYGEditor
          fullWidth
          {...{
            richTextPluginProps,
            htmlParserPluginProps,
            onChangePluginProps,
            imagePluginProps,
            onBlur: handleFormFieldBlur({ onBlur, id, isHookForm, validateFormFields }),
            validationProps,
            toolbarPluginProps: {
              dropdownClassName: ENABLE_CLICK_ON_PORTAL_CLASS_NAME,
              tableModalBackgroundClassName: ENABLE_CLICK_ON_PORTAL_CLASS_NAME,
            },
            tableActionMenuProps: {
              containerClassName: ENABLE_CLICK_ON_PORTAL_CLASS_NAME,
            },
          }}
        />
      </Box>
    )
  )
}

export default memo(WYSIWYGEditorFormField)
