import { useMutation } from "urql"

import { SearchableSelect } from "@ninjaone/components"

import { useMountedState } from "js/includes/common/hooks"
import { reportErrorAndShowMessage, showErrorMessage } from "js/includes/common/utils"
import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import { getTagErrors } from "js/includes/ticketing/commonMethods"
import { createTag as _createTag } from "js/includes/ticketing/graphql"

export const TagsEditor = ({
  selected: _selected,
  tags,
  onChange,
  disableEditor,
  onFocus,
  ariaLabel,
  errorMessage,
}) => {
  const selected = _selected || []
  const [isCreating, setIsCreating] = useMountedState(false)
  const [, createTag] = useMutation(_createTag)

  const onCreate = async newTag => {
    if (isCreating) {
      return
    }

    const tagErrors = getTagErrors(newTag)
    if (tagErrors) {
      tagErrors.forEach(errorToken => {
        showErrorMessage(errorToken)
      })
      return
    }
    try {
      setIsCreating(true)
      // In case we migrate this to a REST endpoint, consider that currently the tags list is
      // updated automatically after creating a tag. If we migrate to REST, we will need to update
      // the tags list "manually".
      const { error } = await createTag({
        tag: {
          name: newTag,
        },
      })

      if (error) {
        return reportErrorAndShowMessage(error, localizationKey("Error creating tag"))
      }
      onChange([...selected, newTag])
    } finally {
      setIsCreating(false)
    }
  }

  return (
    <SearchableSelect
      ariaLabel={ariaLabel}
      options={tags}
      isMulti
      value={selected}
      comboboxProps={{
        creatable: true,
        onCreate,
      }}
      onChange={onChange}
      errorMessage={errorMessage}
    />
  )
}
