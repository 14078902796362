import { map } from "ramda"
import { useMemo } from "react"
import { connect } from "react-redux"

import styled from "@emotion/styled"
import { SearchableSelect } from "@ninjaone/components"
import tokens from "@ninjaone/tokens"

import { Box } from "js/includes/components/Styled"
import {
  getStatusBackgroundFromId,
  isClosedStatus,
} from "js/includes/configuration/integrations/ticketing/ticketCustomStatus/common"
import { getStatusOptions } from "js/includes/ticketing/shared/utils/options"

const StatusIcon = styled(Box)`
  width: ${tokens.spacing[2]};
  height: ${tokens.spacing[2]};
  border-radius: 50%;
`

const setIconInOptions = map(option => ({
  ...option,
  icon: (
    <StatusIcon
      {...{
        backgroundColor: getStatusBackgroundFromId(option.value),
        ...(isClosedStatus(option.value) && {
          border: "2px solid",
          borderColor: "ninjaLight",
        }),
      }}
    />
  ),
}))

const _StatusEditor = ({ value, handleOnChange, statusList, parentOnly, hideClose, errorMessage, ariaLabel }) => {
  const statusOptions = useMemo(() => setIconInOptions(getStatusOptions(statusList, { parentOnly, hideClose })), [
    statusList,
    parentOnly,
    hideClose,
  ])

  return (
    <SearchableSelect
      options={statusOptions}
      ariaLabel={ariaLabel}
      value={+value || "__NONE__"}
      onChange={handleOnChange}
      errorMessage={errorMessage}
    />
  )
}

export const StatusEditor = connect(({ ticketing }) => ({ statusList: ticketing.status.list }))(_StatusEditor)
