import { useEffect, useState } from "react"
import qs from "qs"
import { compose, map, pluck, uniq } from "ramda"
import { fetchJson, localizationKey, localized, reportErrorAndShowMessage } from "js/includes/common/utils"
import SearchableDeviceDropdown from "js/includes/components/SearchableDeviceDropdown"
import { Box, StyledSpan } from "js/includes/components/Styled"
import DeviceIcon from "js/includes/components/DeviceIcon"
import { useMountedState } from "js/includes/common/hooks"
import Loading from "js/includes/components/Loading"
import {
  handleFormFieldBlur,
  handleFormFieldChange,
  searchableDropdownCommonProps,
} from "js/includes/components/CustomFields/common"

export default function DeviceMultiSelectFormField({
  id,
  value,
  onChange,
  onBlur,
  isHookForm = false,
  validationState,
  advancedSettings,
  dropdownContainerId,
  technicianPermission,
  data,
}) {
  const [clientIds] = useState(
    compose(
      uniq,
      map(clientId => (clientId === 0 && data?.clientId ? data?.clientId : clientId)),
    )(advancedSettings?.org ?? []),
  )

  const [initialValue] = useState(value)
  const [loading, setLoading] = useMountedState(false)
  const [devices, setDevices] = useMountedState([])

  useEffect(() => {
    const getInitialSelection = async () => {
      try {
        setLoading(true)

        const _qs = qs.stringify(
          {
            pageSize: initialValue?.entityIds?.length,
            nodeId: initialValue?.entityIds,
          },
          { indices: false },
        )

        const devices = await fetchJson(`${"/query/devices"}?${_qs}`)

        setDevices(devices)
      } catch (error) {
        reportErrorAndShowMessage(error)
      } finally {
        setLoading(false)
      }
    }

    if (initialValue?.entityIds?.length) {
      getInitialSelection()
    }
  }, [initialValue, setLoading, setDevices])

  return loading ? (
    <Loading />
  ) : (
    <SearchableDeviceDropdown
      {...{
        org: clientIds,
        nodeClass: advancedSettings?.nodeClass,
        pageSize: 50,
        customDropdownOptions: {
          ...searchableDropdownCommonProps,
          dropdownContainerId,
          isMulti: true,
          value: devices,
          width: "100%",
          enableClear: true,
          keepInView: false,
          excludeSelectedFromSelectableValues: true,
          disabled: technicianPermission !== "EDITABLE",
          noRowsRendererToken: localizationKey("No options available"),
          validationState,
          tagRenderer: ({
            option,
            renderTagOptionName,
            renderTagDeleteAction,
            deleteTagAction,
            tagLabelValue,
            TagWrapper,
          }) => {
            return (
              <TagWrapper key={option.id}>
                <StyledSpan marginRight={1}>
                  <DeviceIcon {...{ nodeClass: option.nodeClass }} />
                </StyledSpan>

                {renderTagOptionName()}
                {renderTagDeleteAction()}
              </TagWrapper>
            )
          },
        },
        customRowRenderer: rowData => {
          const { organizationName, nodeClass, name, id } = rowData
          return (
            <Box key={`${id}-device`}>
              <h4 className="no-margin p-l-xs line-height-initial">
                <StyledSpan marginRight={1}>
                  <DeviceIcon {...{ nodeClass, className: "" }} />
                </StyledSpan>
                <StyledSpan>{name}</StyledSpan>
              </h4>
              <Box marginTop={1} paddingLeft={1} lineHeight="initial" className="text-ellipsis">
                {organizationName || localized("N/A")}
              </Box>
            </Box>
          )
        },
        onSelect: selected => {
          setDevices(selected)

          handleFormFieldChange({
            id,
            onChange,
            isHookForm,
          })(
            !!selected.length
              ? {
                  entityIds: pluck("id", selected),
                  type: "NODE",
                }
              : null,
          )
        },
        onBlur: handleFormFieldBlur({ onBlur, id, isHookForm }),
      }}
    />
  )
}
