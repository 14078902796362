import { anyPass, isNil } from "ramda"
import { isNilOrEmpty } from "js/includes/common/utils"

export const isNullOperator = condition => ["IS_NULL", "IS_NOT_NULL"].includes(condition.value?.operator)

const rejectOperator = condition => isNil(condition.value?.operator)
const rejectAttributeId = condition => isNil(condition.value?.attributeId)
const rejectAttributeValue = condition => !isNullOperator(condition) && isNilOrEmpty(condition.value?.attributeValue)

export const isInvalidCondition = anyPass([rejectOperator, rejectAttributeId, rejectAttributeValue])
