import { useEffect, useState } from "react"
import qs from "qs"
import { compose, map, uniq } from "ramda"
import { fetchJson, reportErrorAndShowMessage } from "js/includes/common/utils"
import SearchableDropDown from "js/includes/components/SearchableDropDown"
import { Box, StyledFontAwesomeIcon, StyledSpan } from "js/includes/components/Styled"
import { useMountedState } from "js/includes/common/hooks"
import Loading from "js/includes/components/Loading"
import { faMapMarker } from "@fortawesome/pro-solid-svg-icons"
import {
  handleFormFieldBlur,
  handleFormFieldChange,
  searchableDropdownCommonProps,
} from "js/includes/components/CustomFields/common"
import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"

export default function CustomerLocationDropdownFormField({
  id,
  value,
  onChange,
  onBlur,
  isHookForm = false,
  validationState,
  advancedSettings,
  dropdownContainerId,
  technicianPermission,
  data,
}) {
  const [clientIds] = useState(
    compose(
      uniq,
      map(clientId => (clientId === 0 && data?.clientId ? data?.clientId : clientId)),
    )(advancedSettings?.org ?? []),
  )

  const [initialValue] = useState(value)
  const [loading, setLoading] = useMountedState(false)
  const [location, setLocation] = useMountedState(null)

  useEffect(() => {
    const getInitialSelection = async () => {
      try {
        setLoading(true)

        const _qs = qs.stringify({
          pageSize: 1,
          id: initialValue?.entityId,
        })

        const [location] = await fetchJson(`/query/locations?${_qs}`)

        setLocation(location)
      } catch (error) {
        reportErrorAndShowMessage(error)
      } finally {
        setLoading(false)
      }
    }

    if (initialValue?.entityId) {
      getInitialSelection()
    }
  }, [initialValue, setLoading, setLocation])

  return loading ? (
    <Loading />
  ) : (
    <SearchableDropDown
      {...{
        ...searchableDropdownCommonProps,
        dropdownContainerId,
        value: location,
        searchableKey: "name",
        valueSelectorKey: "id",
        endpoint: "/query/locations",
        width: "100%",
        enableClear: true,
        keepInView: false,
        keepDropdownInView: true,
        excludeSelectedFromSelectableValues: true,
        disabled: technicianPermission !== "EDITABLE",
        noRowsRendererToken: localizationKey("No options available"),
        pageSize: 50,
        rowHeight: 50,
        validationState,
        getInitialValue: () => location?.name,
        searchParams: ({ query }) => ({
          ...(query && { name: query }),
          ...(clientIds && { org: clientIds }),
        }),
        rowRenderer: rowData => {
          const { name, id, organizationName } = rowData
          return (
            <Box key={`${id}-location`}>
              <h4 className="no-margin p-l-xs text-ellipsis line-height-initial">
                <StyledFontAwesomeIcon marginRight={1} icon={faMapMarker} />
                <StyledSpan>{name}</StyledSpan>
              </h4>
              <Box paddingLeft={1}>{organizationName}</Box>
            </Box>
          )
        },
        onSelect: selected => {
          setLocation(selected)

          handleFormFieldChange({
            id,
            onChange,
            isHookForm,
          })(
            !!selected
              ? {
                  entityId: selected.id,
                  type: "CLIENT_LOCATION",
                }
              : null,
          )
        },
        onBlur: handleFormFieldBlur({ onBlur, id, isHookForm }),
      }}
    />
  )
}
