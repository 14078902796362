import React, { useCallback, useEffect, useState } from "react"
import {
  getCurrentSubscription,
  isNotNilOrEmpty,
  isReportableWampException,
  retryPromise,
  unSubscribeFromWamp,
  ninjaReportError,
} from "js/includes/common/utils"
import { useIntervalWhen, useMounted } from "js/includes/common/hooks"
import { requestNinjaControl } from "js/includes/common/client"
import { qcExtractUrlsFromResponse } from "js/includes/quickConnect/utils"
import { handleQCConnectError } from "js/includes/quickConnect/utils/errors"
import NinjaControlClientCommonModal from "./NinjaControlClientCommonModal"

const TIMER_DURATION_IN_SECONDS = 5
const TIMER_INTERVAL_IN_MILLISECONDS = 1000
const MAX_RETRY_COUNT = 5

export default function NinjaControlClientDownloadModal({ nodeId, userType, stealthMode = false, unmount }) {
  const [macClientDownloadUrl, setMacClientDownloadUrl] = useState("")
  const [windowsClientDownloadUrl, setWindowsClientDownloadUrl] = useState("")
  const [loading, setLoading] = useState(true)
  const [launchUrl, setLaunchUrl] = useState()
  const [timer, setTimer] = useState(TIMER_DURATION_IN_SECONDS)
  const mounted = useMounted()

  useIntervalWhen(
    () => mounted.current && setTimer(prev => prev - 1),
    TIMER_INTERVAL_IN_MILLISECONDS,
    !!launchUrl && timer > 0,
  )

  const subscribeToChannelWithRetry = useCallback(
    async channel => {
      await retryPromise(async () => {
        if (!window.wamp.session) window.wamp.initializeConnection()

        await window.wamp.subscribeToChannel(channel, kwargs => {
          if (mounted.current && kwargs[0] === "SESSION_ESTABLISHED") {
            setTimer(0)
            unmount()
          }
        })
      }, MAX_RETRY_COUNT)
    },
    [mounted, unmount],
  )

  useEffect(() => {
    const channel = `device.${nodeId}.ninjaConnectSessions`
    const getClientDownloadUrls = async () => {
      try {
        mounted.current && setLoading(true)
        const response = await requestNinjaControl(nodeId, userType, stealthMode)
        const { launchUrl, macClientUrl, windowsClientUrl } = qcExtractUrlsFromResponse(response)

        if (isNotNilOrEmpty(launchUrl)) {
          try {
            await subscribeToChannelWithRetry(channel)
          } catch (error) {
            if (isReportableWampException(error)) ninjaReportError(error)
          }

          mounted.current && setLaunchUrl(launchUrl)
        }
        if (isNotNilOrEmpty(macClientUrl) && isNotNilOrEmpty(windowsClientUrl)) {
          mounted.current && setMacClientDownloadUrl(macClientUrl)
          mounted.current && setWindowsClientDownloadUrl(windowsClientUrl)
        }
      } catch (error) {
        handleQCConnectError(error)
      } finally {
        mounted.current && setLoading(false)
      }
    }
    getClientDownloadUrls()
    return () => unSubscribeFromWamp(getCurrentSubscription(channel))
  }, [mounted, nodeId, subscribeToChannelWithRetry, userType, stealthMode])

  const isTimerExpired = timer === 0

  const showDownloadOptions =
    isTimerExpired && isNotNilOrEmpty(macClientDownloadUrl) && isNotNilOrEmpty(windowsClientDownloadUrl)

  return (
    <NinjaControlClientCommonModal
      {...{
        launchUrl,
        loading,
        macClientDownloadUrl,
        showDownloadOptions,
        unmount,
        windowsClientDownloadUrl,
      }}
    />
  )
}
