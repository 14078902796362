import moment from "moment"
import { DatePicker } from "@ninjaone/components"
import { date, localized } from "js/includes/common/utils"
import SearchableDropDown from "js/includes/components/SearchableDropDown"
import {
  disabledDays,
  ENABLE_CLICK_ON_PORTAL_CLASS_NAME,
  handleFormFieldBlur,
  handleFormFieldChange,
  isValidDate,
} from "js/includes/components/CustomFields/common"

export default function DateFormField({
  id,
  value,
  onChange,
  onBlur,
  isHookForm = false,
  validationState,
  validationMessage,
  fixedRdtPicker,
  advancedSettings,
  validateFormFields,
  dropdownContainerId,
  technicianPermission,
}) {
  const dateValue = value ? new Date(value) : null

  const handleChange = handleFormFieldChange({
    id,
    onChange,
    isHookForm,
  })

  const handleBlur = handleFormFieldBlur({ onBlur, id, isHookForm, validateFormFields })

  return advancedSettings?.dateFilters?.type === "INCLUDE" ? (
    <SearchableDropDown
      dropdownClassName={ENABLE_CLICK_ON_PORTAL_CLASS_NAME}
      openOnFocus
      enableClear
      width="100%"
      minHeight="32px"
      borderRadius="2px"
      searchableKey="label"
      valueSelectorKey="id"
      rowHeight={30}
      keepInView={false}
      keepDropdownInView={true}
      dropdownContainerId={dropdownContainerId}
      disabled={technicianPermission !== "EDITABLE"}
      options={advancedSettings?.dateFilters?.selected?.sort()?.map(filterDate => {
        const milliseconds = moment(filterDate, "YYYY-MM-DD Z").valueOf()
        return {
          id: milliseconds,
          label: date(milliseconds),
        }
      })}
      value={
        value
          ? {
              id: value,
              label: date(value),
            }
          : null
      }
      onSelect={selection => {
        handleChange(selection?.id ?? null)
      }}
      validationState={validationState}
      onBlur={handleBlur}
    />
  ) : (
    <DatePicker
      fullWidth
      id={id}
      ariaLabel={localized("custom field date picker")}
      disabledDays={disabledDays(advancedSettings)}
      selectedDays={dateValue}
      onDayChange={({ date }) => {
        if (!date) {
          handleChange(null)

          return
        }

        if (!isValidDate(date, advancedSettings)) return

        const newDate = moment(date).valueOf()

        handleChange(newDate)
      }}
      onError={handleBlur}
      errorMessage={validationMessage}
      disabled={technicianPermission !== "EDITABLE"}
      readOnly
      useDropdownNavigation
    />
  )
}
