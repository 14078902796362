import { memo, useCallback, useEffect, useMemo } from "react"
import { compose, evolve, includes, omit, pluck, reject } from "ramda"

import { Card, Body } from "@ninjaone/components"
import DataTable, { filterTypes } from "@ninjaone/components/src/DataTable"
import tokens from "@ninjaone/tokens"

import {
  localizationKey,
  defaultToDash,
  localized,
  useNinjaPSAConfigurations,
  formatNumber,
  doesUserHaveTicketProductPermission,
  isUserAllowedToViewNinjaPSAProductPrice,
  isUserAllowedToUseNinjaPSAAdministrativeActions,
  reportErrorAndShowMessage,
  showSuccessMessage,
} from "js/includes/common/utils"
import {
  fetchTicketProducts,
  addCatalogTicketProduct,
  addAdhocTicketProduct,
  deleteTicketProducts,
  fetchTicketProductById,
  updateTicketProduct,
} from "js/includes/common/client"
import showModal from "js/includes/common/services/showModal"
import { getUserNameOrEmail } from "js/includes/ticketing/editor/shared/utils"
import {
  productTypeTokenMapper,
  getBillingStatusData,
  getBillingStatusFilterOptions,
  productTypes,
} from "js/includes/configuration/integrations/psa/psaProducts/productCommons"
import { CREATE_PRODUCT, EDIT_PRODUCT } from "js/includes/configuration/integrations/psa/psaProducts/actions"
import { getProductTypeOptions } from "js/includes/configuration/integrations/psa/psaProducts/productForm/formCommons"
import { Flex } from "js/includes/components/Styled"
import ProductTypeSelectorModal from "js/includes/configuration/integrations/psa/psaProducts/productForm/ProductTypeSelectorModal"
import ProductFormModal from "js/includes/configuration/integrations/psa/psaProducts/productForm"
import { ExistingProductSelectorModal } from "js/includes/editors/Agreement/content/products/productEditorModals/ExistingProductSelectorModal"
import {
  simplifyAgreementProduct,
  overrideFieldsInCatalogProduct,
} from "js/includes/editors/Agreement/agreementEditorUtils"
import { useMountedState } from "js/includes/common/hooks"

import { DeleteTicketProductsConfirmationModal } from "./DeleteTicketProductsConfirmationModal"

const UNSUPPORTED_PRODUCT_TYPES = [
  productTypes.LABOR_TICKET_TIME_ENTRY,
  productTypes.MANAGED_DEVICES,
  productTypes.PRODUCT_GROUP,
]

const mapCatalogProductToNewAgreementProduct = catalogProduct => ({
  ...catalogProduct,
  productId: catalogProduct.id,
  id: null,
})

const commonProductFormProps = {
  useDynamicQuantity: true,
  fieldTooltipInfoTokens: {
    description: localizationKey(
      "This field will be shown as description on the invoices generated for the current agreement",
    ),
  },
}

const getSupportedTypeFilterOptions = () => {
  return reject(({ value }) => UNSUPPORTED_PRODUCT_TYPES.includes(value), getProductTypeOptions({ labelKey: "label" }))
}

const getColumns = ({ currency }) => [
  {
    id: "name",
    Header: localized("Name"),
    accessor: "name",
  },
  {
    id: "description",
    Header: localized("Description"),
    accessor: "description",
    maxWidth: "300px",
  },
  {
    id: "billingStatus",
    Header: localized("Status"),
    accessor: ({ billingStatus }) => getBillingStatusData(billingStatus)?.labelText,
  },
  {
    id: "type",
    Header: localized("Type"),
    accessor: ({ type }) => localized(productTypeTokenMapper[type]),
  },
  ...(isUserAllowedToViewNinjaPSAProductPrice()
    ? [
        {
          id: "price",
          Header: localized("Price"),
          accessor: ({ content: { price } }) => formatNumber({ value: price, withCurrencyStyle: true, currency }),
          sortType: (rowA, rowB) => rowA?.original?.content?.price - rowB?.original?.content?.price,
        },
        {
          id: "cost",
          Header: localized("Cost"),
          accessor: ({ content: { cost } }) => formatNumber({ value: cost, withCurrencyStyle: true, currency }),
          sortType: (rowA, rowB) => rowA?.original?.content?.cost - rowB?.original?.content?.cost,
        },
      ]
    : []),
  {
    id: "quantity",
    Header: localized("Quantity"),
    accessor: ({ content: { quantity } }) => quantity,
  },
  {
    id: "technician",
    Header: localized("Created By"),
    accessor: ({ technician }) => (technician ? getUserNameOrEmail(technician) : null),
  },
]

const whenEditingRemoveFields = evolve({ content: omit(["taxable", "billing", "licensingType", "term"]) })

export const TicketProductsTable = memo(({ ticketId, agreementId, isTicketEditable, fetchTicketLogEntries }) => {
  const { currency } = useNinjaPSAConfigurations()
  const [loading, setLoading] = useMountedState(false)
  const [ticketProducts, setTicketProducts] = useMountedState([])

  const isUserAllowedToCreateTicketProducts = useMemo(() => {
    return (
      (isUserAllowedToUseNinjaPSAAdministrativeActions() || doesUserHaveTicketProductPermission({ create: true })) &&
      isTicketEditable
    )
  }, [isTicketEditable])

  const getTicketProducts = useCallback(async () => {
    try {
      setLoading(true)
      const ticketProducts = await fetchTicketProducts(ticketId)
      setTicketProducts(ticketProducts)
      setLoading(false)
    } catch (error) {
      reportErrorAndShowMessage(error, localizationKey("Error while fetching ticket products"))
    }
  }, [setTicketProducts, setLoading, ticketId])

  useEffect(getTicketProducts, [getTicketProducts])

  //TODO: remove this in favor of WAMP
  const updateLocalStateProduct = useCallback(
    newOrUpdatedProduct => {
      let exists = false
      setTicketProducts(prevList =>
        prevList.map(product => {
          if (product.id === newOrUpdatedProduct.id) {
            exists = true
            return newOrUpdatedProduct
          }
          return product
        }),
      )
      if (!exists) {
        setTicketProducts(prevList => [...prevList, newOrUpdatedProduct])
      }
    },
    [setTicketProducts],
  )

  //TODO: remove this in favor of WAMP
  const removeProductsFromLocalList = useCallback(
    ticketProductIds => {
      setTicketProducts(prevList => reject(product => ticketProductIds.includes(product.id), prevList))
    },
    [setTicketProducts],
  )

  const saveExistingTicketProductHandler = useCallback(
    catalogProduct => async ({ productToPersist, unmount, setIsPersisting }) => {
      try {
        setIsPersisting(true)
        const result = await addCatalogTicketProduct({
          ...whenEditingRemoveFields(productToPersist),
          productId: catalogProduct.id,
          ticketId,
        })
        updateLocalStateProduct(result)
        fetchTicketLogEntries()
        showSuccessMessage()
        unmount()
      } catch (error) {
        reportErrorAndShowMessage(error, localizationKey("Error while adding product"))
      } finally {
        setIsPersisting(false)
      }
    },
    [ticketId, updateLocalStateProduct, fetchTicketLogEntries],
  )

  const updateTicketProductHandler = useCallback(
    catalogProduct => async ({ productToPersist, unmount, setIsPersisting }) => {
      try {
        setIsPersisting(true)
        const result = await updateTicketProduct({
          ticketProduct: {
            id: catalogProduct.id,
            productId: catalogProduct.productId,
            ...(catalogProduct.productId ? whenEditingRemoveFields(productToPersist) : productToPersist),
          },
          ticketId,
        })
        updateLocalStateProduct(result)
        fetchTicketLogEntries()
        showSuccessMessage()
        unmount()
      } catch (error) {
        reportErrorAndShowMessage(error, localizationKey("Error while updating product"))
      } finally {
        setIsPersisting(false)
      }
    },
    [ticketId, updateLocalStateProduct, fetchTicketLogEntries],
  )

  const openProductEditingModal = useCallback(
    ({ ticketProduct, catalogProduct, isUpdate = false, isAdhoc = false, disabled } = {}) => {
      const disableFieldsToEdit = (isUpdate || ticketProduct?.productId) && !isAdhoc

      showModal(
        <ProductFormModal
          disabled={disabled}
          action={EDIT_PRODUCT.id}
          saveButtonText={localized("Apply")}
          currentProducts={[]}
          product={overrideFieldsInCatalogProduct(catalogProduct, ticketProduct)}
          saveHandler={
            isUpdate ? updateTicketProductHandler(catalogProduct) : saveExistingTicketProductHandler(catalogProduct)
          }
          loadProduct={isUpdate}
          productFetcher={fetchTicketProductById}
          currency={currency}
          {...{
            ...commonProductFormProps,
            //This is to only disable these fields for the catalog products
            ...(disableFieldsToEdit && {
              enabledFields: {
                description: true,
                quantity: true,
                price: true,
                cost: true,
                // taxable: true, // TODO add this field when server supports it
              },
            }),
          }}
        />,
      )
    },
    [currency, saveExistingTicketProductHandler, updateTicketProductHandler],
  )

  const openProductCreationModal = useCallback(
    type => {
      showModal(
        <ProductFormModal
          action={CREATE_PRODUCT.id}
          saveButtonText={localized("Apply")}
          product={{ type }}
          saveHandler={async ({ productToPersist, unmount, setIsPersisting }) => {
            try {
              setIsPersisting(true)
              const result = await addCatalogTicketProduct({
                ...productToPersist,
                ticketId,
              })
              updateLocalStateProduct(result)
              fetchTicketLogEntries()
              showSuccessMessage()
              unmount()
            } catch (error) {
              reportErrorAndShowMessage(error, localizationKey("Error while adding product"))
            } finally {
              setIsPersisting(false)
            }
          }}
          currency={currency}
          {...commonProductFormProps}
        />,
      )
    },
    [currency, ticketId, updateLocalStateProduct, fetchTicketLogEntries],
  )

  const createNewProduct = useCallback(() => {
    showModal(
      <ProductTypeSelectorModal
        handleSelect={openProductCreationModal}
        hiddenProductTypes={UNSUPPORTED_PRODUCT_TYPES}
      />,
    )
  }, [openProductCreationModal])

  const addExistingProduct = useCallback(() => {
    showModal(
      <ExistingProductSelectorModal
        hiddenProductTypes={UNSUPPORTED_PRODUCT_TYPES}
        onSelect={selectedProduct => {
          openProductEditingModal({
            catalogProduct: selectedProduct,
            ticketProduct: simplifyAgreementProduct(
              mapCatalogProductToNewAgreementProduct(selectedProduct),
              ticketProducts.length,
            ),
          })
        }}
      />,
    )
  }, [openProductEditingModal, ticketProducts])

  const createNewNonCatalogProduct = useCallback(() => {
    showModal(
      <ProductTypeSelectorModal
        hiddenProductTypes={UNSUPPORTED_PRODUCT_TYPES}
        handleSelect={type => {
          showModal(
            <ProductFormModal
              action={CREATE_PRODUCT.id}
              product={{ type }}
              saveButtonText={localized("Apply")}
              saveHandler={async ({ productToPersist, unmount, setIsPersisting }) => {
                try {
                  setIsPersisting(true)
                  const result = await addAdhocTicketProduct({
                    ...productToPersist,
                    ticketId,
                  })
                  updateLocalStateProduct(result)
                  fetchTicketLogEntries()
                  showSuccessMessage()
                  unmount()
                } catch (error) {
                  reportErrorAndShowMessage(error, localizationKey("Error while adding product"))
                } finally {
                  setIsPersisting(false)
                }
              }}
              currency={currency}
              {...commonProductFormProps}
            />,
          )
        }}
      />,
    )
  }, [currency, updateLocalStateProduct, ticketId, fetchTicketLogEntries])

  const handleView = useCallback(
    ({ selected }) => {
      /**
       * This takes advantage to the already existing method to edit products
       * in order to view products in a disabled state.
       */
      openProductEditingModal({
        disabled: true,
        isUpdate: true,
        ticketProduct: selected[0],
        catalogProduct: selected[0],
      })
    },
    [openProductEditingModal],
  )

  const handleEdit = useCallback(
    ({ selected }) => {
      openProductEditingModal({
        isAdhoc: !selected[0].productId,
        isUpdate: true,
        ticketProduct: selected[0],
        catalogProduct: selected[0],
      })
    },
    [openProductEditingModal],
  )

  const handleDelete = useCallback(
    ({ selected }) => {
      showModal(
        <DeleteTicketProductsConfirmationModal
          list={selected}
          onDelete={async ({ unmount, list }) => {
            try {
              const ticketProductIds = pluck("id", list)
              await deleteTicketProducts({
                ticketId,
                ticketProductIds: ticketProductIds,
              })
              removeProductsFromLocalList(ticketProductIds)
              fetchTicketLogEntries()
              showSuccessMessage()
              unmount()
            } catch (error) {
              const tokenWithList =
                list.length > 1
                  ? localizationKey("Error while deleting ticket products")
                  : localizationKey("Error while deleting ticket product")

              reportErrorAndShowMessage(error, tokenWithList)
            }
          }}
        />,
        { withProvider: true },
      )
    },
    [ticketId, removeProductsFromLocalList, fetchTicketLogEntries],
  )

  return (
    <Card>
      <DataTable
        loading={loading}
        tableId="ticket-products-table"
        rows={ticketProducts}
        columns={getColumns({ currency })}
        onRefresh={getTicketProducts}
        initialSortBy={[
          {
            id: "name",
            desc: false,
          },
        ]}
        filters={{
          primary: [
            {
              name: "billingStatus",
              type: filterTypes.MULTISELECT,
              labelToken: localizationKey("Status"),
              componentProps: {
                options: getBillingStatusFilterOptions(),
              },
            },
            {
              name: "type",
              type: filterTypes.MULTISELECT,
              labelToken: localizationKey("Type"),
              componentProps: {
                options: getSupportedTypeFilterOptions(),
              },
            },
            {
              name: "technician",
              type: filterTypes.SEARCHABLE_SELECT,
              labelToken: localizationKey("Technician"),
              filter: ({ row, value }) => {
                return compose(includes(row.technician.id), pluck("id"))(value)
              },
              componentProps: {
                rowHeight: 50,
                pageSize: 50,
                placeholderToken: localizationKey("Search"),
                valueSelectorKey: "uid",
                dataFormatter: ({ displayName, ...rest }) => ({ label: displayName, ...rest }),
                endpoint: "/app-user-contact/search",
                searchParams: ({ query: searchCriteria }) => ({
                  userType: "TECHNICIAN",
                  ...(searchCriteria && { searchCriteria }),
                }),
                rowRenderer: ({ label, userType, email }) => {
                  return (
                    <Flex flexDirection="column" justifyContent="center">
                      <Body fontWeight={tokens.typography.fontWeight.semiBold}>{defaultToDash(label)}</Body>
                      <Body>{defaultToDash(email)}</Body>
                    </Flex>
                  )
                },
              },
            },
          ],
        }}
        actions={{
          primary: [
            {
              labelToken: localizationKey("View"),
              action: handleView,
              hideMultiAction: selected =>
                selected?.length > 1 || selected?.some(({ billingStatus }) => billingStatus === "PENDING"),
              hideRowAction: row => row?.billingStatus === "PENDING",
            },
            {
              labelToken: localizationKey("Edit"),
              action: handleEdit,
              hideMultiAction: selected =>
                !isTicketEditable ||
                !doesUserHaveTicketProductPermission({ update: true }) ||
                selected?.length > 1 ||
                selected?.some(({ billingStatus }) => billingStatus === "BILLED"),
              hideRowAction: row =>
                !isTicketEditable ||
                !doesUserHaveTicketProductPermission({ update: true }) ||
                row?.billingStatus === "BILLED",
            },
            {
              splitBefore: true,
              variant: "danger",
              isRed: true,
              labelToken: localizationKey("Delete"),
              action: handleDelete,
              hideMultiAction: selected =>
                !isTicketEditable ||
                !doesUserHaveTicketProductPermission({ delete: true }) ||
                selected?.some?.(({ billingStatus }) => billingStatus === "BILLED"),
              hideRowAction: row =>
                !isTicketEditable ||
                !doesUserHaveTicketProductPermission({ delete: true }) ||
                row?.billingStatus === "BILLED",
            },
          ],
        }}
        {...{
          ...(isUserAllowedToCreateTicketProducts && {
            globalActionsButton: {
              ...(!agreementId && {
                disabled: true,
                tooltipToken: localizationKey("The ticket requires a valid Agreement in order to add products"),
              }),
              ...(isUserAllowedToUseNinjaPSAAdministrativeActions()
                ? {
                    actions: [
                      { labelToken: localizationKey("New"), action: createNewProduct },
                      { labelToken: localizationKey("Existing"), action: addExistingProduct },
                      { labelToken: localizationKey("Non Catalog"), action: createNewNonCatalogProduct },
                    ],
                  }
                : {
                    buttonProps: {
                      action: addExistingProduct,
                    },
                  }),
            },
          }),
        }}
      />
    </Card>
  )
})
