import { always, compose, isNil, mapObjIndexed, omit, values, when } from "ramda"

// Routes
import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import { isFeatureEnabled } from "js/includes/common/utils"

export const ticketingRoutes = {
  ticketing: "/ticketing",
  ticket: "/ticketing/ticket/:id",
  ticketWithForm: "/ticketing/ticket/new/:formId",
  ticketBoardView: "/ticketing/board/:id",
  ticketBoardEdit: "/ticketing/board/edit/:id",
  ticketBoardNew: "/ticketing/board/new",
  pendingEmails: "/ticketing/pendingEmails",
}

export const ninjaPSARoutes = {
  ninjaPSA: "/ninjaPsa",
  invoices: "/ninjaPsa/invoices",
  agreements: "/ninjaPsa/agreements",
  products: "/ninjaPsa/products",
  timeEntries: "/ninjaPsa/timeEntries",
}

export const ninjaPSAEditorRoutes = {
  ninjaPSAQuickBooksEditorItems: "/editor/ninjaPsa/quickBooks/items",
  ninjaPSAQuickBooksEditorAccounts: "/editor/ninjaPsa/quickBooks/accounts",
  ninjaPSAQuickBooksEditorCustomers: "/editor/ninjaPsa/quickBooks/customers",
  ninjaPSAQuickBooksEditorTaxes: "/editor/ninjaPsa/quickBooks/taxes",
}

export const vmDashboardRoutes = {
  vmDashboard: "/vmDashboard/:id/(overview|details|settings|vmlist|activities|customFields|ticketing)",
  vmGuestDashboard: "/vmGuestDashboard/:id/(overview|details|settings|activities|customFields|ticketing)",
}

export const unmanagedDeviceRoutes = {
  unmanagedDeviceDashboard: "/unmanagedDeviceDashboard/:id/(overview|settings|activities|customFields)/:subtab?",
}

export const deviceSearchRoutes = {
  groupSearch: "/group/:id",
  deviceSearch: "/deviceSearch",
}

export const endUserSearchRoutes = {
  endUserSearch: "/endUserSearch",
  endUserSearchDetails: "/endUserSearch/:id/(overview)",
}

export const endUserEditorRoutes = {
  globalEndUser: "/editor/endUser/:id(\\d+)?",
  endUserEditorGeneral: "/editor/endUser/:id(\\d+)/general",
  endUserEditorRoles: "/editor/endUser/:id(\\d+)/roles",
  endUserEditorDevices: "/editor/endUser/:id(\\d+)/devices",
  endUserEditorPermissions: "/editor/endUser/:id(\\d+)/permissions/(connections|backup|eusTicketing)",
  newEndUserEditorGeneral: "/editor/endUser/general",
  newEndUserEditorRoles: "/editor/endUser/roles",
  newEndUserEditorDevices: "/editor/endUser/devices",
  newEndUserEditorPermissions: "/editor/endUser/permissions/(connections|backup|eusTicketing)",
  orgEndUser: "/editor/customer/:organizationId/endUser/:id(\\d+)?",
  orgNewEndUser: "/editor/customer/:organizationId/endUser/general",
  orgNewEndUserRoles: "/editor/customer/:organizationId/endUser/roles",
  orgNewEndUserDevices: "/editor/customer/:organizationId/endUser/devices",
  orgNewEndUserPermissions: "/editor/customer/:organizationId/endUser/permissions/(connections|backup|eusTicketing)",
  orgEndUserGeneral: "/editor/customer/:organizationId/endUser/:id(\\d+)/general",
  orgEndUserRole: "/editor/customer/:organizationId/endUser/:id(\\d+)/roles",
  orgEndUserDevices: "/editor/customer/:organizationId/endUser/:id(\\d+)/devices",
  orgEndUserPermissions:
    "/editor/customer/:organizationId/endUser/:id(\\d+)/permissions/(connections|backup|eusTicketing)",
  remoteSupport: "/remoteSupport",
}

export const roleEditorRoutes = {
  roleEditor: "/editor/role/:id(\\d+)?",
  roleEditorGeneral: "/editor/role/:id(\\d+)/general",
  roleEditorMembers: "/editor/role/:id(\\d+)/members",
  roleEditorPermissions:
    "/editor/role/:id(\\d+)/permissions/(system|organizations|devices|policies|scriptLibrary|ticketing|ninjaPSA|documentation|connections|backup|eusTicketing|mdmConnections)",
  newRoleEditorGeneral: "/editor/role/general",
  newRoleEditorMembers: "/editor/role/members",
  newRoleEditorPermissions:
    "/editor/role/permissions/(system|organizations|devices|policies|scriptLibrary|ticketing|ninjaPSA|documentation|connections|backup|eusTicketing|mdmConnections)",
}

export const userEditorRoutes = {
  userEditor: "/editor/user/:id(\\d+)?",
  userEditorGeneral: "/editor/user/:id(\\d+)/general",
  userEditorRole: "/editor/user/:id(\\d+)/roles",
  userEditorPermissions:
    "/editor/user/:id(\\d+)/permissions/(system|organizations|devices|policies|scriptLibrary|ticketing|ninjaPSA|documentation|mdmConnections)",
  newUserEditorGeneral: "/editor/user/general",
  newUserEditorRole: "/editor/user/roles",
  newUserEditorPermissions:
    "/editor/user/permissions/(system|organizations|devices|policies|scriptLibrary|ticketing|ninjaPSA|documentation|mdmConnections)",
}

export const mdmDashboardRoutes = {
  mdmDashboard:
    "/mobileDashboard/:id/(overview|details|settings|security|locationTracking|activities|software|customFields)/:subtab?",
}

export const appIntegrationsRoutes = {
  apps: "/administration/apps",
  appConfiguration: "/administration/apps/:appName/:tabName?",
}

export const getStartedRoutes = {
  getStarted: "/getStarted",
}

export const reportingRoutes = {
  reporting: "/reporting",
  reportLibrary: "/reporting/reports",
  reportSchedules: "/reporting/schedules",
  reportHistory: "/reporting/history",
  reportSettings: "/reporting/settings",
  report: "/reporting/reports/:id/:mode?",
}

export const systrayEditorRoutes = {
  newSystray: "/editor/systray",
  editSystray: "/editor/systray/:id",
}

export const agreementEditorRoutes = {
  agreementEditorGeneral: "/editor/agreement/:id(\\d+)/general",
  agreementEditorBilling: "/editor/agreement/:id(\\d+)/billing",
  agreementEditorProducts: "/editor/agreement/:id(\\d+)/products",
  agreementEditorEmail: "/editor/agreement/:id(\\d+)/email",
  agreementEditorQuickBooks: "/editor/agreement/:id(\\d+)/quickbooks",
  newAgreementEditorGeneral: "/editor/agreement/general",
  newAgreementEditorBilling: "/editor/agreement/billing",
  newAgreementEditorProducts: "/editor/agreement/products",
  newAgreementEditorEmail: "/editor/agreement/email",
  newAgreementEditorQuickBooks: "/editor/agreement/quickbooks",
}

export const agreementTemplateEditorRoutes = {
  agreementTemplateEditorGeneral: "/editor/agreementTemplate/:id(\\d+)/general",
  agreementTemplateEditorBilling: "/editor/agreementTemplate/:id(\\d+)/billing",
  agreementTemplateEditorProducts: "/editor/agreementTemplate/:id(\\d+)/products",
  newAgreementTemplateEditorGeneral: "/editor/agreementTemplate/general",
  newAgreementTemplateEditorBilling: "/editor/agreementTemplate/billing",
  newAgreementTemplateEditorProducts: "/editor/agreementTemplate/products",
}

export const invoiceEditorRoutes = {
  invoiceEditorGeneral: "/editor/invoice/:id(\\d+)/general",
  invoiceEditorInvoiceTable: "/editor/invoice/:id(\\d+)/invoiceTable",
}

export const administrationRoutes = {
  ...appIntegrationsRoutes,
  general: "/administration/general/:subtab/:thirdtab?",
  users: "/administration/users/:subtab/:thirdtab?",
  integrations: "/administration/integrations/:subtab",
  customers: "/administration/customers/:subtab",
  reports: "/administration/reports/:subtabName",
  devices: "/administration/devices/:tab/:subtab?/:thirdtab?",
  policies: "/administration/policies/:subtab",
  tasks: "/administration/tasks",
  knowledgeBase: "/administration/library/documentation/knowledgeBase(.*)",
  scripting: "/administration/library/scripting/:subtab",
  library: "/administration/library/:subtab",
  legacyConfigurationRoutes: "/configuration/:tab/:subtab?/:thirdtab?", // used for redirects
}

export const globalSearchRoutes = {
  globalSearch: "/search",
}

export const quickConnectRoutes = {
  quickConnect: "/quickConnect",
  quickConnectDevices: "/quickConnect/devices",
  quickConnectInvitations: "/quickConnect/invitations",
  quickConnectActivities: "/quickConnect/activities",
}

export const quickConnectTabs = {
  quickConnect: localizationKey("Quick Connect"),
  devices: localizationKey("Devices"),
  invitations: localizationKey("Invitations"),
  activities: localizationKey("Activities"),
}

export const DASHBOARD_TYPES = {
  SYSTEM: "systemDashboard",
  CUSTOMER: "customerDashboard",
  DEVICE: "deviceDashboard",
  VM: "vmDashboard",
  VM_GUEST: "vmGuestDashboard",
  CLOUD: "cloudMonitorDashboard",
  NMS: "nmsDashboard",
  MOBILE: "mobileDashboard",
  UNMANAGED_DEVICE: "unmanagedDeviceDashboard",
}

export const PATCH_TYPES = {
  INSTALLED: "Installed",
  APPROVED: "Approved",
  FAILED: "Failed",
  PENDING: "Pending",
}

// Label tokens by route

const commonTabs = {
  overview: localizationKey("Overview"),
  activities: localizationKey("Activities"),
}

export const dashboardTabs = {
  [DASHBOARD_TYPES.SYSTEM]: {
    overview: localizationKey("Overview"),
    organizations: localizationKey("Organizations"),
    software: localizationKey("Software Patches"),
    osPatches: localizationKey("OS Patches"),
    maintenance: localizationKey("Maintenance"),
    alerts: localizationKey("Alerts"),
    threats: localizationKey("Threats"),
    approvals: localizationKey("Approvals"),
    backup: localizationKey("Backup"),
    activities: localizationKey("Activities"),
    pendingScripts: localizationKey("Pending Automations"),
    devices: localizationKey("Devices"),
    knowledgeBase: localizationKey("Knowledge Base"),
    agentInstallers: localizationKey("Agent Installers"),
    vulnerabilities: localizationKey("Vulnerabilities"),
  },
  [DASHBOARD_TYPES.CUSTOMER]: {
    ...commonTabs,
    location: localizationKey("Location"),
    software: localizationKey("Software Patches"),
    osPatches: localizationKey("OS Patches"),
    maintenance: localizationKey("Maintenance"),
    alerts: localizationKey("Alerts"),
    threats: localizationKey("Threats"),
    approvals: localizationKey("Approvals"),
    backup: localizationKey("Backup"),
    documentation: localizationKey("Documentation"),
    customFields: localizationKey("Custom Fields"),
    ticketing: localizationKey("Ticketing"),
    pendingScripts: localizationKey("Pending Automations"),
    finance: localizationKey("Finance"),
    devices: localizationKey("Devices"),
    vulnerabilities: localizationKey("Vulnerabilities"),
    agentInstallers: localizationKey("Agent Installers"),
  },
  [DASHBOARD_TYPES.DEVICE]: {
    ...commonTabs,
    details: localizationKey("Details"),
    settings: localizationKey("Settings"),
    software: localizationKey("Software"),
    osPatches: localizationKey("OS Patches"),
    tools: localizationKey("Tools"),
    backup: localizationKey("Backup"),
    customFields: localizationKey("Custom Fields"),
    ticketing: localizationKey("Ticketing"),
    vulnerabilities: localizationKey("Vulnerabilities"),
  },
  [DASHBOARD_TYPES.NMS]: {
    ...commonTabs,
    netflow: localizationKey("NetFlow"),
    details: localizationKey("Details"),
    settings: localizationKey("Settings"),
    customFields: localizationKey("Custom Fields"),
    ticketing: localizationKey("Ticketing"),
  },
  [DASHBOARD_TYPES.CLOUD]: {
    overview: localizationKey("Overview"),
    customFields: localizationKey("Custom Fields"),
    ticketing: localizationKey("Ticketing"),
  },
  [DASHBOARD_TYPES.VM_GUEST]: {
    ...commonTabs,
    details: localizationKey("Details"),
    settings: localizationKey("Settings"),
    vmlist: localizationKey("VM List"),
    customFields: localizationKey("Custom Fields"),
    ticketing: localizationKey("Ticketing"),
  },
  [DASHBOARD_TYPES.VM]: {
    ...commonTabs,
    details: localizationKey("Details"),
    settings: localizationKey("Settings"),
    vmlist: localizationKey("VM List"),
    customFields: localizationKey("Custom Fields"),
    ticketing: localizationKey("Ticketing"),
  },
  [DASHBOARD_TYPES.MOBILE]: {
    ...commonTabs,
    details: localizationKey("Details"),
    settings: localizationKey("Settings"),
    software: localizationKey("Software"),
    customFields: localizationKey("Custom Fields"),
    locationTracking: localizationKey("Location Tracking"),
  },
  [DASHBOARD_TYPES.UNMANAGED_DEVICE]: {
    ...commonTabs,
    settings: localizationKey("Settings"),
    customFields: localizationKey("Custom Fields"),
  },
}

export const windowsToolsOptions = {
  taskManager: localizationKey("Task Manager"),
  fileBrowser: localizationKey("File Browser"),
  services: localizationKey("Service Manager"),
  remoteRegistry: localizationKey("Remote Registry"),
  activeDirectory: localizationKey("Active Directory"),
}

export const macToolsOptions = {
  taskManager: localizationKey("Task Manager"),
  fileBrowser: localizationKey("File Browser"),
  services: localizationKey("Service Manager"),
}

export const linuxToolsOptions = {
  taskManager: localizationKey("Task Manager"),
  fileBrowser: localizationKey("File Browser"),
  services: localizationKey("Service Manager"),
}

export const deviceBackupOptions = {
  overview: localizationKey("Overview"),
  manage: "general.manage",
}

export const organizationBackupOptions = {
  overview: localizationKey("Overview"),
  history: localizationKey("History"),
  running: localizationKey("Running"),
}

export const systemBackupOptions = {
  overview: "general.overview",
  history: "general.history",
  running: localizationKey("Running"),
  usage: localizationKey("Usage"),
}

export const DetailsSubTab = {
  agent: "agent",
  mdm: "mdm",
}

export const dashboardSubTabs = {
  details: {
    [DetailsSubTab.agent]: localizationKey("Agent"),
    [DetailsSubTab.mdm]: localizationKey("MDM"),
  },
  software: {
    pending: localizationKey("Pending"),
    approved: localizationKey("Approved"),
    rejected: localizationKey("Rejected"),
    installed: localizationKey("Installed"),
    failed: localizationKey("Failed"),
  },
  osPatches: {
    overview: localizationKey("Overview"),
    pending: localizationKey("Pending"),
    approved: localizationKey("Approved"),
    rejected: localizationKey("Rejected"),
    installed: localizationKey("Installed"),
    failed: localizationKey("Failed"),
  },
  threats: {
    active: localizationKey("Active/Blocked"),
    quarantined: localizationKey("Quarantined"),
  },
  approvals: {
    pending: localizationKey("Pending"),
    rejected: localizationKey("Rejected"),
  },
  activities: {
    all: localizationKey("All"),
    deletedDevices: localizationKey("Deleted Devices"),
    endUserLoggedInOrOut: localizationKey("End User Logged In or Out"),
    policyChanges: localizationKey("Policy Changes"),
    remoteControl: localizationKey("Remote Control"),
    remoteTools: localizationKey("Remote Tools"),
    softwareAddedOrRemoved: localizationKey("Software Added/Removed"),
    patchManagement: localizationKey("Patch Management"),
  },
  documentation: {
    appsAndServices: localizationKey("Apps & Services"),
    knowledgeBase: localizationKey("Knowledge Base"),
    checklists: localizationKey("Checklists"),
  },
  backup: {
    ...deviceBackupOptions,
    ...systemBackupOptions,
    scheduledDeletes: localizationKey("Scheduled Deletes"),
  },
  tools: {
    ...windowsToolsOptions,
    ...macToolsOptions,
    ...linuxToolsOptions,
  },
  finance: {
    agreements: localizationKey("Agreements"),
    invoices: localizationKey("Invoices"),
    timeEntries: localizationKey("Time Entries"),
  },
  manage: {
    maintenance: localizationKey("Maintenance"),
    alerts: localizationKey("Alerts"),
    pendingScripts: localizationKey("Pending Scripts"),
  },
}

export const getDashboardTabToken = (dashboard, tabName) => dashboardTabs[dashboard][tabName]
export const getDashboardSubTabToken = (subRoute, subSubRoute) => dashboardSubTabs[subRoute][subSubRoute]

export const getOSPatchesInstalledRoute = (key, entityId) => {
  switch (key) {
    case PATCH_TYPES.INSTALLED:
      return isNil(entityId)
        ? `/systemDashboard/osPatches/installed`
        : `/customerDashboard/${entityId}/osPatches/installed`
    case PATCH_TYPES.APPROVED:
      return isNil(entityId)
        ? `/systemDashboard/osPatches/approved`
        : `/customerDashboard/${entityId}/osPatches/approved`
    case PATCH_TYPES.FAILED:
      return isNil(entityId) ? `/systemDashboard/osPatches/failed` : `/customerDashboard/${entityId}/osPatches/failed`
    default:
      return isNil(entityId) ? `/systemDashboard/osPatches/pending` : `/customerDashboard/${entityId}/osPatches/pending`
  }
}

export const getSoftwarePatchesRoutes = tabRoute =>
  compose(
    values,
    mapObjIndexed((token, subTabName) => ({
      subTabName,
      token,
      route: `${tabRoute}/${subTabName}`,
    })),
  )(dashboardSubTabs.software)

export const getOSPatchesRoutes = (tabRoute, isDeviceTab) =>
  compose(
    values,
    mapObjIndexed((token, subTabName) => ({
      subTabName,
      token,
      route: `${tabRoute}/${subTabName}`,
    })),
    when(always(isDeviceTab), omit(["overview"])),
    when(always(!isFeatureEnabled("os_patching_dashboard")), omit(["overview"])),
  )(dashboardSubTabs.osPatches)
