import { handleFormFieldBlur, handleFormFieldChange } from "js/includes/components/CustomFields/common"
import { TextArea } from "@ninjaone/components"
import { localized } from "js/includes/common/utils"

export default function TextAreaFormField({
  id,
  value,
  onChange,
  onBlur,
  isHookForm = false,
  validateFormFields,
  validationMessage,
  technicianPermission,
  label,
  required,
  tooltipText,
}) {
  return (
    <TextArea
      componentClass="textarea"
      labelText={localized(label)}
      maxLength={10000}
      minHeight="122px"
      resize="vertical"
      value={value ?? ""}
      onBlur={handleFormFieldBlur({ onBlur, id, isHookForm, validateFormFields })}
      onChange={e =>
        handleFormFieldChange({
          id,
          onChange,
          isHookForm,
        })(e.target.value || null)
      }
      disabled={technicianPermission !== "EDITABLE"}
      required={required}
      errorMessage={validationMessage}
      tooltipText={tooltipText}
    />
  )
}
