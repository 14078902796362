import moment from "moment"
import { getDateTimeLocale, localized } from "js/includes/common/utils"
import {
  ENABLE_CLICK_ON_PORTAL_CLASS_NAME,
  handleFormFieldBlur,
  handleFormFieldChange,
} from "js/includes/components/CustomFields/common"
import { TimePicker } from "@ninjaone/components"

export default function TimeFormField({
  id,
  value,
  onChange,
  onBlur,
  isHookForm = false,
  validationState,
  validationMessage,
  fixedRdtPicker,
  validateFormFields,
  technicianPermission,
  label,
  required,
  tooltipText,
}) {
  const isDisabled = technicianPermission !== "EDITABLE"

  const handleChange = handleFormFieldChange({ onChange, id, isHookForm })

  return (
    <TimePicker
      labelText={localized(label)}
      value={value ? new Date(value) : ""}
      onTimeChange={datetime => {
        const date = getDateTimeLocale(datetime)
        handleChange(date.isValid() ? moment(datetime).valueOf() : null)
      }}
      onBlur={handleFormFieldBlur({ onBlur, id, isHookForm, validateFormFields })}
      disabled={isDisabled}
      errorMessage={validationMessage}
      required={required}
      tooltipText={tooltipText}
      forwardedClassName={ENABLE_CLICK_ON_PORTAL_CLASS_NAME}
    />
  )
}
