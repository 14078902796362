import { useCallback, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import PropTypes from "prop-types"
import { T, always, compose, cond, equals, includes, isEmpty, isNil, map, pluck, toLower, trim, values } from "ramda"
import styled from "@emotion/styled"
import { Body, Button, DataTable, Input, SearchBar, Text } from "@ninjaone/components"
import { SearchIcon, ExternalLinkIcon } from "@ninjaone/icons"
import { sizer } from "@ninjaone/utils"
import { spacing } from "@ninjaone/tokens"

import { Box, Flex } from "js/includes/components/Styled"
import { useMountedState } from "js/includes/common/hooks"
import {
  getAppleAppName,
  isEnterKey,
  localizationKey,
  localized,
  reportErrorAndShowMessage,
  showSuccessMessage,
  showWarningMessage,
} from "js/includes/common/utils"
import { getAppsAndBooksApplications, searchIOSApplications } from "js/includes/common/client"
import countries from "js/includes/common/_countries"
import showModal from "js/includes/common/services/showModal"
import { openExternalLinkDialog } from "js/includes/components/ExternalLink"
import SearchableDropDown from "js/includes/components/SearchableDropDown"

import InstallationMethodModal from "./InstallationMethodModal"
import ViewOrganizationsModal from "./ViewOrganizationsModal"

const StyledBox = styled(Box)`
  margin: ${sizer(0, 2)};

  [data-ninja-select-trigger] {
    height: 44px;
  }
`

const OpenAppStoreComponent = () => (
  <Flex alignItems="center" justifyContent="between">
    <Body color="colorTextAction">{localized("View in app store")}</Body>
    <Box marginLeft={sizer(1)} marginTop="1px">
      <ExternalLinkIcon size="xs" color="colorTextAction" />
    </Box>
  </Flex>
)

export const SearchAppsForm = ({ unmount, installedApps = [], onAddApp, type, isVPP }) => {
  const locationOptions = map(country => ({ value: country.code, label: localized(country.name) }), countries)
  const LOCATION_DEFAULT_VALUE = { label: localized("United States"), value: "US" }

  const [searchText, setSearchText] = useState("")
  const [locationSearch, setLocationSearch] = useState(LOCATION_DEFAULT_VALUE)
  const [applications, setApplications] = useMountedState([])
  const [loadingSearch, setLoadingSearch] = useMountedState(false)
  const [initialLoadCompleted, setInitialLoadCompleted] = useMountedState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [filteredVPPApps, setFilteredVppApps] = useMountedState([])

  const applicationsState = useSelector(state => state.policyEditor.policy?.content?.application?.applications || {})
  const nodeClass = useSelector(state => state.policyEditor.policy.nodeClass)

  const columns = useMemo(
    () => [
      {
        id: "name",
        Header: localized("Name"),
        accessor: app => getAppleAppName(app),
        getImg: ({ iconUrl512 }) => (iconUrl512 ? { src: iconUrl512, size: "40" } : null),
        maxWidth: "288px",
      },
      {
        id: "publisher",
        Header: localized("Seller"),
        accessor: "publisher",
        maxWidth: "256px",
      },
      {
        id: "formattedPrice",
        Header: localized("Cost"),
        accessor: ({ formattedPrice, currency }) =>
          cond([
            [isNil, always("-")],
            [compose(equals("free"), toLower), always(localized("Free"))],
            [T, always(trim(`${formattedPrice} ${currency || ""}`))],
          ])(formattedPrice),
      },
      {
        id: "minimumOsVersion",
        Header: localized("Minimum OS support"),
        accessor: "minimumOsVersion",
      },
      ...(isVPP
        ? [
            {
              id: "distributionType",
              Header: localized("Distribution type"),
              accessor: () => localized("Apps and Books"),
            },
          ]
        : []),
    ],
    [isVPP],
  )

  const loadVPPApps = useCallback(async () => {
    setLoadingSearch(true)
    try {
      const apps = await getAppsAndBooksApplications({ nodeClass })
      setApplications(apps)
      setFilteredVppApps(apps)
    } catch (error) {
      if (error.resultCode === "content_token_not_found") {
        showWarningMessage(
          localized("Apps failed to load. Please verify your Content Tokens and ensure assets are added."),
        )
      } else {
        reportErrorAndShowMessage(error, localizationKey("Error while loading Apps and Books"))
      }
    } finally {
      setLoadingSearch(false)
    }
  }, [nodeClass, setApplications, setFilteredVppApps, setLoadingSearch])

  useEffect(() => {
    if (isVPP) loadVPPApps()
  }, [isVPP, loadVPPApps])

  const isAlreadyInstalled = appId => includes(appId, pluck("applicationId", values(applicationsState)))

  const searchApplications = async () => {
    if (isEmpty(searchText.trim())) {
      setErrorMessage(localized("Required"))
      return
    }

    setErrorMessage(null)
    setLoadingSearch(true)
    setApplications([])

    const apps = await searchIOSApplications(searchText, locationSearch?.value?.toLowerCase())
    const mappedApps = map(
      app => ({
        ...app,
        installed: isAlreadyInstalled(app.id),
      }),
      apps,
    )
    setApplications(mappedApps)
    setLoadingSearch(false)
    setInitialLoadCompleted(true)
  }

  const showInstallMethodModal = selectedApp => {
    if (isAlreadyInstalled(selectedApp.id)) {
      showSuccessMessage(localized("{{appName}} is already added to the policy", { appName: selectedApp.name }))
      return
    }

    showModal(
      <InstallationMethodModal
        {...{ country: locationSearch?.value, selectedApp, type, isVPP }}
        onSelectMethod={app => {
          onAddApp?.({
            ...app,
            name: selectedApp.name,
          })
        }}
      />,
      { withProvider: true },
    )
  }

  const onRefreshTable = () => {
    if (isVPP) {
      loadVPPApps()
    } else {
      searchApplications()
    }
  }

  const filterVPPApps = text => {
    if (isEmpty(text.trim())) {
      setFilteredVppApps(applications)
      return
    }
    const filteredVPPApps = applications.filter(
      ({ name, publisher }) => name?.toLowerCase()?.includes(text) || publisher?.toLowerCase()?.includes(text),
    )
    setFilteredVppApps(filteredVPPApps)
  }

  const showViewOrganizations = selected => {
    const [appData] = selected
    showModal(<ViewOrganizationsModal {...{ appData }} />)
  }

  return (
    <>
      <Flex>
        <Box width="100%">
          {isVPP ? (
            <SearchBar
              placeholderText={localized("Search for app")}
              width="320px"
              onChange={e => filterVPPApps(e.target.value)}
              disabled={loadingSearch}
            />
          ) : (
            <Input
              {...{ errorMessage }}
              ariaLabel={localized("App name or seller")}
              data-testid="search-input-apps-modal"
              placeholder={localized("Type app name or seller")}
              onChange={e => setSearchText(e.target.value)}
              onKeyUp={e => {
                e.stopPropagation()
                isEnterKey(e) && searchApplications()
              }}
            />
          )}
        </Box>
        {!isVPP && (
          <>
            <StyledBox>
              <SearchableDropDown
                minHeight="38px"
                searchableKey="label"
                valueSelectorKey="value"
                options={locationOptions}
                onSelect={setLocationSearch}
                alignRight={false}
                value={locationSearch}
                disabled={isVPP}
              />
            </StyledBox>
            <Button
              maxHeight="46px"
              labelToken={localizationKey("Search")}
              variant="primary"
              onClick={searchApplications}
              disabled={loadingSearch || isVPP}
            />
          </>
        )}
      </Flex>
      <Box height="464px" marginTop={sizer(2)}>
        {loadingSearch || initialLoadCompleted || isVPP ? (
          <DataTable
            tableId="search-mobile-applications-table"
            loading={loadingSearch}
            columns={columns}
            rows={isVPP ? filteredVPPApps : applications}
            onRefresh={onRefreshTable}
            showSearchBar={false}
            noRowsToken={
              isVPP
                ? localizationKey("No applications available in content tokens")
                : localizationKey("No applications found")
            }
            actions={{
              primary: [
                {
                  labelToken: localizationKey("Install app"),
                  action: ({ selected: [selectedApp] }) => {
                    showInstallMethodModal(selectedApp)
                  },
                },
                ...(isVPP
                  ? [
                      {
                        labelToken: localizationKey("View organizations"),
                        action: ({ selected }) => showViewOrganizations(selected),
                      },
                    ]
                  : []),
                {
                  id: "open-in-app-store-action",
                  variant: "secondary",
                  LabelComponent: OpenAppStoreComponent,
                  action: ({ selected: [selectedApp] }) => {
                    const appId = selectedApp?.id
                    openExternalLinkDialog(`https://apps.apple.com/app/id${appId}`, true)
                  },
                },
              ],
              row: {
                action: showInstallMethodModal,
              },
            }}
            hideCheckboxes
            hideSettingsButton
          />
        ) : (
          <Flex
            height="100%"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            borderTopStyle="solid"
            borderTopWidth="1px"
            borderTopColor="ninjaLight"
            marginTop={spacing[4]}
          >
            <SearchIcon size="lg" />
            <Text token={localizationKey("Search by application name or seller")} marginTop={spacing[2]} />
          </Flex>
        )}
      </Box>
    </>
  )
}

SearchAppsForm.propTypes = {
  unmount: PropTypes.func,
  type: PropTypes.oneOf(["POLICY", "ACTION"]).isRequired,
  installedApps: PropTypes.array,
  onAddApp: PropTypes.func.isRequired,
  isVPP: PropTypes.bool,
}
