import React from "react"
import { localized } from "js/includes/common/utils"
import { Select, Body } from "@ninjaone/components"

const options = () => [
  {
    value: "mustContainOneInteger",
    labelText: localized("Must contain one integer"),
  },
  {
    value: "mustContainOneUppercaseLetter",
    labelText: localized("Must contain one uppercase letter"),
  },
  {
    value: "mustContainOneLowercaseLetter",
    labelText: localized("Must contain one lowercase letter"),
  },
  {
    value: "greaterOrEqualThanSixCharacters",
    labelText: localized("Must contain at least six characters"),
  },
]

export default function SecureFieldComplexityRules({ onChange, value }) {
  const selectedValues = Object.keys(value).filter(key => value[key])

  const handleSelectionChange = selected => {
    onChange({
      ...value,
      [selected]: !value[selected],
    })
  }

  return (
    <Select
      options={options()}
      onChange={handleSelectionChange}
      value={selectedValues}
      labelRenderer={() => (
        <Body color={!selectedValues.length ? "colorTextWeakest" : "colorTextStrong"}>
          {selectedValues.length > 0
            ? localized("{{count}} selected", { count: selectedValues.length })
            : localized("Select rules")}
        </Body>
      )}
      triggerAriaLabel={localized("Complexity rules")}
    />
  )
}
