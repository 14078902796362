import { useEffect } from "react"
import qs from "qs"
import { isNil } from "ramda"
import { fetchJson, reportErrorAndShowMessage } from "js/includes/common/utils"
import { Box } from "js/includes/components/Styled"
import { useMountedState } from "js/includes/common/hooks"
import Loading from "js/includes/components/Loading"
import { Body, Link } from "@ninjaone/components"

export default function SingleCustomerValueRenderer(field, { id, value } = {}, renderTextOnly) {
  const [loading, setLoading] = useMountedState(false)
  const [client, setClient] = useMountedState(null)

  useEffect(() => {
    const getInitialSelection = async () => {
      try {
        setLoading(true)

        const _qs = qs.stringify({
          id: value?.entityId,
        })

        const [client] = await fetchJson(`${"/client"}?${_qs}`)

        setClient(client)
      } catch (error) {
        reportErrorAndShowMessage(error)
      } finally {
        setLoading(false)
      }
    }

    if (value?.entityId) {
      getInitialSelection()
    }
  }, [value?.entityId, setLoading, setClient])

  return loading ? (
    <Loading />
  ) : isNil(value) || isNil(client) ? null : renderTextOnly ? (
    <Body>{client?.name}</Body>
  ) : (
    <Box key={`${client?.id}-client`}>
      <Link href={`#/customerDashboard/${client?.id}/overview`}>
        <Body color="inherit">{client?.name}</Body>
      </Link>
    </Box>
  )
}
