import React from "react"
import { clamp } from "ramda"
import { Stepper } from "@ninjaone/components"
import { localized } from "js/includes/common/utils"

export default function FileMaxSizeMb({ option: { minValue, maxValue }, onChange, value }) {
  return (
    <Stepper
      compact
      ariaLabel={localized("File Max Size (MB)")}
      step={2}
      min={minValue}
      max={maxValue}
      value={value}
      onKeyPress={e => {
        const ALLOWED_CHARS_REGEXP = /[-\d]/

        if (!ALLOWED_CHARS_REGEXP.test(e.key)) {
          e.preventDefault()
        }
      }}
      onChange={value => onChange(clamp(minValue, maxValue, value))}
    />
  )
}
