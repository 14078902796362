import { isEmpty, isNil } from "ramda"
import { MAX_INTEGER_VALUE, MIN_INTEGER_VALUE } from "js/includes/common/_constants"
import { handleFormFieldBlur, handleFormFieldChange } from "js/includes/components/CustomFields/common"
import { Input } from "@ninjaone/components"
import { localized } from "js/includes/common/utils"

export default function NumericFormField({
  id,
  value,
  onChange,
  onBlur,
  isHookForm = false,
  advancedSettings,
  validateFormFields,
  validationMessage,
  technicianPermission,
  label,
  required,
  tooltipText,
}) {
  return (
    <Input
      type="number"
      step="1"
      labelText={localized(label)}
      min={advancedSettings.numericRange.min ?? MIN_INTEGER_VALUE}
      max={advancedSettings.numericRange.max ?? MAX_INTEGER_VALUE}
      value={isNil(value) || isNaN(value) ? "" : value}
      onKeyPress={e => {
        const ALLOWED_CHARS_REGEXP = /[-\d]/

        if (!ALLOWED_CHARS_REGEXP.test(e.key)) {
          e.preventDefault()
        }
      }}
      onInput={e => {
        const { valid } = e.target.validity
        const userInput = e.target.value
        let _value
        if (isEmpty(userInput)) _value = null
        else if (valid === false) _value = value
        else _value = parseFloat(userInput, 10)

        return handleFormFieldChange({ onChange, id, isHookForm })(_value)
      }}
      onBlur={handleFormFieldBlur({ onBlur, id, isHookForm, validateFormFields })}
      disabled={technicianPermission !== "EDITABLE"}
      required={required}
      errorMessage={validationMessage}
      tooltipText={tooltipText}
    />
  )
}
